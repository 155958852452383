import * as userActions from '../../reducers/user';
import Logger from '../../../services/Logger';
import { normalizeUserAvatarSrc } from '../../helpers/teammates';
import sdk from '../../../sdk';
import {
  checkIsKeywordsActionsAllowed,
  checkIsSyncAllowed,
  initAmplitude,
  getUserStorageName,
} from '../../helpers/user';

import { setSort } from '../../keywords/actions';
import { getPage } from '../../keywordsList/actions';
import { setRequiredFields } from '../../import/actions';

const getUser = () => async (dispatch, getState) => {
  try {
    dispatch(userActions.getUserStart());
    const { data: response } = await sdk.users.current();
    if (response && response.user) {
      if (response.authenticatedUsers) {
        const {
          _id,
          email,
          avatar,
          displayName,
          role,
          parent,
          team,
        } = response.user;

        Logger.setUser({ email: response.user.email, displayName: response.user.displayName });

        const currentUser = {
          _id,
          email,
          displayName,
          avatar: normalizeUserAvatarSrc(avatar, 'medium'),
          roleName: !parent ? 'Team owner' : role.name,
          current: true,
        };
        if (team) {
          currentUser.teamName = (team.policies && team.policies.teamName) || team.displayName;
          currentUser.teamLogo = team.logoUrl;
        }

        dispatch(
          userActions.setAuthorizedUsers([{ ...currentUser }, ...response.authenticatedUsers]),
        );
      }

      const { user } = response;

      if (user.avatar) {
        user.avatarOriginal = user.avatar;
        user.avatar = normalizeUserAvatarSrc(user.avatar, 'large', true);
      }
      if (!user.settings) user.settings = {};
      if (!user.team.policies) user.team.policies = {};
      if (!user.team.policies.teamName) user.team.policies.teamName = 'My team';
      user.isTeammate = !!(user.parent && user.parent.confirmed === true);
      user.isSyncAllowed = checkIsSyncAllowed(user.role, user, getState);
      user.isKeywordsActionsAllowed = checkIsKeywordsActionsAllowed(
        user.role.permissions,
        user.team.policies,
      );
      user.ignoreLockMetadataFields = user.team?.settings?.metadater?.ignoreLockMetadataFields
        || false;
      user.liveChatIconSeen = user.achievements?.liveChatIconSeen || false;
      if (!user.team.settings?.okta) user.team.settings.okta = {};

      if (user.isTeammate) {
        user.picsioStorage = user.team.picsioStorage;
      }

      if (user.stealth) {
        Logger.disableAmplitude();
      } else {
        initAmplitude(user);
      }
      user.storageName = getUserStorageName(user.team);

      if (!user?.settings?.timeZone) {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        user.settings.timeZone = { userTimeZone, autoDetect: true };
        await sdk.users.update({ settings: { ...user.settings } });
      }
      // set keywords sortType to keywords store, from user.keywordsSortType
      const { keywordsSortType } = user;
      if (keywordsSortType && typeof keywordsSortType === 'object') {
        dispatch(setSort({ ...keywordsSortType, preventFetch: true }));
      }

      /** Get first page for keywords dropdown */
      dispatch(getPage());

      dispatch(userActions.getUserSuccess({ user }));

      /** Set required fields for import */
      dispatch(setRequiredFields({ role: user.role, features: user.subscriptionFeatures }));
    }
  } catch (err) {
    Logger.setUser({ email: 'undefined email', displayName: 'undefined pics.io user' });
    Logger.error('User can not be fetched from backend.');
    dispatch(userActions.getUserFailure(err));
    throw err;
  }
};

export default getUser;

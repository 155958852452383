import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { createRoot } from 'react-dom/client';
import {
  Menu,
  MenuItem,
  MenuItemIcon,
  MenuItemText,
  Icon,
  Button,
} from '@picsio/ui';
import { Computer, Link, CreateProduct } from '@picsio/icons';
import { useSelector } from 'react-redux';
import l10n from '../../../../shared/strings';
import Logger from '../../../../services/Logger';
import { showDialog } from '../../../../components/dialog';
import AddFromUrlDialog from '../../../../components/AddFromUrlDialog';
import DropboxChooser from '../../../../components/DropboxChooser';
import picsioConfig from '../../../../../../../config';
import useCreateProduct from '../useCreateProduct';

export default function useImportMenu({
  targetRef,
  placement = 'right-start',
  uploadCollectionId,
  importTo = 'collection',
  initiatedFrom = null,
}) {
  const parentRef = useRef(null);
  const rootRef = useRef(null); // Ref to store the root
  const mobileInputRef = useRef();

  const dropboxUser = useSelector(
    (state) => state.user.connectedCloud?.dropbox,
  );

  const [isOpened, setOpened] = useState(false);

  const close = useCallback(() => setOpened(false), [setOpened]);

  const toggleMenu = useCallback(() => {
    setOpened((prevValue) => !prevValue);
  }, [setOpened]);

  const { createProduct } = useCreateProduct();

  const handleUploadChange = useCallback(
    ({ target }) => {
      const { files } = target;
      if (!files || !files.length) return;

      Logger.log('User', 'ImportMenuFilesSelected', { assets_number: files.length });

      window.dispatchEvent(
        new CustomEvent('importPanel:add', {
          detail: { files, uploadCollectionId },
        }),
      );
      close();
    },
    [close, uploadCollectionId],
  );

  const handleMobileUploadChange = useCallback(() => {
    mobileInputRef.current.click();
  }, []);

  const handleFromUrlClick = useCallback(async () => {
    close();
    const $div = document.createElement('div');
    document.body.appendChild($div);
    const root = createRoot($div);

    const closeDialog = () => {
      root.unmount();
      document.body.removeChild($div);
    };

    root.render(<AddFromUrlDialog onClose={closeDialog} uploadCollectionId={uploadCollectionId} />);
  }, [close, uploadCollectionId]);

  const handleLiveSupport = useCallback(async (e) => {
    close();
    const { cloudName } = e.currentTarget.dataset;
    if (window.Intercom) {
      const {
        title, getContent, textBtnOk, getMessage,
      } = l10n.DIALOGS.MIGRATION_ASSISTANCE;
      const children = getContent(cloudName);
      const message = getMessage(cloudName);

      const onOk = async () => {
        Logger.log('User', 'LiveChat');
        await window.Intercom('showNewMessage');
        setTimeout(() => {
          window.Intercom('private:sendMessage', message);
        }, 2000);
      };

      showDialog({
        title, children, textBtnOk, onOk,
      });
    }
    Logger.log('User', `${cloudName}ImportClicked`, { initiatedFrom: initiatedFrom });
  }, [close, initiatedFrom]);

  useEffect(() => {
    window.addEventListener('importPanel:closeImportMenu', close);
    return () => {
      window.removeEventListener('importPanel:closeImportMenu', close);
    };
  }, [close]);

  const handleOpenDropboxChooser = useCallback(async () => {
    close();
    if (!dropboxUser) {
      const authWindow = window.open(
        `https://www.dropbox.com/oauth2/authorize?client_id=${picsioConfig.dropboxClientId}&redirect_uri=${picsioConfig.dropboxRedirectUri}&response_type=code&token_access_type=offline`,
        '_blank',
      );
      const checkWindowClosed = setInterval(() => {
        if (authWindow.closed) {
          clearInterval(checkWindowClosed);
          window.dispatchEvent(
            new CustomEvent('window:dropbox:auth:closed'),
          );
        }
      }, 1000);
    }
    DropboxChooser({ uploadCollectionId });
    Logger.log('User', 'DropboxImportClicked', { initiatedFrom: initiatedFrom });
  }, [close, dropboxUser, initiatedFrom, uploadCollectionId]);

  const handleCreateProduct = useCallback((e) => {
    Logger.log('User', 'CreateProduct');
    close();
    createProduct(e);
  }, [close, createProduct]);

  const menu = useCallback(() => {
    /** Mobile */
    if (window.innerWidth < 1024) {
      return (
        <If condition={isOpened}>
          <div className="simpleDialog">
            <div className="simpleDialogUnderlayer" />
            <div className="simpleDialogBox">
              <div className="simpleDialogHeader">
                <h2 className="simpleDialogTitle">
                  {l10n.IMPORT.MENU.mobileDialogTitle}
                </h2>
              </div>
              <div className="simpleDialogContent">
                <div className="simpleDialogContentInner">
                  <div
                    className="firstLine"
                    onKeyPress={handleMobileUploadChange}
                    onClick={handleMobileUploadChange}
                    role="button"
                    tabIndex={0}
                  >
                    <Icon>
                      <Computer />
                    </Icon>
                    <div className="mobileDownloadText">
                      {l10n.IMPORT.MENU.upload}
                    </div>
                    <input
                      type="file"
                      multiple
                      ref={mobileInputRef}
                      onChange={handleUploadChange}
                    />
                  </div>
                  <div
                    className="secondLine"
                    onKeyPress={handleFromUrlClick}
                    role="button"
                    tabIndex={0}
                    onClick={handleFromUrlClick}
                  >
                    <Icon>
                      <Link />
                    </Icon>
                    <div className="mobileDownloadText">
                      {l10n.IMPORT.MENU.addExternal}
                    </div>
                  </div>
                </div>
              </div>
              <div className="simpleDialogFooter">
                <Button
                  color="secondary"
                  size="md"
                  variant="contained"
                  onClick={close}
                >
                  {l10n.DIALOGS.CANCEL}
                </Button>
              </div>
            </div>
          </div>
        </If>
      );
    }

    if (picsioConfig.isPim && initiatedFrom === 'left navigation panel') {
      return (
        <Menu
          target={targetRef}
          isOpen={isOpened}
          onClose={close}
          padding="m"
          placement={placement}
          outsideClickListener
          className="PicsioImportMenu"
        >
          <MenuItem
            className="PicsioImportMenu__item"
            onClick={handleCreateProduct}
          >
            <MenuItemIcon size="xl">
              <CreateProduct />
            </MenuItemIcon>
            <MenuItemText>Create product</MenuItemText>
          </MenuItem>
          <MenuItem
            className="PicsioImportMenu__item"
            onClick={Function.prototype}
          >
            <input type="file" multiple onChange={handleUploadChange} />
            <MenuItemIcon size="xl">
              <Computer />
            </MenuItemIcon>
            <MenuItemText>Upload file</MenuItemText>
          </MenuItem>

        </Menu>
      );
    }
    /** Desktop */
    return (
      <Menu
        target={targetRef}
        isOpen={isOpened}
        onClose={close}
        padding="m"
        placement={placement}
        outsideClickListener
        className="PicsioImportMenu"
      >
        <MenuItem
          className="PicsioImportMenu__item"
          onClick={Function.prototype}
        >
          <input type="file" multiple onChange={handleUploadChange} />
          <MenuItemIcon size="xl">
            <Computer />
          </MenuItemIcon>
          <MenuItemText>{l10n.IMPORT.MENU.upload}</MenuItemText>
        </MenuItem>
        <If condition={!picsioConfig.isPim}>
          <MenuItem
            className="PicsioImportMenu__item"
            onClick={handleFromUrlClick}
          >
            <MenuItemIcon size="xl">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link />
            </MenuItemIcon>
            <MenuItemText>{l10n.IMPORT.MENU.addExternal}</MenuItemText>
          </MenuItem>
        </If>
        <If condition={importTo === 'collection'}>
          <MenuItem
            className="PicsioImportMenu__item"
            onClick={!picsioConfig.isPim ? handleOpenDropboxChooser : handleLiveSupport}
            data-cloud-name="Dropbox"
          >
            <MenuItemIcon size="xl">
              <img
                src="https://assets.pics.io/img/icons/importers/dropbox.svg"
                style={{ width: '24px', height: '24px' }}
                alt="Dropbox logo"
              />
            </MenuItemIcon>
            <MenuItemText>{l10n.IMPORT.MENU.importDropbox}</MenuItemText>
            <If condition={picsioConfig.isPim}>
              <span className="labelBeta">{l10n.IMPORT.assisted}</span>
            </If>
          </MenuItem>
          <MenuItem
            className="PicsioImportMenu__item"
            onClick={handleLiveSupport}
            data-cloud-name="Google Drive"
          >
            <MenuItemIcon size="xl">
              <img
                src="https://assets.pics.io/img/icons/importers/google-drive.svg"
                style={{ width: '24px', height: '20px' }}
                alt="Google drive logo"
              />
            </MenuItemIcon>
            <MenuItemText>{l10n.IMPORT.MENU.importGD}</MenuItemText>
            <span className="labelBeta">{l10n.IMPORT.assisted}</span>
          </MenuItem>
          <MenuItem
            className="PicsioImportMenu__item"
            onClick={handleLiveSupport}
            data-cloud-name="OneDrive"
          >
            <MenuItemIcon size="xl">
              <img
                src="https://assets.pics.io/img/icons/importers/one-drive.svg"
                style={{ width: '24px', height: '24px' }}
                alt="One drive logo"
              />
            </MenuItemIcon>
            <MenuItemText>{l10n.IMPORT.MENU.importOneDrive}</MenuItemText>
            <span className="labelBeta">{l10n.IMPORT.assisted}</span>
          </MenuItem>
          <MenuItem
            className="PicsioImportMenu__item"
            onClick={handleLiveSupport}
            data-cloud-name="Box"
          >
            <MenuItemIcon size="xl">
              <img
                src="https://assets.pics.io/img/icons/importers/box.svg"
                style={{ width: '24px', height: '24px' }}
                alt="Box logo"
              />
            </MenuItemIcon>
            <MenuItemText>{l10n.IMPORT.MENU.importBox}</MenuItemText>
            <span className="labelBeta">{l10n.IMPORT.assisted}</span>
          </MenuItem>
          <MenuItem
            className="PicsioImportMenu__item"
            onClick={handleLiveSupport}
            data-cloud-name="SharePoint"
          >
            <MenuItemIcon size="xl">
              <img
                src="https://assets.pics.io/img/icons/importers/share-point.svg"
                style={{ width: '24px', height: '24px' }}
                alt="Share point logo"
              />
            </MenuItemIcon>
            <MenuItemText>{l10n.IMPORT.MENU.importSharePoint}</MenuItemText>
            <span className="labelBeta">{l10n.IMPORT.assisted}</span>
          </MenuItem>
        </If>
      </Menu>
    );
  }, [
    isOpened,
    handleUploadChange,
    close,
    placement,
    handleFromUrlClick,
    handleMobileUploadChange,
    handleLiveSupport,
    handleOpenDropboxChooser,
    importTo,
    targetRef,
    handleCreateProduct,
    initiatedFrom,
  ]);

  useEffect(() => {
    const $parent = document.createElement('div');
    const renderTimeout = setTimeout(() => {
      parentRef.current = $parent;
      document.body.appendChild($parent);

      rootRef.current = createRoot(parentRef.current); // Create root once
      rootRef.current.render(menu());
    });

    return () => {
      clearTimeout(renderTimeout);
      const root = rootRef.current;
      rootRef.current = undefined;

      setTimeout(() => {
        root?.unmount();
        document.body.removeChild($parent);
      });
    };
  }, [rootRef]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (rootRef.current) rootRef.current.render(menu()); // Reuse the existing root
  }, [isOpened, menu]);

  return { toggleMenu };
}

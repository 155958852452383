import { io } from 'socket.io-client';
import picsioConfig from '../../../../config';
import Logger from './Logger';
import getSessionId from '../helpers/getSessionId';
import PubSubRouter from './PubSubRouter';

let socket;
let initFinished = false;

export default {
  init: (id, teammatesIds) => {
    if (initFinished) {
      Logger.info('socket.io is already initialized');
      return;
    }
    initFinished = true;

    if (!id) {
      Logger.error(new Error('Cannot connect to socket. ID is not provided'), { error: 'No id to connect' }, [
        'SocketConnectionFailed',
        { message: 'ID is not provided' },
      ]);
      return;
    }

    socket = io(`${picsioConfig.eventsBaseUrl}/frontEndApp`, {
      // query: { session_id: getSessionId() },
      transports: ['websocket'],
    });

    socket.on('connect', () => socket.emit('join', { _id: id, teammatesIds }));

    socket.on('error', (error) => {
      Logger.error(new Error('Can not connect to socket.io'), { error }, [
        'SocketConnectionFailed',
        (error && error.message) || 'NoMessage',
      ]);
    });

    /** Subscribe store to notifications */
    PubSubRouter(socket);
  },
  subscribe: (type, callback) => {
    socket.on(type, callback);
  },
  unsubscribe: (type, callback) => {
    socket.removeListener(type, callback);
  },
};

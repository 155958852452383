import React from 'react';
import { Provider, connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cn from 'classnames';

import {
  BestMatch,
  User,
  DateEmpty,
  Star,
  Filesize,
  File,
  Dropper,
  Time,
  Copyright,
  OpenInFull,
  List,
  Title,
  Checkbox,
  Hash,
  Date,
  CustomSort,
} from '@picsio/icons';

import DropOpener from '../toolbars/DropOpener';
import localization from '../../shared/strings';
// store
import store from '../../store';
import * as actionsCollections from '../../store/actions/collections';
import * as actionsLightboards from '../../store/actions/lightboards';
import * as userActions from '../../store/actions/user';
import DropOpenerSelect from './DropOpenerSelect';
import SortItem from './SortItem';
import { isRouteFiltering } from '../../helpers/history';
import picsioConfig from '../../../../../config';
import CustomFieldsSort from './CustomFieldsSort';
import ua from '../../ua';
import Logger from '../../services/Logger';

const sortConfig = {
  score: { icon: () => <BestMatch />, isNewFeature: true },
  alphabetical: { icon: () => <Title />, asc: 'A-Z', desc: 'Z-A' },
  uploadTime: { icon: () => <Time />, asc: 'Old first', desc: 'New first' },
  updateTime: { icon: () => <Time />, asc: 'Old first', desc: 'New first' },
  createTime: { icon: () => <DateEmpty />, asc: 'Old first', desc: 'New first' },
  rating: { icon: () => <Star />, asc: 'Stars 0-5', desc: 'Stars 5-0' },
  color: { icon: () => <Dropper />, asc: 'A-Z', desc: 'Z-A' },
  fileSize: { icon: () => <Filesize />, asc: 'Small first', desc: 'Large first' },
  fileType: { icon: () => <File />, asc: 'A-Z', desc: 'Z-A' },
  creator: { icon: () => <User />, asc: 'A-Z', desc: 'Z-A' },
  copyright: { icon: () => <Copyright />, asc: 'A-Z', desc: 'Z-A' },
  custom: { icon: () => <CustomSort /> },
  imageResolution: { icon: () => <OpenInFull />, asc: 'Low first', desc: 'High first' },
  meta: { icon: () => <List />, asc: 'A-Z', desc: 'Z-A' },
};

class ToolbarSort extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortType: { type: 'uploadTime', order: 'desc' },
      subcollectionsSortType: { type: 'alphabetical', order: 'asc' },
      filterIcon: 'uploadTime',
      filterIconCollection: 'alphabetical',
      showCustomFieldsSelector: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.sortType && props.sortType !== state.sortType) {
      return {
        sortType: props.sortType,
        filterIcon: sortConfig[props.sortType.type]?.icon,
      };
    }

    if (
      props.subcollectionsSortType
      && props.subcollectionsSortType !== state.subcollectionsSortType
    ) {
      return {
        subcollectionsSortType: props.subcollectionsSortType,
        filterIconCollection: sortConfig[props.subcollectionsSortType.type].icon,
      };
    }

    return null;
  }

  selectCustomField = (item) => {
    const newItem = { ...item };
    const {
      selectedSortCustomFields, websiteCustomFieldsSort, userSettings, view,
    } = this.props;
    switch (newItem.type) {
    case 'enum':
      if (newItem.multiple) {
        newItem.value = '';
      } else {
        newItem.value = '';
      }
      break;
    case 'int':
      newItem.value = '';
      break;
    case 'input':
      newItem.value = '';
      break;
    case 'boolean':
      newItem.value = false;
      break;
    default:
      break;
    }
    const newSelectedCustomFields = view ? [...websiteCustomFieldsSort, item] : [...selectedSortCustomFields, item];

    if (!picsioConfig.isProofing) {
      // for website presets
      if (view) {
        this.props.changeCustomFields(newSelectedCustomFields);
      } else {
        this.props.userActions.updateUser({ settings: { ...userSettings, customFieldsSort: newSelectedCustomFields } }, false);
      }
    }
    Logger.log('User', 'AddCustomFieldSort', { number: newSelectedCustomFields.length, fieldName: item.title });
    this.setState({ showCustomFieldsSelector: false });
  }

  deselectCustomField = (item) => {
    const {
      selectedSortCustomFields, userSettings, websiteCustomFieldsSort, view,
    } = this.props;
    const newSelectedCustomFields = view ? [...websiteCustomFieldsSort] : [...selectedSortCustomFields];
    const itemIndex = newSelectedCustomFields.findIndex((field) => field.title === item.title);

    if (itemIndex !== -1) {
      newSelectedCustomFields.splice(itemIndex, 1);

      if (!picsioConfig.isProofing) {
        // for website presets
        if (view) {
          this.props.changeCustomFields(newSelectedCustomFields);
        } else {
          this.props.userActions.updateUser({ settings: { ...userSettings, customFieldsSort: newSelectedCustomFields } }, false);
        }

        if (`meta.${item.title}` === this.props.sortType.type) {
          this.props.changeSort('uploadTime', 'desc');
        }
      }
      Logger.log('User', 'RemoveCustomFieldSort', { number: newSelectedCustomFields.length, fieldName: item.title });
    }
  };

  openDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showCustomFieldsSelector: true });
  }

  render() {
    let {
      filterIcon,
      filterIconCollection,
    } = this.state;

    const {
      sortType,
      subcollectionsSortType,
      showCustomFieldsSelector,
    } = this.state;
    const {
      selectedSortCustomFields,
      resetDropdowns,
      isToolbarDropdownOpened,
      changeSort,
      view,
      rootCollectionId,
      collectionsSortTypeUpdating,
      lightboardsSortTypeUpdating,
      inboxesSortTypeUpdating,
      userActions,
      isBestMatchVisited,
      isCollectionsCustomSortAllowed,
      customFields,
      updatingUserSettings,
      websiteCustomFieldsSort,
      userSettings,
    } = this.props;

    const { isProofing } = picsioConfig;
    const isBusy = collectionsSortTypeUpdating || lightboardsSortTypeUpdating || inboxesSortTypeUpdating || updatingUserSettings;
    const { collectionIds } = this.props.location.query;

    let isSingleCollection = true;
    if (Array.isArray(collectionIds) && collectionIds.length > 1) {
      isSingleCollection = false;
    }
    let selectedCustomFields = view && websiteCustomFieldsSort ? websiteCustomFieldsSort : selectedSortCustomFields;

    if (picsioConfig.isProofing) {
      selectedCustomFields = window.websiteConfig.customFieldsSort || [];
    }

    let sortOrder;

    if (typeof sortType !== 'string') {
      if (sortType.type.startsWith('meta.')) {
        sortOrder = sortConfig.meta[sortType.order];

        const customField = selectedCustomFields.find((selectedSortCustomField) => sortType.type === `meta.${selectedSortCustomField.title}`);

        if (customField?.type === 'input') {
          filterIcon = () => <Title />;
        } else if (customField?.type === 'boolean') {
          if (sortType.order === 'asc') {
            sortOrder = 'Unset first';
          } else {
            sortOrder = 'Set first';
          }
          filterIcon = () => <Checkbox />;
        } else if (customField?.type === 'int') {
          if (sortType.order === 'asc') {
            sortOrder = 'Min first';
          } else {
            sortOrder = 'Max first';
          }
          filterIcon = () => <Hash />;
        } else if (customField?.type === 'date') {
          if (sortType.order === 'asc') {
            sortOrder = 'Old first';
          } else {
            sortOrder = 'New first';
          }
          filterIcon = () => <Date />;
        } else {
          filterIcon = sortConfig.meta.icon;
        }
      } else {
        filterIcon = sortConfig[sortType.type].icon;
        sortOrder = sortConfig[sortType.type][sortType.order];
      }
    } else {
      filterIcon = sortConfig[sortType].icon;
    }

    if (typeof subcollectionsSortType !== 'string') {
      filterIconCollection = sortConfig[subcollectionsSortType.type].icon;
    } else {
      filterIconCollection = sortConfig[subcollectionsSortType].icon;
    }

    const sortOrderCollections = sortConfig[subcollectionsSortType.type][subcollectionsSortType.order];
    const { thatsCustomizationTab, openOnClick } = this.props;
    const isCollectionSort = this.props.collectionSort;

    const dropdownContent = (
      <>
        <SortItem
          name="score"
          sortConfig={sortConfig}
          sort={sortType}
          changeSort={changeSort}
          onNewClicked={userActions.updateUser}
          isBestMatchVisited={isBestMatchVisited}
          userSettings={userSettings}
        />
        <SortItem
          name="alphabetical"
          sortConfig={sortConfig}
          sort={sortType}
          changeSort={changeSort}
        />
        <SortItem name="rating" sortConfig={sortConfig} sort={sortType} changeSort={changeSort} />
        <SortItem name="fileSize" sortConfig={sortConfig} sort={sortType} changeSort={changeSort} />
        <SortItem
          name="uploadTime"
          sortConfig={sortConfig}
          sort={sortType}
          changeSort={changeSort}
        />
        <SortItem
          name="createTime"
          sortConfig={sortConfig}
          sort={sortType}
          changeSort={changeSort}
        />
        <SortItem
          name="updateTime"
          sortConfig={sortConfig}
          sort={sortType}
          changeSort={changeSort}
        />
        <If condition={(collectionIds !== rootCollectionId && !isRouteFiltering() && isSingleCollection) || (view === 'select')}>
          <SortItem name="custom" sortConfig={sortConfig} sort={sortType} changeSort={changeSort} disabled={isProofing} />
        </If>
        <SortItem
          name="imageResolution"
          sortConfig={sortConfig}
          sort={sortType}
          changeSort={changeSort}
        />
        {
          selectedCustomFields.map((selectedCustomField) => (
            <SortItem
              key={selectedCustomField.title}
              name={selectedCustomField.title}
              isCustomField
              sortConfig={sortConfig}
              sort={sortType}
              changeSort={changeSort}
              removeCustomField={this.deselectCustomField}
              selectedCustomField={selectedCustomField}
            />
          ))
        }
        <If condition={!picsioConfig.isProofing}>
          <CustomFieldsSort
            addField={this.selectCustomField}
            removeField={this.deselectCustomField}
            customFields={customFields}
            selectedCustomFields={selectedCustomFields}
            showCustomFieldsSelector={showCustomFieldsSelector}
            openDropdown={this.openDropdown}
            disablePortal={!ua.browser.isNotDesktop() && !ua.isMobileApp()}
            dropdownPosition="top"
            additionalClassName="customFieldsSort"
          />
        </If>
      </>
    );

    const dropdownContentCollectionsSort = (
      <>
        <SortItem
          name="alphabetical"
          sortConfig={sortConfig}
          sort={subcollectionsSortType}
          changeSort={changeSort}
        />
        <SortItem
          name="createTime"
          sortConfig={sortConfig}
          sort={subcollectionsSortType}
          changeSort={changeSort}
        />
        <SortItem
          name="updateTime"
          sortConfig={sortConfig}
          sort={subcollectionsSortType}
          changeSort={changeSort}
        />
        <SortItem
          name="custom"
          disabled={!isCollectionsCustomSortAllowed}
          sortConfig={sortConfig}
          sort={subcollectionsSortType}
          changeSort={changeSort}
        />
      </>
    );

    let sortName = localization.SORT[subcollectionsSortType.type];
    if (!isCollectionSort) {
      sortName = localization.SORT[sortType.type];
    }
    if (sortType.type.startsWith('meta.')) {
      sortName = sortType.type.replace('meta.', '');
    }

    return (
      <>
        <Choose>
          <When condition={view}>
            <DropOpenerSelect
              icon={!isCollectionSort ? filterIcon : filterIconCollection}
              sortName={sortName}
              sortOrder={!isCollectionSort ? sortOrder : sortOrderCollections}
              name="Sort order"
              isToolbarDropdownOpened={isToolbarDropdownOpened}
              resetDropdowns={resetDropdowns}
              additionalClass="dropdownSort"
              thatsCustomizationTab={thatsCustomizationTab}
            >
              {!isCollectionSort ? dropdownContent : dropdownContentCollectionsSort}
            </DropOpenerSelect>
          </When>
          <Otherwise>
            <DropOpener
              additionalClass={cn('tabletNotVisible dropdownSort', { isBusy })}
              icon={(!isCollectionSort) ? filterIcon : filterIconCollection}
              sortTypeOrder={!isCollectionSort ? sortType.order : subcollectionsSortType.order}
              name="Sorting"
              isToolbarDropdownOpened={isToolbarDropdownOpened}
              resetDropdowns={resetDropdowns}
              isBestMatchVisited={isBestMatchVisited}
              openOnClick={openOnClick}
              leftFilter
              left
            >
              <div>
                <If condition={isBusy}>
                  <div className="jobs-status-spinner" />
                </If>
                {dropdownContent}
              </div>
            </DropOpener>
          </Otherwise>
        </Choose>
      </>
    );
  }
}

const ConnectedToolbarSort = connect(
  (state) => ({
    location: state.router.location,
    rootCollectionId: state.collections?.collections?.my._id,
    collectionsSortTypeUpdating: state.collections.sortTypeUpdating,
    lightboardsSortTypeUpdating: state.lightboards.sortTypeUpdating,
    inboxesSortTypeUpdating: state.inboxes.sortTypeUpdating,
    isBestMatchVisited: state.user?.settings?.isBestMatchVisited,
    selectedSortCustomFields: state.user?.settings?.customFieldsSort || [],
    updatingUserSettings: state.user?.loading,
    userSettings: state.user.settings,
    customFields: state.customFields.items,
  }),
  (dispatch) => ({
    actionsCollections: bindActionCreators(actionsCollections, dispatch),
    actionsLightboards: bindActionCreators(actionsLightboards, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
  }),
)(ToolbarSort);

export default (props) => (
  <Provider store={store}>
    <ConnectedToolbarSort {...props} />
  </Provider>
);

/* global L */
import React, {
  useRef, useEffect,
} from 'react';

import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import 'leaflet.markercluster';

const LeafletMap = ({ locations }) => (
  <MapContainer className="map" center={[51.0, 19.0]} zoom={4} maxZoom={18}>
    <TileLayer
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    />
    <Markers locations={locations} />
  </MapContainer>
);

const Markers = ({ locations }) => {
  const map = useMap();
  const markerCluster = useRef(null);

  useEffect(() => {
    if (!map) return;

    if (markerCluster.current) {
      map.removeLayer(markerCluster.current);
    }

    markerCluster.current = L.markerClusterGroup({
      chunkedLoading: true,
      singleMarkerMode: false,
      spiderfyOnMaxZoom: false,
    });

    const bounds = new L.LatLngBounds();

    locations.forEach((location) => {
      const loc = new L.LatLng(location.lat, location.lng);
      bounds.extend(loc);

      const icon = L.divIcon({
        html: '<div class="marker-pin"></div>',
        className: 'marker-default',
        iconSize: L.point(43, 43),
      });

      const marker = L.marker(loc, { icon });
      markerCluster.current?.addLayer(marker);
    });

    if (markerCluster.current) {
      map.addLayer(markerCluster.current);
    }

    if (locations.length > 1) {
      map.fitBounds(bounds, { padding: [20, 20] });
    } else if (locations.length === 1) {
      map.setView([locations[0].lat, locations[0].lng], 8);
    }
  }, [locations, map]);

  return null;
};

export default LeafletMap;

import React, { memo } from 'react';
import { InfoBlock } from '@picsio/ui';
import pluralize from 'pluralize';
import { Warning } from '@picsio/icons';
import Tag from '../components/Tag';
import { navigate } from '../helpers/history';
import picsioConfig from '../../../../config';

const appName = picsioConfig.isPim ? 'Toriut.com' : 'Pics.io';
const supportEmail = picsioConfig.isPim ? 'support@toriut.com' : 'support@pics.io';

const getStorageTitle = (storageName) => {
  if (storageName === 'gd') {
    return 'Google Drive';
  }

  if (storageName === 's3') {
    return 'Amazon S3';
  }

  return `${appName} Storage`;
};

const getTextErrorGDOne = (onClick, titleStorage) => (
  <>
    <p>
      You have a limited access to this file in Google Drive, which means you can't rename it in {appName} either.
    </p>
    <p>
      Tired of these limitations from {titleStorage === 'Picsio' ? `${appName} Storage` : ' Google Drive?'} Switch to
      <span aria-label="link to storage" className="dialogLink" onClick={onClick} role="presentation"> {appName} storage</span>.
    </p>
  </>
);

const getTextErrorGDBulk = (onClick) => (
  <>
    <p>
      Tired of these limitations from Google Drive? Switch to
      <span aria-label="link to storage" className="dialogLink" onClick={onClick} role="presentation"> {appName} storage</span>.
    </p>
  </>
);

export default {
  ARCHIVE_TREE: {
    PLACEHOLDER: {
      TITLE: 'Archive',
      DESCRIPTION: 'This will display the collection tree of archived assets',
    },
  },
  OPEN_COLLECTIONS_DIALOG: 'Open collections dialog',
  CHOOSE_COLLECTION: 'Choose collection',
  COLLECTION_SELECTION_EXCEEDED: 'Max 10 collections can be selected',
  APP_START: {
    ERROR_WORKINGFOLDER_ADMIN:
      `${appName} cannot access the working folder within the cloud storage associated with your account. Please make sure it is still accessible and hasn’t been deleted. You’re welcome to contact ${supportEmail} if you need any help from our side.`,
    ERROR_WORKINGFOLDER_TEAMMATE:
      `${appName} cannot access your account storage. Please contact your {txtAdminsList} about the issue. You’re also welcome to contact ${supportEmail} if you need any help from our side.`,
    ERROR_CONNECTING_GDRIVE:
      `${appName} cannot access your cloud storage. Please make sure it’s accessible (e.g. check your billing status) and refresh the page. If the error persists, contact us at ${supportEmail}.`,
    ERROR_LOADING_USERDATA:
      `Sorry, ${appName} couldn’t load some data from your user profile. Please try to refresh the page. If the problem persists, contact us at ${supportEmail}`,
    DEFAULT_ERROR:
      `Cannot start ${appName} application. Try refreshing the browser tab. If the problem persists, contact us at <a href="mailto:${supportEmail}" target="_blank">${supportEmail}</a>.`,
  },
  STORAGE_FOLDER_NOT_FOUND: `${appName} folder not found`,
  STORAGE_FILES_NOT_FOUND: `${appName} folder is empty`,
  STORAGE_CANT_TRASH_FILE: "Can't trash file",
  DISCLAIMER_PATTERN:
    `<b>Hold up there, %name%.</b><br/><br/>You’re getting access to the really early version of ${appName}.<br/><br/><b>It's an alpha.</b><br/><br/>I bet you've got it - it doesn't work more often than it works. Sometimes it delivers weird results only Andy Warhol would be satisfied.<br/><br/>We are big fans of rapid delivery so you will see small changes almost every day. Please let me know if you see that something goes wrong using our Helpdesk, Twitter or email.<br/><br/>Love,<br/>Vlad from TopTechPhoto`,
  STORAGE_GDRIVE_NOT_CONNECTED:
    `Looks like your <b>${appName}</b> account is not connected to your<b>cloud storage</b> or you are not logged in!`,
  LOGOUT_QUESTION: '<center>You are going to <b>logout</b>.<br/><br/> Continue?</center>',
  GOBACK_FROM_DEVELOP_QUESTION:
    '<center>If you return to catalog your adjustments <b>will be lost</b>.<br/><br/>Return to catalog?</center>',
  DELETE_IMAGE_QUESTION:
    '<center>Are you sure you want to <b>delete</b> asset from your cloud storage?<br/><br/>If you are using cloud storage item will be placed to trash.</center>',
  RESETPASSWORD_QUESTION:
    '<center>You are going to <b>reset your password</b>.<br/>We will send you an <b>email</b> with instructions.<br/><br/> Continue?</center>',
  RESETPASSWORD_SUCCESS_MESSAGE:
    "<center>Reset password instructions sent to <b>%email%</b>. Don't forget to log in after changing password. =)</center>",
  NOT_IMPLEMENTED_YET:
    "<center><b>Coming soon...</b><br/><br/>Feature is not ready yet, and we are working hard on it.<br/><br/>If you have any suggestions or ideas, just <a href='javascript:app.openSupport()'>let us now</a>.</center>",
  FORMAT_NOT_SUPPORTED_YET:
    "<center><b>Coming soon...</b><br/><br/>Editing of this raw format is not available yet, and we are working hard on it.<br/><br/>If you have any suggestions or ideas, just <a href='javascript:app.openSupport()'>let us now</a>.</center>",
  IMPORT_FORMAT_NOT_SUPPORTED_YET: {
    title: 'Unsupported format',
    text:
      `It seems that you've dropped a file that ${appName} doesn't support yet.<br /><br />You can find supported formats list <a href="https://blog.pics.io/the-most-complete-list-of-pics-io-features-d9073d0c5034#.vgnlz9clz" target="_blank">here</a>.`,
    OK_TEXT: 'Ok',
  },
  NO_PHOTO_IN_COLLECTION:
    "<div>This collection is empty... for now.</div><span>To add files, use Upload button, or select and drag'n'drop assets from other collections.</span>",
  NO_PHOTO_IN_COLLECTION_RECURSIVE:
    '<div>This collection is empty.</div><span>Try to switch the yellow toggle above to see if there are any assets in nested collections.</span>',
  NO_PHOTO_IN_COLLECTION_WITHOUT_UPLOAD: '<div>There are no files in this collection</div>',
  NO_PHOTO_IN_PROOFING_TEMPLATE:
    "<div>There are no files on this site.</div><span>Please use <a href='https://pics.io/' target='_blank'>pics.io</a> to add files.</span>",
  NO_PHOTO_IN_PROOFING_COLLECTION: '<div>There are no files in this collection.</div>',
  NO_PHOTO_IN_FILTER:
    '<div>No files match the filter</div><span>Try to change filtering options or reset filters</span>',

  SELECTED_ASSETS_EMPTY: {
    TITLE: () => <>There are no files in collection <span className="act">Selection</span>.<br /></>,
    TEXT: 'Please select any asset from you library.',
  },
  NO_PHOTO_IN_SEARCH: '<div>Your search did not match any files.',
  NO_PHOTO_IN_SMART_COLLECTION: '<div>There are no files in this collection</div>',
  NO_PHOTO_IN_LIGHTBOARD:
    "<div>Your personal lightboard is empty... for now.</div><span>To add files, use Upload, or select and drag'n'drop assets from other collections.</span>",

  NO_PERMISSION_TO_ACCESS: 'Team administrator disabled access to this feature for your user account. Contact them to get this permission',
  CSV_IMPORT: {
    importingError: 'Importing error',
    close: 'Close',
    importNewCsv: 'Import new csv',
    importNewCsvError: 'Import CSV access error',
    downloadReport: 'Download CSV report',
    takingTooLong: 'This file is taking longer to process, once it’s done, we will send you a report about this import operation to email',
    done: 'Refresh and close',
    importTitle: 'Import your CSV file',
    learnMore: 'Learn more',
    dragnDrop: 'Drag and drop your CSV file here',
    or: 'or',
    browse: 'Browse',
    import: 'Import',
    validationDialogTitle: 'Incorrect type or size',
    validationDialogText: 'File must be of CSV format, larger than 0MB and smaller than 20MB',
    textUploadCsv: 'Upload csv',
    canUploadOnlyOneFileDialogText: 'You can upload only one csv file',
    canUploadOnlyOneFileDialogTitle: 'Upload error',
    hasNoAccessDialogTitle: 'You have no access to upload a CSV. Contact your team manager',
    refreshText: 'Close this page and refresh the browser tab to see the imported metadata on assets',
    helpText: 'How to import metadata',

    importText: () => (
      <>
        <p>
          Importing a CSV allows you to add new metadata to assets in {appName}. If a picsioId field in your CSV matches an existing asset in {appName}, it will be updated with the mapped values. Once the import is finished, you will get a CSV file where each row that failed to import contains an error reason.
        </p>
        <b>IMPORTANT:</b> CSV file must contain a picsioId column with IDs of assets you want to add new metadata to
      </>
    ),
  },
  BULK_MEDIA_MATCH: {
    CONFIRM_ADDITION_OF_MEDIA_FILES: {
      TITLE: 'Confirm addition of media files',
      TEXT: (count) => (
        <>
          You are about to add {count} selected media files
          to the suggested products.
          <br />
          <br />
          This operation will run in the background, and changes may take some
          time to appear.
          <br />
          <br />
          Do you want to proceed?
          <br />
          <br />
          <InfoBlock>For variants that already have media, suggested matches won't overwrite the existing file. Instead, they'll be added to the product's main media.</InfoBlock>
        </>
      ),
      BTN_CANCEL: 'Cancel',
      BTN_OK: 'Confirm',
    },
    MATCHING_LIMIT_REACHED: {
      TITLE: 'Matching limit reached',
      TEXT: (limit) => (
        <>
          As a trial user, you can match up to <span className="highlight">{limit}</span> product media files for free with our Bulk Media Match feature.
          <br />
          <br />
          If the current selection exceeds the limit, consider trying again with a smaller batch.
          <br />
          <br />
          To continue using this functionality beyond the trial limit, please consider upgrading to one of our available plans.
        </>
      ),
      BTN_CANCEL: 'Cancel',
      BTN_OK: 'View plans',
    },
    BTN_APPROVE_MATCHES: 'Approve matches',
    BTN_OPEN_PLUGIN: 'Bulk Media Match',
    TOAST_MATCHING_IN_PROCESS: 'Adding media to products according to the selected matches. This may take a moment.',
    TEXT_INFO_BLOCK: () => (
      <>
        Bulk Media Match automatically associates new media with products. By
        naming files to match product SKUs, suggested matches will appear in the
        feed below.
        <br />
        Review the suggestions and approve them using the bottom toolbar.
      </>
    ),
    LINK_INFO_BLOCK: 'Read the full guide',
    TITLE_PLACEHOLDER: 'No matches found',
    TEXT_PLACEHOLDER: 'It seems there are no new media files matching your product SKUs. Start by renaming files to match SKUs, and they’ll appear here.',
  },
  DIALOGS: {
    ADD_WATERMARK: {
      TITLE: 'Upgrade required!',
      TEXT: 'Creating watermarks from images and logos is only available for plans starting from Small. Upgrade to Small or higher to enable custom watermarks.',
      BTN_OK: 'See all plans',
    },
    SYNC_PRODUCTS_ERROR: {
      TITLE: 'Product sync unavailable',
      TEXT: 'Full sync is temporarily unavailable because a background operation is managing media attachments. Once it finishes, you can safely run the sync.',
      BTN_OK: 'Ok',
    },
    UNSAVED_CHANGES_DETECTED: {
      TITLE: 'Unsaved changes detected',
      TEXT: (
        <>Leaving now will discard any unsaved changes.
          <br />Click  <span className="highlight">Save Changes</span> to keep them or <span className="highlight">Discard</span> to exit.
          <br />
          <br />
          How do you want to proceed?
        </>
      ),
      BTN_CANCEL: 'Discard',
      BTN_OK: 'Save Changes',
    },
    UNSUPPORTED_MEDIA_DIALOG: {
      TITLE: 'Unsupported Media Detected',
      TEXT: (errorAssetsCount, allAssetCount) => `${errorAssetsCount} out of ${allAssetCount} media items can’t be added to products or their variants due to the following reasons:`,
      SUB_TEXT: 'For more information on Shopify’s media requirements, refer to Shopify’s media guidelines.',
      BTN_OK: 'Ok',
    },
    DELETE_FACE_REGION: {
      TITLE: 'Delete face region',
      TEXT: 'Face region will be deleted on this asset',
      BTN_OK: 'Delete',
      BTN_CANCEL: 'Cancel',

    },
    ADD_ADDON_NOT_PERMISSION: {
      TITLE: 'Not enough permissions',
      TEXT: 'You do not have permissions to add more (inboxes, websites, teammates) than included in your team’s subscription plan. Ask you team admin to do that for you.',
      BTN_OK: 'Ok',
    },
    INVALID_SAVED_SEARCH: {
      TITLE: 'This saved search is not valid',
      TEXT: (invalidEntities) => {
        const invalidEntityTypes = invalidEntities.map((invalidEntity) => {
          if (invalidEntity.type === 'collections') {
            return `${pluralize('collection', invalidEntity.ids.length)}`;
          }
          if (invalidEntity.type === 'lightboard') {
            return 'lightboard';
          }
          if (invalidEntity.type === 'inbox') {
            return 'inbox';
          }
          if (invalidEntity.type === 'keywords') {
            return `${pluralize('keyword', invalidEntity.ids.length)}`;
          }
          if (invalidEntity.type === 'faces') {
            return `${pluralize('face', invalidEntity.ids.length)}`;
          }
          if (invalidEntity.type === 'customFields') {
            return `${pluralize('customField', invalidEntity.ids.length)}`;
          }
          return '';
        });
        return `The saved search refers to a deleted ${invalidEntityTypes.join(', ')} and is no longer valid. Do you want to delete the <b>Irrelevant search</b>?`;
      },
      OK_TEXT: 'Delete this search',
      CANCEL_TEXT: 'Cancel',
    },
    SAVED_SEARCH_DOES_NOT_EXIST: {
      TITLE: 'This saved search is not valid',
      TEXT: 'Saved search does not exist',
    },
    SELECT_TO_IMPORT: {
      TITLE: 'Select collection to import',
      TEXT: 'Select an existing collection or create a new one to proceed with uploading assets',
      OK_TEXT: 'Import',
      CANCEL_TEXT: 'Cancel',
    },
    CANNOT_REMOVE_ADDON: (featureName) => {
      const defaultName = featureName.replace('Limit', '');
      switch (featureName) {
      case 'teammatesLimit':
        return {
          TITLE: 'Can’t remove teammates',
          TEXT: (hideDialog) => (
            <span>To be able to remove teammates, you need to first delete those extra user seats in <span aria-hidden="true" className="dialogLink" onClick={() => { navigate('/teammates'); hideDialog(); }}>Teammates tab</span>.
              Once it’s done, come back here and remove extra seats from your plan.
            </span>
          ),
          BTN_OK: 'OK',
        };
      case 'inboxesLimit':
        return {
          TITLE: 'Can’t remove inboxes',
          TEXT: (hideDialog, { firstIndoxId, openedTree, handleChangeTree }) => (
            <span>To be able to remove inboxes, you need to first delete those extra inboxes in <span aria-hidden="true" className="dialogLink" onClick={() => { if (openedTree !== 'inbox') handleChangeTree('inbox'); navigate(`/search?inboxId=${firstIndoxId}`); hideDialog(); }}>Inboxes tab</span>.
              Once it’s done, come back here and remove extra inboxes from your plan.
            </span>
          ),
          BTN_OK: 'OK',
        };
      case 'websitesLimit':
        return {
          TITLE: 'Can’t remove websites',
          TEXT: (hideDialog, { firstWebsiteId, openedTree, handleChangeTree }) => (
            <span>To be able to remove websites, you need to first delete those extra websites in <span aria-hidden="true" className="dialogLink" onClick={() => { if (openedTree !== 'collections') handleChangeTree('collections'); navigate(`/search?collectionIds=${firstWebsiteId}`); hideDialog(); }}>Websites tab</span>.
              Once it’s done, come back here and remove extra websites from your plan.
            </span>
          ),
          BTN_OK: 'OK',
        };
      default:
        return {
          TITLE: `Can’t remove ${defaultName}`,
          TEXT: () => (<span>To be able to remove {defaultName}, you need to first delete those extra user seats. Once it’s done, come back here and remove extra seats from your plan.</span>),
          BTN_OK: 'OK',
        };
      }
    },
    DELETE_ASSETS: {
      TITLE: (deleteWithoutTrash, idsLength) => (deleteWithoutTrash ? `Delete ${pluralize('file', idsLength, false)}` : 'Delete forever'),
      TEXT: (deleteWithoutTrash, idsLength) => (deleteWithoutTrash
        ? `<div>This operation will remove ${idsLength} assets from your connected cloud storage and ${appName}.</div>`
        : `<div>This operation will remove ${idsLength} assets from your connected cloud storage and ${appName} trash.</div>`),
    },
    DROPBOX_CONNECTION_ERROR: {
      TITLE: "We weren't able to connect to that account",
      TEXT: `It looks like you didn't give ${appName} permission to access your Dropbox files. Try connecting your account again making sure you allow access.`,
      BTN_OK: 'OK',
    },
    UNSUPPORTED_FILE: {
      TITLE: 'Unsupported file format',
      TEXT: 'Document editing is not supported yet',

    },
    DOWNLOAD_DUPLICATES: {
      TITLE: (duplicates) => `${pluralize('file', duplicates.length, false)} already added`,
      TEXT: (duplicates) => (
        <>The following {duplicates.length} {pluralize('file', duplicates.length, false)} are already added for downloading:
          <ul>
            {duplicates.map((file) => <li className="dialog-item">{file.name}</li>)}
          </ul>
          {duplicates.length > 1 && <p>Press OK to skip these files and proceed with downloading</p>}
        </>
      ),
    },
    DOWNLOAD_ERROR: {
      TITLE: 'Download failed',
      TEXT: 'Failed to download. Check your internet connection.',
    },
    ERROR_RESOLVE_DUPLICATE: {
      TITLE: '',
      TEXT: (duplicateItemName, duplicateItemId) => `Cannot resolve duplicate for file ${duplicateItemName} (${duplicateItemId})`,
    },
    ENABLE_CONTROLLED_VOCABULARY: {
      TITLE: 'Enable Сontrolled vocabulary',
      TEXT: "Controlled Vocabulary ensures team members use an approved list of keywords, preventing any unauthorized keyword modifications. Teammates without 'Add keywords outside of controlled vocabulary' permission can't edit, delete, or add new keywords.",
      BTN_CANCEL: 'Cancel',
      BTN_OK: 'Enable',
    },
    DISABLE_CONTROLLED_VOCABULARY: {
      TITLE: 'Disable Сontrolled vocabulary',
      TEXT: 'Controlled Vocabulary ensures team members use an approved list of keywords, preventing any unauthorized keyword modifications. After disabling all teammates can edit, delete, or add new keywords.',
      BTN_CANCEL: 'Cancel',
      BTN_OK: 'Disable',
    },
    COLLECTION_DELETE_ERROR: {
      TITLE: 'Error',
      TEXT: (displayName, collectionName) => <>{displayName} has just deleted "{collectionName}" collection.</>,
      BTN_OK: 'Ok',
    },
    AUTOGENERATE_KEYWORDS: {
      TITLE: 'Confirm action',
      TEXT: 'Do you want to have keywords generated by AI for all new assets?',
      BTN_OK: 'Confirm',
      BTN_CANCEL: 'Cancel',
    },
    DELETE_FACE: {
      TITLE: 'Delete face',
      TEXT: 'This face will be removed from all images where it was recognized previously. Continue?',
      BTN_OK: 'Delete',
      BTN_CANCEL: 'Cancel',
    },
    BILLING_DOWNGRADE: {
      TITLE: 'Request sent',
      TEXT: (action) => <>We’ve received your {action} request. Our Support Team will contact you shortly.</>,

    },
    IMPORT_WARNING_ADDED_DUPLICATES: {
      TITLE: 'Files already in uploading batch',
      TEXT: (localDuplicates) => (`The following ${localDuplicates.length
      } files are already in the uploading batch: <ul>${localDuplicates
        .map((file) => `<li>${file.name}</li>`)
        .join('')}</ul><p>Press Skip to skip these files and proceed with uploading</p>`),
      BTN_OK: 'Skip',
    },
    KEYWORDS_GENERATION_ASSETS_NOT_FOUND: {
      TITLE: 'Keyword generation error',
      TEXT: () => (
        <>
          <InfoBlock icon={() => <Warning />} iconColor="warning">The collection you selected is currently empty or contains unsupported asset types for keyword generation</InfoBlock>
          <br />
          <p>Please select a different collection or add assets to the selected one before using the Keyword Generation feature.</p>
        </>
      ),
      BTN_OK: 'Ok',
    },
    FACE_RECOGNITION_ASSETS_NOT_FOUND: {
      TITLE: 'Face recognition error',
      TEXT: () => (
        <>
          <InfoBlock icon={() => <Warning />} iconColor="warning">The collection you selected is currently empty or contains unsupported asset types for face recognition</InfoBlock>
          <br />
          <p>Please select a different collection or add assets to the selected one before using the Face Recognition feature.</p>
        </>
      ),
      BTN_OK: 'Ok',
    },
    IMPORT_WARNING_FILES_EXCEEDS: {
      TITLE: 'Files size exceed 40GB',
      TEXT: (largeFiles) => (`The following ${largeFiles.length} files are too large for upload: <ul>${largeFiles
        .map((file) => `<li>${file.name}</li>`)
        .join('')}</ul><p>Press ok to skip these files and proceed with upload.</p>`),
      BTN_OK: 'Ok',
    },
    UPLOAD_ERROR: {
      TITLE: 'Not enough space in your storage.',
      TEXT: 'Cannot upload an asset',
    },
    DOWNLOAD_LIMIT: {
      TITLE: 'Download',
      TEXT:
        'If you download more than 50 assets at a time, they can only be downloaded as an archive',
    },
    DOWNLOAD_PROOFING_AS_ZIP: {
      TITLE: (countLimit) => `Cannot download more than ${countLimit} files`,
      TEXT: (countLimit) => `This website contains more than ${countLimit} files. Due to technical limitations, downloading files as an archive is not available for this number of files.<br />You can still select and download individual assets if you like.`,

    },
    CHECK_FOR_RESTRICTED_ASSETS: {
      TITLE: 'Restricted assets detected',
      TEXT: 'Please note that you are about to publish the collection with restricted assets inside.',
      BTN_OK: 'Ok',
      BTN_CANCEL: 'Cancel',
    },
    SCREEN_PLAN_CANCELLATION: {
      TITLE: 'Cancellation request sent',
      TEXT: 'We’ve received your cancellation request. Our Support Team will contact you shortly.',

    },
    MERGE_FACE: {
      CANCEL_TEXT: 'Cancel',
      OK: 'Merge',
    },
    LIMIT_CHECK_DUPLICATES: {
      TITLE: 'Exceeded assets selection limit',
      TEXT:
        'You have selected more than 10 000 assets. The system cannot check for duplicates at that number of assets. Please reduce the number of selected assets and try again.',
      CANCEL_TEXT: 'Ok',
    },
    // WRONG_GOOGLE_ACCOUNT: {
    // 	TITLE: 'Wrong Cloud Storage Account',
    // 	TEXT_TPL:
    // 		"Note that you're authorized to <b style='color:#FC0'>{authorized}</b>.<br/><br/>Previously you've connected <b style='color:#FC0'>{saved}</b> to pics.io. <br><br>You won't see any files from your original Cloud Storage Account. You might want to login to your original Cloud Storage Account before.",
    // 	OK_TEXT: 'Ok, got it',
    // 	CANCEL_TEXT: 'Cancel',
    // },
    ERROR_PLAN_LIMIT: {
      TITLE: 'Plan limit reached',
      TEXT: 'On Solo you can share only 10 assets. To get unlimited single-shared assets, you should upgrade to Micro plan or higher.',
      OK_TEXT: 'See all plans',
    },
    DUPLICATE_ASSET_TO: {
      TITLE: 'Select collection to duplicate to',
      OK: 'Duplicate',
      CANCEL: 'Cancel',
    },
    HAS_WORKER_BUT_NO_CONTROLLER: {
      TITLE: 'Attention!',
      TEXT:
        `New version of ${appName} is available. Refresh the browser tab to see the updates.`,
      OK_TEXT: 'Refresh',
      CANCEL_TEXT: 'Cancel',
    },
    MOVE_TO_TRASH: {
      TITLE: 'Move to trash',
      TEXT: (count, name) => <div>You are about to delete <span className="highlight">{name || count} {count !== 1 ? 'assets' : ''}</span>{count === 1 ? ' asset' : ''}. This will detele {name ? 'it' : 'them'} from all associated collections.</div>,
      INFO_BLOCK: (count) => `If you would like to remove ${count === 1 ? 'it' : 'them'} from specific collections only, use “Remove from collection” instead`,
      BTN_OK: 'Delete',
    },
    ERROR_TRASH_DIALOG: {
      TITLE: 'Error',
      TEXT_ASSET_BULK_OPERATION: (successful, failed, onClick) => (
        <>
          <p>
            {pluralize('asset', successful, true)} will be moved to trash, {pluralize('asset', failed, true)} cannot be trashed because you have a limited access to the files in Google Drive.
          </p>
          {getTextErrorGDBulk(onClick)}
        </>
      ),
      TEXT_ASSET_ONE_OPERATION: (onClick) => (
        getTextErrorGDOne(onClick)
      ),
    },
    ERROR_UNTRASH_DIALOG: {
      TITLE: 'Error',
      TEXT_ASSET_BULK_OPERATION: (successful, failed, onClick) => (
        <>
          <p>
            {pluralize('asset', successful, true)} will be restored from trash, {pluralize('asset', failed, true)} cannot be restored because you have a limited access to the files in Google Drive.
          </p>
          {getTextErrorGDBulk(onClick)}
        </>
      ),
      TEXT_ASSET_ONE_OPERATION: (onClick) => (
        getTextErrorGDOne(onClick)
      ),
    },
    SELECT_COLLECTION_FOR_GENERATE_KEYWORD: {
      TITLE: 'Select collection',
      TEXT: 'AI will automatically generate and add new keywords to assets within the selected collection.',
      BTN_OK: 'Generate keywords',

    },
    ERROR_DOWNLOAD_ASSET: {
      TITLE: 'Error',
      TEXT: (onClick) => (
        getTextErrorGDOne(onClick)
      ),
    },
    UNABLE_TO_RENAME_ASSET: {
      TITLE: 'This name already taken',
      TEXT: 'This name is used by the original file. Pick a different name for this duplicate',
    },
    ERROR_RENAME_ASSET: {
      TITLE: 'Error',
      TEXT: (onClick) => (
        getTextErrorGDOne(onClick)
      ),
    },
    ERROR_COPY_ASSET: {
      TITLE: (titleStorage) => (titleStorage === 'Picsio' ? 'Not enough storage space' : 'Not enough  space in Google Drive'),
      TEXT: (titleStorage) => (
        <p>
          {titleStorage === 'Picsio'
            ? 'Duplicating all of the selected assets will require more space than is available in your storage. You can either select fewer assets or buy more storage'
            : 'Duplicating all of the selected assets will require more space than is available in your Google Drive. You can either select fewer assets or buy more storage with Google.'}
        </p>
      ),
    },
    ERROR_DELETE_DIALOG: {
      TITLE: 'Error',
      TEXT_ASSET_BULK_OPERATION: (successful, failed, onClick) => (
        <>
          <p>
            {pluralize('asset', successful, true)} will be moved to trash,  {pluralize('asset', failed, true)} cannot be trashed because you have a limited access to the files in Google Drive.
          </p>
          {getTextErrorGDBulk(onClick)}
        </>
      ),
      TEXT_ASSET_ONE_OPERATION: (onClick) => (
        getTextErrorGDOne(onClick)
      ),
    },
    ATTENTION_DIALOG: {
      TITLE: 'Attention!',
      TEXT:
        `You're going to cancel your subscription. This will block an access to ${appName} services to you and all your teammates. Continue?`,
      OK_TEXT: 'Yes',
      CANCEL_TEXT: 'No',
    },
    LOGOUT_DIALOG: {
      TITLE: 'Confirm logout',
      TEXT: <span>You are going to <b>logout</b>. Continue?</span>,
      OK_TEXT: 'Ok',
      CANCEL_TEXT: 'Cancel',
    },
    SAVE_EDITOR_CHANGES_DIALOG: {
      TITLE: 'Warning',
      TEXT: 'Want to close editor without saving changes?',
      OK_TEXT: 'Continue editing',
      CANCEL_TEXT: 'Close',
    },
    EDITOR_SAVE_AS_REVISION: {
      TITLE: 'Save changes',
      TEXT: 'Want to save the edited file as a new revision?',
      OK_TEXT: 'Save',
      CANCEL_TEXT: 'Continue editing',
    },
    EDITOR_CANT_SAVE_AS_REVISION: {
      TITLE: 'Warning',
      TEXT: (
        fileExtension,
        isRevisionsAllowed,
      ) => `Editor won’t be able to save your modified ${fileExtension.toUpperCase()} file back to ${appName}.</br>
         You will have to <b>download</b> the edited file on your device${isRevisionsAllowed ? ` and <b>upload it manually</b> as a new revision to ${appName}` : ''
}.`,
      LABEL: (fileExtension) => `Do not show this warning about ${fileExtension.toUpperCase()} again`,
    },
    DRAG_AND_DROP_ONLY_ASSETS: {
      TITLE: 'Error',
      TEXT: 'Please note that  drag and drop will work only for assets here',
      TEXT_CANCEL: 'Ok',
      TEXT_OK: null,
    },
    GENERATING_KEYWORDS: {
      title: 'Unsupported Assets Detected',
      text: 'Some non-image items were detected in the current selection and will not be processed. AI keywording works only with image assets.  Your AI asset processing limit will remain unaffected.',
      textError: 'All selected assets have unsupported format and cannot be sent to processing by our AI.',
      TEXT_OK: 'Ok',
    },
    GENERATING_FACES: {
      title: 'Unsupported Assets Detected',
      text: 'Some non-image items were detected in the current selection and will not be processed. Face Recognition works only with image assets.  Your AI asset processing limit will remain unaffected.',
      TEXT_OK: 'Ok',
    },
    INCORRECT_EXTENSION_ERROR: 'Unsupported formats',
    EDITOR_CANT_DOWNLOAD_FILE: {
      TITLE: 'Error',
      TEXT:
        `For some reason, Editor can’t get access to this file. Here’s what you can do:<ul><li>Refresh this page and try again later</li><li>Check if this file is still available in your Cloud Storage</li><li>Contact us at <a class="picsioLink" href="mailto:${supportEmail}">${supportEmail}</a> and we’ll see what we can do</li></ul>`,
    },
    CHANGE_GOOGLE_DRIVE: {
      TITLE: 'Attention! Storage reset!',
      TEXT:
        `By changing your cloud storage account, you will lose all the changes you made in ${appName}. Do you confirm?`,
      OK_TEXT: 'Confirm',
      CANCEL_TEXT: 'Cancel',
    },
    CHANGE_WORKING_FOLDER: {
      TITLE: 'Warning! Storage reset!',
      TEXT: (storageType) => (storageType === 's3'
        ? `You are about to destroy all the information about your assets and the library itself in your ${appName} account. When you are changing your working folder all the changes you’ve made in ${appName} will be lost. This operation is not reversible and we will not be able to restore your information. Please note that your assets will remain in your Amazon S3 Bucket working folder.<br><br> If you are not sure about this action please contact <a class="picsioLink" href="mailto:${supportEmail}">${supportEmail}</a> and we will help you with the issue. If you are sure to proceed, please type “erase all data in my library”`
        : `You are about to destroy all the information about your assets and the library itself in your ${appName} account. When you are changing your working folder all the changes you’ve made in ${appName} will be lost. This operation is not reversible and we will not be able to restore your information. Please note that your assets will remain in your Google Drive working folder.<br><br> If you are not sure about this action please contact <a class="picsioLink" href="mailto:${supportEmail}">${supportEmail}</a> and we will help you with the issue. If you are sure to proceed, please type “erase all data in my library”`),
      OK_TEXT: 'Confirm',
      CANCEL_TEXT: 'Cancel',
    },
    SOMETHING_WENT_WRONG: {
      TITLE: 'Oops! Something went wrong',
      TEXT:
        `<p>It seems you've come across a bug we've been hunting for a long time. Please, send an email to <a href="mailto:${supportEmail}?subject=\${subject}&body=\${body}">${supportEmail}</a> to inform us about this. We'll really appreciate if you could tell us how you got here. Also, please copy and paste this information in your email:</p><code>\${code}</code><p>It's a rare error indeed, so refreshing your browser should probably help. Thanks for your patience!</p>`,
      OK_TEXT: 'Refresh page',
      CANCEL_TEXT: 'Close',
    },
    WAIT_AND_RETRY: {
      TITLE: 'Too many requests',
      TEXT: ({ subject, body, code }) => (
        <>
          <p>
            It seems that you\'ve sent too many requests to your cloud storage. Please wait for a
            few minutes and press "Retry" button. In case this error persists, please
            email us at{' '}
            <a href={`mailto:${supportEmail}?subject=${subject}&body=${body}`}>{supportEmail}</a>.
            We'll really appreciate if you told us how you got here. Also, please copy and
            paste this information in your email:
          </p>
          <code>{code}</code>
          <p>
            It's a rare error indeed, so refreshing your browser should probably help. Thanks for
            your patience!
          </p>
        </>
      ),
      OK_TEXT: 'Retry',
      CANCEL_TEXT: 'Close',
    },
    FILE_DELETED_FROM_GD: {
      TITLE: 'Cannot access the file',
      TEXT:
        `It seems that the file you're trying to access was deleted from your cloud storage. Please check if the file is still there. If the file is OK, please tell us about the problem at <a href="mailto:${supportEmail}?subject=The file is not here">${supportEmail}</a>`,
      OK_TEXT: 'Ok',
    },
    LIGHTBOARD_FOLDER_NOT_FOUND_IN_GD: {
      TITLE: 'Cannot access the lightboard',
      TEXT:
        `It seems that the folder you're trying to access was deleted from your cloud storage. Please check if the folder is still there. If the folder is OK, please tell us about the problem at <a href="mailto:${supportEmail}?subject=Lightboards folder is not found in your cloud storage">${supportEmail}</a>`,
      OK_TEXT: 'Ok',
    },
    NO_SPACE_LEFT: {
      TITLE: 'Storage disk space used up',
      TEXT:
        "It seems that your cloud storage is full. Please make sure that you have enough disk space. You can delete something you don't need or just buy more space",
      OK_TEXT: 'Ok',
      CANCEL_TEXT: 'Close',
    },
    NO_SIZE_LEFT: {
      TITLE: 'Storage is used up',
      TEXT:
        `Your storage has reached its limit, so ${appName} cannot upload any more files. Contact as at <a class="picsioLink" href="mailto:${supportEmail}">${supportEmail}<a> to purchase more GBs for your account.`,
    },
    SLOW_CONNECTION: {
      TITLE: 'Your connections is slow',
      TEXT:
        `<p>Seems that you have a slow or unstable internet connection. Please check your connection and retry.</p><p><a href="https://help.pics.io/pics-io-settings/optimization-for-slow-internet-connection" target="_blank">Here</a> you can find some advice on how to optimize your work in ${appName} for slow internet connection</p>`,
      OK_TEXT: 'Retry',
      CANCEL_TEXT: 'Ok',
    },
    UPLOAD_QUEUE_BUSY: {
      TITLE: 'Not so fast, please!',
      TEXT:
        `We are already uploading assets to ${appName}. Please wait until this process if finished, then add more assets.`,
    },
    btnYes: 'Yes',
    btnNo: 'No',
    btnOk: 'Ok',
    btnCreate: 'Create',
    btnCancel: 'Cancel',
    btnClose: 'Close',
    btnConfirm: 'Confirm',
    btnDelete: 'Delete',
    btnRemove: 'Remove',
    btnBuyMore: 'Buy more',
    btnSubscribe: 'Subscribe',
    btnReinstall: 'Reinstall',
    btnRestore: 'Restore',
    REQUIRED_COMMENT: {
      TITLE: 'Add a comment',
      TEXT:
        'According to team policy, you are required to add a comment every time you upload a new revision.',
      TEXTEXPLAIN: 'Let your teammates know what has changed in this revision.',
    },
    FILES_NOT_SELECTED: {
      TITLE: 'Ooops! Files were not selected',
      TEXT: 'Please press OK and take one step back to select file(s) for uploading.',
    },
    WARNING_ALLOWED_COLLECTION: {
      TITLE: 'Warning',
      TEXT:
        `<p>You are about to deny access to all collections in ${appName}. Teammates with this role will not see any of your assets.</p><p>Are you sure you want to continue?</p>`,
    },
    WARNING_EDIT_ASSET_COLLECTIONS: {
      TITLE: 'Warning',
      TEXT:
        'It seems that you don’t have permission to manage collections for some of the selected assets. Please contact your team owner for further assistance.',
    },
    WARNING_EDIT_ASSET_KEYWORDS: {
      TITLE: 'Warning',
      TEXT:
        'It seems that you don’t have permission to attach keywords to some of the selected assets. Please contact your team owner for further assistance.',
    },
    WARNING_CHANGE_ASSETS_ORDER: {
      TITLE: 'Warning',
      TEXT:
        'Custom sorting mode is already applied to this collection. Do you want to proceed and change the current sorting mode?',
      OK_TEXT: 'Yes, change the order',
      CANCEL_TEXT: 'No, I don’t',
    },
    WARNING_COLLECTION_NAME_LENGTH: {
      TITLE: 'Create new collection error',
      TEXT:
        'Collection cannot be created. <br> It’s name is too long. Maximum allowed length is 500 characters. Shorten the name and try again.',
      OK_TEXT: 'Ok',
    },
    MOVE_COLLECTION_DIALOG: {
      TITLE: (collectionName) => `Move '${collectionName}' collection`,
      OK_TEXT: 'Move',
      CANCEL_TEXT: 'Cancel',
    },
    COPY_COLLECTIONS_TREE_DIALOG: {
      TITLE: 'Select collection to duplicate to',
      OK_TEXT: 'Duplicate',
      CANCEL_TEXT: 'Cancel',
    },
    COPY_COLLECTION_RADIO_DIALOG: {
      TITLE: 'Duplicate Collection',
      COPY_ONE: 'Duplicate only selected collection',
      COPY_ALL: 'Duplicate selected collection and it’s sub-collections',
      OK: 'Duplicate',
      CANCEL: 'Cancel',
      DESCRIPTION: 'Files WILL NOT be duplicated, only collections structure',
    },
    COPY_COLLECTION_ERRORS: {
      SOMETHING_WENT_WRONG: {
        TITLE: 'Something went wrong',
        TEXT:
          `<p>Please try again. Contact <a class="picsioLink" href="mailto:${supportEmail}">${appName}</a> support if the error repeats.</p>`,
      },
      LIMIT_EXCEEDED: {
        TITLE: 'Cannot duplicate collections',
        TEXT: 'Only less than 100 collections can be copied in one go. Choose fewer collections and try again.',
      },
    },
    MOVE_ASSETS_DIALOG: {
      TITLE: 'Asset organization options',
      TEXT: (count) => <>Specify the action you'd like to perform on <span className="highlight">{count} selected {pluralize('asset', count)}</span>:</>,
      COPY_RADIO: {
        LABEL: (collectionName) => `Add to <span class="highlight">${collectionName}</span>`,
        DESCRIPTION: 'Place the same asset in multiple collections. Assets added to multiple collections aren\'t moved or duplicated, they represent original files with the same metadata.',
      },
      MOVE_RADIO: {
        LABEL: (collectionName) => `Move to <span class="highlight">${collectionName}</span>`,
        DESCRIPTION: 'Remove the asset from all current collections and relocate it exclusively to the selected one.',
      },
      CHECKBOX_LABEL: "Don't show it again",
      OK_TEXT: 'Confirm',
      CANCEL_TEXT: 'Cancel',
    },
    VIDEO_ERRORS: {
      TITLE: 'Error',
      SOMETHING_WENT_WRONG: {
        TITLE: 'Something went wrong',
        TEXT:
          `<p>Please try again. Contact <a class="picsioLink" href="mailto:${supportEmail}">${appName}</a> support if the error repeats.</p>`,
      },
      UNABLE_TO_PROCESS:
        'Sorry, your cloud storage does not allow us to process this type of file. Please try to open it in your storage web interface.',
      NO_PERMISSION_TO_ACCESS: "Sorry, you don't have permission to access this video.",
      MAX_PLAYBACKS_EXCEEDED:
        'Sorry, video cannot be played because the maximum number of views has been exceeded.',
      OK_TEXT: 'Ok',
      CANCEL_TEXT: '',
    },
    DISALLOW_COLLECTION: {
      TITLE: 'Change permissions',
      TEXT:
        'You are about to change permissions for collection. Please note that some assets from this collection will be still available in the Lightboards of your teammates.',
      OK_TEXT: 'Continue',
      CANCEL_TEXT: 'Cancel',
    },
    ASSIGN_ROLE_WARNING: {
      TITLE: 'Change roles',
      TEXT:
        'You are about to change the role of your teammate. As a result his permissions on some collections will change. But, please note that some assets within these collections will be still available in the Lightboards of your teammate.',
      TEXT_MULTIPLE:
        'You are about to change the roles of your teammates. As a result, their permissions on some collections will change.',
      OK_TEXT: 'Confirm',
      CANCEL_TEXT: 'Cancel',
    },
    CAPTCHA: {
      TITLE: 'Suspicious traffic from your network',
      TEXT: (text) => `Our system has detected an unusual traffic from your network. Please pass our robot test to prove that you are a human. Solve the following puzzle: ${text}.`,
      OK_TEXT: 'Confirm',
    },
    CAPTCHA_ERROR: {
      TEXT: 'Please try again later',
    },
    FILE_TO_LARGE: {
      TITLE: 'File upload limit',
      TEXT: (maxSize, currentSize) => `The maximum upload file size is <strong>${maxSize}</strong>.<br/> The size of the file you are uploading is <strong>${currentSize}</strong>.<br/>Please reduce the file size and upload it again.`,
      TEXT_IN_GENERAL:
        'Maximum file size exceeded.<br/>Please reduce the file size and upload it again.',
    },
    CANNOT_UPLOAD_FILE: 'Cannot upload file',
    INVALID_FILE_TYPE: {
      TITLE: 'File format is invalid',
      TEXT: (extension) => `You can upload the files in the following formats: .jpeg, .jpg, .png, .gif. The file you are uploading is in ${extension} format. Please use the supported file format and try again.`,
      TEXT_IN_GENERAL:
        'File format is invalid.<br/> Please use supported file format and try again.',
    },
    ASSETS_LIMIT: {
      TITLE: 'Assets limit during trial exceeded',
      TEXT:
        'You’ve reached a 50 000 assets limit on a trial period. You can purchase a subscription to upload as many assets as you need.',
    },
    DOWNLOAD_CONSENT: {
      TITLE: (title) => title,
      TEXT: (text) => text,
      LABEL: "Don't show again",
      OK_TEXT: 'Confirm',
      CANCEL_TEXT: 'Reject',
    },
    UNDERSTAND: 'I understand',
    CANCEL: 'Cancel',
    EDIT: 'Edit',
    DOWNLOAD_ASSETS_QUANTITY_LIMITATIONS: {
      TITLE: 'Too many files',
      TEXT: (countLimit) => `More than ${countLimit} files are selected for download. Please unselect a few files and try again.`,
    },
    DOWNLOAD_ASSETS_SIZE_LIMITATIONS: {
      TITLE: 'Archive size limit exceeded',
      TEXT: (sizeLimit) => `You have created an archive exceeding ${sizeLimit}. Unselect a few assets in your selection and try downloading again.`,
    },
    DOWNLOAD_COLLECTION_EMPTY: {
      TITLE: 'Download is not possible',
      TEXT: 'Collection is empty. Download is not possible.',
    },
    DOWNLOAD_COLLECTION_RESTRICTED: {
      TITLE: 'Download is restricted',
      TEXT:
        'You do not have permissions to download assets from these collections. Please contact your team managers for support.',
    },
    DOWNLOAD_COLLECTION_LIMITED: {
      TITLE: 'Limited download permissions',
      TEXT:
        'Some assets within these collections are restricted for download. Please check your download permissions and try again or continue without these assets.',
      OK_TEXT: 'Continue',
      CANCEL_TEXT: 'Discard',
    },
    SELECT_COLLECTION: {
      TITLE: 'Select collection to restore',
      OK: 'Confirm',
      CANCEL: 'Cancel',
    },
    UPLOAD_KEYWORDS_PERMISSION: {
      TITLE: 'No permission for keywords',
      TEXT:
        'You do not have a permission to manage keywords fields. Contact the team manager, if you need this permission.',
      OK_TEXT: 'Ok',
    },
    ARCHIVE_COLLECTION_DIALOG: {
      TITLE: 'Confirm archiving',
      TEXT: (name, websites) => `You are about to archive the following items: <span class="highlight">${name}</span> with all the nested collections and assets. <br/> This action requires confirmation. ${websites.length
        ? `<br/><br/> <span class="highlight">Attention!</span> This operation will also remove the website: ${websites.map(
          (website) => `<br/> <a href='${website}' target='_blank'>${website}</a>`,
        )}`
        : ''
      }`,
      OK: 'Confirm',
      CANCEL: 'Cancel',
    },
    UNARCHIVE_COLLECTION_DIALOG: {
      TITLE: 'Confirm unarchiving',
      TEXT: (name, parentNames) => `You are about to unarchive the following items: <span class="highlight">${name}</span> with all the nested collections and assets. <br/> This action requires confirmation. ${parentNames.length
        ? `<br/><br/> <span class="highlight">Attention!</span> The following parent collection(s) will be also unarchived: <span class="highlight">${parentNames}</span>`
        : ''
      }`,
      OK: 'Confirm',
      CANCEL: 'Cancel',
    },
    ARCHIVE_ASSET_DIALOG: {
      TITLE: 'Confirm archiving',
      TEXT: (name, link) => `You are about to archive the following items: <span class="highlight">${name}</span>. <br/> This action requires confirmation. ${link
        ? `<br/><br/> <span class="highlight">Attention!</span> After this operation, the asset will become inaccessible via the following link: <a href="${link}" target="_blank">${link}</a>`
        : ''
      }`,
      OK: 'Confirm',
      CANCEL: 'Cancel',
    },
    ARCHIVE_ASSETS_DIALOG: {
      TITLE: 'Confirm archiving',
      TEXT: (assets, count, sharedAssets) => `You are about to archive the following items: ${assets}${count > 0 ? ` and <span class="highlight">${count}</span> more.` : '.'
      } <br/> This action requires confirmation. ${sharedAssets.length
        ? `<br/><br/> <span class="highlight">Attention!</span> Some assets from those you want to archive are shared. After this operation, they will become inaccessible via the following links: ${sharedAssets.map(
          (link) => `<br/> <a href='${link}' target='_blank'>${link}</a>`,
        )}`
        : ''
      }`,
      OK: 'Confirm',
      CANCEL: 'Cancel',
    },
    ARCHIVE_ALL_ASSETS_DIALOG: {
      TITLE: 'Confirm archiving',
      TEXT: (collectionName) => `You are going to archive all assets in the <span class=""highlight"">${collectionName}</span> collection. <br/> Do you want to archive the <span class=""highlight"">${collectionName}</span> collection?`,
      OK: 'Confirm',
      CANCEL: 'Cancel',
    },
    UNARCHIVE_ASSETS_DIALOG: {
      TITLE: 'Confirm unarchiving',
      TEXT: (assets, count) => `You are about to unarchive the following items: ${assets}${count > 0 ? ` and <span class="highlight">${count}</span> more.` : '.'
      } <br/> This action requires confirmation.`,
      OK: 'Confirm',
      CANCEL: 'Cancel',
    },
    UNARCHIVE_ALL_ASSETS_DIALOG: {
      TITLE: 'Confirm unarchiving',
      TEXT: (collectionName) => `You are going to unarchive all assets in the <span class=""highlight"">${collectionName}</span> collection. Do you want to unarchive the <span class=""highlight"">${collectionName}</span> collection?`,
      OK: 'Confirm',
      CANCEL: 'Cancel',
    },
    UNARCHIVE_ASSETS_TO: {
      TITLE: 'Select collection to restore to',
      OK: 'Restore',
      CANCEL: 'Cancel',
    },
    TEAMMATE_ARCHIVED_COLLECTION: {
      TITLE: 'Collection archived',
      TEXT: (initiator, name) => `${initiator} archived the <span class="highlight">${name}</span> collection.`,
    },
    TEAMMATE_UNARCHIVED_COLLECTION: {
      TITLE: 'Collection unarchived',
      TEXT: (initiator, name) => `${initiator} unarchived the <span class="highlight">${name}</span> collection.`,
    },
    TEAMMATE_DELETED_ARCHIVED_COLLECTION: {
      TITLE: 'Collection deleted',
      TEXT: (initiator, name) => `${initiator} deleted the <span class="highlight">${name}</span> collection.`,
    },
    TEAMMATE_ARCHIVED_ASSET: {
      TITLE: 'Asset archived',
      TEXT: (initiator, name) => `${initiator} archived the <span class="highlight">${name}</span> asset.`,
    },
    TEAMMATE_UNARCHIVED_ASSET: {
      TITLE: 'Asset unarchived',
      TEXT: (initiator, name) => `${initiator} unarchived the <span class="highlight">${name}</span> asset.`,
    },
    ASSETS_LIMIT_APP: {
      TITLE: 'Assets limit reached',
      TEXT: (assetsLimit, isTrialUser = false) => `You have reached a ${assetsLimit} assets limit for ${isTrialUser ? 'a trial period' : 'your plan'
      }. To continue adding more assets, consider subscribing to one of our plans on Billing page.`,
      TEXT_OK: 'Go to Billing',
    },
    ASSETS_LIMIT_INBOX: {
      TITLE: 'Inbox has reached files limit',
      TEXT: () => 'The inbox has reached its limit. You can notify its owner so they address this issue.',
      TEXT_OK: 'Notify inbox owner',
      TEXT_CANCEL: 'Cancel',
      TEXT_SUCCESS: 'Notification sent to the inbox owner',
    },
    ROLES_LIMIT_APP: {
      TITLE: 'Roles limit reached',
      TEXT: (rolesLimit, isTrialUser = false) => `You have reached ${rolesLimit} roles limit for ${isTrialUser ? 'a trial period' : 'your plan'
      }. To continue adding more roles, consider subscribing to one of our plans on Billing page.`,
      TEXT_OK: 'Go to Billing',
    },
    KEYWORDS_MERGE: {
      TITLE: 'Choose merge-to keyword.',
      OK: 'Merge',
      CANCEL: 'Cancel',
    },
    KEYWORDS_MERGE_ALL_SELECTED: {
      TITLE: 'Can\'t merge keywords',
      TEXT: 'To merge a keyword, at least one other keyword must be unselected. Try deselecting one or more keywords and repeat the operation.',
      OK: 'Ok',
    },
    KEYWORDS_MERGE_CONFIRM: {
      TITLE: (count) => `Merge ${pluralize('keyword', count)}`,
      TEXT: (count) => `You're going to merge ${pluralize('keyword', count)}. The operation is not reversible. Continue?`,
      OK: 'Merge',
      CANCEL: 'Cancel',
    },
    KEYWORDS_NOT_IN_ROOT: {
      TITLE: (count) => `Merge ${pluralize('keyword', count)}`,
      TEXT: (keywords) => `Merging is possible only for keywords that are not sub-keywords of other keywords. The following keywords are sub-keywords: ${keywords} <br/> By continuing this merge process, you merge all selected keywords except the above mentioned.<br/>`,
      OK: 'Continue',
      CANCEL: 'Cancel',
    },
    KEYWORDS_MERGE_ONLY_ROOT: {
      TITLE: (count) => `Merge ${pluralize('keyword', count)}`,
      TEXT: 'Only root-keywords merge is possible. All of the selected keywords are attached to other keywords. Detach them and try merging again.',
      OK: 'Ok',
    },
    KEYWORDS_DELETE_LIMIT: {
      TITLE: (count) => `Delete ${count} ${pluralize('keyword', count)}`,
      TEXT: (limit) => `It is not possible to delete more than <span class="highlight">${limit}</span> keywords in one batch due to processing speed limitations.<br />Select fewer keywords and try again.`,
      CANCEL: 'Ok',
    },
    KEYWORDS_DELETE: {
      TITLE: (count, isDeleteAll) => `Delete ${isDeleteAll ? 'ALL' : ''} ${pluralize('keyword', count)}${isDeleteAll ? '?' : ''}`,
      TEXT: (name, count, isParent) => (
        <>
          <Choose>
            <When condition={name}>
              You're about to delete <span className="highlight">{name}</span>.
              This action cannot be undone and will remove this keyword from all assets it's added to.

            </When>
            <Otherwise>
              You're about to delete <span className="highlight">{pluralize('keyword', count, true)}</span>. This action cannot be undone and will remove these keywords from all assets it's added to.
            </Otherwise>
          </Choose>
          <If condition={isParent}>
            <br />
            <br />
            <InfoBlock>To delete the parent keyword without affecting nested sub-keywords, move it out of the hierarchy by dragging it to the root level,then delete it as a separate keyword</InfoBlock>
          </If>
        </>
      ),
      OK: 'Delete',
      CANCEL: 'Cancel',
    },
    RESTRICTED_ASSET_DETECTED: {
      TITLE: 'Restricted asset detected',
      TEXT: 'Please note that you are about to make the restricted assets public and shared. Are you sure you want to continue?',
      BTN_OK: 'Ok',
      BTN_CANCEL: 'Cancel',
    },
    ERROR_CREATING_THUMBNAIL:
      `Cannot create custom thumbnail.<br> Please try again later or contact <a class="picsioLink" href="mailto:${supportEmail}">${supportEmail}<a>.`,
    ADD_ASSETS_FROM_URLS: {
      TITLE: 'Add assets from URLs',
      TOTAL: 'Total URLs',
      LABEL: 'Only publicly shared links can be displayed within your library as external links. Make sure that a link you’re about to paste here is shared and not private.',
      PLACEHOLDER: 'Paste URL with "https://" here',
      OK: 'Add',
      CANCEL: 'Cancel',
    },
    WARNING_ADDON_UPGRADE: {
      TITLE: 'Upgrade subscription with add-on',
      TEXT: (name, count) => `You are about to add <b>${pluralize(name, count, true)}</b> to your subscription.`,
      OK: 'Upgrade',
      CANCEL: 'Cancel',
    },
    PUBLISH_WEBSITE: {
      TITLE: 'Publish website',
      TEXT: 'Select one of the available presets or create new custom settings',
      PUBLISH: 'Publish',
      CANCEL: 'Cancel',
      CONTINUE: 'Continue',
      DEFAULT_PRESET: 'Create custom settings for publishing',
      CHECKBOX_LABEL: 'Skip this step next time',
      SPINNER: 'Loading presets...',
    },
    SAVE_PRESET: {
      TITLE: 'Save preset',
      TEXT: 'Create name for new preset',
      OK: 'Save',
      INPUT_LABEL: 'Name new preset',
      INPUT_PLACEHOLDER: 'Type preset name',
      INPUT_ERROR: 'This name is already used!',
    },
    DELETE_PRESET: {
      TITLE: 'Delete preset',
      TEXT: (name) => `You are about to delete <b>preset<b><br><br><b>${name}<b>`,
      OK: 'Delete',
    },
    RENAME_PRESET: {
      TITLE: 'Rename preset',
      OK: 'Save',
      INPUT_LABEL: 'Preset name',
      INPUT_PLACEHOLDER: 'Type preset new name',
      INPUT_ERROR: 'This name is already used!',
    },
    APPLY_PRESET: {
      TITLE: 'Switch to another preset',
      TEXT: 'You are about to apply new preset. Your previous settings will be lost.',
      OK: 'Continue',
    },
    RESTORE_ASSETS: {
      title: (count) => `Restore asset${count > 1 ? 's' : ''}`,
      text: (count) => `You are about to restore ${count > 1 ? `${count} deleted assets` : 'a deleted asset'}. Select the location for restoration`,
      previousCollectionRadioLabel: 'Restore to previous collection(s)',
      selectCollectionRadioLabel: 'Select collection(s) to restore',
    },
    REMOVE_FROM_COLLECTIONS: {
      TITLE: 'Remove from collection',
      TEXT: (name) => <>The asset <span className="highlight">{name}</span> is added to the following collections:</>,
      TEXT_NO_COLLECTIONS: 'The asset doesn\'t belong to any collection',
      INFO_BLOCK: 'If you would like to entirely delete it from the library, use “Move to trash” instead',
      CHECKBOX: 'Don\'t show this window and remove assets from selected collection only next time',
      OK: 'Remove from all collections',
      CANCEL: 'Close',
    },
    REMOVE_FROM_ALL_COLLECTIONS: {
      TITLE: 'Remove from all collections',
      TEXT: (name) => <p>You are about remove <span className="highlight">{name}</span> asset from all collections and moved it to root collection</p>,
      OK: 'Confirm',
      ERROR: `Cannot remove all collections from the asset.<br> Please try again later or contact <a class="picsioLink" href="mailto:${supportEmail}">${supportEmail}<a>.`,
    },
    GENERATING_DESCRIPTION_UNSUPPORTED: {
      title: 'Unsupported Assets Detected',
      text: 'Some non-image items were detected in the current selection and will not be processed. Image Captioning works only with image assets.  Your AI asset processing limit will remain unaffected.',
      textError: 'All selected assets have unsupported format and cannot be sent to processing by our AI.',
      TEXT_OK: 'Ok',
    },
    GENERATING_DESCRIPTION_CHOOSE_OPTION: {
      title: 'Update Your Asset Description',
      text: 'Our AI can help enhance your asset descriptions. Choose how you want to proceed:',
      margeDescriptionLabel: 'Combine the existing description with the new AI-generated content',
      replaceDescriptionLabel: 'Replace the existing description entirely with the new AI-generated text',
      okName: 'Confirm',
      cancelName: 'Cancel',
      checkboxLabel: 'Don’t show it again',
    },
    DETACH_SEVERAL_KEYWORDS: {
      TITLE: 'Confirm Keyword Removal',
      TEXT: (keywordCount, assetCount) => <>You are about to remove {keywordCount} keywords from {assetCount} selected {pluralize('asset', assetCount)}.<br />Are you sure you want to proceed?</>,
      CHECKBOX: 'Don\'t show it again',
    },
    RETRY_IMPORT_ASSETS: {
      TITLE: 'Retry Import',
      TEXT: 'It looks like some other assets in this collection also failed to import. You can either retry importing just this asset or attempt to import all failed assets in the collection.',
      RADIO_LABEL_ONE: 'Retry This Asset',
      RADIO_LABEL_ALL: 'Retry All Failed Assets',
    },
    ERROR_ADD_MEDIA_TO_PRODUCT: {
      TITLE: 'Error adding media to product',
      TEXT_UNSUPPORTED_FORMAT: () => (
        <>
          <p>The system encountered the following error while trying to associate the selected media with your products:</p>
          <p><span className="highlight">Unsupported File Format</span></p>
          <p>Please ensure that:</p>
          <p>
            - Images are in <span className="highlight">JPEG</span>, <span className="highlight">PNG</span>, <span className="highlight">WEBP</span>, <span className="highlight">HEIC</span>, and <span className="highlight">GIF</span> formats.<br />
            - Videos are <span className="highlight">MOV</span>, <span className="highlight">MP4</span>, and <span className="highlight">WEBM</span> or embedded from YouTube or Vimeo.<br />
            - 3D Models are in <span className="highlight">GLB</span> or <span className="highlight">USDZ</span> formats.<br />
          </p>
          <p>For more details on file size limits and supported formats, visit our <a className="picsioLink" href="https://help.toriut.com" target="_blank" rel="noreferrer">Help Center</a> for a full guide on media asset requirements.</p>
        </>
      ),
      TEXT_UNSUPPORTED_FILE_SIZE: () => (
        <>
          <p>The system encountered the following error while trying to associate the selected media with your products:</p>
          <p><strong>File size exceeds the supported limit</strong></p>
          <p>Please ensure that:</p>
          <p>
            - Images are no larger than <span className="highlight">4472 x 4472 px</span>, <span className="highlight">20MP</span> or <span className="highlight">20 MBs</span>.<br />
            - Videos are no longer than <span className="highlight">10 minutes</span> and under <span className="highlight">1 GB</span> in size.<br />
            - 3D Models are in <span className="highlight">GLB</span> or <span className="highlight">USDZ</span> formats, and under 500 MB.<br />
          </p>
          <p>For more details on file size limits and supported formats, visit our <a className="picsioLink" href="https://help.toriut.com" target="_blank" rel="noreferrer">Help Center</a> for a full guide on media asset requirements.</p>
        </>
      ),
      TEXT_UNSUPPORTED_FORMAT_AND_FILE_SIZE: () => (
        <>
          <p>The system encountered the following error while trying to associate the selected media with your products:</p>
          <p><span className="highlight">File size exceeds the supported limit + Unsupported file format</span></p>
          <p>Please ensure that:</p>
          <p>
            - Images are in <span className="highlight">JPEG</span>, <span className="highlight">PNG</span>, <span className="highlight">WEBP</span>, <span className="highlight">HEIC</span>, <span className="highlight">GIF</span> formats and no larger than <span className="highlight">4472 x 4472 px</span>, <span className="highlight">20MP</span> or <span className="highlight">20 MBs</span>.<br />
            - Videos are <span className="highlight">MOV</span>, <span className="highlight">MP4</span>, <span className="highlight">WEBM</span> or hosted on YouTube/Vimeo, less than <span className="highlight">10 minutes</span> and under <span className="highlight">1 GB</span>.<br />
            - 3D Models are <span className="highlight">GLB</span> or <span className="highlight">USDZ</span> formats, and under <span className="highlight">500 MB</span>.<br />
          </p>
          <p>For more details on file size limits and supported formats, visit our <a className="picsioLink" href="https://help.toriut.com" target="_blank" rel="noreferrer">Help Center</a> for a full guide on media asset requirements.</p>
        </>
      ),
    },
    MIGRATION_ASSISTANCE: {
      title: 'Manual Migration Assistance',
      getContent: (cloudName) => (
        <>
          <p>‘Import from {cloudName}’ feature isn’t fully implemented in the app yet. However, our support team can assist you in completing this migration manually.</p>
          <p>Would you like to proceed and open a chat with our support team to get started?</p>
        </>
      ),
      textBtnOk: 'Proceed to chat',
      getMessage: (cloudName) => `Hi, I'd like to import my data from ${cloudName} to Pics.io. Could you guide me through the process?`,
    },
    BULK_AI_PROCESSING: {
      title: 'Bulk AI Processing',
      text: (
        <div>
          <div>You are about to process the selected assets using all available AI services:
            <span className="f-w-5"> Face Recognition, Auto-Keywording, Image Captioning, and Audio/Video Transcription.</span>
          </div>
          <br />
          <div>Some assets in your selection <span className="f-w-5">may not be compatible</span> with certain AI services.
            Only supported assets will be processed, while<span className="f-w-5"> unsupported file types will be skipped.</span>
          </div>
          <br />
          <div>
            This operation will run in the background, and changes may take some time to appear.
          </div>
          <br />
          <InfoBlock>
            <span>AI processing will not be applied to unsupported file formats, and your AI processing balance will not be deducted for these skipped files.</span>
          </InfoBlock>
          <br />
        </div>
      ),
      textBtnCancel: 'Cancel',
      textBtnOk: 'Confirm',
      checkboxLabel: 'Don’t show it again',
    },
    BULK_AI_PROCESSING_LIMIT_REACHED: {
      title: 'Al Processing Limit Reached',
      text: (
        <div>You reached the limit of assets / minutes available for Al processing.
          Click "Subscribe" below to add the <span>Al Kit</span> to your plan and continue using Face Recognition, Transcription, Al Keywording, and Image Captioning.
        </div>
      ),
      textBtnOk: 'Subscribe',
    },
    BILLING_ACCESS_REQUIRED: {
      title: 'Billing Access Required',
      text: (adminRoles) => <div><span>You don’t have permission to manage billing settings. For billing-related changes, please contact your team\'s billing admins:</span>{adminRoles && <div>{adminRoles}</div>}</div>,
      textBtnOk: 'Ok',
    },
  },

  SPINNERS: {
    GENERATING_THUMBNAIL: 'Generating thumbnail...',
    STARTING_APPLICATION: `Starting ${appName}...`,
    LOADING_LIBRARY: 'Loading library...',
    LOADING_COLLECTIONS: 'Loading collections...',
    LOADING_KEYWORDS: 'Loading keywords...',
    CONNECTING_STORAGES: (storageName) => (storageName === 'picsioStorage'
      ? `Connecting ${appName} storage...`
      : storageName === 's3'
        ? 'Connecting Amazon S3...'
        : 'Connecting Google Drive...'),
    CHECKING_SUBSCRIPTION: 'Checking subscription...',
    SEARCHING: 'Searching...',
    LOADING: 'Loading...',
    DOWNLOADING: 'Downloading...',
    CHECKING_DOWNLOAD_PERMISSIONS: 'Checking download permissions...',
  },

  TOAST: {
    WARNING_COLLECTION_ALREADY_EXISTS: {
      TEXT: 'Collection with this name already exists',
    },
    WARNING_BUSY_COLLECTION: {
      TITLE: 'Incomplete operations error',
      TEXT: 'This collection is still processing. Please wait a moment and try again',
    },
    UPGRADE_ADDON_SUCCESS: (featureName) => <><b>{featureName}</b> added to your subscription</>,
    COLLECTION_ARCHIVED: (collectionName) => `Collection "${collectionName}" archived`,
    COLLECTION_COPIED: (collectionName) => `Collection "${collectionName}" duplicated`,
    COLLECTION_UNARCHIVED: (collectionName) => `Collection "${collectionName}" unarchived`,
    ASSETS_ARCHIVED: (assetsCount) => `${pluralize('asset', assetsCount, true)} archived`,
    ASSETS_UNARCHIVED: (assetsCount) => `${pluralize('asset', assetsCount, true)} unarchived`,
    COLLECTION_REMOVED: (collectionName) => `Collection "${collectionName}" moved to trash`,
    WARNING_THUMBNAIL_GENERATING:
      'Your custom thumbnail is on the way. It may take a couple of minutes.',
    REVISION_UPLOADED: 'Revision uploaded',
    NOT_COPIED: 'URL not copied',
    SYSTEM_MESSAGE: 'New version is released! Refresh page to see updates',
    FACE_RECOGNIZING_COMPLETE: (countAssets, countFaces) => `${pluralize('asset', countAssets, true)} recognized<br />${pluralize('face', countFaces, true)} have been found`,
    FACE_RECOGNIZING_COLLECTION_COMPLETE: (countAssets) => `${pluralize('asset', countAssets, true)} will be recognized in background`,
    FACE_RECOGNIZING_REJECTED: 'Face recognition is not supported for this file format',
    ADD_TO_FAVORITES_SUCCESS: (collectionName, actionText) => `"${collectionName}" ${actionText} favorites`,
    SAVED_SEARCHES_FAVORITE: (name, value) => `"${name}" ${value ? 'added to' : 'removed from'} "Favorites"`,
    UPDATE_USER_SEARCH_SETTINGS: () => <>User search settings cannot be updated. Refresh and try again</>,
    SEND_TO_RECOGNIZE_ONE: (countFaces) => `1 asset recognized<br>${pluralize('face', countFaces, true)} have been found`,
    SEND_TO_RECOGNIZE_ONE_BUTTON: 'See faces',
    SEND_TO_RECOGNIZE: (assetsCount) => `${pluralize('asset', assetsCount, true)} will be recognized in background`,
    PERSON_DELETED: 'Person deleted',
    PERSON_RENAMED: 'Person renamed',
    API_KEY: {
      CREATE: 'Can not create api key',
      GET: 'Can not get api keys',
      REMOVE: 'Can not remove api key',
    },
    REQUIRED_FIELDS: {
      ADD: 'Can not add required field',
      REMOVE: 'Can not remove required field',
      UPDATE: 'Can not update required field',
    },
    hiddenFields: {
      update: 'Can not update hidden fields',
    },
    HOT_KEY: (key) => `Useful hot keys: "${key} + mouse click" - selects a file<br />&mdash; "shift + mouse click" - selects a files range<br />&mdash; "${key} + A" - selects all files in current filter`,
    HOT_KEY_BUTTON: 'Don\'t show it again',
    NAME_CAN_NOT_INCLUDE_SLASH: 'Asset name can not include "/"(forward slash) character !',
    CANNOT_MOVE_COLLECTION: 'Can not move collection',
    CANNOT_MOVE_COLLECTION_INTO_ITSELF: 'Cannot move a collection into itself. Please choose a different destination',
    IMPORTING_ASSETS_COMPLETE: (countAssets) => `Import complete: ${pluralize('file', countAssets, true)} ${countAssets > 1 ? 'have' : 'has'} been successfully added`,
    IMPORTING_ASSETS_FAILED: (assets) => `Import failed: ${pluralize('file', assets.length, true)} ${assets.length > 1 ? 'were' : 'was'} not added`,
    IMPORTING_COLLECTION_COMPLETE: (folderName) => `Import complete: '${folderName}' folder has been successfully added`,
    IMPORTING_COLLECTION_FAILED: (collectionPaths) => `Import failed: ${pluralize('folder', collectionPaths.length, true)} ${collectionPaths.length > 1 ? 'were' : 'was'} not added`,
    ATTACHING_ASSETS_TO_PRODUCT_COMPLETE: 'Media attachment complete. Selected media has been added to the products.',
    ATTACHING_ASSETS_TO_PRODUCT_FAILED: 'Media attachment failed. Check your connection or try again later.',
    ATTACHING_ASSETS_TO_PRODUCT_SENT: 'Adding selected media. This may take a moment.',
    ASSETS_FOR_RETRY_NOT_FOUND: 'Assets for retry import not found',
    RETRY_IMPORT_ASSETS_FAILED: (assetCount) => `Retry ${pluralize('asset', assetCount)} import failed`,
    RETRY_IMPORT_COLLECTION_FAILED: 'Retry import failed for collection',
  },

  COLLECTION_IS_EXISTED_AND_ARCHIVED:
    'There is an archived collection with the same name. Try using a different name. ',
  UNABLE_TO_CREATE_COLLECTION: `Cannot create collection. Please, contact <a class="picsioLink" href="mailto:${supportEmail}">support</a>.`,
  UNABLE_TO_RENAME_COLLECTION: `Cannot rename collection. Please, contact <a class="picsioLink" href="mailto:${supportEmail}">support</a>.`,
  UNABLE_TO_REMOVE_COLLECTION: `Cannot remove collection. Please, contact <a class="picsioLink" href="mailto:${supportEmail}">support</a>.`,
  UNABLE_TO_REMOVE_COLLECTION_NO_GD_PERMISSIONS:
    `<p>The collection was <span class="highlight">not deleted</span> from ${appName}.<br />The user does not have sufficient <span class="highlight">permissions to delete</span> this folder from Cloud Storage Account.</p><p>Check permissions in Cloud Storage Account to perform the removal.<br />Consult our <a href="https://help.pics.io/en/articles/1269158-deleting-assets-from-pics-io" target="_blank">HelpCenter</a> for details.</p>`,
  UNABLE_TO_MOVE_COLLECTION:
    `Unable to move collection.<br /> Try again and contact <a class="picsioLink" href="mailto:${supportEmail}">support</a> if mistake persists.`,
  TargetCollectionInsideApiError: 'Can not move nested collection into parent. Select a different target location',
  UNABLE_TO_CHANGE_COLLECTION_COLOR:
    `Cannot change collection color. Please, contact <a class="picsioLink" href="mailto:${supportEmail}">support</a>.`,
  UNABLE_TO_CHANGE_COLLECTION_DESCRIPTION:
    `Cannot change collection description. Please, contact <a class="picsioLink" href="mailto:${supportEmail}">support</a>.`,
  UNABLE_TO_REMOVE_IMAGE: `Unable to remove asset. Please, contact <a class="picsioLink" href="mailto:${supportEmail}">support</a>`,
  THUMBNAIL_GENERATE_SUCCESS: 'Thumbnail generated',
  UNABLE_TO_GENERATE_THUMBNAIL: `Unable to generate thumbnail. Please, contact <a class="picsioLink" href="mailto:${supportEmail}">support</a>`,
  textBtnUndo: 'Undo',

  FOLDER_SYNC: {
    syncAlreadyRunningErrorTitle: 'Folder sync is not available now',
    syncAlreadyRunningErrorText: 'Wait a sec, please. It seems that sync is already running.',
    syncHasStartedTitle: 'Sync started',
    syncHasStartedText: `We have started syncing files in this collection with your storage, this operation will be running in background. You can continue using ${appName}`,
  },

  FILES_ARE_REMOVED: 'Files are removed',
  FILES_WERENT_REMOVED: "Files weren't removed. Please, try again later",

  ERROR_UPDATING_ASSET:
    `Error updating asset. Please try again. Contact us at <a class='picsioLink' href='mailto:${supportEmail}'>${appName}</a> support if the error repeats.`,
  ERROR_GETTING_AGGREGATED_DATA:
    `Error retrieving assets data. Please try again. Contact us at <a class='picsioLink' href='mailto:${supportEmail}'>${appName}</a> support if the error repeats.`,
  ERROR_ADD_REACTION:
    `Please try again. Contact <a class='picsioLink' href='mailto:${supportEmail}'>${appName}</a> support, if the error persists.`,

  TAGLIST: {
    errorItemNotValid: 'The {type} is not valid.',
    errorItemsNotValid: 'The {types} not valid.',
    errorItemIsEmpty: 'The field is empty.',
    errorItemAlreadyAdded: 'This {type} is already added.',
  },

  ACCOUNT: {
    title: 'My account',

    tabTitleProfile: 'Profile',
    tabTitleSettings: 'Settings',
    tabTitleNotifications: 'Notifications',
    tabTitleSecurity: 'Security',
    tabTitleLegal: 'Legal',

    errorName: 'Should always exists.',
    errorBlog: 'The link is not valid.',
    errorLinkStartURL: 'Link should start with',
    errorLinkNotValid: 'The link is not valid.',
    placeholderEnterEmail: 'Enter teammate\'s email(s)',

    titleProfile: 'Profile',
    inputLabelEmail: 'Email',
    linkResetPassword: 'Reset password',
    inputLabelName: 'Full name',
    inputPlaceholderName: 'Full name',
    inputLabelPosition: 'Position',
    inputPlaceholderPosition: 'Position',
    inputPhotoButtonText: 'Add your photo',
    inputPhotoDescription:
      'Please use small square images 125-250 pixels in a transparent background: PNG or GIF. You can also use small progressive JPG. Image size should not exceed 1 MB.',
    inputLabelPhone: 'Phone',
    inputLabelBlogURL: 'Blog url',
    inputLabelAbout: 'About',
    inputLabelContacts: 'Contacts',
    titleChangePassword: 'Change password',
    inputLabelCurrentPassword: 'Current password',
    inputLabelNewPassword: 'New password',
    inputLabelConfirmNewPassword: 'Confirm new password',

    sectionNameNetwork: 'Network',
    timeZone: 'Time zone',
    sectionPreviewLabel: 'Image size on preview screen',
    sectionUploadLabel: 'Upload one asset at a time',
    sectionTimeZoneLabel: 'Set time zone automatically',
    sectionUploadText:
      `If this option is set ${appName} will upload only one asset at a time. Useful for slow connections and heavy assets.`,

    sectionNameLocale: 'Date and time format',
    sectionLocaleText: `Change of this option affects date and time format in ${appName}.`,

    sectionNameScheme: 'UI color scheme',
    sectionSchemeLabelDark: 'Dark',
    sectionSchemeLabelLight: 'Light',

    noteSlackConnected: (teamName) => (
      <>
        {appName} bot connected to <a className="picsioLink" href="https://slack.com/apps/manage" rel="noreferrer" target="_blank">{teamName}</a> Slack team.<br /><br />To receive notifications, you need to invite bot to one of channels by sending message <b className="msgInvPicsio">/invite @picsio</b> to that channel.<br />
      </>
    ),
    noteSlackNotConnected: `${appName} is not connected to Slack.`,
    noteAskOwner: ' Ask your team owner to connect to Slack',
    noteNotificationWillSent: 'Notifications will be sent to',

    titleYouCan: 'You can also do the following:',

    linkSeeAllData: 'See all personal data',
    textSeeAllData:
      'You can see all personal information about yourself that you entered in your User Profile (name, email, role, userpic, position in your team, etc.)',

    linkDownloadAllData: 'Download all personal data',
    textDownloadAllData:
      'You can download all personal information about yourself that you entered in your User Profile (name, email, role, userpic, position in your team, etc.). The information will appear as a .js file in the downloads folder of your browser.',

    linkDeleteAllData: 'Delete all personal data',
    textDeleteAllData:
      `You can delete all personal information about yourself that you used during registration in ${appName}. This is an irreversible action, after which you will not be able to use ${appName} service. To regain access, you will have to register again.`,
    titleDeleteAllData: 'Delete all personal data',
    warningDeleteAllData: `Warning! You’re going to delete all your personal data from ${appName}!`,
    contentDeleteAllData: <span>You have the right to delete all information about yourself from {appName} and our partners’ databases, such as your name, email, role, userpic, position in your team, etc.<br /><br />Please note that this action is irreversible. After you choose to delete all your personal information from {appName} database, {appName} team will not be able to help you restore it. And you will no longer be able to use {appName} service unless you register again.<br /><br />{appName} team will delete all your personal data manually and beyond retrieval within the next 30 days. A notification will be sent to you when it is done. <br /><br />Please enter your email and password, and tick the box “I understand” to proceed with deleting.</span>,
    labelDeleteAllData: 'I understand',
    buttonDeleteAllData: 'Delete',
    dailogTextDeleteAllData:
      `Ok, we respect your decision. ${appName} team will delete all your personal data manually and beyond retrieval within the next 30 days. Upon completion, an email notification will be sent to you.`,

    linkRestrictProcessing: 'Restrict processing',
    textRestrictProcessing:
      `With this irreversible action you can prevent ${appName} team from using your email address and other personal information, which is necessary to provide you with technical support and identify you as a user. You will be able to use ${appName}, but without any support from ${appName} team.`,
    titleRestrictProcessing: 'Restrict processing',
    warningRestrictProcessing:
      `Warning! You’re going to restrict ${appName} from using your personal information!`,
    contentRestrictProcessing:
  <span>You have the right to restrict {appName} from using your personal information. However, with this irreversible action you will prevent {appName} team from providing you with technical support and identify you as a system user. You will be able to use {appName} service, but {appName} team will not be able to help you restore your password in case you forget it or render any other technical assistance if you need it.<br /><br />{appName} team will perform this action manually and beyond retrieval within the next 30 days. A notification will be sent to you when it is done.<br /><br />Please enter your email and password, and tick the box “I understand” to proceed with restricting.</span>,
    labelRestrictProcessing: 'I understand',
    buttonRestrictProcessing: 'Restrict',
    restricted: 'Processing restricted',

    linkUnsubscribe: 'Unsubscribe',
    textUnsubscribe:
      `You can choose not to receive any onboarding information, newsletters, and other marketing materials from ${appName} to your email.`,

    linkRevokeConsents: 'Revoke consent',
    textRevokeConsents:
      `You have the right not to agree with our Terms of Service / Privacy Policy. If you revoke your consent, you will not be able to access ${appName} service.`,
    titleRevokeConsents: 'Revoke consent',
    warningRevokeConsents:
      'Warning! You’re going to revoke your consent to our Terms of Service and Privacy Policy',
    contentRevokeConsents:
  <span>Your consent to our Terms of Service and Privacy Policy is the necessary condition so that {appName} could deliver any services to you. You will not be able to use {appName} until you accept them.<br /><br />If you are sure about revoking your consent, please enter your email and password, and tick the box “I understand” to proceed with this action.</span>,
    labelRevokeConsents: 'I understand',
    buttonRevokeConsents: 'Revoke',

    titleSecurity: 'Security',

    helpText: 'Learn how to edit your account info',
    labelAuthentication: 'Enable Two-factor authentication (2FA)',
    labelAuthenticationTooltip: 'Switch on the Two-factor authentication (2FA) for your account',
    setup2FANow: 'Set up Two-factor authentication (2FA) now',
  },

  TWO_FACTOR_AUTH: {
    scan:
      'Scan this image with your authenticator app. You will see a 6-digit code on your screen. Enter the code below to verify your phone.',
    inCase:
      'In case of emergency, the code below will be necessary to disable your Two-factor authentication (2FA) and access your account.',
    save: 'Store this code in safe place',
    input: 'Please input the code below:',
    complete: 'Two-factor authentication (2FA) enabled',
    error:
      `Something went wrong. Please try again. Contact <a class='picsioLink' href='mailto:${supportEmail}'>${appName}</a> support if the error repeats.`,
    termsTitle: 'Two-factor authentication (2FA) terms of use.',
    upgradeTooltip: () => (
      <>
        Two-factor authentication (2FA)n functionality is available on Small, Medium and Enterprise plan.{' '}
        <br /> Click to Subscribe now.
      </>
    ),
    planRestriction: (onClick) => (
      <p>
        Two-factor authentication (2FA) functionality is available on Small, Medium and Enterprise plan.{' '}
        <span className="picsioLink" onClick={onClick} role="presentation">
          Subscribe now
        </span>{' '}
        to keep using it after trial expires.
      </p>
    ),
    myAccountTextEnabled: 'You have enabled Two-factor authentication (2FA).',
    myTeamTextEnabled: () => (
      <span>
        You have activated Two-factor authentication (2FA) login for all teammates.
        <br /> Each one of them has received a notification to set up the second step for their
        account.
      </span>
    ),
    setupNow: 'Set up Two-factor authentication (2FA) now',
  },

  SET_NEW_PASSWORD: {
    oldPassword: 'Old password',
    newPassword: 'New password',
    confirmPassword: 'Confirm password',
    success: 'New password set',
    fail: () => <>New password cannot be set. Refresh and try again</>,
    emptyPasswordError: 'Password cannot be empty',
    passwordLengthError: 'Password needs to be at least 8 characters long',
    passwordsAreNotEqualError: 'Passwords are not equal',
    save: 'Save',
  },

  TWO_FACTOR_SCREEN: {
    recoveryTitle:
      'Use the recovery code to access your account and reconfigure Two-factor authentication (2FA)',
    useApp: (handleClick) => (
      <p>
        Or use{' '}
        <span className="picsioLink" onClick={handleClick} role="presentation">
          app for a authentication
        </span>
      </p>
    ),
    enterCodeTitleApp: 'Enter the six-digit code for authentication from Google authentication app',
    enterCodeTitleEmail: `Input 6-digit code to proceed to ${appName}`,
    enterCodeLabelApp: 'Enter the six-digit code from Google authentication app',
    enterCodeLabelEmail: 'Enter the six-digit code from email',
    resendButton: 'Resend code',
    useRecovery: (handleClick) => (
      <>
        <span className="picsioLink" onClick={handleClick} role="presentation">
          Recovery code
        </span>
      </>
    ),
    verify: 'Verify',
    clear: 'Clear',
  },

  CONFIRM: {
    placeholderLogin: 'Login',
    placeholderPassword: 'Password',
    emailInvalid: 'Email is invalid',
    passwordInvalid: 'Password is empty',
    checkboxInvalid: 'Should be checked',
    serverError: 'Email or password is incorrect',
  },

  KEYWORDS: {
    alertCantAddKeyword: () => (
      <div>
        Keyword cannot be added. Refresh and try again
      </div>
    ),
    alreadyExists: 'Keyword with this name already exists',
    errorLoading: 'Keywords has not loaded. Try refreshing the page to see them',
    errorLoadingSomeKeywords: 'Some keywords has not loaded. Try refreshing the page to see them.',
  },

  AUDIT: {
    title: 'Audit trail',
    logsTab: 'Logs',
    login: 'User logins',
    logout: 'User logouts',
    analyticsTab: 'Analytics',

    tabTitleUser: 'Any user',
    tabTitleEvent: 'Any event',

    linkWebsite: 'website',
    linkTeam: 'your team',

    downloadCSV: 'Download CSV',

    textNothingFound:
      'Nothing found. Please try again with some different keywords.',
    textNoRecords: 'There are no records yet.',

    syncToStart: 'started sync operation to your cloud storage',
    syncToSuccess: 'Sync operation to your cloud storage finished',
    syncToFail: 'Sync operation to your connected cloud storage failed',
    syncFromStart: 'started sync operation from your cloud storage',
    syncFromSuccess: 'Sync operation from your cloud storage finished',
    syncFromFail: 'Sync operation from your cloud storage failed',

    timeOptionsAny: 'Anytime',
    timeOptionsToday: 'Today',
    timeOptionsYesterday: 'Yesterday',
    timeOptionsLast7: 'Last 7 days',
    timeOptionsLast30: 'Last 30 days',
    timeOptionsLast90: 'Last 90 days',
    timeOptionsCustomized: 'Customized',

    tagImageAdded: 'Asset uploaded to collection',
    tagImageImported: 'Asset imported to collection',
    tagAssetTranscribed: 'Asset transcribed',
    tagImageDeleted: 'Asset deleted',
    tagImageTrashed: 'Asset trashed',
    tagCommentAdded: 'Comment added',
    tagCommentDeleted: 'Comment deleted',
    tagAssetDownloaded: 'Asset downloaded',
    tagRevisionCreate: 'Revision created',
    tagRatingChanged: 'Rating changed',
    tagFlagChanged: 'Flag changed',
    tagColorChanged: 'Color changed',
    tagAssetsUploaded: 'Assets uploaded',
    tagAssetsAttached: 'Assets attached',
    tagAssetsDetached: 'Assets detached',
    tagAssetsMoved: 'Assets moved',
    tagAssetsKeywordAttached: 'Keywords attached',
    tagAssetsKeywordDetached: 'Keyword detached',
    tagAssetsKeywordsDetached: 'Keywords detached',
    tagAssetsMetadataChanged: 'Metadata changed',
    tagCollectionCreated: 'Collection created',
    tagCollectionDeleted: 'Collection deleted',
    tagCollectionMoved: 'Collection moved',
    tagCollectionShared: 'Collection shared',
    tagCollectionUnshared: 'Collection unshared',
    tagCollectionColorChanged: 'Collection color changed',
    tagCollectionDescriptionChanged: 'Collection description changed',
    tagFacesCreated: 'Face created',
    tagFaceRenamed: 'Face renamed',
    tagFaceDeletedForPerson: 'Face deleted for a person',
    tagFaceMergedForPerson: 'Face merged for a person',
    tagAssetsFacesRecognized: 'Recognized faces',
    tagAssetsFaceDeleted: 'Face deleted from an asset',
    tagTeammateInvited: 'Teammate invited',
    tagTeammateConfirmed: 'Teammate confirmed',
    tagTeammateRejected: 'Teammate rejected',
    tagRoleChanged: 'Role changed',
    tagTeammateRemoved: 'Teammate removed',
    tagSyncToStart: `Sync from ${appName} to GD started`,
    tagSyncToSuccess: `Sync from ${appName} to GD finished`,
    tagSyncToFail: `Sync ${appName} to GD failed`,
    tagSyncFromStart: `Sync from GD to ${appName} started`,
    tagSyncFromSuccess: `Sync from GD to ${appName} finished`,
    tagSyncFromFail: `Sync GD to ${appName} failed`,
    metadatingFailed: 'Extracting metadata failed',
    replicationFailed: 'Saving assets metadata to your cloud storage failed',
    contentingFailed: 'Content extracting failed',
    keywordingFailed: 'Autokeywording failed',
    workingFolderChanged: 'Working folder changed',
    You: 'You',
    changedWorkingFolder: 'changed working folder',
    changedWorkingFolderTo: 'changed working folder to',
    trialExpires: 'Trial expires',
    trialExpiresIn2Days: 'Trial expires in 2 days',
    trialExpiresIn5Days: 'Trial expires in 5 days',

    MedatataExtractingFailedFor: 'Metadata extracting failed for',
    SavingAssetsMetadataToYourStorageFailedFor:
      'Saving assets metadata to your cloud storage failed for',
    AutogenerateKeywordsFailedFor: 'Autogenerate keywords failed for',
    ContentExtractingFailedFor: 'Content extracting failed for',
    assetApproved: 'Asset approved',
    assetDisapproved: 'Asset disapproved',
    assetsAssigned: 'Assets assigned',
    assetsUnassigned: 'Assets unassigned',
    approved: 'approved',
    disapproved: 'disapproved',
    asset: 'asset',
    revision: 'revision',
    and: 'and',
    more: 'more',
    websitePresetCreated: 'Website preset created',
    websitePresetDeleted: 'Website preset deleted',
    websitePresetRenamed: 'Website preset renamed',

    helpText: {
      audit: 'Learn more about Audit Trail log',
      analytics: `Explore how to work with ${appName} analytics & reporting`,
    },
  },

  UPGRADE_PLAN_BLOCK: {
    persons: {
      title: 'Persons',
      description: 'Discover a revolutionary new way of embracing your digital content by recognizing people in your assets.',
      helperText: 'Learn more about Face recognition',
    },
    inboxes: {
      title: 'Inboxes',
      description: `An Inbox is a place that allows non-${appName} users to upload files to your library for further review.`,
      helperText: 'Learn more about Inboxes',
    },
    branding: {
      title: 'Branding',
      description: 'Add logo, favicon, custom domains to your websites, inboxes, and sign-in page for teammates.',
      helperText: `Learn how to customize your ${appName} experience`,
    },
    watermarking: {
      title: 'Watermarking',
      description: `Create image or text watermarks to protect your assets shared outside of ${appName}.`,
      helperText: 'Learn more about watermarks',
    },
    roles: {
      title: 'Roles',
      description: 'Create custom set of permissions for different groups of users.',
      helperText: 'Learn how to assign roles to teammates',
    },
    okta: {
      title: 'Okta SSO',
      description: 'Single Sign-On (SSO) through Okta',
      helperText: 'Learn more about Okta',
    },
    twoFactorAuth: {
      title: 'Two-factor authentication (2FA)',
      description: `Protect your ${appName} account with Two-factor authentication (2FA)`,
      helperText: 'Learn how to set up Two-factor authentication (2FA)',
    },
    comments: {
      title: 'Commenting and marking',
      description: `${appName} as one more useful channel of communication with your team`,
      helperText: 'Learn how to comment and add markers to assets',
    },
    customFields: {
      title: 'Custom fields schema',
      description: 'Create custom fields to build your own metadata taxonomy.',
      helperText: 'Learn how to manage your custom fields',
    },
    transcript: {
      title: 'Transcript',
      description: 'Here you will see a speech-to-text transcription of an audio track of current asset',
      helperText: 'Learn how to use transcripts',
    },
    assetAnalytics: {
      title: 'Asset analytics',
      description: 'Control your team activity and report on library efficiency.',
      helperText: 'Learn more about analytics',
    },
    teamAnalytics: {
      title: 'Teammates analytics',
      description: 'Control your team activity and report on library efficiency.',
      helperText: 'Learn more about analytics',
    },
    websiteAnalytics: {
      title: 'Website analytics',
      description: 'Control your team activity and report on library efficiency.',
      helperText: 'Learn more about analytics',
    },
    auditTrail: {
      title: 'Library analytics',
      description: `To get the full picture of what is happening in your ${appName} account, we have library-level analytics.`,
      helperText: 'Learn more about analytics',
    },
    WARNING_ADDON_UPGRADE: {
      TITLE: 'Upgrade subscription with add-on',
      TEXT: (addonName) => `You are about to add <b>${addonName}</b> to your subscription.`,
      OK: 'Upgrade',
      CANCEL: 'Cancel',
    },
    authFlows: {
      title: 'Allowed authentication methods',
      description: 'Select authentication methods for your teammates Sign In',
    },
  },

  BILLING: {
    billingOldPlanInfo(activePlanPrice, activePlanInterval, periodEndDateFormatted) {
      return `<span style="color: #FF6B00">You are subscribed to a plan that was discontinued, but your price remains the same: $${activePlanPrice}/${activePlanInterval}, next payment ${periodEndDateFormatted}.`;
    },
    title: 'Billing',
    logoutText: 'Logout',

    tabTitleOverview: 'Overview',
    tabTitleInfo: 'Info',
    tabTitleInvoices: 'Invoices',
    tabTitleCard: 'Card',
    titlePlan: 'Plan',
    titleBestOffer: 'Best offer',
    textPlanMonth: 'Monthly',
    textPlanYear: 'Yearly',
    textPlanAnnually: 'Annually',
    textPlanDiscount: 'save 10%',

    stateInvoices: 'Fetching invoices...',

    textCantValidateCard:
      `Can't validate card. Your card WON'T be charged. This indicates technical problems. Please contact ${supportEmail}.`,
    textEmptyCoupon: "Empty coupon can't be applied",
    textCoupon: 'Coupon',
    textCouponCant: "can't be applied to your account.",
    textCouponCant2: "can't be applied",
    textCouponSuccess: (coupon) => (<>Coupon {coupon} applied</>),
    textCardErrorReason: (reason) => (<>Reason: {reason}</>),
    textCouponApplying: 'Applying coupon...',
    textCouponDialogTitle: 'Enter your coupon here',
    textCardDetailsLoading: 'Loading card details...',
    textCardCantCheck:
      `Can't check card status! Please try again later or contact ${supportEmail}.`,
    textCardSaving: 'Saving card details...',
    textCardChanged: 'Card details updated',
    textCardCantChange: `Can't change card! Please try again later or contact ${supportEmail}.`,
    textPaymentLoadingDetails: 'Loading payment details...',
    textPaymentFinishing: 'Finishing payment...',
    textPlanChangedSuccess: 'Subscription plan updated',
    textPaymentCantFinishing:
      `Can't finish payment! Please try again later or contact ${supportEmail}.`,
    textDetailsSaving: 'Saving subscription details...',
    textDetailsLoading: 'Loading subscription details...',
    textSubscriptionCancelled: 'Subscription cancelled',
    textSubscriptionCantCancel:
      `Can't cancel subscription! Please try again later or contact ${supportEmail}.`,
    textTitleThanks: 'Subscription updated',
    textTextSubcriptionUpdated:
      `Your subscription has been updated.<br>Thank you for choosing ${appName}. DAM service.`,
    textBtnGoToLibrary: 'Go to My Library',

    textBillingSubscribed1: "You're subscribed to",
    textBillingSubscribed2:
      'plan that is discontinued. You may upgrade to our new plans or stay with your current subscription.',
    textYourSubscription1: 'Your subscription to',
    textYourSubscription2: 'plan',
    textYourSubscription3: 'Will cancel at',
    textYourSubscribed1: 'You are subscribed to',
    textYourSubscribed2: 'plan',
    textYourSubscribed3: 'Next payment at',
    textYourTrial1: 'You are on trial period that',
    textYourTrial2: 'will end at',
    textYourTrial3: 'You can use all the functionality without any restrictions. <br/> Except for a number of assets, you have a limit of 50,000 files during the Trial period.',
    textYourTrialEnded:
      'Please subscribe to any plan to continue using our app.',

    stripeCardErrors: {
      revocation_of_all_authorizations: 'The card was declined for an unknown reason.',
      revocation_of_authorization: 'The card was declined for an unknown reason.',
      security_violation: 'The card was declined for an unknown reason.',
      service_not_allowed: 'The card was declined for an unknown reason.',
      stolen_card: 'The payment was declined because the card is reported stolen.',
      stop_payment_order: 'The card was declined for an unknown reason.',
      testmode_decline: 'A Stripe test card number was used.',
      transaction_not_allowed: 'The card was declined for an unknown reason.',
      try_again_later: 'The card was declined for an unknown reason.',
      withdrawal_count_limit_exceeded: 'The customer has exceeded the balance or credit limit available on their card.',
      invalid_amount: "The payment amount is invalid, or exceeds the amount that's allowed.",
      invalid_cvc: 'The CVC number is incorrect.',
      invalid_expiry_month: 'The expiration month is invalid.',
      invalid_expiry_year: 'The expiration year is invalid.',
      invalid_number: 'The card number is incorrect.',
      invalid_pin: 'The PIN entered is incorrect.',
      issuer_not_available: "The card issuer couldn't be reached, so the payment couldn't be authorized.",
      lost_card: 'The payment was declined because the card is reported lost.',
      merchant_blacklist: "The payment was declined because it matches a value on the Stripe user's block list.",
      new_account_information_available: 'The card, or account the card is connected to, is invalid.',
      no_action_taken: 'The card was declined for an unknown reason.',
      not_permitted: 'The payment isn’t permitted.',
      offline_pin_required: 'The card was declined because it requires a PIN.',
      online_or_offline_pin_required: 'The card was declined as it requires a PIN.',
      pickup_card: "The customer can't use this card to make this payment (it's possible it was reported lost or stolen).",
      pin_try_exceeded: 'The allowable number of PIN tries was exceeded.',
      processing_error: 'An error occurred while processing the card.',
      reenter_transaction: "The payment couldn't be processed by the issuer for an unknown reason.",
      restricted_card: "The customer can't use this card to make this payment (it's possible it was reported lost or stolen).",
      authentication_required: 'The card was declined because the transaction requires authentication such as 3D Secure.',
      approve_with_id: 'The payment can’t be authorized.',
      call_issuer: 'The card was declined for an unknown reason.',
      card_not_supported: 'The card does not support this type of purchase.',
      card_velocity_exceeded: 'The customer has exceeded the balance, credit limit, or transaction amount limit available on their card.',
      currency_not_supported: 'The card does not support the specified currency.',
      do_not_honor: 'The card was declined for an unknown reason.',
      do_not_try_again: 'The card was declined for an unknown reason.',
      duplicate_transaction: 'A transaction with identical amount and credit card information was submitted very recently.',
      expired_card: 'The card has expired.',
      fraudulent: 'The payment was declined because Stripe suspects that it’s fraudulent.',
      generic_decline: 'The card was declined for an unknown reason or Stripe Radar blocked the payment.',
      incorrect_number: 'The card number is incorrect.',
      incorrect_cvc: 'The CVC number is incorrect.',
      incorrect_pin: 'The PIN entered is incorrect. This decline code only applies to payments made with a card reader.',
      incorrect_zip: 'The postal code is incorrect.',
      insufficient_funds: 'The card has insufficient funds to complete the purchase.',
      invalid_account: 'The card, or account the card is connected to, is invalid.',
      default: `The card was declined for an unknown reason. Please try again later or contact <a class='picsioLink' href='mailto:${supportEmail}'>${supportEmail}</a>.`,
    },

    textInfo: () => (
      <span>
        See our <a href="https://pics.io/privacy-policy" target="_blank" rel="noreferrer" className="picsioLink">privacy policy</a><br />for details.
      </span>
    ),

    inputLabelCompany: 'Company name',
    inputLabelName: 'Name',
    inputLabelSurname: 'Surname',
    inputLabelStreetAdress: 'Street address',
    inputLabelCity: 'City',
    inputLabelPostalCode: 'Postal code',
    selectLabelCountry: 'Country',
    inputLabelState: 'State',
    inputLabelEmail: 'Email',
    inputLabelInformation: 'Information',

    inputPlaceholderCompany: 'Company',
    inputPlaceholderName: 'John',
    inputPlaceholderSurname: 'Doe',
    inputPlaceholderStreetAdress: 'Street address',
    inputPlaceholderCity: 'Los Angeles',
    inputPlaceholderPostalCode: '123456',
    inputPlaceholderState: 'California',
    inputPlaceholderEmail: 'johndoe@mail.com',
    inputPlaceholderInformation:
      'Additional information, i. e. full name of the company. This is information will appear in all invoices and receipts. Do not enter any confidential financial information here.',

    invoiceThDate: 'Date',
    invoiceThAmount: 'Amount',
    invoiceThDescription: 'Description',
    invoiceThStatus: 'Status',
    invoiceNone: 'No invoices',
    invoiceSubscriptionTo: 'Subscription to',
    invoiceInvoiceFrom: 'Invoice from',
    titleInfo: 'Info',
    titleInvoices: 'Invoices',

    titleCredits: 'Credits',
    titleFeatures: 'Features',
    titleIncluded: 'Included',
    titleAdditional: 'Additional',
    titlePrice: 'Price',
    textFeatureNumberLabel: (featureAmount, featureTitle) => (`${featureAmount} per ${pluralize.singular(featureTitle)}`),
    textStartsFrom: 'Starts from',
    textFeatureBoolenLabel: 'Add feature for',
    textMaxGBOptionLabel: `(to decrease storage, contact ${appName} Support)`,
    textCreditsBuy: 'Buy',
    textSelectPlan: 'Select plan',
    textDowngradePlan: 'Downgrade plan',
    textUpgradePlan: 'Upgrade plan',
    textAddToPlan: 'Add to plan',
    textUpdatePlan: 'Update plan',
    textPurchase: 'Purchase',
    btnUpdatePlan: 'Update plan',
    btnCancel: 'Cancel',
    textIncluded: (planName) => `included in ${planName}`,
    textUnsubscribe: 'Unsubscribe',
    titleCard: 'Card',
    titleCardAndCoupon: 'Card and coupon',
    textRedeemCoupon: 'Redeem coupon',
    textAddCoupon: 'Add coupon',
    textTotal: 'Total',
    month: 'mo',
    year: 'yr',
    textPoweredBy: 'Powered by',
    textPaymentNote: `${appName} does not store any credit card information. All payments are processed in Stripe.`,

    planIndividualsText: 'Perfect for individuals',
    planIndividualsWebsites: 'Websites',
    planIndividualsTeammates: 'Teammates',
    textMonth: 'month',
    textUsers: 'Up to <b>{value}</b> users in your team',
    textFreeWebsites: 'free websites',

    textCardChange: 'Change card',
    textCardNoAdded: 'No card added',
    textCardAdd: 'Add new card',
    textValidate: 'Validate',

    textAppliedCoupon: (id, off, duration) => (
      <span>
        Applied coupon <span>{id}</span> <br /> -{off} {duration}{' '}
      </span>
    ),

    checkoutSubscribe: 'Subscribe',
    proceedButton: 'Proceed',
    confirmAiKitPurchaseTitle: 'Complete Your AI Kit Purchase',
    confirmAiKitPurchaseText: (planName, payDate) => `Since you're adding the AI Kit after subscribing to the Pics.io <b>${planName}</b>, we've aligned its renewal date with your plan to make billing easier.<br><br> This means both the AI Kit and your main subscription will renew on the same date, and any unused assets or minutes will reset at that time.<br><br> Your Pics.io <b>${planName}</b> is set to renew on ${payDate}, along with all associated add-ons.`,
    confirmationChangeTitle: 'Change subscription plan',
    confirmationUpdateTitle: 'Update subscription',
    confirmationChangePlan: (planName) => `You are about to change your plan to <b>${planName}</b>.<br><br> Confirm that action`,
    confirmationUpdatePlan: (addedAddons, removedAddons) => (
      <div>
        <p>You are about to update your subscription with add-ons</p>
        {addedAddons.length > 0
          && (
            <p><b>Added:</b> {addedAddons.join(', ')}</p>
          )}
        {removedAddons.length > 0
          && (
            <p><b>Removed:</b> {removedAddons.join(', ')}</p>
          )}
      </div>
    ),

    textEnterAmount: 'Please enter amount you want to buy (USD)',
    placeholderNumber: 'Number',
    textBuy: 'Buy',

    titleTransactionComplete: 'Transaction complete',
    textYouBought: (amountValue) => `You bought ${amountValue} credits. Your balance is now`,

    titleTransactionFailed: 'Transaction failed',
    textTryAgain: 'Error, please try again later',
    textBuyAiCreditsFailed: `Can't buy AI credits. Please try again later or contact <a class='picsioLink' href='mailto:${supportEmail}'>${supportEmail}</a>.`,

    statusPaid: 'Paid',
    statusUnpaid: 'Unpaid',
    statusUpcoming: 'Upcoming',

    storageDisabledByPlan: 'Available starting with<br />Solo',
    storageDisabledBySize: 'The amount of data exceeds the<br />capacity of the plan.',
    storageSizeUserUsing: (amount, unit) => `You are using ${amount} ${unit}`,
    textRequestMoreStorage: 'Request more storage',
    textProcessingRequest: 'Processing request...',
    DIALOG_LIMITS_EXCEEDED: {
      title: 'Limit exceeded',
      text:
        'On your current plan you have more teammates or websites than is included into the price of the plan you want to upgrade to. Ask our support team to add extra teammates or websites to your account on a target plan',
      btnOk: 'Write to support',
    },
    textStorageRequest: 'Request for additional storage sent. Our support team will contact you shortly',
    titlePlanTotalAccount: (planName) => `${planName} plan`,
    titleStorageTotalAccount: (storageName) => `${storageName} ${appName} storage`,
    titleTrial: 'Trial',
    titleDiscount: 'Discount',
    tooltipAddonLimitations: 'Please note that this functionality is available as add-on',
    helpText: `Learn how to set up billings in ${appName}`,
    couponPlaceholderText: 'Add coupon',
    couponButtonText: 'Apply',
    tierFeatureAssetLimit: 'Asset limit ',
    tierFeatureTranscriptionLimit: 'Transcription limit ',
    tierFeatureAddonCost: 'Add-on cost ',
    aiKitTooltipText: 'The assets and minutes included in your selected tier are available only during the paid billing cycle. Unused amounts will not be carried over and will reset at the end of each billing period.',
    aiKitUsedAssetsLabel: 'Asset limit',
    aiKitUsedAssetsSubLabel: '(assets)',
    aiKitUsedMinutesLabel: 'Transcription limit',
    aiKitUsedMinutesSubLabel: '(minutes)',
    aiKitIntercomMsg: `Hello ${appName} Support Team, I am interested in exploring a custom pricing option for the AI Kit add-on that better suits my specific needs`,
    aiKitIntercomDowngradeMsg: `Hello ${appName} Support Team, I would like to request a downgrade for my current AI Kit add-on tier. Could you please guide me through the process of making this change?`,
    aiKitUpgradeToastText: 'Your AI Kit has been upgraded to the next tier as you’ve exceeded your current limit.',
    btnSeeDetails: 'See details',
    aiKitInfoText: (onClick) => (
      <>
        If none of the presented tiers meet your needs, you can <span className="picsioLink" onClick={onClick}>request a custom AI Kit</span> tailored to include only the specific services you require.
      </>
    ),
    aiKitTierTooltipText: (tierName, assetsCount, minutes, frequency) => (
      <div>
        <div className="tierFeature__tooltipHeader">AI Kit {tierName}</div>
        <div>Allows you to:</div>
        <ul className="tierFeature__tooltipList">
          <li>Recognize faces in {assetsCount} assets per {frequency}</li>
          <li>Auto-generate and apply keywords to up to {assetsCount} assets per {frequency}</li>
          <li>Create meaningful descriptions for up to {assetsCount} assets</li>
          <li>Convert up to {minutes} minutes of video and audio to text</li>
          <li>Combine multiple AI services on the same asset, counting each service as a separate processed asset</li>
        </ul>
        <div>Note:</div>
        <div>An 'asset' refers to a file processed by one AI service. Using multiple services on the same file will count each as a separate processed asset. Once the number of processed assets exceeds the allocated limit, the system will automatically upgrade you to the next tier for a seamless workflow.</div>
      </div>
    ),
  },

  RESIZER: {
    showPanel: 'Show panel',
    hideOrResize: 'Hide or resize panel',
    expandFilters: 'Expand filters',
    collapseFilters: 'Minimize filters',
  },

  REORDER: {
    title: "Can't set custom sorting",
    text: 'Your current sorting is <b>Best Match</b>. Please select other sorting and try again.',
  },

  CUSTOMFIELDS: {
    alertCantUpdateData:
      `Can't update data. Please try again. Contact <a class='picsioLink' href='mailto:${supportEmail}'>${appName}</a> support if the error repeats.`,
  },

  CUSTOMFIELDSSCHEMA: {
    configTypesString: 'Text',
    configTypesInt: 'Number',
    configTypesEnum: 'List',
    configTypesBoolean: 'Checkbox',
    configTypesDate: 'Date',
    configTypesSeparator: 'Group',

    configVisibilityHidden: 'Hidden',
    configVisibilityVisible: 'Visible',
    configVisibilityHiddenWhileEmpty: 'Hidden while empty',

    alertCantLoadData:
      `Can't load custom fields data. Please try again. Contact <a class='picsioLink' href='mailto:${supportEmail}'>${appName}</a> support if the error repeats.`,

    title: 'Custom Fields Schema',
    textReorder: 'To reorder drag and move',

    inputPlaceholderEnterTitle: 'Enter title',
    textButtonDelete: 'Delete',
    textButtonCancel: 'Cancel',

    textErrorTitleEmpty: '"Title" - is empty',
    textErrorTitleTaken: 'The "Title" you\'ve entered is already taken',
    textErrorDbCant: "db can't handle it",

    // textFieldEmpty: 'Field {field} is empty.',
    textErrorTitleName: 'Only letters, numbers, underscore and dash are allowed',
    textErrorTitleNameSpace: 'Only letters, numbers, underscore, dash, and space are allowed',
    textErrorComma: "Can't set comma in options name, please use another sign",
    textErrorLengthLimit: (limit) => `You have reached character limit (${limit})`,
    textErrorNameAlreadyExist: (name) => `Custom field name "${name}" already exist`,
    textTooltipCustomSort: 'Drag & drop to reorder values in the list',

    type: 'Type',
    createGroup: 'Create New Group',
    createField: 'Create New Custom Field',
    editGroup: 'Edit Group',
    editField: 'Edit Custom Field',
    edit: 'Edit',

    labelName: 'Name',
    placeholderName: 'Enter Name',
    captionName: 'Use Latin characters, do not put spaces and punctuation.',
    labelDescription: 'Description',
    placeholderDescription: 'Enter Description',
    addNewItem: 'Add new item to the list',
    btnSave: 'Save',
    btnAdd: 'Add',

    uploadText: 'Select your .picsioconf for upload.',
    btnSubmit: 'Import scheme',
    btnExport: 'Export',
    btnImport: 'Import',
    textImportError: () => (
      <div>
        Schema cannot be imported. Refresh try again
      </div>
    ),
    textMoveFieldError: () => (
      <div>
        Custom field cannot be moved. Refresh and try again
      </div>
    ),
    deleteWarning: {
      title: 'Restricted custom field detected',
      text:
        "You're about to delete the required custom field for uploads and revisions. Are you sure you want to complete this action?",
      btnOk: 'Confirm',
      btnCancel: 'Cancel',
    },
    changeTypeWarningDialog: {
      title: 'Warning',
      text: 'Values for this custom field were set for some assets. Do you want to proceed with changing the field type and reset those values for all assets?',
      textBtnOk: 'Reset',
    },
    changeTypeLimitDialog: {
      title: 'Error',
      text: 'Changing the type of the field will affect too many assets which may cause performance and data integrity issues. To prevent this, we change field types only via our support engineers',
      textBtnOk: 'Write to support',
    },
    helpText: 'Learn how to configure custom fields',
    customFieldsInfoBlockText: (
      <>
        The custom fields visibility setting is now set relative to roles in team settings.
        <br />
        You can check it here{' '}
        <a className="picsioLink" href="/teammates?tab=roles" target="_blank">
          My team / Roles / Asset metadata fields configuration
        </a>
      </>
    ),
  },

  DOWNLOADDIALOG: {
    titleDownload: 'Download',
    labelQuality: 'Quality',
    labelSize: 'Size',
    labelPixels: 'Pixels',
    labelResolution: 'Resolution',
    labelUnits: 'Units',
    labelDownloadAs: 'Download as an archive',
    labelDownloadWithoutWatermark: 'Download without watermark',
    labelOrganizeBy: 'Organize files by collections',
    textDownload: 'Download',

    textAsOriginal: 'As original',
    textLargestSide: 'largest side',
    textWidth: 'width',
    textHeight: 'height',

    tooltipOrganize: 'To choose this option, check “Download as an archive” first.',
    titleErrorCantCreateArchive: 'Error creating archive',
    errorCantCreateArchive:
      `Cannot create an archive. Try again later or contact <a class="picsioLink" href="mailto:${supportEmail}">${appName}</a>.`,
    errorDownloadingTitle: 'Download failed',
    errorDownloading:
      `Assets weren’t downloaded. Please try again. If the error persists, contact <a class='picsioLink' href='mailto:${supportEmail}'>${appName}</a>`,
    getDownloadPresetError:
      `Cannot get download preset list. <br> Try again later or contact <a class="picsioLink" href="mailto:${supportEmail}">${supportEmail}.</a>.`,
    createDownloadPreset: 'Create download preset',
    saveDownloadPreset: 'Save download preset',
    saveDownloadPresetError:
      `Cannot save download preset.<br> Try again later or contact<br> <a class="picsioLink" href="mailto:${supportEmail}">${supportEmail}.</a>.`,
    removeDownloadPreset: 'Delete download preset',
    removeDownloadPresetText: (presetName) => `You are about to delete ${presetName} preset`,
    removeDownloadPresetError:
      `Cannot remove download preset.<br> Try again later or contact <a class="picsioLink" href="mailto:${supportEmail}">${supportEmail}.</a>.`,
  },

  DIRECT_LINK_DOWNLOAD_DIALOG: {
    TITLE: () => 'Download by direct link',
    TEXT: (assets, urls, logger) => (
      <div>
        {pluralize('This', assets.length, false)} {pluralize('file', assets.length, false)}{' '}
        {pluralize('is', assets.length, false)} over 40MB and can be downloaded only via a web link:{' '}
        <br />
        <ul className="simpleList">
          {assets.map((asset, index) => (
            <li key={asset._id}>
              <a
                href={urls[index]}
                onClick={() => logger(asset._id, asset.fileSize)}
                download={asset.name}
              >
                {asset.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    ),
    errorText: "Can't get a link for direct download.",
  },

  DOWNLOAD_PANEL: {
    titleDownloading: 'Downloading',
    textRetry: 'Retry',
    textCancel: 'Cancel',
    titleDialogConfirm: 'Confirm removing',
    btnOkDialogConfirm: 'Remove',
    textDialogConfirm: 'Are you sure you want to remove all files from download queue?',
    btnRemoveAll: 'Remove all',
    textFilesLeft: 'files left',
    textTotal: 'Total: ',
    textDownloadingComplete: 'Download completed',
    textDownloaded: 'downloaded',
    textDownloadingProgress: 'files, downloading in progress',
  },

  HISTORY: {
    textAdded: 'added',
    textVisualMark: 'visual mark',
    textRevision: 'revision',
    textAddMarker: 'Add marker',
    textPullToResize: 'Pull to resize',
    textPostComment: 'Post a comment to start a discussion.',
    textCommentImageIsRestricted: 'Images cannot be inserted in comments. Delete this image and leave text only',
    textPlaceholderGuest: 'Guest',
    textPlaceholderAddComment: 'Add comment',
    textShowMarkers: 'Show markers',
    textHideMarkers: 'Hide markers',
    textCopyLink: 'Copy link',
    textSaveButton: 'Save',
    textCancelButton: 'Cancel',
    textEdit: '(edited)',
    textDeleteComment: 'Delete comment',
    textDownloadRevision: 'Download revision',
    textMetadataUpdated: 'Asset metadata updated',
    textTechnicalRevision: 'technical revision',
    textSeeDifference: 'See the difference',
    textShowAllMarkers: 'Show all markers',
    textHideAllMarkers: 'Hide all markers',
    textRevertRevision: 'Set as current revision',
    textNoteRemovedSuccess: 'Your revision was successfully removed',
    textNoteRemovedWasnt: "Your revision wasn't removed. Please try a bit later",
    textNoteLoadedWasnt: "Your revision wasn't loaded. Please try a bit later",
    textRevisionLoading: 'Revision is loading...',
    switchDialogTitle: 'Warning',
    toggleActiveCommentTitle: 'Marker from a different revision',
    switchDialogText: 'This marker(s) belongs to a different revision. <br />Switch revisions?',
    switchDialogOk: 'Ok',
    switchDialogCancel: 'Cancel',
    tooltipAddMarkers: 'Click to add new marker',
    tooltipAddMarkersLocked: 'You can add markers to the latest version only',
    initialRevisionDialogText:
      'This revision is unavailable because it’s deleted from your cloud storage',
    textInitialRevision: 'Initial revision',
    approved: 'approved',
    disapproved: 'disapproved',
    titleDialogError: 'Error',
    titleDeletingCommentError: 'Error deleting comment',
    titleApproveError: 'Error approving asset',
    addMarker: 'Add marker',
    approve: 'Approve',
    proofingCommentsPlaceholder: 'No comments yet',
    textCommentDeleted: 'comment deleted',
    textErrorDeletingComment: "Your comment wasn't deleted. Please try a bit later",
    textErrorRevertRevision: 'Cannot set a revision as current. Please try again later.',
    switchDialogTitleError: 'Error',
    textCantShowRevisionButDownload:
      'Unfortunately, revisions for this file format can’t be displayed. But you still may download it from the Activity panel.',
    textCantShowRevision:
      `Sorry, ${appName} cannot process this request. Please contact us at <a class="picsioLink" href="mailto:${supportEmail}">${supportEmail}<a>`,
    textRevisionAdded: (revisionNumber) => (
      <span className="revisionText">
        added{' '}
        <span className="revision">
          revision <span className="revisionNumber">{revisionNumber}</span>
        </span>
      </span>
    ),
    textRevisionIsACopyOf: (revisionNumber) => (
      <span className="revisionCopyText">
        (copy of <span className="revision">revision</span>{' '}
        <span className="revisionNumber">{revisionNumber}</span>)
      </span>
    ),
    textAssetNotFound: 'Comment cannot be added because this asset does not exist in the library anymore',
    textEditCommentApiError: 'Unexpected error occurred and comment was not added. Refresh and try again',
    textCreateCommentApiError: 'Unexpected error occurred and comment was not added. Refresh and try again',
    textCantUpdateComment: 'Unexpected error occurred and comment was not added. Refresh and try again',
    textCantAddComment: 'Unexpected error occurred and comment was not added. Refresh and try again',
    textBtnRefresh: 'Refresh',
  },

  HOTKEYS: {
    actionSelectDeselect: '(de)select all',
    actionImportpanel: 'upload panel',
    actionTreeCollections: 'tree collections',
    actionInfopanel: 'info panel',
    actionSearch: 'search',
    actionDialogHotkeys: 'dialog hotkeys',
    actionScrollUp: 'Scroll up',
    actionScrollDown: 'Scroll down',
    actionScrollUpPage: 'Scroll up one page',
    actionScrollDownPage: 'Scroll down one page',
    actionScrollToBegining: 'Scroll to the beginning of a catalogue',
    actionScrollToEnd: 'Scroll to the end of a catalogue',

    actionMassDownload: 'mass download',
    actionFlagApprove: 'flag approve',
    actionFlagReject: 'flag reject',
    actionUnflag: 'unflag',
    actionRating: 'rating 1-5',

    actionColorRed: 'color red',
    actionColorYellow: 'color yellow',
    actionColorGreen: 'color green',
    actionColorBlue: 'color blue',
    actionColorNone: 'color none',

    actionPrevImg: 'previous asset',
    actionNextImg: 'next asset',
    actionClosePreview: 'close preview',
    actionHistorypanel: 'activity panel',

    actionSelectOneAsset: '(de)select one asset',
    actionSelectSomeAssets: '(de)select random assets',
    actionSelectRangeAssets: '(de)select assets in sequence',
  },

  KEYWORDSTREE: {
    title: 'Keywords',
    description: 'Failed to load keywords. Click the button below to try again.',
    sortByAlphabetEl: 'Sort by name',
    sortByDateEl: 'Sort by last used',
    sortByHotEl: 'Sort by most used',
    buttonText: 'Load keywords',

    textPlaceholderText: "You don't have any keywords yet. Start adding them now",
    placeholderNewKeyword: 'Keyword name',
    textButtonCreate: 'Create new',

    inputPlaceholderSearch: 'Search keywords',

    textTooltipCreate: 'Create keyword',
    textTooltipCreateSub: 'Create sub-keyword',
    textTooltipDelete: 'Delete keyword',
    textTooltipDeleteKeywords: (count) => `Delete selected ${pluralize('keyword', count)}`,
    textTooltipRename: 'Rename keyword',
    textTooltipRemove: 'Remove from favorites',
    textTooltipAddToFavorites: 'Add to favorites',
    textTooltipMerge: 'Merge selected keywords into this keyword',

    errorKeywordsDeleting: () => (
      <>
        Keywords cannot be deleted. Refresh and try again
      </>
    ),

    errorKeywordMerging: () => (
      <>
        Selected keywords cannot be merged. Refresh and try again
      </>
    ),

    textTitleGenerateKeywords: 'Generate keywords',
    textTextGenerateKeywords: 'You are about to generate keywords for all assets in your library.',
    textGenerate: 'Generate',
    textEnoughCredits: 'Unfortunately you have not enough credits to autofill keywords.',
    textGenerationgInBackground:
      'Keywords are generating in background... Refresh page in few minutes.',
    textAskTeamowner: ' Please ask your team owner / billing manger to buy credits.',
    textGoToBillingpage: ' Please go to billing page to buy credits.',
    textOpenBilling: 'Open Billing',
    placeholderFooterText: picsioConfig.isPim ? 'Import a pre-made list of tags to quickly categorize and enhance searchability for your media or products.' : 'or <br><br> Use AI to auto-generate keywords for your library assets or import a pre-made list.',
    tooltiopDisabledAddForm: 'Your account does not have permission to create keywords',
    btnUploadDictionary: 'Import keyword list',
    tooltipUploadDictionary: 'Please use .csv or .txt files for uploading',
    tooltipDisabledUploadDictionary: 'Your account does not have permission to import a keyword list',
    tooltipDisabledAIGeneration: 'Your account does not have permission to use AI keyword generation',
    btnGenerateKeywords: 'Generate keywords',
    tooltipGenerateKeywords: 'Automatic keyword generation using AI',
    tooltipDisabledGenerateKeywords: 'Your account does not have permission to generate keywords automatically using AI',
    tooltipControlledVocabularyEnabled: 'Controlled Vocabulary enabled. Click to deactivate.',
    tooltipControlledVocabularyDisabled: 'Enable Controlled Vocabulary',
    tooltipControlledVocabularyEnabledAndNoPermission: () => (<div><span>Controlled Vocabulary feature is enabled</span></div>),
    tooltipControlledVocabularyDisabledAndNoPermission: () => (<div><span>Controlled Vocabulary feature is disabled.</span><br /><span>Contact your team admins to turn it on.</span></div>),
    tooltipAiKeywords: 'Automatic keyword generation using AI',
    tooltipImportKeywordsList: 'Import keyword list',
    tooltipDisableControlledVocabularyToImport: 'Disable Controlled Vocabulary to use the Import keyword list functionality',
    tooltipDisableControlledVocabularyToAI: 'Disable Controlled Vocabulary to use AI keyword generation',
    tooltipExportKeywordsList: 'Export keyword list',
    tooltipEditMode: 'Edit mode',
    tooltipExitEditMode: 'Exit Edit mode',
    tooltipDeleteSelectedKeywords: 'Delete selected keywords',
    textForSpinner: 'Processing <br> keywords generation',
  },

  FACESTREE: {
    title: 'Persons',
    description: 'Failed to load faces. Click the button below to try again.',
    buttonText: 'Load persons',
    inputPlaceholderSearch: 'Search persons',
    textPlaceholderText: 'There are no persons recognized yet',
    textTooltipDelete: 'Delete person',
    textTooltipDeleteUnnamed: 'Delete all unnamed faces',
    textTooltipRename: 'Rename person',
    textTooltipRemove: 'Remove from favorites',
    textTooltipAddToFavorites: 'Add to favorites',
    textEditPerson: 'Rename person',
    modalDeleteUnnamed: 'You are about to delete all unnamed faces. This action cannot be undone. Do you want to proceed?',
    renameButton: 'Rename',
    deleteUnnamedPersonsTitle: 'Delete unnamed faces',
    confirm: 'Delete',
    cancel: 'Cancel',
    textDialogBtnOk: 'Find persons',
    senCollectionRecognizeDialogTitle: 'Select collection',
    textForSpinner: 'Processing images and finding persons',
    error: 'Faces were not deleted, try again later.',
    mergePersone: 'Merge faces',
    mergeInto: (name) => `Into assets with a face of ${name}`,
    mergeCountFace: (count, name) => `Merge ${pluralize('asset', count, true)} with a face of ${name}`,
    errorMergeFace: '',
    errorDialog: `Something went wrong. Please try again. Contact <a class="picsioLink" href="mailto:${supportEmail}">${appName}</a> support, if the error persists.`,
  },

  TREE_PLACEHOLDER: {
    createNew: 'Create',
    enterNewName: 'Enter new name',
  },

  MARKERS: {
    removeMarkerTooltip: 'Remove marker',
    removeBoundingBoxFrameTooltip: 'Delete face region',
    selectPerson: 'Select person',
  },

  CLIPBOARD: {
    title: 'Confirm action',
    text: 'Assets can be selected only across collections. If you want to go to another tab, current selection will be lost. Please confirm this action.',
    btnCancelText: 'Cancel',
    btnConfirmText: 'Confirm',
  },

  DETAILS: {
    new: 'New',
    tooltipReplicatorRunning: 'This asset metadata is not synchronized with Cloud Storage Account',
    tooltipKeywordingWaiting: 'Waiting to parse keywords',
    tooltipKeywordingRunning: 'Parsing keywords',
    tooltipKeywordingError: 'Failed to parse keywords',
    tooltipMetadatingWaiting: 'Waiting to parse metadata',
    tooltipMetadatingRunning: 'Parsing metadata',
    tooltipMetadatingError: 'Failed to parse metadata',
    autogenerateError: 'Not sufficient funds',
    aiCredits: 'AI credits',

    tooltipLock: (param) => `${param} details panel for editing`,
    tooltipGenerateKeywords: 'Generate keywords',
    tooltipFaceRecognize: 'Recognize face',
    tooltipCreateBoundingBox: 'Draw face region',
    tooltipAddToCollection: 'Add to collection',

    textLock: 'Lock',
    textUnlock: 'Unlock',
    textUnlockAlways: 'Always unlock',
    textUnlockLogout: 'Unlock until logout',
    textLockAlways: 'Always lock',

    textDownload: 'Download',
    textDuplicate: 'Duplicate',
    textExportToCSV: 'Export CSV',
    textDelete: 'Move to trash',
    textDeleteForever: 'Delete forever',
    textRename: 'Rename',

    textResetToDefaultView: 'Reset to default view',

    textNoFiles: 'No assets',
    textSelectedAll: 'Add all to selection',
    textSelectedAllCollection: 'Add to selection all',
    textMainInfo: 'Main info',
    textCreatedAt: 'Created by:',
    textUpdatedAt: 'Updated at:',
    textSyncedAt: 'Synced at:',
    textSyncButton: 'Sync this collection',
    textDeselectAll: 'Remove all from selection',
    textDownloadFiles: 'Download assets',
    textRemoveFromCollections: 'Remove from collection',
    textAssetsInCollection: 'assets in collection',
    textAssetFound: 'asset found',
    textAssetsFound: 'assets found',
    textNoAssets: 'There are no files in this collection',
    textAssetDuplicateFail: () => (
      <div>
        Asset cannot be duplicated. Refresh and try again
      </div>
    ),
    textAssetsLimitExceededError: 'Up to 50,000 assets can be uploaded during trial. Delete some assets to upload more',
    textCopyAssetsLimitError: 'No more than 100 assets can be duplicated in one go. Select fewer assets and try again',
    textRestrictedAssetsError: 'Some selected assets are restricted and cannot be duplicated. Only not restricted assets will be duplicated',

    textMarkFace: 'Draw face region',
    textRecognizeFace: 'Recognize Faces',
    textRecognizingFace: 'Recognizing Faces',
    textGetMoreCredits: 'Get more',
    textTagWithAI: 'Tag With AI',
    textTagging: 'Tagging',
    textBueMoreRuns: 'Get more',
    textTranscribe: 'Transcribe',
    textTranscribing: 'Transcribing',

    textSelectedFiles: (collectionLength) => `Selected ${collectionLength} assets`,
    placeholderMultipleSelection: 'Multiple selection',
    placeholderMultipleSelectionRestricted: 'Restricted assets selected',
    placeholderEnterTitle: 'Enter title',
    placeholderUneditabledTitle: 'Title',
    placeholderEnterDescription: 'Enter description',
    placeholderAIDescription: 'Enter manually or use AI to generate description',
    placeholderUneditabledDescription: 'Description',
    placeholderKeywords: (count) => (
      <>
        {count === 1 ? 'This asset doesn\'t' : 'These assets don\'t'} have any keywords.<br />
        Start adding them now.
      </>
    ),
    placeholderTranscribe: <>This video asset might have a speech on it.<br />Check it out by transcribing it now.</>,
    placeholderFaces: 'Scan this asset for faces to simplify finding other assets featuring these individuals by clicking the button below',
    placeholderFacesWithoutPermission: 'This asset hasn\'t been scanned for faces yet',
    textTitleAndDescription: 'Title & Description',
    textProcessing: 'Processing',
    textKeywordsSuccess: 'Keywords attached',
    placeholderCustomTextField: 'Input new value',
    textCantUpdateKeywords: () => (
      <div>
        Keywords cannot be updated. Refresh and try again
      </div>
    ),
    textCantUpdateAssignees: (length) => (
      <div>
        {pluralize('Assignee', length)} cannot be updated. Refresh and try again
      </div>
    ),
    textCantUpdateAssetName: () => (
      <div>
        Asset name cannot be updated. Refresh and try again
      </div>
    ),
    textCantRemoveAsset: (length) => (
      <div>
        {pluralize('Asset', length)} cannot be removed. Refresh and try again
      </div>
    ),
    textCantDeleteAsset: (length) => (
      <div>
        {pluralize('Asset', length)} cannot be deleted. Refresh and try again
      </div>
    ),
    textCantRestoreAsset: (length) => (
      <div>
        {pluralize('Asset', length)} cannot be restored. Refresh and try again
      </div>
    ),
    textNotPermissionRestoreAsset: 'You don\'t have permissions to restore assets to the previous collections. Please select a different location to restore them',
    textCantSetSingleAssetSettings: () => (
      <span>
        Asset settings cannot be changed. Refresh and try again
      </span>
    ),
    textKeywords: 'Keywords',
    textFaces: 'Faces',
    textTranscript: 'Transcript',
    titleGenerating: 'Generating...',
    textKeywordsGeneration: 'AI keyword generation started',
    textAssetsNotAllSupported: 'Unsupported asset formats detected: only image assets will be processed.',
    titleNotEnoughCredits: 'AI Processing Limit Reached',
    textNotEnoughtCredits: 'Unfortunately you have not enough credits to autofill keywords.',
    textNotEnoughtCreditsForKeywording: (onClickHandler) => (
      <div>
        You have reached the limit for the first free 1000 images with autokeywording. To use
        autokeywording feature further, please{' '}
        <span className="picsioLink" onClick={onClickHandler} role="presentation">
          {' '}
          buy more API calls{' '}
        </span>{' '}
        to your {appName} account balance{' '}
      </div>
    ),
    textReachedAutokeywordingLimit: (canNotBuyApiCalls, usersToWrite) => (
      <>
        You reached the limit of assets / minutes available for AI processing.{' '}
        <Choose>
          <When condition={canNotBuyApiCalls}>
            <> Please ask your team admins {usersToWrite.map((user) => (
              <Tag
                type="user"
                avatar={user.avatar}
                key={user._id}
                text={user.email}
              />
            ))} to subscribe to the <b>AI Kit add-on</b> to continue using AI-powered services.
            </>
          </When>
          <Otherwise>
            <>
              Click “Subscribe” below to add the <b>AI Kit</b> to your plan and continue using Face Recognition, Transcription, AI Keywording, and Image Captioning.
            </>
          </Otherwise>
        </Choose>
      </>
    ),
    textReachedAiCreditLimit: (canNotBuyApiCalls, usersToWrite) => (
      <>
        You reached the limit of assets available for AI processing.{' '}
        <Choose>
          <When condition={canNotBuyApiCalls}>
            <>Please ask your team admins {usersToWrite.map((user) => (
              <Tag
                type="user"
                avatar={user.avatar}
                key={user._id}
                text={user.email}
              />
            ))} to subscribe to the <b>AI Kit add-on</b> to continue using AI-powered services.
            </>
          </When>
          <Otherwise>
            <>
              Click “Subscribe” below to add the <b>AI Kit</b> to your plan and continue using Face Recognition, Transcription, AI Keywording, and Image Captioning.
            </>
          </Otherwise>
        </Choose>
      </>
    ),
    textCantAutokeywordingForSelectedAssets: (
      onClickHandler,
      canNotBuyApiCalls,
      usersToWrite,
      assetsLengthAllowedToKeywording,
    ) => (
      <>
        The number of assets selected for AI Keywording exceeds your current AI processing limit. Please select up to {assetsLengthAllowedToKeywording} assets.{' '}
        <Choose>
          <When condition={canNotBuyApiCalls}>
            <>Or please ask your team admins {usersToWrite.map((user) => (
              <Tag
                type="user"
                avatar={user.avatar}
                key={user._id}
                text={user.email}
              />
            ))} to subscribe to the <b>AI Kit add-on</b> to continue using AI-powered services.
            </>
          </When>
          <Otherwise>
            <>
              Click “Subscribe” below to add the <b>AI Kit</b> to your plan and continue using Face Recognition, Transcription, AI Keywording, and Image Captioning
            </>
          </Otherwise>
        </Choose>
      </>
    ),
    textCantDescribingForSelectedAssets: (
      onClickHandler,
      canNotBuyApiCalls,
      usersToWrite,
      totalAssetsAllowedForDescribing,
    ) => (
      <>
        The number of assets selected for Image Captioning exceeds your current AI processing limit. Please select up to {totalAssetsAllowedForDescribing} assets.{' '}
        <Choose>
          <When condition={canNotBuyApiCalls}>
            <>Or please ask your team admins {usersToWrite.map((user) => (
              <Tag
                type="user"
                avatar={user.avatar}
                key={user._id}
                text={user.email}
              />
            ))} to subscribe to the <b>AI Kit add-on</b> to continue using AI-powered services.
            </>
          </When>
          <Otherwise>
            <>
              Click “Subscribe” below to add the <b>AI Kit</b> to your plan and continue using Face Recognition, Transcription, AI Keywording, and Image Captioning.
            </>
          </Otherwise>
        </Choose>
      </>
    ),
    textOpenBilling: 'Open Billing',

    textAskTeamowner: ' Please ask your team owner / billing manager to buy credits.',
    textGoToBillingpage: ' Please go to billing page to buy credits.',
    titleError: 'Error',
    textServiceUnavailable: 'This service is unavailable now. Please try again later.',
    textAssignees: 'Assignees',
    textShare: 'Share',
    textShareLink: 'Sharing link',
    textDirectLink: 'Direct URL for embedding',
    textRestrict: 'Restrict',
    textCollections: 'Collections',
    textLightboards: 'Lightboards',
    textProducts: 'Products',
    textCustomFields: 'Custom fields',
    titleArchive: 'Archive',
    titleWatermarks: 'Watermarks',
    labelArchiveReason: 'Archived asset reason message',
    defaultArchiveReason: 'Asset is moved to archive',
    titleRemove: 'Remove',
    titleRemoving: 'Remove from collection',
    titleRemovingFromLightboard: 'Remove from lightboard',
    textRemoveFilesFrom: (nameOrCount, collectionName, entity = 'collection') => (
      <>Remove{' '}
        <Choose>
          <When condition={typeof nameOrCount === 'number'}>
            <span className="highlight">{nameOrCount}</span> {pluralize('asset', nameOrCount)}
          </When>
          <Otherwise>
            asset <span className="highlight">{nameOrCount}</span>
          </Otherwise>
        </Choose> from {entity} <span className="highlight">{collectionName}</span>?
      </>
    ),
    textRemoveFilesFromLastCollection: (assetName) => (
      <>
        You are about remove <span className="highlight">{assetName}</span> asset from all collections and move it to the root collection
        <br />
        <br />
        <InfoBlock>
          If you would like to entirely delete it from the library, use “Move to trash” instead
        </InfoBlock>
      </>
    ),
    textFinishInBg: 'This operation affects large amount of assets and will be finished on the background',
    textFinishDuplicationInBg: 'Duplication has started. It will run in background. You can continue using Picsio.',
    textRemoveFilesFromLightboardAndDelete:
      'This operation will delete assets that do not belong to any lightboard and/or collection.',
    textCantUpdateTags:
      `Can't update tag(s). Please try again. Contact <a class="picsioLink" href="mailto:${supportEmail}">${appName}</a> support if the error repeats.`,
    textCantUpdateLightboards:
      `Can't update lightboard(s). Please try again. Contact <a class="picsioLink" href="mailto:${supportEmail}">${appName}</a> support if the error repeats.`,
    textAssetMarks: 'Asset marks',
    titleConfirm: 'Confirm',
    titleWarning: 'Warning!',
    textAreYouSure: (value) => `You’re about to change ${value} of assets. Are you sure?`,
    textAreYouRealySure:
      `You have selected more than 50 000 assets. It is not possible to make changes on them at once.<br><br>If you still want to proceed with updating information on more than 50 000 files, please contact <a class="picsioLink" href="mailto:${supportEmail}">${appName}</a> for further assistance.`,
    textCantUpdate: (value) => (
      <div>
        {value} cannot be updated. Refresh and try again
      </div>
    ),
    textDetachFromCollectionSomeAssetsFailed: ({ count, collection }) => (
      <div>
        For some reason {pluralize('asset', count, true)} {count > 1 ? 'were' : 'was'} not removed from collection "{collection}". Refresh and try again
      </div>
    ),
    textDetachFromLightboardSomeAssetsFailed: ({ count, lightboard }) => (
      <div>
        For some reason {pluralize('asset', count, true)} {count > 1 ? 'were' : 'was'} not removed from lightboard "{lightboard}". Refresh and try again
      </div>
    ),
    textAssetsRemovedFromCollection: ({ count, collectionName }) => (
      <div>{`${count} ${pluralize('asset', count)} have been removed from the collection "${collectionName}"`}</div>
    ),

    textRejectedFiles: 'Rejected assets',
    textDeselectFlags: 'Deselect flags',
    textUnflaggedFiles: 'Unflagged assets',
    textFlaggedFiles: 'Flagged assets',
    textLabeledRed: 'Labeled "red"',
    textLabeledYellow: 'Labeled "yellow"',
    textLabeledGreen: 'Labeled "green"',
    textLabeledBlue: 'Labeled "blue"',
    textDeselectColors: 'Deselect colors',
    textLabeledPurple: 'Labeled "purple"',
    textNoColorLabel: 'No color label',
    textMoveToTrash: 'Move to trash',

    panelPlaceholderNoLabels: 'No label is attached to this asset',
    panelPlaceholderNoKeywords: 'No keyword is attached to this asset',
    panelPlaceholderNoUsers: 'No teammate is assigned to this asset',
    panelPlaceholderAssetNoCollections: 'This asset doesn’t belong to any collection',
    panelPlaceholderAssetsNoCollections: 'These assets don’t belong to any collection',
    panelPlaceholderNoCoordinates:
      'To see the map, please specify GPSLatitude and GPSLongitude in the list of EXIF metadata fields.',
    panelPlaceholderNoLightboards: 'This asset doesn’t belong to any lightboard',
    mixedField: 'You lack permission to edit some of selected files',

    labelCheckboxDontShow: "Don't show it again",

    editWidgets: 'Customize view',
    emptyEditWidgetText: 'If you wish to view metadata fields, please contact your admin',
    emptyMetadataFieldsText: () => (
      <>
        Metadata fields are hidden.{' '}
        <br />
        Click Customize view to adjust and reveal field visibility.
      </>
    ),

    titleArchiveAssets: 'Move to archive',
    textArchiveAssets: (amount) => `You are about to archive ${amount} asset(s)`,
    textCopy: 'Copy',
    textInviteTeammates: 'Invite people to collaborate',
    textRequestDemo: 'Request a demo',
    textRequestTraining: 'Request a training',
    brandPageUrlCopied: 'Brand page URL has been copied',
    apiKeyCopied: 'API Key copied',
    inboxUrlCopied: 'Inbox link copied',
    disabledCopyWebsitesTooltipText: 'Publish a website to enable this link',
    disabledCopyInboxTooltipText: 'Publish a inbox to enable this link',
    disabledCopyAssetsTooltipText: 'Publish asset sharing to enable this link',
    textLinkCopied: 'Link copied!',
    textCopyLink: 'Copy link!',
    uploadDateTooltipText: 'Upload date',
    creationDateTooltipText: 'Create date',
    toastTextDescriptionGeneration: 'AI description generation started',
    describingBtnText: 'Generating description',
    generateDescriptionBtnText: 'Generate description',
    aiBadgeAssetsTooltipText: (tier, available, used, cn) => (
      <div className={cn}>
        { tier ? <div>{tier}</div> : null }
        <div>Assets available for processing: {available}</div>
        <div>Processed assets: {used}</div>
      </div>
    ),
    aiBadgeMinutesTooltipText: (tier, available, used, cn) => (
      <div className={cn}>
        { tier ? <div>{tier}</div> : null }
        <div>Available transcription time: {available}</div>
        <div>Transcription time used: {used}</div>
      </div>
    ),
    aiIconTooltipText: (field, available, cn) => (
      <div className={cn}>
        { field ? <><div>{field}</div><br /></> : null }
        <span>Assets available for processing: {available}</span>
      </div>
    ),
    aiIconMinutesTooltipText: (field, available, cn) => (
      <div className={cn}>
        { field ? <><div>{field}</div><br /></> : null }
        <span>Available transcription time: {available}</span>
      </div>
    ),
  },
  TOOLBAR_ACTION_AI_ICON_MENU: {
    GENERATE_KEYWORDS: 'Generate Keywords',
    GENERATE_DESCRIPTION: 'Generate Description',
    RECOGNISE_FACES: 'Recognize Faces',
    TRANSCRIBE: 'Transcribe',
  },
  TOOLBAR_ACTION_AI: {
    PROCESS_WITH_AI: 'Process with AI',
    PROCESSING_WITH_AI: 'Processing selected assets. Wait a bit.',
    ARROW_ICON: 'AI actions',
  },
  TOOLBAR_ACTION_ICONS: {
    ARROW_UP: 'Show action toolbar',
    ARROW_DOWN: 'Hide action toolbar',
  },

  RESTRICT: {
    TITLE: 'Warning',
    RESTRICTED_REASON: 'Asset is restricted. Use with caution',
    restrictReasonLabel: 'Reason for asset restriction',
    attachAssetTitle: 'Cannot move restricted assets',
    attachAssetToCollection:
      'You are planning to move the restricted asset. You do not have permission to move it. Please select only non-restricted assets and try again.',
    attachAssetsToCollection:
      'Some of the assets you are planning to move are restricted. You do not have permission to move them. Please unselect restricted assets and try again.',
    cantRestrict: (length) => (
      <div>
        {pluralize('Asset', length)} cannot be restricted. Refresh and try again
      </div>
    ),
    BTN_OK: 'Ok',
  },



  LinkedAssets: {
    title: 'Linked assets',
    buttonLinkAssets: 'Link assets',
    buttonUnlinkAssets: 'Unlink selected',
    textNoLinkedAssets: 'no linked assets',
    titleUnlinkFrom: 'Unlink all assets',
    titleUnlink: 'Unlink this asset',
    infoAlreadyLinked: 'All selected assets are already linked',
    infoAlreadyUnlinked: 'All selected assets are already not linked',
    placeholder: (allowedAssetsCount) => `Sorry, you can’t link more than ${allowedAssetsCount} assets at a time.`,
    errorMessage: "can't get selected assets, please try again",
  },

  RESOLVEDUPLICATESDIALOG: {
    titleFileAlreadyExists: 'Asset already exists',
    textKeepBoth: 'Keep both',
    textAddAsNewRevision: 'Add as a new revision',
    textReplace: 'Replace',
    textRename: 'Rename',
    textSkip: 'Skip',
    textApplyToAll: 'Apply to all',
    textFileAlreadyExists: `You’re about to upload the asset that already exists in ${appName}`,
  },

  EXPORT_TO_CSV_DIALOG: {
    title: 'Export as CSV',
    /** @param {number} numberOfAssets */
    text: (numberOfAssets) => `Export ${numberOfAssets} selected ${pluralize('asset', numberOfAssets)} as CSV. Include required asset metadata.`,
    textBtnCancel: 'Cancel',
    textBtnOk: 'Export',
    noPermissions: 'Team administrator disabled access to this feature for your user account. Contact them to get this permission',
    validationError: 'Validation error',
    fileName: 'assets_data.csv',
    exportKeywordsFileName: 'keywords_data.txt',
    exportAllFields: 'All fields',
    exportSelectedFields: 'Select required fields',
    notAvailable: {
      title: 'Feature is not available',
      text: 'This feature is not available on mobile devices.',
      textBtnCancel: 'Ok',
    },
    assetsLimit: {
      title: 'Cannot export to CSV',
      text: (assetsLimit) => `It’s not possible to export data of more than ${assetsLimit} assets in one CSV. <br> Select fewer assets or contact us at <a class='picsioLink' href='mailto:${supportEmail}'>${supportEmail}</a> if you still need to export that number of assets.`,
      textBtnCancel: 'Ok',
    },
  },

  SEARCH_FILTERS_DIALOG: {
    title: 'Add filters',
    text: 'Selected: ',
    textNone: 'None',
    textBtnCancel: 'Cancel',
    textBtnOk: 'Confirm',
    placeholder: 'Search name of filter or metadata field',
  },

  REVISION_FIELDS_DIALOG: {
    title: 'Required fields',
    textBtnCancel: 'Cancel',
    textBtnOk: 'Upload',
    labelCommentRevision: 'Comment revision',
    labelTitleAndDescription: 'Title & Description',
    labelKeywords: 'Keywords',
    labelAssignees: 'Assignees',
    labelAssetMarks: 'Asset marks',
    description: (action) => `According to team policy, you are required to ${action} every time you upload a new revision.`,
    titles: {
      comments: 'Add a comment',
      titleAndDescription: 'Add title and description',
      keywords: 'Attach keywords',
      assignees: 'Assign users',
      flag: 'Set flag',
      rating: 'Set rating',
      color: 'Set color',
    },
  },

  SEARCH: {
    text: {
      // keep keys in alphabetical order
      asset: 'asset',
      assets: 'assets',
      'Click to add custom field': 'Click to add custom field',
      'Click to add keywords': 'Click to add keywords',
      Color: 'Color',
      'Custom field': 'Custom field',
      Flag: 'Flag',
      Keywords: 'Keywords',
      Faces: 'Persons',
      'Last changed': 'Last changed',
      'Learn more': 'Learn more',
      noProducts: 'No products',
      'No assets': 'No assets',
      Rating: 'Rating',
      Reset: 'Reset',
      'Save this search': 'Save this search',
      Search: 'Search',
      'Search in': 'Search in',
      'Select custom fields': 'Select custom fields',
      Type: 'Type',
      'Upload date': 'Upload date',
      Restricted: 'Restricted',
      'Restricted show': 'Show restricted assets',
      showArchivedAssets: 'Show archived assets',
      CustomFields: 'Custom fields',
      collection: 'Collection',
    },
    trialTooltipText: 'Click to see Search & Filter panel availability across plans',
    addMoreFilters: 'Add more filters',
    emptyPlaceholder: {
      cantOpenLink: (externalLink) => (<>{appName} can not open your <a href={externalLink} target="_blank" rel="noopener noreferrer"> file.</a></>),
      clickLink: 'Click upward link to open in a browser tab.',
      // emptyCollection: (activeCollectionName) => (<>There are no files in collection <span className="act">{activeCollectionName}</span>.</>),
      emptyCollections: (collectionsName) => (
        <div>There are no files in {pluralize('collection', collectionsName.length)}{' '}
          {collectionsName.map((collectionName, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <span className="act" key={collectionName + index}>
              {collectionName}{collectionsName.length > 1 && index !== collectionsName.length - 1 ? ',' : ''}&nbsp;
            </span>
          ))}
        </div>
      ),
      clickUpload: 'You can start adding assets by selecting the suggested options',
    },
    tooltipContentSearchIsNotAllowed: 'Search in documents content is available starting Micro billing plan',
    noAssetsFound: () => (
      <div>
        <b>Nothing found</b><br />
        <p>Try using less refining filters or shorten your text search query to broaden your search results</p>
      </div>
    ),

    addCollectionButtonText: 'Add collection',

    searchButtonText: (text) => `Search whole library for: ${text}`,

    searchInRoot: 'Try searching in the root collection',

    fieldAny: 'All fields',
    fieldName: 'Filename',
    fieldContent: 'Content',
    fieldCollectionName: 'Collection name',
    fieldTitle: 'Title',
    fieldPersons: 'Persons',
    fieldDescription: 'Description',
    fieldKeywords: 'Keywords',
    fieldMeta: 'Custom fields',
    fieldTranscript: 'Transcription',

    optionAny: 'Any',
    optionImages: 'Images',
    optionVideo: 'Video',
    optionAudio: 'Audio',
    optionText: 'Text documents',
    optionPDF: 'PDF documents',
    optionOfficeDocument: 'Office documents',
    optionSketch: 'Sketch files',
    optionRAW: 'DSLR RAW photos',
    optionPhotoshop: 'Photoshop documents',
    option3D: '3D models',
    optionLinks: 'External links',

    dialogTitleCreateSearch: 'Create saved search',
    dialogCreateSearchLabel: 'Name',
    dialogCreateSearchLabelShare: 'Share with my team',
    dialogErrorNameEmpty: 'Name is empty',
    dialogTitleSearchHaveAlready: 'Name already in use',
    dialogTextSearchHaveAlready:
      'This name is already used for other saved search. Try another name.',
    saveSearch: 'Save this search',
    resetFiltersBtn: 'Reset filters',
    resetFilterBtn: 'Reset filter',
    filterConfirmBtn: 'Confirm',
    addFiltersBtn: 'Add filters',
    saveFiltersTooltip: 'Save filter configuration',
    userSearchFilterPlaceholder: 'Select user',
    userSearchFilterLabel: 'Select user',
    sort: 'Change sorting',
    view: 'Change catalog view',
    notifications: 'Notifications',
    settings: 'Account settings',
  },

  COMPARE: {
    wrongParameters: {
      title: 'Comparison error',
      text: 'Make sure you compare assets of the same format and try again',
    },
  },

  REFERRAL: {
    title: 'Referral page',
    helpText: 'Learn how to use a referral program',
  },

  STORAGE: {
    title: getStorageTitle,
    alertCantLoadStat: () => <>Storage statistics cannot be loaded. Refresh and try again</>,
    textConnectedTo: (email) => (
      <div>
        Connected to <span className="pageStorageMail__mail">{email}</span> storage
      </div>
    ),
    textFolderConnectedTo: ({ name, linkToGoogleDriveFolder, storageType }) => {
      if (storageType === 's3') {
        return <span>Connected to {name} folder</span>;
      }
      return <span>Connected to <a className="picsioLink" href={linkToGoogleDriveFolder} target="blank">{name}</a> folder</span>;
    },
    textSizeTotal: (size, storageType) => {
      if (storageType === 's3') {
        return <span>Amazon S3 <span>({size} total)</span></span>;
      }
      return <span>Google Drive <span>({size} total)</span></span>;
    },
    textGDSizeUnlimited: 'Google Drive (Unlimited)',
    textUsed: 'used',
    textUsedWithoutTrash: 'used (without trashed)',
    textFree: 'free',
    textTrashed: 'trashed',
    textChange: 'Change',
    textAvailable: 'available',
    textCreditsBuySuccess: (amount) => `${amount} credits purchased`,
    textAICreditsBuySuccess: (amount) => `${amount} credits purchased`,
    textFacesBuySuccess: (amount) => `${amount} credits purchased`,
    textLabelMigration: 'Your plan also allows you to use other storage:',
    textMigrationRequestSent: () => <span>Migration request sent.<br />Our support team will contact you shortly</span>,
    textChangeStorage: 'Reset storage',
    textRequestChangeStorage: () => <p>If you want to change a working folder connected to your account, it is possible: contact us at  <a className="picsioLink" href={`mailto:${supportEmail}`}>{supportEmail}</a> and our representative will guide you through a step-by-step process, so there’s no data or files lost.</p>,
    resetingStorage: 'Resetting storage',
    alertCantReset: () => <>Storage cannot be reset. Refresh and try again</>,
    DIALOG_RESET_STORAGE: {
      title: 'Storage Reset Confirmation Required',
      text: 'Resetting a storage will result in removing all assets, collections, keywords, inboxes, and websites created until now.',
      textBtnOk: 'Confirm',
    },
    helpText: `Learn how about ${appName} integration with Team Drive`,
  },
  SYNC: {
    screenTitle: 'Sync Settings',
    textLoading: 'Loading sync settings...',
    textTitleCantStartSync: 'Sync running already',
    textTitleSyncLimitExceeded: 'Sync frequency limit reached',
    textTextCantStartSyncTemplate: (progress) => `Wait a sec, please. Another sync process was started recently and is still in ${progress}% progress.`,
    textManualSyncLimited: (minutesBeforeNextSync, planName) => `For your current ${planName} plan next sync will run in ${minutesBeforeNextSync} minute${minutesBeforeNextSync > 1 ? 's' : ''}. Upgrade your billing plan to speed up a syncing process.`,
    textTitleSyncFailed: 'Sync failed',
    textTextSyncFailed:
      `Sync has not finished. Please contact <a href='mailto:${supportEmail}?subject=Sync from GD failed'>${supportEmail}</a>.`,
    textTitleSyncStarted: 'Sync started',
    textTextSyncStarted: 'Sync has started running in the background. This may take some time before new files appear in your library.',
    textTitleSyncRunning: 'Sync running',
    textTextSyncRunning:
      'Syncing process takes more than expected, we will notify you by email when the process is finished.',
    textTitleSyncCancelled: 'Attention!',
    textTitleDestructiveSync: 'Destructive sync warning',
    textTextSyncCancelledTemplate:
      'Sync cancelled.<br /><br />This sync will delete a lot of assets from your digital library. If you are aware of that and want to resume this sync, click Confirm.',
    textTextSyncDestructiveWebsitesTemplate: (websites) => `Sync process was stopped because we found websites that will be removed by this sync: ${websites} <br/> To fix this, do one of the following:<br/> - rename pics.io collections so they match your Cloud Storage folder names<br/> - rename your Cloud Storage folders, so they match pics.io collections' names<br/> - run 'Sync to your Cloud Storage'<br/> - or unpublish your collections in ${appName}<br/><br/>`,
    textTitleSyncNothing: 'Sync storage folder is empty',
    textTextSyncNothing: (goToStorageLink) => (
      <>
        <p>
          Syncing with an empty{' '}
          <span className="picsioLink" onClick={goToStorageLink} role="presentation">
            storage folder
          </span>{' '}
          will remove original assets from {appName}.
        </p>
        <p>
          Unsure? Please, consult your team admin before proceeding.
        </p>
      </>
    ),
    textBtnOkSyncNothing: 'Proceed',
    textTitleGDS: 'Cloud Storage synchronization',
    textButtonSync: 'Sync',
    textButtonGoToGD: 'Check your connected folder',
    labelSyncToPicsio: `Sync from your Cloud Storage to ${appName}`,
    descriptionPull: 'Pull all folders and files changes from your Cloud Storage working folder',
    labelSyncToGD: `Sync from ${appName} to your Cloud Storage`,
    descriptionPush: 'Push collections and assets changes to your Cloud Storage working folders',

    textSyncingData: 'Syncing data...',
    textSyncFailed: 'Sync failed',
    textLatestSync: (timePeriod) => (`The latest sync happened ${timePeriod} ago`),
    textSyncNow: 'Sync Now',
    textFirstSync: (storageName) => (`Want to synchronize your ${storageName} storage with ${appName}? Press Sync now.`),
    textBigLibraryWarning: 'Full library sync is not available because you have too many assets in it. You can instead perform a collection level sync.',
    textGetAccessDescription: 'We have recently improved our Sync operations. If you want to get better experience with Autosync please provide additional permissions to access your Google Drive data.',
    textGiveAccess: 'Give access',
    errorWrongAccount: 'Wrong user account',
    helpText: `Learn more about how cloud storage syncs with ${appName}`,
  },

  TAGSTREE: {
    errorSomethingWrong:
      `Something went wrong. Please try again. Contact <a class='picsioLink' href='mailto:${supportEmail}'>${appName}</a> support if the error repeats.`,
    textRemoveCollectionAndSite: (collectionName, websiteAlias) => `You are about to delete <span class='highlight'>${collectionName}</span> collection and all its subcollections. ${websiteAlias
      ? `This operation will also remove website <a href='${websiteAlias}' target='_blank'>${websiteAlias}.</a><br />`
      : ''
    } Please choose how to handle the assets inside:`,
    textRemovingCollection: 'Removing collection...',
    textRemovingFilesInGD: 'Removing files in your Cloud Storage...',
    errorNameEmpty: 'Name is empty',
    errorNameAlreadyTaken:
      'This name is already taken at the current level. Please, enter another one',
    alertSearchAdded: (name) => `"${name}" added to "Saved searches"`,
    textDeleteCollection: 'Delete collection',
    textCreateCollection: 'Create collection',
    textCreateNestedCollection: 'Create nested collection',
    textRenameCollection: 'Rename collection',
    textUploadFiles: 'Add assets',
    textCopyWebsiteLink: 'Copy website link to clipboard',
    textRemoveFromFavorites: 'Remove from favorites',
    textAdToFavorites: 'Add to favorites',
    textMoveCollection: 'Move collection',
    textDownloadCollection: 'Download collection',
    textSave: 'Save',
    textCancel: 'Cancel',
    textSyncFolder: 'Sync collection',
    textSyncFolderSubtree: 'Sync subtree',
    textDuplicateCollection: 'Duplicate collection',
    textUpdateWebsite: 'Website settings',
    textCreateWebsite: 'Publish to web',
    textRemoveCollection: 'Delete collection',
    textRemoveFilesFromGD: 'Also remove nested assets',
    textRetryImportCollection: 'Retry import',
    textCantChangeOrder: () => (
      <div>
        Sort order cannot be changed. Refresh and try again
      </div>
    ),
    textCantCompleteSearch: () => (
      <div>
        Cannot complete search for collections. Refresh and try again
      </div>
    ),
    titleRemoveSavedSearch: 'Delete saved search',
    textYouAreAboutRemoveSavedSearch: (name) => `You are about to delete a saved search <span class="highlight">${name}</span>. Continue?`,
    textHasBeenRemoved: (name) => `"${name}" deleted from "Saved searches"`,
    placeholderSearch: 'Search collections',
    textAttachingCollection: 'Adding to collection',
    textAttachingToCollection: 'Adding to collection',
    textDetachingCollection: 'Removing from collection',
    textMovingCollection: 'Moving collection...',
    textMovingToCollection: 'Moving to collection',
    textAddAsShortcuts: 'Adding to collection',
    textMovedCollection: 'Collection moved',
    textUnableToMoveCollection: (errorMessage) => `Cannot move collection. ${errorMessage}`,
    textMoveToArchive: 'Move to archive',
    removeSelectedOnlyRadioLabel: (collectionName) => `Remove from <span class="highlight">${collectionName}</span> collection only`,
    removeSelectedOnlyRadioLabelDescription: 'The assets will remain in any other collections they are added to in the library',
    removeSelectedAndAttachedRadioLabel: 'Delete from <span class="highlight">all</span> collections',
    removeSelectedAndAttachedRadioLabelDescription: 'The assets will be deleted from all collections they are added to throughout the library',
    removeCollectionHelpLinkUrl: 'https://help.pics.io/en/articles/2168102-removing-assets-from-collections',
    removeCollectionHelpLinkTitle: 'Removing assets from collections',
    removeCollectionAmplitudeData: {
      selectedOnly: 'RemoveFromCollection',
      selectedAndAttached: 'DeleteFromCollection',
    },
  },

  SAVEDSEARCHESTREE: {
    placeholderSearch: 'Search in saved searches',
    textDeleteSearch: 'Delete saved search',
    textFavorites: 'Favorites',
    textAddToFavoritesSearch: 'Add to favorites',
    textRemoveFromFavoritesSearch: 'Remove from favorites',
    textSavedSearches: 'Saved Searches',
    textErrorDescription: 'Failed to load saved searches. Click the button below to try again.',
    textErrorButton: 'Load saved searches',
    textPlaceholderText: "You haven't saved any search<br> results yet",
  },

  LIGHTBOARDSTREE: {
    title: 'Lightboards',
    placeholderSearch: 'Search lightboards',
    textDeleteLightboard: 'Delete lightboard',
    textErrorTitleNameSpace: 'Only letters, numbers, underscore, dash, and space are allowed',
    placeholderNoLightboard: "You haven't created any lightboards yet",
    textUploadFiles: 'Upload assets',
    textRenameLightboard: 'Rename lightboard',
    textCreateLightboard: 'Create lightboard',
    textSave: 'Save',
    textButtonFailedToLoad: 'Load lightboards',
    textFailedToLoad: 'Failed to load lightboards. Click the button below to try again.',
    textCancel: 'Cancel',
    errorUnableToRename: `Cannot rename lightboard. Please, contact <a class="picsioLink" href="mailto:${supportEmail}">${appName}</a>`,
    textAttachingToLightboard: 'Adding to lightboard',
  },

  PRODUCTSTREE: {
    title: 'Products',
    placeholderSearch: 'Search products',
    textDelete: 'Delete product',
    textDeleteVariant: 'Delete variant',
    textErrorTitleNameSpace: 'Only letters, numbers, underscore, dash, and space are allowed',
    placeholderNoProducts: "You haven't created any products yet",
    textRename: 'Rename product',
    textCreate: 'Create product',
    textSave: 'Save',
    textButtonFailedToLoad: 'Load products',
    textFailedToLoad: 'Failed to load products. Click the button below to try again.',
    textCancel: 'Cancel',
    errorUnableToRename: 'Cannot rename product. Please, contact <a class="picsioLink" href="mailto:support@toriut.com">toriut.com</a>',
    textAttachingToProduct: 'Adding to product',
  },

  INBOXESTREE: {
    title: 'Inboxes',
    placeholderSearch: 'Search inboxes',
    textDeleteInbox: 'Delete inbox',
    textErrorTitleNameSpace: 'Only letters, numbers, underscore, dash, and space are allowed',
    textFailedToLoad: 'Failed to load inboxes. Click the button below to try again.',
    textLoadInboxes: 'Load inboxes',
    placeholderNoInbox: "You haven't created any inbox yet",
    textUploadFiles: 'Upload assets',
    textRenameInbox: 'Rename inbox',
    textCreateInbox: 'Create inbox',
    textSave: 'Save',
    textCancel: 'Cancel',
    errorUnableToRename: `Unable to rename inbox. Please, contact <a class="picsioLink" href="mailto:${supportEmail}">${appName}</a>`,
    textCopyInboxLink: 'Copy inbox link to clipboard',
    textInboxSettings: 'Inbox settings',
    textDelete: 'Delete inbox',
    textRename: 'Rename inbox',
    errorHaveReachedLimit: 'You have reached a limit of inboxes on this plan',
  },

  INBOXSETTINGS: {
    title: 'Inbox settings',
    share: 'Share inbox',
    unshare: 'Stop sharing inbox',
    textMain: 'Main',
    textSecurity: 'Security',
    textPassword: 'Password',
    startShare: 'Share inbox',
    stopShare: 'Stop sharing inbox',
    textUrlNotValid: 'URL is not valid',
    textWordsCantBeUsed: (words) => `Words "${words}" can\'t be used at the url begging`,
    textPasswordConfirm: 'Password and confirm password are not equal',
    helpText: 'How to receive files via inbox',
    DEFAULT_FIELDS: {
      comment: 'Comment',
      titleAndDescription: 'Title & Description',
      flag: 'Flag',
      rating: 'Rating',
      color: 'Color',
    },
    ERRORS: {
      cantGetInboxes: () => (
        <div>
          Inboxes not loaded. Refresh and try again
        </div>
      ),
      cantChangeAlias: 'Can not change inbox alias',
      cantAddCustomField: () => (
        <div>
          Custom field cannot be added to inbox. Refresh and try again
        </div>
      ),
      cantRemoveCustomField: () => (
        <div>
          Custom field cannot be removed from inbox. Refresh and try again
        </div>
      ),
      inboxUpdatingError: 'Inbox updating was unsuccessful. Please, try again.',
    },
    UNPUBLISH: {
      title: 'Warning',
      text: 'People with a link to this inbox will no longer be able to upload files in your library. Confirm that you want to unpublish this inbox.',
      textBtnCancel: 'Cancel',
      textBtnOk: 'Confirm',
    },
    DELETE: {
      title: 'Delete inbox',
      getText: (inboxName) => `You are going to delete the inbox ${inboxName}. This action cannot be undone. Continue?`,
      textBtnCancel: 'Cancel',
      textBtnOk: 'Delete',
    },
  },
  API_KEYS: {
    title: 'Personal access tokens',
    textCreate: 'Create token',
    textNoDescription: 'No description',
    NoApiKeys: memo(() => <span>You have <strong>no personal tokens</strong></span>),
    createDialog: {
      title: 'Add a description',
      textBtnOk: 'Create',
    },
    limitExceeded: 'You have reached a limit of personal access tokens. To create a new one, delete unused tokens, or reach out ot our support, if you need to expand that limit',
    saveDialog: {
      title: 'Copy this token to a local text file',
      text: 'Remember this access token. You can see this token now and just once, so save it to your local text file',
      textBtnOk: 'Copy to clipboard and close',
      textBtnCancel: null,
    },
    removeDialog: {
      title: 'Deleting personal token',
      text: 'Are you sure you want to delete this personal access token?',
      textBtnOk: 'Delete',
    },
  },

  WATERMARKS: {
    errorNameEmpty: '"Name" - is empty',
    dialogTextWatermarkHaveAlready: 'Watermark with this name already exists. Please try other name.',
    titleNewWatermark: 'New watermark',
    titleDeleteWatermark: 'Delete this watermark?',
    titleUpdateWatermark: 'Update this watermark?',
    titleAttachWatermark: (value) => `You have selected ${value} assets. Watermark will be laid only on JPG, JPEG, PNG formats.`,
    titleDetachWatermark: (value) => `Watermark will be removed from ${value} assets.`,
    applyWatermark: 'Apply this watermark?',
    removeWatermark: 'Removing watermark',
    textUpdateWatermark: (value) => `This watermark is used on ${value} assets. If you make changes to this watermark, they will reflect on those assets immediately.`,
    textDeleteWatermark: (value) => `This watermark is used on ${value} assets. If you delete this watermark, it will get removed from all those assets.`,
    btnOkWatermark: 'Confirm',
    btnOkRemoveWatermark: 'Remove',
    btnCreate: 'Create',
    titleEditName: (value) => `Edit "${value}"`,
    textCreateNewWatermark: 'Create new watermark',
    textPlaceholderTypeWatermark: 'Type a name here',
    labelSettingsWatermarkName: 'Name',
    labelSettingsWatermarkDescription: 'Description',
    watermarkImageUploadErrorTitle: 'Size or type is incompatible',
    watermarkImageUploadText: 'Image must be transparent PNG only and less than 5MB',
    labelSettingsWatermarkSize: 'Size',
    labelSettingsWatermarkOpacity: 'Opacity',
    labelSettingsWatermarkMakeDefault: 'Make as default',
    errorUpdateWatermark: 'Failed to update watermark',
    errorDeletingWatermark: () => (
      <div>
        Watermark cannot be deleted. Refresh and try again
      </div>
    ),
    errorWatermarkName: 'Watermark name cannot be empty',
    errorWatermarkText: 'Watermark text cannot be empty',
    makeDefaultWatermark: 'Make default',
    errorMakeWatermarkDefault: () => (
      <div>
        Watermark cannot be set as default. Refresh and try again
      </div>
    ),
  },

  TEAMMATES: {
    btnOK: 'Add',
    btnCancel: 'Copy token & Close',
    titleAddDomainDialog: 'Add domain',
    textUrlNotValid: 'URL is not valid',
    errorUpdateOktaSettings: (name) => `Update Okta setting "${name}" error`,
    errorOktaSettingsAlreadyExist: (name) => {
      const labels = {
        clientID: 'Client ID',
        oktaDomain: 'Okta domain',
      };
      return `${labels[name]} is already in use`;
    },
    toastAddDoaminToken: 'Token copied. Add it to the DNS settings and get back here to add a verified domain.',
    textYourShouldVerifyDomain: (domain, tokenInfo) => (
      <>
        <p>
          Before adding the domain <strong>{domain}</strong>, verify your ownership by creating the txt record in its DNS settings using the following token:
        </p>
        <code>
          TXT {tokenInfo.txtName} {tokenInfo.token}
        </code>
      </>
    ),
    tooltipRoleGlobalAdmin: 'Full access to all system features and settings, including user management, integrations, and security configurations.',
    tooltipRoleMediaAdmin: 'Manages product media and digital assets, including uploading, editing, and categorizing media files. Media Admins can view product-related information necessary for managing media assets effectively without accessing or modifying product details.',
    tooltipRoleViewer: 'Provides read-only access to product and media information for reference and reporting purposes without modification rights.',
    tooltipRoleCatalogAdmin: 'Organizes and maintains product catalogs, categories, and attributes, ensuring data accuracy and consistency across all listings. Can view and edit media metadata to ensure proper association with products but cannot perform destructive actions on media assets.',
    permissionTitleUpload: 'Upload and duplicate assets',
    permissionTitleSync: ({ isSyncAndDisallowedRootCollections, storageType }) => {
      const defaultTitle = storageType === 's3'
        ? `Synchronize Amazon S3 Bucket with ${appName}`
        : 'Run a sync process for the storage';

      if (isSyncAndDisallowedRootCollections) {
        return 'Synchronization is restricted for users without permissions to manage team collection.';
      }
      return defaultTitle;
    },
    permissionTooltipDisabledSync: 'Permission change is limited due to a conflict: access to the root collection is required to enable sync.',
    permissionTitleWebsites: 'Create, edit or delete websites, perform single asset sharing',
    permissionTitleManageTeam:
      'Invite and remove teammates, edit permissions, manage team settings, receive team status emails',
    permissionTitleManageIntegrations: 'Configure Slack and Webhooks integrations',
    permissionTitleManageBilling:
      'Choose and change subscription plans, add and remove payment methods, edit billing information',
    permissionTitleManageStorage: ({ storageType }) => (storageType === 's3'
      ? `Change Amazon S3 Bucket & working folder connected to ${appName}`
      : `Change storage account & a directory connected to ${appName}`),
    permissionTitleCollectionsManage: 'Manage collections',
    permissionTitleCollectionsCreate: 'Create new collections & sub-collections',
    permissionTitleCollectionsMove:
      'Move collection into another one with the same permission enabled',
    permissionTitleCollectionsEdit: 'Rename existing collections & sub-collections',
    permissionTitleCollectionsDelete: `Delete collections & sub-collections from ${appName}`,
    permissionTitleDownloadFiles: `Download assets from ${appName}`,
    permissionTitleEditImageAttributes: 'Manage assets attributes',
    permissionTitleEditAssetFilename: 'Change the filename of an asset',
    permissionTitleEditAssetTitle: 'Change the asset title',
    permissionTitleEditAssetDescription: 'Change the asset description',
    permissionTitleEditAssetKeywords: 'Attach or remove keywords describing an asset',
    permissionTitleAutogenerateKeywords: 'Automatically generate keywords for an asset',
    permissionTooltipDisabledAutogenerageKeywords: 'Permission change is limited due to a conflict: keywords permissions are required to generate AI keywords.',
    permissionTooltipDisabledAutogenerageDescriptions: 'Permission change is limited due to a conflict: description permission is required to generate AI descriptions.',
    permissionTooltipDisabledEditKeywords: 'Enable “Keywords” permission in Team permissions tab first',
    permissionTooltipDisabledKeywordsControlledVocabulary: 'Enable “Keywords” permission first',
    permissionTooltipDisabledProductDataManagement: 'Enable "Product Data Management" permission first',
    permissionTitleEditAssetCollections: 'Attach or detach assets to the collections & from them',
    permissionTitleEditAssetAssignees: 'Assign assets to other teammates',
    permissionTitleEditAssetLinkedAssets: 'Create or edit linked assets',
    permissionTitleEditAssetMarks:
      'Change assets marks, including flag status, color labels, star rating, etc.',
    permissionTitleEditAssetMetadata: 'Create, remove or edit custom fields',
    permissionTitleManageKeywords: 'Create, edit or remove keywords & upload Controlled Vocabulary',
    permissionTitleAddKeywords: 'Add keywords outside of Controlled Vocabulary',
    permissionDescrAddKeywords: 'Make an exception for adding new keywords if the general setting “Enable Controlled Vocabulary” is active',
    permissionTitleManageTeamSavedSearches: 'Add or remove saved searches for the team',
    permissionTitleEditCustomFieldsSchema: 'Create, remove or edit custom fields',
    permissionTitleAllowEditor: `Use ${appName} image editor`,
    permissionTitleDeleteAssets: `Delete assets from ${appName} library`,
    permissionTitleApproveAssets: 'Approve or discard the asset revision',
    permissionManageInboxes: 'Create, configure and delete an inbox',
    permissionAuditTrail: 'See a lof of actions of all teammates ',
    permissionDuplicateAssets: 'Duplicate assets',
    permissionTitleManageWatermarks: 'Manage watermarks',
    permissionTitleCreateDeleteEditWatermarks: 'Create, edit, delete watermarks',
    permissionTitleImportCsv: 'Import CSV',
    permissionTitleApplyRemoveWatermarkToAsset: 'Apply, remove from asset',
    permissionTitleDownloadWithoutWatermark: 'Download without watermark',
    permissionTitleManageCollectionsCustomSort: 'Custom collection sorting',
    permissionDescrManageCollectionsCustomSort: 'Set and apply a custom order to collections',
    permissionTooltipDisabledManageCollectionsCustomSort: 'Permission change is limited due to a conflict: access to the root collection is required to enable custom collection sorting.',
    textTitleSetPassword: 'Set password',
    textPlaceholderTypePassword: 'Type password here',
    textPlaceholderTypeRole: 'Type role name here',
    permissionDescrAutogenerateKeywords: 'Gives access to the button “Tag with AI” that runs AI for keywords generation',
    permissionDescrAutogenerateDescriptions: 'Gives access to the “Generate description” button that runs AI for description generation',
    permissionTitleManageTranscriptions: 'Transcribe assets, edit transcripts, set a default language for transcription',
    permissionTranscribe: 'Gives access to a button that runs AI to transcribe audio and video assets',
    title: 'My team',
    tabTeammates: 'Teammates',
    tabRoles: 'Roles',
    tabSettings: 'Settings',
    tabIntegrations: 'Integrations',
    tabBranding: 'Branding',
    tabAnalytics: 'Analytics',
    tabSecurity: 'Security',

    qqcomTitle: 'Error',
    qqcomText:
      `Sorry, you can’t invite users from "qq.com".<br />If you have a reason to do that, please contact us at <a class="picsioLink" href="mailto:${supportEmail}">${supportEmail}<a>`,

    errorEmailEmpty: '"Email" - is empty',
    errorCantAddYourself: "You can't add yourself to your team. Please pick someone else",
    errorEmailInvalid: (email) => `${email} isn't valid`,
    errorHaveReachedLimit: 'You have reached a limit of teammate seats on this plan',

    roleInUse: (users, usersList, usersMore) => (users.length > 1 ? (
      <div className="removeRoleDialog">
        The role can't be removed because it is in use by {users.length} users: {usersList}
        {usersMore}.<br />
        <br /> Please detach the role from those users first.
      </div>
    ) : (
      <div className="removeRoleDialog">
        The role can't be removed because it is in use by {usersList}.<br />
        <br /> Please detach the role from this user first.
      </div>
    )),
    usersWithRole: 'Teammates in',
    noUsersWithRole: 'No users assigned for the role',

    titleDeleteRole: 'Delete this role?',
    textDeleteRole: 'You cannot undo this action and restore the role your are about to delete.',
    btnOkDeleteRole: 'Delete',

    textTitleRemoveTeammate: 'Remove teammate',
    textTeammateWillRemoved: 'Teammate will be removed from the team. Are you sure?',
    textCantRemoveTeammate: 'Cannot remove teammate',
    textCantConfirmTeammate: 'Cannot confirm teammate',
    textCantRejectTeammate: 'Cannot reject teammate',
    textCantUpdateTeammate: 'Cannot update teammate',
    textCantReinviteTeammate: 'Cannot reinvite teammate',

    textPasswordSet: 'Password for teammate created',
    errorCantSetPassword: 'Can not set teammate password',
    errorNameEmpty: '"Name" - is empty',
    titleNewRole: 'New role',
    titleCantRemoveRole: 'Cannot delete role',
    textCantRemoveRole: 'The role is in use. Detach the users from it first.',
    titleWarning: 'Warning!',
    titleCantDeleteDefaultRole: 'Cannot delete default role',
    textRemoveOnlyOneRole:
      `<p>Sorry, you can’t do that.</p><p>You must have at least 1 active team role in ${appName}.</p>`,
    titleEditName: (value) => `Edit "${value}"`,
    dialogTextRoleHaveAlready: 'The role with such name already exists. Please try the other name.',
    textRemoveAutoinviteRole: (onClickHandler) => (
      <span>
        This role cannot be deleted as it’s used as the default one to auto-invite new users. Please{' '}
        <span className="picsioLink" onClick={onClickHandler} role="presentation">
          choose another role for auto-inviting
        </span>{' '}
        and try again.
      </span>
    ),
    titleRestrictReason: 'Default restricted asset reason message',
    labelRestrictReason: 'Change the default reason message for restricted assets',
    tooltipMessageAllows:
      'Allows the team to use existing keywords only. Blocks any changes, i.e. add, edit and remove keywords.',
    textKeywords: 'Keywords',

    titleRequiredFields: 'Required fields for uploads and revisions',
    titleMetadataFields: 'Metadata fields',
    titleCustomFields: 'Custom fields',
    actionRequired: 'Required for upload',
    labelCommentRequired: 'Comment',
    labelTitleAndDescriptionRequired: 'Title & Description',
    labelTitleRequired: 'Title',
    labelDescriptionRequired: 'Description',
    labelKeywordsRequired: 'Keywords',
    labelAssigneesRequired: 'Assignees',
    labelFlagRequired: 'Flag',
    labelRatingRequired: 'Rating',
    labelColorRequired: 'Color',

    labelUseControlled: 'Enable Controlled Vocabulary',
    labelFacesAutofill: 'Automatically recognize persons for new assets',
    labelAutomaticallyAllRelevant: 'Automatically run all relevant AI processes for new assets',
    labelKeywordsAutofill: 'Automatically recognize keywords for new assets',
    labelTranscribingAutofill: 'Automatically transcribe new assets (video and audio)',
    textUploadDictionary: 'Import keyword list',
    textKeywordingEngines: 'Keyword Generation Engine',
    textKeywordingConfidence: 'Recognition precision',
    textKeywordingLocale: 'Language to recognize keywords in',
    textTranscribingLocale: 'Choose a default language to transcribe in',
    textKeywordingTabTitle: 'Autotagging with keywords',
    textFacialRecognitionTabTitle: 'Person recognition',
    textAICredits: 'Artificial Intelligence Credits',
    textKeywordSettings: 'Keywords settings',
    textGeneralAi: 'General AI settings',
    faceRecognitionSettings: 'Face recognition settings',
    transcriptionSettings: 'Transcription settings',
    textBuyMoreKeywordCalls: 'Buy more calls',
    textBuyMoreAICredits: 'Purchase credits',
    textKeywordingTabControlledVocabularyTitle: 'Generate keywords only from my vocabulary',
    titleInvalidFileType: 'Invalid file type',
    textOnlyTXTAndCSV: 'You can only upload dictionary as txt or csv file',
    descriptionSettings: 'Description settings',
    labelDescribingAutofill: 'Automatically generate descriptions for new assets',
    labelDescribingSelect: 'Default action for pre-existing descriptions',
    descriptionMergeSelectOption: 'Merge (Combine with AI’s)',
    descriptionReplaceSelectOption: 'Replace (Use AI’s only)',

    titleUploadDictionary: 'Import keyword list',
    descriptionUploadDictionary: "We found discrepancies between the system's keyword list and your uploaded one. Please decide how to proceed:",
    labelMerge: 'Combine imported keyword list with the existing one in the system',
    labelReplace: "Replace the system's existing keyword list with the one imported from your file <span>(Any not listed in the file will be deleted)</span>",
    btnUpload: 'Confirm',
    titleDictionaryUploaded: 'Keyword Import Complete',
    textWeFoundInFile: ({ total }) => `We found ${total} keywords in the uploaded file. All of them have been successfully added to your keyword list.`,
    titleDictionaryUploadFailed: 'Upload of the dictionary failed',
    textErrorTryAgain: 'Error, please try again later',
    titleKeywordsAutogeneration: 'Confirm action',
    titleTranscriptAutogeneration: 'Confirm action',
    titleDescriptionAutogeneration: 'Confirm action',
    textThisWillCost: 'Enabling this setting, you confirm that all newly created assets in your library will be processed by AI, which uses up your available AI processing balance.',
    btnActivate: 'Activate',
    btnConfirm: 'Confirm',

    titleFacesAutogeneration: 'Confirm action',

    textCreateNew: 'Create new role',
    textTooltipDuplicate: 'Duplicate',
    textTooltipEdit: 'Edit',
    textTooltipDelete: 'Delete',
    textAllowedCollections1: 'Permissions for ',
    textAllowedCollections2: ' collection',
    textNotCreatedAllowedCollection:
      `Teammates with this role have no access to any of your collections in ${appName}.`,
    textPressPaperclip: 'Press ‘paperclip’ to choose collections & select permissions.',
    textTeamCollections: 'Collection permissions',
    textMediaPermissions: 'Media Management',
    textAssetDetailsPanel: 'Asset details panel',
    textPermissions: 'Team permissions',
    textGlobalPermissions: 'General Permissions',
    textNoteAllowedCollectionsSelect: () => <>To set similar permissions to several collections, hold <em>cmd/ctrl</em> and select several collections, then tick necessary checkboxes. </>,
    learnMoreAboutAllowedCollection: 'Learn more about Collection Permissions',

    textInviteNewTeammate: 'Add new teammates',
    textNewTeammate: 'Add new teammates',
    textInvite: 'Add',
    placeholderEmail: 'email',
    labelSelectRole: 'Select role',
    labelSelectEmail: 'Email',
    errorPasswordMin: 'Password needs to be at least 8 characters long',
    errorPasswordMax: 'Password can contain up to 128 characters',

    titleInviteFromEmail: 'Invite from email',
    titleUsersFromGSuite: 'Import user list from Google Workspace',
    textSelectGSuiteUsers: 'Select Google Workspace users to invite',
    addUsersFromGSuite: 'Add users from Google Workspace',
    inviteInfoBlock: () => (
      <p>
        Add teammates instantly by entering your corporate domain. Go to{' '}
        <a
          href="/teammates?tab=settings"
          target="_blank"
          className="picsioLink"
        >
          Settings
        </a>
        {' '}to set it up now.
      </p>
    ),
    labelAddNewTeammatesTooltip: () => (
      <>
        <p>
          Invited teammates will receive direct {appName} login link via email.
        </p>
        <p>
          Users without verified domains will be shown on the <b>Invited</b> list before they click the link.
        </p>
        <p>
          Users with verified domains are instantly shown on the <b>Teammates</b> list right after adding them.
        </p>
      </>
    ),
    gSuiteNotAllowed:
      'You are not allowed to invite Google Workspace users to your team. Please activate your Google Workspace account to perform this action.',
    notAuthorizedToGetResourceFromGSuite:
      'Your account does not have sufficient permissions to access Google Workspace users list. Please contact your Google Workspace administrator for authorization to access.',
    reset: 'reset',
    succesfulyInvited: 'Successfully invited:',
    notInvited: 'Not invited:',
    availableUsersExceeded: 'You have exceeded the available number of users.',
    updatePlan: 'Update plan',

    textTeamOwner: 'Team owner',
    textLastVisit: 'Last visit:',
    textNoAnswer: 'n/a',
    labelPassword: 'Password',
    statusInvited: 'Invited',
    statusRequested: 'Requested',
    statusRejected: 'Rejected',
    statusAccepted: 'Accepted',
    statusReinvited: 'Reinvited',
    textStatus: 'status:',
    textClickToReinvite: 'Click to reinvite',

    webhooks: 'Webhooks',
    introWebhooksText:
      `Use webhooks to be notified about events that happen in your ${appName} account.`,
    enterUrl: 'Enter url',
    addWebhook: 'Add webhook',
    editWebhook: 'Edit webhook',
    btnAdd: 'Add',
    btnSave: 'Save',
    btnSend: 'Send',
    sendAllTypes: 'Send all event types',
    sendSelectedTypes: 'Select types to send',
    errorUrlNotValid: 'Please enter a valid URL.',
    errorUrlExists: 'This URL is already in use. Please choose another one.',
    testWebhook: 'Test webhook',
    eventType: 'event type',
    testRequestSent: 'Test request sent',
    testRequestNotSent: "Can't send test request. Please try again.",
    tooltipSendTest: 'Send test',
    tooltipEdit: 'Edit',
    tooltipRemove: 'Remove',

    textTitleRemoveWebhook: 'Remove webhook',
    textWebhookWillRemoved: 'Webhook will be removed. Are you sure?',
    textCantCreateWebhook:
      `Can\'t create webhook. Please try again.<br> Contact <a class="picsioLink" href="mailto:${supportEmail}">${appName}</a> support if the error repeats.`,
    textCantRemoveWebhook:
      `Can\'t remove webhook. Please try again.<br> Contact <a class="picsioLink" href="mailto:${supportEmail}">${appName}</a> support if the error repeats.`,
    textCantUpdateWebhook:
      `Can\'t update webhook. Please try again.<br> Contact <a class="picsioLink" href="mailto:${supportEmail}">${appName}</a> support if the error repeats.`,
    learnMoreAboutWebhooks: 'Learn more about webhooks',
    unableToLoadTypes: 'Unable to load event type list',
    descrManageWebhooks: "You don't have permission to create or edit webhooks",
    descrManageApiKey: 'Ask your admin for the permission to view or copy API Key',
    apiKey: `Your individual API key for integrating ${appName} with Zapier and other services`,
    learnMoreAboutZapier: `How to integrate ${appName} and Zapier`,
    copy: 'Copy',

    titleSettingsName: 'Name',
    labelSettingsYourTeamName: 'Your team name',

    labelProfileYourTeamName: 'Your team name',
    labelProfileYourWebsite: 'Website',
    labelProfileYourDomains: 'Company domains',

    titleSettingsDomain: 'Domain',
    placeholderEnterDomain: 'Enter new domain',
    inviteSettingsTitle: 'Users on team domains',
    inviteSettings: {
      none: 'Can join my team only by invite',
      optional: 'Are offered to join my team after signup',
      auto: 'Automatically join my team after signup',
    },
    labelSettingsYourDomains: 'Your domains',
    labelSetDefaultRole: 'Set default role for new users',

    titleSecurity: 'Two-factor authentication (2FA)',
    titleSecurityOktaSSO: 'Okta SSO',
    labelOktaDomain: 'Okta domain',
    labelOktaClientID: 'Client ID',
    labelOktaClientSecret: 'Client Secret',
    labelOktaClientBaseUrl: 'Custom Auth Server URL',
    oktaSSOLink: 'Okta sign-in link',
    oktaSSOLinkPlaceholder: 'Type your okta domain to generate sign-in link',
    oktaSSOLinkCopiedToast: 'Okta sign-in link copied',
    oktaPlaceholders: {
      oktaDomain: 'Type okta domain',
      clientID: 'Type client ID',
      clientSecret: 'Type client secret',
      oktaAuthServerBaseUrl: 'Type Custom Auth Server URL',
    },
    labelOktaForMobileApp: 'Is mobile app',
    labelAuthentication: 'Activate Two-factor authentication (2FA) for all teammates',
    labelAuthenticationTooltip:
      'Switch on the Two-factor authentication (2FA) for the entire team. Teammates will get an email with instructions.',
    setup2FANow: 'Set up Two-factor authentication (2FA) for my account',
    reset2FASuccess: 'Two-factor authentication (2FA) for a teammate reset',
    status2FASuccess: 'Teammate set up Two-factor authentication (2FA)',
    status2FAInProgress: 'Teammate is yet to set up Two-factor authentication (2FA)',
    status2FAReset: 'Reset',
    titleSecurityAuthMethods: 'Authentication methods',
    btnSecurityCancelAllSessions: 'End all sessions',
    btnCancelUserSession: 'End User Session',
    labelAllowedAuthMethodsTooltip: 'Select authentication methods for your teammates Sign In',
    labels2FA: {
      disabled: 'Allow teammates to login without 2FA',
      optional: 'Optional 2FA setup for team members',
      force: 'Forced 2FA setup for team members',
    },
    statuses2FA: {
      notActive: 'Inactive',
      active: 'Active',
      pending: 'Pending',
    },
    security2FATeammatesList: {
      teammates: 'Teammates',
      status: '2FA Status',
    },
    security2FAResetDialog: {
      title: 'Reset two-factor teammate authentication',
      text: (name) => `<span>You are about to reset two-factor authentication for teammate <b>${name}</b></span>`,
      btnReset: 'Reset',
    },
    security2FAToast: {
      disabled: 'You selected the option to allow teammates to log in without 2FA. This method provides basic login security. Consider other options for better protection.',
      optional: 'You activated optional 2FA setup for all teammates. Each teammate will shortly receive further instructions on how to enable 2FA on their accounts.',
      force: 'You activated a mandatory 2FA setup for all teammates. Each teammate will shortly receive further instructions on how to enable 2FA on their accounts.',
    },
    textAlwaysVisibleFieldTooltip: 'This metadata field is always visible',
    textRequiredNotHiddenTooltip: 'Metadata fields marked as required for upload won\'t be hidden',
    textRequiredForUploadTooltip: 'This field is marked required for upload and cannot be hidden',

    BRANDING: {
      logoUploader:
        "It's better to use small square images 200-400 pixels on a transparent background: PNG or SVG. Or small progressive JPG.",
      backgroundUploader: 'The image will be used for login and signup pages.',
      faviconUploader: 'You can use the image 512x512px, best to use a PNG with transparent background',
      titleAccentColor: 'Accent color',
      descriptionAccentColor: `Use your accent color in the ${appName} UI.`,
      titleBackgroundColor: 'Background color',
      descriptionBackgroundColor: `Use your pages background color in the ${appName} UI.`,
      titleAppLogo: 'App logo',
      labelBrandedLogo: 'Enable branded app logo',
      titleCopyright: 'Copyright',
      descriptionCopyright: 'Content will be visible at websites footer',
      titlePages: 'Pages',
      errorSavingSettings:
        `Can't save settings. Please try again.<br> Contact <a class="picsioLink" href="mailto:${supportEmail}">${appName}</a> support if the error repeats.`,
      labelBrandedDownloadPage: 'Enable branded download page',
      dialogWarningTitle: 'Warning',
      dialogWrongLoginPath:
        'Do not use the word "login" as your customized login. Input the other word in this field.',
      errorDomainMessage: (value) => `Domain name ${value} is already used in ${appName} in another account. Please pick a different domain name.`,
    },
    AUTH_METHODS: {
      plain: 'Plain password',
      google: 'Google SSO',
      microsoft: 'Microsoft SSO',
      okta: 'Okta SSO',
    },
    CANCEL_ALL_SESSIONS_DIALOG: {
      title: 'End the session for all teammates?',
      text: (count) => `You are about to end active sessions for all ${count ? `(${count})` : ''} teammates except yourself`,
      errorText: 'Failed to end all sessions. Please try again.',
      toastText: (usersCount) => `Session for ${usersCount} user${(usersCount > 1 || !usersCount) ? 's' : ''} was ended successfully`,
    },
    CANCEL_USER_SESSION_DIALOG: {
      title: 'End user session',
      text: (userName) => `Are you sure you want to end ${userName} session?`,
      errorText: (userName) => `Failed to end ${userName} session. Please try again.`,
      toastText: (userName) => `Session for ${userName} was ended successfully`,
    },
    helpText: {
      teammates: 'Learn how to invite new teammates',
      roles: 'Learn how to assign roles to teammates',
      integrations: 'integrations',
      settings: 'Learn how to set up team policies',
      analytics: `Explore how to work with ${appName} analytics&reporting`,
      branding: 'Learn how to set up team branding',
      profile: 'Learn how to set up team profile',
      security: 'Learn how to set up 2FA',
      watermarking: 'Learn more about watermarks',
      ai: `Learn more about AI capabilities in ${appName}`,
    },
    assetDetailsPanelInfoBlockText: 'These metadata fields will be only visible on asset details panel and during uploading assets. Visibility of these metadata fields won\'t be affected in other parts of the system.',
  },

  TRANSCRIPT: {
    placeholderTitle: 'Transcript',
    placeholderDecsription: 'Here you will see a speech-to-text transcription of an audio track of current asset',
    placeholderButton: 'Transcribe',
    dropdownOptionText: 'Transcribe in',
    DIALOGS: {
      titleUpgradePlan: 'Upgrade plan',
      textUpgradePlan: 'Transcribing speech to text is not available for the current plan',
      btnUpgradePlan: 'See all plans',
      titleNotEnoughAICredits: 'Insufficient Transcription Minutes',
      textNotEnoughAICredits: (canNotBuyApiCalls, usersToWrite) => (
        <>
          You have run out of available transcription minutes or the length of the selected asset exceeds your remaining transcription minutes. To continue using AI-powered features like Face Recognition, Transcription, AI Keywording, and Image Captioning, {' '}
          <Choose>
            <When condition={canNotBuyApiCalls}>
              <>please ask your team admins {usersToWrite.map((user) => (
                <Tag
                  type="user"
                  avatar={user.avatar}
                  key={user._id}
                  text={user.email}
                />
              ))} to subscribe to the <b>AI Kit add-on</b> to continue using AI-powered services.
              </>
            </When>
            <Otherwise>
              <>
                please subscribe to the <b>AI Kit add-on</b>.
              </>
            </Otherwise>
          </Choose>
        </>
      ),
      btnNotEnoughAICredits: 'Subscribe to AI Kit',
      titleBulkTranscribing: 'Transcribe assets',
      textBulkTranscribing: (counfOfAssets) => `You're about to send ${counfOfAssets} assets for transcription, which will use up your transcription minutes balance. Please confirm this action.`,
      btnCongirmBulkTranscribing: 'Transcribe',
      btnCancelBulkTranscribing: 'Cancel',
    },
    TOASTS: {
      textTranscribeTPanelInProgress: `Transcription is in progress. It's a background operation, you can continue using ${appName}.`,
      textTranscribeIPanelInProgress: (counfOfAssets) => `Transcription is in progress for ${counfOfAssets} assets`,
      textTranscribingHonSupported: 'Transcribing is not supported for this file format',
      transcribingComplete: (assetName, onClick) => (
        <>Transcribing for <span aria-label="link to asset" className="picsioLink" onClick={onClick} role="presentation">{assetName}</span> complete</>
      ),
    },
  },

  IMPORT: {
    textTotalFilesCount: (filesCount) => `${pluralize('asset', filesCount, true)} to upload`,
    textUploadErrorsCount: (errorsCount) => ` / ${pluralize('asset', errorsCount, true)} with errors`,
    textImagesWasntUploadToGD: "Assets weren't upload to your cloud storage",
    titleFileAlreadyExists: 'Asset already exists',
    textFileAlreadyExists: `You’re about to upload the asset that already exists in ${appName}`,
    textRemoveSelected: (count) => `Remove ${count} selected ${pluralize('file', count)}`,
    textKeepBoth: 'Keep both',
    textAddAsNewRevision: 'Add as new revision',
    textReplace: 'Replace',
    textRetry: 'Retry',
    textRetryAll: 'Retry all',
    textSkip: 'Skip',
    textApplyToAll: 'Apply to all',
    // textUploadedFiles: 'Uploaded {uploadedValue} new assets',

    // textFormatIsntSupported: 'Format ."{fileExtensionValue}" is not supported yet!',
    textFileRevisionUpdated: 'Your asset revision was updated',
    textCantSaveImageToDB: "Can't save uploaded asset into database",
    textCantCheckDuplicates: "Can't check duplicates",
    textCantSaveImageToGD: "Can't save uploaded asset into your cloud storage",
    textCantSaveImageToStorage: "Can't save uploaded asset into storage",
    textDontHavePermissionToUpload: "You don't have permission to upload to this folder",

    corruptedFiles: {
      title: (num) => `${num > 1 ? 'These' : 'This'} file${num > 1 ? 's' : ''} ha${num > 1 ? 've' : 's'} 0 bytes`,
      text: (names) => `File${names.length > 1 ? 's' : ''} ${names.length > 1 ? 'are' : 'is'} corrupted: ${names.join(', ')}
 <br />
 <br />
There is no content to preview in it. Check this file in a software it was generated by`,
    },
    blackListedFiles: {
      title: (num) => `${num > 1 ? 'These' : 'This'} file${num > 1 ? 's' : ''} ha${num > 1 ? 've' : 's'} not allowed extension`,
      text: (names) => `File${names.length > 1 ? 's' : ''} ${names.length > 1 ? 'are' : 'is'} not allowed: ${names.join(', ')}`,
    },
    hiddenFiles: {
      title: (num) => `${num > 1 ? 'These' : 'This'} file${num > 1 ? 's' : ''} ${num > 1 ? 'are' : 'is'} hidden`,
      text: (names) => `File${names.length > 1 ? 's' : ''} ${names.length > 1 ? 'are' : 'is'} hidden: ${names.join(', ')}`,
    },
    duplicateCollections: {
      title: (num) => `Folder ${pluralize('name', num)} already exist${num > 1 ? '' : 's'}`,
      text: (names) => `${pluralize('Folder', names.length)} <b>[${names.join(', ')}]</b> already exist${names.length > 1 ? '' : 's'}. You can rename it or select a different export location`,
    },

    toManyAssetsForAnalysis: {
      title: 'Duplicates check limit is 10,000',
      text:
        `<p>Every time you make a new upload to ${appName}, we check your files for duplicates. We cannot check more than 10 000 files in one go.</p><p>But you can still proceed with uploading. If there are duplicates found, we’ll upload them as new assets.</p>`,
      textS3:
        `<p>Please note that ${appName} cannot process more than 10 000 assets at a time due to the duplicates check.</p><p>Please select less than 10 000 files at once and perform the action again.</p>`,
      btnCancel: 'Cancel',
      btnCancelS3: 'Ok',
      btnOk: 'Continue uploading',
    },

    toManyAssetsForUpload: {
      title: 'Sorry, too many assets processed for upload',
      text:
        `<p>${appName} is powerful, but apparently, not powerful enough to process so many files at a time.</p><p>Please make a smaller selection for upload. Thanks.</p>`,
      btnOk: 'Ok',
    },

    restoreUpload: {
      title: 'Some assets were not uploaded',
      /** @param {string[]} filenames */
      list: (filenames) => {
        const showMore = filenames.length > 30;
        const namesToShow = showMore ? filenames : filenames.splice(0, 30);
        return (
          <>
            <p>For some reason, your latest upload ran into a problem and some assets were not uploaded to {appName}.</p>
            <ul>
              {namesToShow.map((name) => <li key={name}>{name}</li>)}
            </ul>
            <If condition={showMore}>
              <div>...and {filenames.length - 30} more</div>
            </If>
          </>
        );
      },
      btnClose: 'Close',
      text:
        `<p>For some reason, your latest upload ran into a problem and some assets were not uploaded to ${appName}.</p><p>Do you want to resume uploading?</p>`,
      btnCancel: 'Discard',
      btnOk: 'Resume',
    },

    wrongFileChoosen: {
      title: 'Error',
      text: 'You might have selected a wrong file - filenames do not match.',
      btnCancel: 'Cancel',
      btnOk: 'Replace file',
    },

    cantCreateCollections: {
      title: 'Sorry',
      text:
        `<p>For some reason, ${appName} failed to create the following collection(s):</p><ul>{$list}</ul>`,
      btnCancel: 'Skip',
      btnOk: 'Retry',
    },

    unsafeCharactersError: (collectionName) => `Cannot create a collection "${collectionName}" with invalid characters. Allowed: numbers (0-9), letters (a-z, A-Z), slash (/), exclamation mark (!), dashes (-, –, —), underscore (_), dot (.), asterisk (*), single quote (’), and parentheses ()`,

    collectionAlreadyExistsInArchiveApiError: (collectionName) => `Collection with the name "${collectionName}" already exists. Change the name of the folder on your computer or the name of target collection.`,

    cantHandleDroppedFiles: {
      title: 'Error',
      text:
        `<p>For some reason, ${appName} failed to process your files.</p><p>Try uploading them again. If the error persists, contact us at <a class="picsioLink" href="mailto:${supportEmail}">${supportEmail}<a>.</p>`,
      btnCancel: 'Ok',
    },

    MENU: {
      mobileDialogTitle: 'Add assets',
      upload: 'Add files from device',
      addExternal: 'Add external links',
      importDropbox: 'Import from Dropbox',
      importGD: 'Import from Google Drive',
      importOneDrive: 'Import from OneDrive',
      importBox: 'Import from Box',
      importSharePoint: 'Import from SharePoint',

    },

    ITEM: {
      removeAll: 'Remove all',
      selectFile: 'select file',
      /** @type {string[]} fields */
      validationFieldsError: (fields) => `Fill out these fields: ${fields.join(', ')}`,
    },

    // textUploadFilesTo: 'Upload {countFiles} assets to',
    additionalFieldsHeader: (count) => `Add metadata for ${pluralize('asset', count, true)}`,
    textKeywords: 'Keywords',
    textUpload: 'Import',
    textNeedRequiredFields: 'Fill in the fields highlighted with red to upload',
    textBrowse: 'Browse files to add',
    textAdd: 'Add more',
    textClear: 'Clear all',
    textSelectAll: 'Select all',
    textDeselectAll: 'Deselect all',
    textCancel: 'Cancel',
    textSelectMedia: 'Add files from device or drag&drop here',
    textAddExternal: 'Add external links',
    textAddFromCloudStorages: 'Import from cloud storages',

    textFilesUploaded: ({
      filesCount, foldersCount, totalSize, collections,
    }) => {
      if ((filesCount && foldersCount) || (foldersCount && !filesCount)) {
        return (
          <div>
            {pluralize('item', filesCount + foldersCount, true)} {(filesCount + foldersCount) > 1 ? 'were' : 'was'} added to{' '}
            <span className="highlight">{collections}</span>
          </div>
        );
      }
      return (
        <div>
          {pluralize('item', filesCount, true)}, {totalSize} {filesCount > 1 ? 'were' : 'was'} added to{' '}
          <span className="highlight">{collections}</span>
        </div>
      );
    },
    textCantUploadFiles: `Can't upload assets. Contact ${supportEmail}.`,
    textYouCannotUpload: (collectionName) => `You can't upload assets into ${collectionName}`,
    textFieldIsRequired: "This field was set as required by your team's admin, please fill it",
    textRequiredFieldError: 'required field error',
    textRequiredFieldWarning: 'required field warning',
    textImportingRequiredFieldFileWarning: 'Team policy requires filling in metadata for this file after import',
    textImportingRequiredFieldFolderWarning: 'Team policy requires filling in metadata for this folder’s contents after import',
    flagAndRating: 'Flag and rating',
    flagAndColor: 'Flag and color',
    colorAndRating: 'Color and rating',
    flagOnly: 'Flag',
    colorOnly: 'Color',
    ratingOnly: 'Rating',
    title: 'Title',
    description: 'Description',

    titleNotification: 'Notification',
    textPreviewMode: 'Preview mode works only for less than 50 assets.',

    titleTheUserExceededQuota: 'The user has exceeded their Drive storage quota',
    textNoFreeSpaceGD:
      'It seems that you have no free space in your cloud storage. Consider freeing up some space or <a target = "_blank" href = "https://www.google.com/settings/storage">purchasing</a> additional storage from your Cloud Storage.',
    titleSelectCollection: 'Uploading to the root collection',
    textItBetterToUpload: (rootCollection) => `You’re uploading assets into ${rootCollection} where they may not be seen by your teammates. Upload to the root anyway?`,
    textContinue: 'Continue',
    btnConfirm: 'Confirm',

    textErrorRevisionUploadType: (extension) => `You've tried to upload the revision with a type different from original. The new revision should be a ${extension} type`,
    commentsTitle: (isAllowed) => (isAllowed ? 'Comment revision' : 'Comment (read only)'),
    placeholderInputComment: 'Add a comment',
    helpLink: 'Details on how to upload new files',
    uploadPanelOfferText: 'Click to request import from GDrive, Dropbox, FTP',
    titleSelectStorages: 'Select the cloud storage you want to import files from',
    beta: 'beta',
    assisted: 'ASSISTED',
    labelImportFromDropbox: 'Import from Dropbox',
    labelImportFromGD: 'Import from Google drive',
    labelImportFromOneDrive: 'Import from OneDrive',
    labelImportFromBox: 'Import from Box',
    labelImportFromSharePoint: 'Import from Sharepoint',
  },
  DROPBOX_CHOOSER: {
    dialogTitle: 'Import from Dropbox',
    dialogDescription: `Select files or folders you want to copy from Dropbox to ${appName}`,
    dialogBtnCancel: 'Cancel',
    dialogBtnOk: 'Proceed',
    rootBreadcrumb: 'All files',
    toastFailedGetList: 'Failed to get the list of files and folders from dropbox',
    toastFailedAuthentication: 'Failed authentication to Dropbox. Please try again to connect your Dropbox account.',
    toastCalculatingDifferenceStorageFailed: 'Error calculating the difference between available space on storage and downloaded files from dropbox',
    spinnerTextDisconnect: 'Dropbox disconnecting...',
    toastDisconnectSuccess: 'Dropbox account has disconnected',
    toastDisconnectFailed: "Dropbox account hasn't disconnected",
    tooltipDisconnect: 'Disconnect your account',
    tooltipForDisabledFolder: 'Importing whole folders is available to subscribed users only.<br> Trial users can only import individual files.',
    labelName: 'Name',
    labelSize: 'Size',
    labelLastModified: 'Last Modified',
    textFolderEmptyState: 'Folder is empty',
    textWarningStorageLimit: (onClick, excessStorageSize, storageType) => (
      <span>
        The total size of imported files and folders exceeds the available space in your storage.
        Please, deselect some of them {excessStorageSize} excess to proceed{storageType !== 'picsio' ? ' ' : ''}
        <Choose>
          <When condition={storageType === 'picsio'}>
            or <span className="picsioLink" onClick={onClick} role="presentation">purchase additional space</span> for your storage.
          </When>
          <Otherwise>
            .
          </Otherwise>
        </Choose>
      </span>
    ),

  },
  TOOLBARS: {
    titleClosePreview: 'Close preview [Esc]',
    titleClose: 'Close [Esc]',
    titleDetails: 'Details',
    titleActivity: 'Activity',
    titleLiveSupport: 'Live support',
    titleUpgradePlanForSupport: (planName) => (
      <p>
        Unfortunately, chat support is not included in the {planName} plan. To get access to chat support, please consider upgrading to one of our higher plans. Now, you can contact our support team at <a className="picsioLink" href={`mailto:${supportEmail}`}>{supportEmail}</a>
      </p>
    ),
    titleSettings: 'Settings',
    titleView: 'View mode',
    titleSorting: 'Sort mode',
    titleHelpCenter: 'Help center',
    titleAnalytics: 'Analytics',
    titleTranscriptions: 'Transcript',
    titleUpload: 'Import',
    titleAddAssets: 'Add assets',
    titleAddLightboard: "To add assets to a Lightboard, hover over it in the Lightboards tree and click the 'Add Assets' icon. To add assets to Collections, switch to the Collection tree and select a collection.",
    titleAddInbox: 'Uploading to inboxes is only possible via inbox links. To add assets to Collections, switch to the Collection tree and select a collection.',
    titleCollections: 'Collections',
    titleMedia: 'Media library',
    titleKeywords: 'Keywords',
    titleSearches: 'Searches',
    titleLightboards: 'Lightboards',
    titleInbox: 'Inboxes',
    titleDownloadAssets: 'Download assets',
    titleDownloadSelectedAssets: (count) => `Download selected ${pluralize('asset', count)}`,
    titleDownloadAssetsAsArchive: 'Download all assets as an archive',
    titleRemoveAssets: 'Move to trash',
    titleCompareAssets: 'Compare assets',
    titleDownloadDialog: 'Download list',
    titleArchive: 'Archive',
    titlePersons: 'Persons',
    titleShare: 'Share asset',

    titleAddRevision: 'Add revision',
    titleDownload: 'Download',
    titleRemove: 'Move to trash',
    titleRemoveFromCollections: 'Remove from collection',
    titleRetryImporting: 'Retry Import',
    titleRemoveForever: 'Delete forever',
    titleRotateCW: 'Rotate CW',
    titleRotateCCW: 'Rotate CCW',
    titleHorizontalFlip: 'Horizontal flip',
    titleVerticalFlip: 'Vertical flip',
    titleEdit: 'Edit',
    titleZoom: 'Zoom to 100%',
    titleFit: 'Fit to screen',
    titleHideMultipagePanel: 'Hide multipage panel',
    titleShowMultipagePanel: 'Show multipage panel',

    textMyAccount: 'My account',
    textSync: 'Sync',
    textBilling: 'Billing',
    textGoogleDrive: 'Google Drive',
    textStorage: 'Storage',
    textCustomFields: 'Custom Fields',
    textMyTeam: 'My team',
    textAuditTrail: 'Audit trail',
    textReferralProgram: 'Referral program',
    textTutorials: 'Help center',
    textWhatsNew: "What's new",
    textSwitchAccount: 'Switch account',
    textLogOut: 'Log out',
    textDetails: 'Details',
    textNewFirst: 'New first',
    textOldFirst: 'Old first',
    textAtoZ: 'A to Z',
    textZtoA: 'Z to A',
    textCustom: 'Custom',
    textGrid: 'Grid',
    textList: 'List',
    textListSize: {
      textListx1: 'x1',
      textListx1_5: 'x1.5',
      textListx2: 'x2',
    },
    textMap: 'Map',
    tooltipMapNotAllowed: 'Clipboard does not support map view. Use grid or list view instead.',
    textDownloadAll: 'Download all',
    textIntegrations: `Check all products that integrate with ${appName}`,
    revisionsDropdown: {
      textAddNewRevision: 'Add new revision',
    },
    textLiveSupportSpeech: 'Click here to connect with our live support, when you have questions or issues to report.',
  },

  WEBSITES: {
    textYouCanUseImage300x300:
      'You can use the image 300x300px, best to use a monochrome PNG with a transparent background',
    textUploadLogo: 'Upload logo',
    textBestUseImage500x1000: 'It is best to use 500-1000px image',
    titleUploadThumbnail: 'Thumbnail',
    textBestUseImage500x500: 'It is best to use 500x500px image',
    textUploadYourPhoto: 'Upload your photo',
    textImageWillBeUsed: 'The image will be used for login page',
    textUploadBackground: 'Upload background',
    textUploadThumbnail: 'Upload thumbnail',
    textUploadFavicon: 'Upload favicon',
    titleContacts: 'Contacts',
    labelShowFacebook: 'Facebook',
    labelShowTwitter: 'Twitter',
    labelShowInstagram: 'Instagram',
    labelShowPhone: 'Phone',
    labelShowEmail: 'Email',
    labelShowBlog: 'Blog',
    titleFileSettings: 'File settings',
    labelShowFlaggedImages: 'Only flagged assets on home page',
    labelShowFilename: 'Filename',
    labelShowDescription: 'Title & Description',
    titleWebsitePages: 'Website pages',
    labelEnableAboutPage: 'About page',
    labelEnableContactsPage: 'Contacts page',
    titleSearch: 'Search',
    labelAllowSearchAssets: 'Search bar',
    labelAllowSearchTags: 'Collections tree',
    labelUnfoldAllCollections: 'Unfold all collections',
    unfoldAllCollectionsTooltipText: 'Enabling the ‘Unfold all collections’ setting may slow down performance due to the number of requests needed to load and display all content. Consider enabling this option for websites with no more than 20 collection levels to maintain optimal speed.',
    titleDownloads: 'Downloads',
    labelAllowDownloadArchive: 'Download archive with all assets',
    labelAllowDownloadFile: 'Download one asset',
    labelAllowDownloadFiles: 'Download assets',
    titleHistory: 'Collaboration & History',
    labelRevisions: 'Revisions',
    labelRevisionsDisabled: 'Revisions are disabled',
    labelComments: 'Comments',
    tooltipComments: <>Check to allow external users to add comments.<br />Use the eye icon to control comment visibility.</>,
    labelCommentsDisabled: 'Comments are disabled',
    labelCommentsHideInternal: 'Restrict access to internal comments',
    tooltipCommentsHideInternal: <>When enabled, internal comments added by your Pics.io teammates<br />will be hidden from external viewers</>,
    titleInfopanel: 'Infopanel options',
    labelTitle: 'Title',
    labelDescription: 'Description',
    labelKeywords: 'Keywords',
    labelCustomFields: 'Custom fields',
    labelCustomFieldsDisabled: 'Custom fields are disabled',
    titleThumbnail: 'Thumbnail options',
    labelFilename: 'Filename',
    labelFlag: 'Flag',
    labelRating: 'Rating',
    labelColor: 'Color',
    titleCollections: 'Collections',
    titleCustomization: 'Customization',
    titleHomeScreen: 'Home screen layout',
    titleExpires: 'Expires at',
    titleSort: 'Assets sort order',
    titleCollectionSort: 'Collections sort',
    homeScreenCollection: 'Select collection for the home screen layout',
    titleRestrictedSettings: 'Restricted assets',
    labelRestrictedWarning: 'Additional restricted warning on assets preview',

    titleSocialButtons: 'Social buttons',
    textDontShow: "Don't show",
    textLeft: 'Left',
    textRight: 'Right',

    textChange: 'Change',
    textAllow: 'Allow',
    textAdd: 'Add',
    textShow: 'Show',

    textHttpsNotValid: 'https is not valid, please use http',
    textUrlNotValid: 'URL is not valid',
    textWordsCantBeUsed: (words) => `Words "${words}" can\'t be used at the url begging`,
    textTopLevelFolders: 'URL should contain only top-level folders',
    textUrlInUse: 'URL is already in use',
    textGoogleAnalIsntValid: 'This Google analytics identifier is not valid',
    textMain: 'Main',
    textSecurity: 'Security',
    textPassword: 'Password',
    textNotifications: 'Notifications',
    textCustomization: 'Customization',
    textSEO: 'SEO',
    textAnalytics: 'Analytics',

    textCreateWebsite: 'Website settings',
    textUpdateWebsite: 'Website settings',
    textCreate: 'Publish',
    textDelete: 'Unpublish',
    textLoading: 'Loading',

    titleError: 'Error',
    textWebsiteWasntFound: "Website wasn't found",

    titleErrors: 'Errors',

    titleAttention: 'Attention!',

    textNorecord:
      "The domain name you specified couldn't be resolved. That might be because of a typo. Please, check the domain name carefully. If you just bought the domain, probably, DNS changes still haven't been propagated, it takes up to 48 hours. Press OK to proceed anyway.",
    textAnotherip:
      "Domain name you specified points to the different ip address. Should be: 54.243.40.217. That might be because of a typo. Please, check the domain name carefully. If the name is correct and you just changed DNS records, probably, DNS changes still haven't been propagated, it takes up to 48 hours. Press OK to proceed anyway.",

    textDomainCantVerified: 'Failed to verify this domain',

    titleWarning: 'Warning',
    textSavingAsseets: 'Saving assets...',
    textSavingWebsite: 'Preparing website...',
    textCreatingWebsite: 'Creating website...',

    alertWebsiteCreated:
      'Website <span class="highlight">published</span>. We\'re preparing thumbnails now. You will receive an email when the website is ready',
    textVisitWebsite: 'Visit website',
    textWebsiteCreationUnsuccessful: 'Website publishing was unsuccessful. Please, try again.',
    textWebsiteUpdatingUnsuccessful: 'Website publishing was unsuccessful. Please, try again.',
    textWebsiteSomeImagesUnsuccessful:
      'Some images for website settings not uploaded. Please, try again.',
    textWebsiteDeleteImagesUnsuccessful:
      'Some images for website settings not deleted. Please, try again.',
    alertErrorHappened:
      'Unpublishing/deleting website failed. Refresh and try again ',

    titleWarningChangePlan: 'Websites functionality is restricted',
    descriptionWarningChangePlan: (onClickHandler) => (
      <div>
        Websites functionality is not available for your current plan. Please upgrade your{' '}
        <span className="picsioLink" onClick={onClickHandler} role="presentation">
          subscription
        </span>{' '}
        to use this feature.
      </div>
    ),

    textContact: 'Contact',
    textSiteWillBeDeleted: 'Website will be unpublished and ALL analytics data will be lost! Are you sure?',
    textPasswordConfirm: 'Password and confirm password are not equal',
    titleMainOptions: 'Main options',
    labelYourSiteLink: 'Your new website link',
    labelSelectTemplate: 'Select your template',
    labelTypePassword: 'Type password',
    labelConfirmPassword: 'Confirm password',
    titleSeo: 'SEO',
    labelGoogleAnalIdentifier: 'Google Analytics identifier',
    labelCustomGalleryTitle: 'Custom gallery title',
    placeholderTitle: 'Title',
    titleRobots: 'Search robots indexing',
    labelNoIndex: 'No index',
    labelNoFollow: 'No follow',

    titleNotificationsEmail: 'Email for notifications',
    placeholderEnterEmail: 'Enter email',
    labelSelectEmail: 'Email',
    shareAssetsDialog: {
      title: 'Name shared collection (website)',
      text: 'You are about to create a new website with selected files. These files will stay in their original collections and will also be attached to the new one.',
      textBtnOk: 'Continue',
      label: 'Collection name',
      placeholder: 'Type a name of a shared collection',
    },
    errorHaveReachedLimit: 'You have reached a limit of websites on this plan',
    titlePresetsPanel: 'Presets',
    textPresetsDefault: 'Сurrent website settings',
    textPresetsSave: 'Save as a new preset',
    textPresetsDelete: 'Delete',
    textPresetsRename: 'Rename',
    helpText: 'How to share collections (websites)',
    embeddedHelpText: `How to embed ${appName} content into third-party pages?`,
  },

  COLLECTIONS: {
    titleAddKeyword: 'Add keyword',
    textApply: 'Apply',
    textPlaceholderName: 'Name',
    textCantRemoveKeyword:
      `Can't remove keyword. Please try again. Contact <a class="picsioLink" href="mailto:${supportEmail}">${appName}</a> support if the error repeats.`,
    textCantUpdateKeyword: () => <>Cannot update keywords. Refresh the browser tab and try again. If the error persists, contact us at <a className="picsioLink" href={`mailto:${supportEmail}`}>{supportEmail}</a></>,
    titleRenameKeyword: 'Rename keyword',
    textYouAreGoingToRenameKeyword: 'You are going to rename keyword. Continue?',
    textPlaceholderNewName: 'New name',
    textAddedTo: 'added to',
    textRemovedFrom: 'removed from',
    // textHasBeenFavorites: 'has been {actionValue} Favorites',
    titleFavorites: 'Favorites',
    titleKeywords: 'Keywords',
    textCantGetKeywords:
      `Can't get keywords. Please try again. Contact <a class="picsioLink" href="mailto:${supportEmail}">${appName}</a> support if the error repeats.`,

    errorTeammatesWasntAdded: 'Teammates were not added. Looks like they are already on your team.',
    errorTeammateWasntAdded:
      `Teammate wasn't added because he is already on other team account in ${appName} Using the same email for being on more than one team in ${appName} is not technically possible.`,
    errorUnableToAddTeammates: 'Unable to add teammates. Please try again.',
    textSearching: 'Searching...',
    textProcessingFiles: 'Processing assets...',
    titleAssetInCollectionAllreadyExists: 'File already exists',
    textAssetInCollectionAllreadyExists: 'This file already exists in this collection, it is not possible to add an identical duplicate to the same collection. Change a name of a new file and try again.',

    textFilesAddedToCollection: (count, collectionName) => `${pluralize('asset', count, true)} ${count > 1 ? 'were' : 'was'} added to collection "${collectionName}"`,
    textFilesNotAddedToCollection: (count, collectionName) => `${count} ${pluralize(
      'asset',
      count,
    )} haven't been added to collection "${collectionName}". Please contact ${supportEmail}`,
    textFilesAddedToLightboard: (lightboardName) => `Assets added to lightboard "${lightboardName}"`,
    textFilesNotAddedToLightboard: (lightboardName) => `Assets haven't been added to lightboard "${lightboardName}". Please contact ${supportEmail}`,
    textLightboardFolderNotFound:
      `Your lightboards folder is not found in your cloud storage. Please contact <a href="mailto:${supportEmail}?subject=Lightboards folder is not found in Google Drive">${supportEmail}</a>`,
  },

  LIGHTBOARDS: {
    title: 'Lightboards',
    buttonAddLightboard: 'Create lightboard',
    titleAddLightboard: 'Add new lightboard',
    textDeleteLightboard: 'Delete lightboard',
    textDeleteName: (name) => `You are about to delete a lightboard <span class="highlight">${name}</span>. Continue?`,
    // textRenameName: name => `Rename "${name}"?`,
    textEnterNewName: 'Enter new name',
  },

  CATALOG_VIEW: {
    textError: 'Error',
    cantLoadData:
      `Can't load data. Please try again. Contact <a class='picsioLink' href='mailto:${supportEmail}'>${appName}</a> support if the error repeats`,
    collectionNotFound: 'Collection not found',
    errorCheckSearchQueryTitle: 'Incorrect search query',
    errorCheckSearchQuery:
      "It seems there’s an error in your search query.<br>Please review your syntax for mistakes or typos.<br>If the issue persists, visit our <a href='https://help.pics.io/search-engine' target='_blank'>Help Center</a> or contact support for assistance.",
    notifications: {
      collectionUpdated: 'This collection has been updated. Refresh to see changes.',
      inboxUpdated: 'This inbox has been updated. Refresh to see changes.',
      searchByLocationEnabled:
        'Only assets with geolocation are displayed. To see all assets please press the refresh button.',
    },
  },

  PREVIEW_VIEW: {
    generateSubtitles: 'Generate subtitles',
    errorLoadFile: "Can't load asset",
    noPreview: 'no preview available',
    textDropFile: 'Drop file to add a new revision',
    movedToTrash: 'moved to trash',
    restore: 'restore',
    errorFilesCount: 'To add a new revision drop one file',
    editingNotSupportedInSafariTitle: 'Editor not working in Safari',
    editingNotSupportedInSafari:
      `${appName} editor is temporarily unavailable in Safari. Please use another browser (e.g. Google Chrome or Firefox) to edit assets.`,
    titleEditingNotSupported: 'Unsupported file format',
    editingNotSupported: `This file format is not supported in ${appName} editor yet`,
    fileNotFound: 'File not found',
    externalNoPreviewText: () => (
      <>
        <p>This URL does not allow to be viewed within other web-resources.</p>
        <p>You can view it by opening in a new browser tab.</p>
      </>
    ),
    externalCrawlingDataText: () => (
      <>
        <p>Reading content from the external link</p>
        <p>You can view it by opening in a new browser tab.</p>
      </>
    ),
    externalNoPreviewBtnText: 'Open in new tab',
  },

  INPUT_DATE_RANGE: {
    any: 'Any time',
    today: 'Today',
    yesterday: 'Yesterday',
    lastweek: 'Last 7 days',
    lastmonth: 'Last 30 days',
    last90days: 'Last 90 days',
    custom: 'Customized...',
  },

  ASSING_USER: {
    title: 'Assign users',
    placeholder: 'You have not invited',
    filterText: 'Assign users',
  },

  DROPDOWN: {
    create: 'Create',
    createKeyword: 'Create keyword',
    createFace: 'Create person',
    chooseKeywords: 'Add or paste keywords',
    chooseKeywordsCV: 'Add keywords from CV',
    chooseFaces: 'Select person',
    chooseCustomFields: 'Choose custom fields',
    placeholderKeywords: 'You have no keywords yet',
    placeholderFaces: 'You have no persons yet',
    placeholderKeywordsCreate: 'Start typing to create a keyword',
    noResults: 'No results found for',
    tooltipItemsCopied: 'Items copied to clipboard',
  },

  AUTH_ERROR: {
    title: 'Error',
    text: `Email address or password is incorrect. Please, double-check your details and try again. <br><br> If this issue persists, contact us at <a href="mailto:${supportEmail}" target="_blank">${supportEmail}</a>.`,

  },

  SERVER_ERROR: {
    title: 'Something went wrong.',
    text: `Refresh the browser tab. If the error persists, contact us at <a href="mailto:${supportEmail}" target="_blank">${supportEmail}</a>.`,
  },

  INPUT_FILE: {
    placeholder: 'No file is chosen',
    btnText: 'Choose',
  },

  SORT: {
    alphabetical: 'File name',
    uploadTime: 'Upload time',
    updateTime: 'Update time',
    createTime: 'Create time',
    rating: 'Rating',
    color: 'Color',
    fileSize: 'File size',
    pageNumber: 'Page number',
    fileType: 'File type',
    creator: 'Creator',
    copyright: 'Copyright',
    custom: 'Custom',
    imageResolution: 'Image resolution',
    assetNumber: 'Assets number',
    score: 'Best match',
    maxCustomFieldsCountReached: 'You can add only three custom fields here',
  },

  SCREEN_ASSET_SHARING: {
    title: 'Asset sharing settings',
    textMain: 'Main',
    textSecurity: 'Security',

    textChange: 'Change',
    textShow: 'Show',
    textAllow: 'Allow',
    textAdd: 'Add',
    share: 'share',
    unshare: 'stop sharing',

    titleLink: 'Link',
    titlePassword: 'Password',
    placeholderEnterPassword: 'Enter Password',
    placeholderEnterPasswordRetype: 'Retype Password',

    titleDownloads: 'Downloads',
    labelAllowDownloadFile: 'Download asset',
    titleHistory: 'Collaboration & History',
    labelRevisions: 'Revisions',
    labelRevisionsDisabled: 'Revisions are disabled',
    labelComments: 'Comments',
    tooltipComments: <>Check to allow external users to add comments.<br />Use the eye icon to control comment visibility.</>,
    labelCommentsDisabled: 'Comments are disabled',
    labelCommentsHideInternal: 'Restrict access to internal comments',
    tooltipCommentsHideInternal: <>When enabled, internal comments added by your Pics.io teammates<br />will be hidden from external viewers</>,
    titleInfopanel: 'Infopanel options',
    labelTitle: 'Title',
    labelDescription: 'Description',
    labelKeywords: 'Keywords',
    labelCustomFields: 'Custom fields',
    titleMarks: 'Asset marks',
    labelFlag: 'Flag',
    labelRating: 'Rating',
    labelColor: 'Color',
    helpText: 'How to share a single asset',
    titleRestrictedSettings: 'Restricted assets',
    labelRestrictedWarning: 'Additional restricted warning on assets preview',
  },

  RECURSIVE_SEARCH: {
    labelOnTreeDontShow: "Don't show assets in nested collections",
    labelOnCatalogPanelDontShow:
      'Assets from nested collections are NOT included in the search results. Switch the toggle to include them into the search.',
    labelOnCatalogPanelShow:
      'Assets from nested collections are included in the the search results. Switch the toggle to exclude them from the search.',
  },

  BEST_MATCH_SEARCH: {
    infoText: 'Use Best Match sorting for more relevant search results',
    buttonText: 'Apply Best match sorting',
  },

  SHORTCUTS: {
    title: 'Shortcuts',
  },

  SETTINGS: {
    errorTitle: 'Error save settings',
    errorText:
      `Can't update settings. Please try again. Contact <a class="picsioLink" href="mailto:${supportEmail}">${appName}</a> support if the error repeats.`,
  },

  CATALOG_ITEM: {
    tooltipCopyAsset: 'Copy asset link',
    tooltipDelete: 'Move to trash',
    tooltipEdit: 'Edit',
    tooltipDownload: 'Download',
    tooltipAddRevision: 'Add revision',
    tooltipAddComment: 'Add comment',
    tooltipEditCollections: 'Edit collections',
    tooltipShareAsset: 'Share asset',
    tooltipShareAssetSettings: 'Sharing settings',
    tooltipRestore: 'Restore',
    tooltipDeleteForever: 'Delete forever',
    tooltipRemoveFromCollections: 'Remove from collection',
    tooltipRemoveFromLightboard: 'Remove from lightboard',
    tooltipGoToComments: 'Go to comments',
    tooltipGoToTranscription: 'Go to transcription',
    tooltipGoToRevisions: 'Go to revisions',
    busyStatus: {
      isGoingToTrash: 'Deleting an asset',
      isGoingToDelete: 'Permanently deleting an asset',
      isGoingToRestore: 'Restoring an asset',
      isGoingToMove: 'Moving an asset',
      isGoingToCopy: 'Copying an asset',
    },
    tooltipDuplicate: 'Duplicate',
    tooltipDuplicateDisabled: 'You can duplicate up to 500 assets at once. Select fewer to use this feature',
  },

  ACTIONS_TOOLBAR: {
    ASSETS: {
      share: 'Share asset',
      download: 'Download',
      addRevision: 'Add revision',
      addRevisionDisabled: 'Revisions can only be added to single selected assets, excluding external links',
      attachCollection: 'Add to collection',
      attachToCollection: 'Add to collection',
      removeFromCollections: 'Remove from collection',
      delete: 'Move to trash',
      restore: 'Restore',
      deleteForever: 'Delete forever',
      archive: 'Archive',
      unarchive: 'Unarchive',
      compare: 'Compare',
      compare1asset: 'Select a second asset to enable comparison tool',
      compare3asset: 'Select only two assets to enable comparison tool',
      selectAll: 'Select all',
      deselectAll: 'Deselect all',
      selectedAssetsDisabled: 'List of selected assets are not available in the map view. Please change the view to the list or grid views',
    },
    COLLECTIONS: {
      upload: 'Add assets',
      createWebsite: 'Publish to web',
      websiteSettings: 'Website settings',
      move: 'Move collection',
      download: 'Download collection',
      archive: 'Archive collection',
      unarchive: 'Unarchive collection',
      delete: 'Delete collection',
    },
    INBOXES: {
      settings: 'Inbox settings',
      delete: 'Delete inbox',
    },
    LIGHTBOARDS: {
      upload: 'Add assets',
      delete: 'Delete lightboard',
    },
    KEYWORDS: {
      delete: (count) => `Delete selected ${pluralize('keyword', count)}`,
      merge: (count) => `Merge selected ${pluralize('keyword', count)}`,
    },
  },

  HELP_CENTER: {
    previewView: {
      url: 'general-info/help-center-index',
      tooltip: 'Learn more about Pics.io features',
    },
    catalogView: {
      url: 'general-info/help-center-index',
      tooltip: 'Learn more about Pics.io features',
    },
    auditTrail_audit: {
      url: 'teamwork-in-pics-io/settings-audit-trail',
      tooltip: 'Learn more about Audit Trail log',
    },
    auditTrail_analytics: {
      url: 'en/articles/4097770-analytics',
      tooltip: 'Explore how to work with Pics.io analytics&reporting',
    },
    customFieldsSchema: {
      url: 'working-with-metadata/settings-custom-fields',
      tooltip: 'Learn how to configure custom fields',
    },
    myAccount_account: {
      url: 'pics-io-settings/edit-user-account-information',
      tooltip: 'Learn how to edit your account info',
    },
    myAccount_settings: {
      url: 'pics-io-settings/edit-user-account-information',
      tooltip: 'Learn how to edit your account info',
    },
    myAccount_notifications: {
      url: 'pics-io-settings/edit-user-account-information',
      tooltip: 'Learn how to edit your account info',
    },
    myAccount_security: {
      url: 'en/articles/5406508-two-factor-authentication-2fa',
      tooltip: 'Learn how to edit your account info',
    },
    myAccount_legal: {
      url: 'en/articles/4514944-login-consent-for-support-team',
      tooltip: 'Learn more about Personal Data Protection in Pics.io',
    },
    myBilling_overview: {
      url: 'pics-io-settings/billing',
      tooltip: 'Learn how to set up billing in Pics.io',
    },
    myBilling_info: {
      url: 'pics-io-settings/billing',
      tooltip: 'Learn how to set up billing in Pics.io',
    },
    myBilling_invoices: {
      url: 'pics-io-settings/billing',
      tooltip: 'Learn how to set up billing in Pics.io',
    },
    myBilling_card: {
      url: 'pics-io-settings/billing',
      tooltip: 'Learn how to set up billing in Pics.io',
    },
    myTeam_teammates: {
      url: 'teamwork-in-pics-io/inviting-new-teammates',
      tooltip: 'Learn how to invite new teammates',
    },
    myTeam_roles: {
      url: 'teamwork-in-pics-io/assigning-roles-to-teammates',
      tooltip: 'Learn how to assign roles to teammates',
    },
    myTeam_integrations: {
      url: 'integrations',
      tooltip: 'Learn more about Pics.io integrations',
    },
    myTeam_settings: {
      url: 'teamwork-in-pics-io/team-policies',
      tooltip: 'Learn how to set up team policies',
    },
    myTeam_analytics: {
      url: 'en/articles/4097770-analytics',
      tooltip: 'Explore how to work with Pics.io analytics&reporting',
    },
    myTeam_branding: {
      url: 'en/articles/3738906-branding-for-teams',
      tooltip: 'Learn how to set up team branding',
    },
    myTeam_profile: {
      url: 'teamwork-in-pics-io/team-policies',
      tooltip: 'Learn how to set up team profile',
    },
    myTeam_security: {
      url: 'en/articles/5406508-two-factor-authentication-2fa',
      tooltip: 'Learn how to set up Two-factor authentication (2FA)',
    },
    myTeam_watermarking: {
      url: 'en/articles/5695407-watermarking',
      tooltip: 'Learn more about watermarks',
    },
    myTeam_ai: {
      url: 'en/?q=artificial+intelligence',
      tooltip: 'Learn more about AI capabilities in Pics.io',
    },
    storage: {
      url: 'integration-with-google-drive/integration-with-team-drive',
      tooltip: 'Learn more about Pics.io integration with Team Drive',
    },
    syncDialog: {
      url: 'integration-with-google-drive/synchronizing-google-drive-with-picsio',
      tooltip: 'Learn more about how cloud storage syncs with Pics.io',
    },
    sync: {
      url: 'integration-with-google-drive/synchronizing-google-drive-with-picsio',
      tooltip: 'Learn more about how cloud storage syncs with Pics.io',
    },
    uploadPanel: {
      url: 'en/articles/1269153-uploading-new-files',
      tooltip: 'Details on how to upload new files',
    },
    websites: {
      url: 'en/articles/6422126-sharing-assets-via-shared-collections-websites-in-pics-io',
      tooltip: 'Learn how to share assets outside Pics.io',
    },
    singleAssetSharing: {
      url: 'sharing-assets-to-non-pics-io-members-websites/single-asset-sharing',
      tooltip: 'Learn how to share individual assets outside Pics.io',
    },
    webhooks: {
      url: 'integrations/webhooks',
    },
    zapier: {
      url: 'integrations/zapier',
    },
    referralProgram: {
      url: 'en/articles/4097656-pics-io-referral-program',
      tooltip: 'Learn how to use a referral program',
    },
    inboxes: {
      url: 'en/articles/4066282-inbox',
      tooltip: 'Learn how non-Pics.io users can upload files to your library',
    },
    importCsv: {
      url: 'en/articles/6151893-export-and-import-metadata-in-pics-io',
      tooltip: 'Details on how to import metadata',
    },
    embeddedGallery: {
      url: 'en/articles/6482857-how-can-i-embed-content-from-pics-io-into-another-online-page-via-iframe',
      tooltip: 'How to embed Pics.io content into third-party pages?',
    },
  },

  HELP_CENTER_TORIUT: {
    previewView: {
      url: '/', // @TODO We need to insert a link
      tooltip: 'Learn more about Toriut.com features',
    },
    catalogView: {
      url: '/', // @TODO We need to insert a link
      tooltip: 'Learn more about Toriut.com features',
    },
    customFieldsSchema: {
      url: '/', // @TODO We need to insert a link
      tooltip: 'Learn how to configure custom fields',
    },
    myAccount_account: {
      url: '/', // @TODO We need to insert a link
      tooltip: 'Learn how to edit your account info',
    },
    myAccount_settings: {
      url: '/', // @TODO We need to insert a link
      tooltip: 'Learn how to edit your account info',
    },
    myAccount_notifications: {
      url: '/', // @TODO We need to insert a link
      tooltip: 'Learn how to edit your account info',
    },
    myAccount_security: {
      url: '/', // @TODO We need to insert a link
      tooltip: 'Learn how to edit your account info',
    },
    myAccount_legal: {
      url: '/', // @TODO We need to insert a link
      tooltip: 'Learn more about Personal Data Protection in Toriut.com',
    },
    myBilling_overview: {
      url: '/', // @TODO We need to insert a link
      tooltip: 'Learn how to set up billing in Toriut.com',
    },
    myBilling_info: {
      url: '/', // @TODO We need to insert a link
      tooltip: 'Learn how to set up billing in Toriut.com',
    },
    myBilling_invoices: {
      url: '/', // @TODO We need to insert a link
      tooltip: 'Learn how to set up billing in Toriut.com',
    },
    myBilling_card: {
      url: '/', // @TODO We need to insert a link
      tooltip: 'Learn how to set up billing in Toriut.com',
    },
    myTeam_teammates: {
      url: '/', // @TODO We need to insert a link
      tooltip: 'Learn how to invite new teammates',
    },
    myTeam_roles: {
      url: '/', // @TODO We need to insert a link
      tooltip: 'Learn how to assign roles to teammates',
    },
    myTeam_settings: {
      url: '/', // @TODO We need to insert a link
      tooltip: 'Learn how to set up team policies',
    },
    myTeam_analytics: {
      url: '/', // @TODO We need to insert a link
      tooltip: 'Explore how to work with Toriut.com analytics&reporting',
    },
    myTeam_profile: {
      url: '/', // @TODO We need to insert a link
      tooltip: 'Learn how to set up team profile',
    },
    myTeam_security: {
      url: '/', // @TODO We need to insert a link
      tooltip: 'Learn how to set up Two-factor authentication (2FA)',
    },
    myTeam_ai: {
      url: '/', // @TODO We need to insert a link
      tooltip: 'Learn more about AI capabilities in Toriut.com',
    },
    storage: {
      url: '/', // @TODO We need to insert a link
      tooltip: 'Learn more about Toriut.com integration with Team Drive',
    },
    uploadPanel: {
      url: '/', // @TODO We need to insert a link
      tooltip: 'Details on how to upload new files',
    },
    webhooks: {
      url: '/', // @TODO We need to insert a link
    },
    referralProgram: {
      url: '/', // @TODO We need to insert a link
      tooltip: 'Learn how to use a referral program',
    },
    importCsv: {
      url: 'en/articles/6151893-export-and-import-metadata-in-pics-io',
      tooltip: 'Details on how to import metadata',
    },
    embeddedGallery: {
      url: 'en/articles/6482857-how-can-i-embed-content-from-pics-io-into-another-online-page-via-iframe',
      tooltip: 'How to embed Toriut.com content into third-party pages?',
    },
  },

  ANALYTICS: {
    axisXDays: 'days',
    titleSummOfVisitors: 'Number of visitors',
    axisYSummOfVisitors: 'Visitors',
    titleWebsiteDownloadedByWEbUsers: 'Assets downloads',
    AxisYWebsiteDownloadedByWEbUsers: 'Asset downloads',
    titleTeammates: 'Number of teammates',
    axisYTeammates: 'Teammates',
    titleUserLoginStats: 'Number of teammates logged in',
    axisYTitleUserLoginStats: 'Logged in',
    titleAssetDownloadedByTeam: 'Asset downloaded by team',
    axisYAssetDownloadedByTeam: 'Asset downloaded',
    titleAssetDownloadedByWebUsers: 'Asset downloaded by web users',
    axisYAssetDownloadedByWebUsers: 'Asset downloaded',
    titleAssetChanged: 'Number of asset revisions',
    titleMetadataChangedData: 'Total asset metadata changes',
    titleAssetsUploaded: 'assets in the library',
    axisYAssetsUploaded: 'Assets',
    titleAssetsDownloadedByTeam: 'Assets downloads',
    axisYAssetsDownloadedByTeam: 'Assets downloads',
    titleWebsitesDownloadedByWebUsersData: 'Assets downloads from websites',
    axisYwebsitesDownloadedByWebUsersData: 'Asset downloads',
    titleRatingTableGraph: 'Top assets downloads',
    axisYRatingTableGraph: '',
    titleMetadataChanged: 'Total metadata changes on all assets',
    axisYMetadataChanged: 'Metadata changed',
    titleAssetsCommented: 'Assets commented',
    axisYAssetsCommented: 'Assets commented',
    titleActiveWebSites: 'active websites',
    axisYActiveWebSites: 'active websites',
    titleAssetsInTheLibrary: ' assets in the library',
    placeholder: {
      fetching: 'Processing...',
      noAvailable: 'You have no data yet',
      noSupported: 'This data is not available on your plan',
    },
  },

  NOTIFICATION_SETTINGS: {
    titleNotificationsByCollection: (collectionName) => (
      <>
        Settings for <span className="pageItemTitleColor">"{collectionName}"</span> collection
      </>
    ),
    enableAll: 'Send all event types',
    disableAll: 'Do not send anything',
    sendSelected: 'Select event types to send',
    loading: 'Event type list is loading...',
    errorGetSettingsTypes:
      `Can\'t load data. Please try again.<br> Contact <a class="picsioLink" href="mailto:${supportEmail}">${appName}</a> support if the error repeats.`,
    errorSavingSettings:
      `Cannot save settings. Refresh the browser tab. If the error persists, contact us at <a class="picsioLink" href="mailto:${supportEmail}">${appName}</a>.`,
    errorGetSettingsCollections:
  <span>Can't load collections data. Please try again.<br /> Contact <a className="picsioLink" href={`mailto:${supportEmail}`}>{appName}</a> support if the error repeats.</span>,

    emailNotifications: {
      title: 'Global email notifications',
      collectionTitle: (collectionName) => (
        <>
          <span className="pageItemTitleColor">"{collectionName}"</span> email notifications
        </>
      ),
    },

    slackNotifications: {
      title: 'Slack Notifications',
    },

    socketNotifications: {
      title: 'Global browser push notifications',
      collectionTitle: (collectionName) => (
        <>
          <span className="pageItemTitleColor">"{collectionName}"</span> browser push notifications
        </>
      ),
      descriptionNotSupportedNotificationsApi: 'Your browser does not support Notifications API.',
      descriptionPushNotificationsEnabled: `Push notifications are allowed for ${appName}.`,
      descriptionPushNotificationsBlocked: `Push notifications are blocked for ${appName}.`,
      descriptionPushNotification1: `Push notifications are disabled for ${appName}. `,
      descriptionPushNotification2: ' to enable.',
      textClickButton: 'Click',
      howToEnableOrDisablePushNotifications:
        'How to enable or disable Push Notifications on browsers',
    },

    notificationCenter: {
      title: 'Global notification center',
      collectionTitle: (collectionName) => (
        <>
          <span className="pageItemTitleColor">"{collectionName}"</span> notification center
        </>
      ),
    },

    allowMentions: 'Allow notifications about mentions in any case',
  },

  VIDEO: {
    playVideo: 'Play video',
    pauseVideo: 'Pause video',
    muteSound: 'Mute sound',
    unmuteSound: 'Unmute sound',
    takeSnapshot: 'Take a snapshot',
    createThumbnail: 'Create custom thumbnail',
    cropVideo: 'Crop video',
    cropVideoTooltip: 'Download',
    enterFullscreenMode: 'Enter fullscreen mode',
    errorCreatingScreenshot:
      `Cannot create screenshot.<br> Please try again later or contact <a className="picsioLink" href="mailto:${supportEmail}">${appName}</a>.`,
    errorProxyResponding:
      `Server is not responding.<br> Please try again later or contact <a className="picsioLink" href="mailto:${supportEmail}">${appName}</a>.`,
    warningThumbnailGeneratingTitle: 'Please wait',
    spinnerCreatingThumbnail: 'Creating video thumbnail',
    spinnerCreatingScreenshot: 'Creating video screenshot',
    safariErrorTitle: 'Feature is not available',
    safariErrorTxt:
      'This feature is not available in Safari.<br> Please try to use Google Chrome or Firefox.',
    iosErrorTxt: 'This feature is not available on iOS devices.',
  },

  PROCESSING: {
    title: 'The number of assets under processing:',
    keywording: 'Generating keywords',
    faceRecognizing: 'Image is being processed by face recognition',
    embedding: 'Indexing assets for AI-powered search',
    replicating: 'Saving XMP/EXIF metadata to your cloud storage',
    metadating: 'Reading XMP/IPTC metadata',
    transcribing: 'Creating a text from audio track',
    importing: 'in the process of importing from cloud storage',
    textImportingMedia: (count) => `${pluralize('media file', count, true)} ${count > 1 ? 'are' : 'is'} being imported from Shopify`,
    textProductAttaching: (count) => `${pluralize('media file', count, true)} ${count > 1 ? 'are' : 'is'} being added to products`,
    productAttaching: 'Attaching to product',
    contenting: 'Indexing document’s content for search',
    thumbnailing: 'Generating thumbnails',
    converting: 'Preparing and optimizing an asset for preview',
    trashing: 'Moving to trash',
    crawling: 'Reading content from the external link',
    untrashing: 'Restoring',
    deleting: 'Deleting forever',
    moving: 'Moving',
    transcoding: 'Converting videos',
    syncing: 'Sync is running',
    describing: 'Generating description',
    failed: {
      keywording: <span>Keyword generation ran into a problem. Please shoot an email to <a className="picsioLink" href="mailto:support@pics.io">support@pics.io</a> if you want to have this issue resolved.</span>,
      replicating: <span>Saving XMP/EXIF metadata to your cloud storage ran into a problem. Please shoot an email to <a className="picsioLink" href="mailto:support@pics.io">support@pics.io</a> if you want to have this issue resolved.</span>,
      metadating: <span>Reading XMP/IPTC metadata ran into a problem. Please shoot an email to <a className="picsioLink" href="mailto:support@pics.io">support@pics.io</a> if you want to have this issue resolved.</span>,
      contenting: <span>Preparing content for text search ran into a problem. Please shoot an email to <a className="picsioLink" href="mailto:support@pics.io">support@pics.io</a> if you want to have this issue resolved.</span>,
      crawling: <span>Reading content from the external link ran into a problem. Please shoot an email to <a className="picsioLink" href="mailto:support@pics.io">support@pics.io</a> if you want to have this issue resolved.</span>,
      faceRecognizing: <span>Face recognizing ran into a problem. Please shoot an email to <a className="picsioLink" href="mailto:support@pics.io">support@pics.io</a> if you want to have this issue resolved.</span>,
      describing: <span>Description generation ran into a problem. Please shoot an email to <a className="picsioLink" href="mailto:support@pics.io">support@pics.io</a> if you want to have this issue resolved.</span>,
      embedding: <span>Visual Search indexing for this asset failed. Please email <a className="picsioLink" href="mailto:support@pics.io">support@pics.io</a> so we can help you troubleshoot and make it searchable.</span>,
    },
    rerun: {
      metadating: 'Re-run metadata parsing',
      replicating: 'Re-run metadata saving',
      thumbnailing: 'Re-run generating thumbnail',
      crawling: 'Re-run reading content from the external link',
      describing: 'Re-run generating description',
    },
  },

  MAPVIEW: {
    placeholderNoAssets: 'There are no assets with Geo data in this collection',
    spinnerLoadingAssets: 'Loading assets...',
  },

  TEAMMATES_DIALOG: {
    title: 'My team',
    btnOk: 'Ok',
    text: (managers = 'owner') => (
      <>
        You do not have permissions to manage your team settings. Please contact
        your team {managers} if you need to add or delete teammates.
      </>
    ),
  },

  GLOBAL: {
    tooltipMinimize: 'Minimize',
  },

  UPGRADE_PLAN: {
    text: 'Upgrade plan',
    textForHigherPlan: 'For higher plan',
    tooltipBasic: 'Upgrade plan to use this feature.',
    tooltip: 'Upgrade to use this feature. Click to learn more.',
    tooltipPlanLimitations: 'Please note that this functionality is available on higher plan',
    tooltipForButtons: (text) => (
      <span
        style={{ cursor: 'pointer' }}
        aria-hidden="true"
        onClick={(e) => {
          e.stopPropagation();
          const tooltipEl = document.querySelector('.tooltip-container');
          tooltipEl.style.display = 'none';
          navigate('/billing?tab=overview');
        }}
      >{text || 'Add to plan'}
      </span>
    ),
    textForMobileApps: 'Not available on your plan',
  },

  DONE: 'Done',

  CONSENT: {
    title: 'Consent',
    WEBSITE: {
      VISITING: {
        labelStatus: 'Show consent when visiting',
        labelTitle: 'Title for consent when visiting',
        labelMessage: 'Message for consent when visiting',
        defaultTitle: 'Copyright consent',
        defaultMessage:
          "The contents of this website, including (but not limited to) all written materials, images, photos, videos are protected under international copyright and trademarks laws. Please confirm that you will not copy, reproduce, modify, republish, transmit or distribute any material from this site without the owner's permission.",
      },
      ACTION: {
        labelStatus: 'Show consent when downloading',
        labelTitle: 'Title for consent when downloading',
        labelMessage: 'Message for consent while downloading',
        defaultTitle: 'Copyright consent',
        defaultMessage:
          "Please note that you are not allowed to copy, reproduce, modify, republish, transmit or distribute any material from this site without the owner's permission.",
      },
    },

    SAS: {
      VISITING: {
        labelStatus: 'Show consent when visiting',
        labelTitle: 'Title for consent when visiting',
        labelMessage: 'Message for consent when visiting',
        defaultTitle: 'Copyright consent',
        defaultMessage:
          "The contents of this website, including (but not limited to) all written materials, images, photos, videos are protected under international copyright and trademarks laws. Please confirm that you will not copy, reproduce, modify, republish, transmit or distribute any material from this site without the owner's permission.",
      },
      ACTION: {
        labelStatus: 'Show consent when downloading',
        labelTitle: 'Title for consent when downloading',
        labelMessage: 'Message for consent while downloading',
        defaultTitle: 'Copyright consent',
        defaultMessage:
          "Please note that you are not allowed to copy, reproduce, modify, republish, transmit or distribute any material from this site without the owner's permission.",
      },
    },

    INBOX: {
      VISITING: {
        labelStatus: 'Show consent when visiting',
        labelTitle: 'Title for consent when visiting',
        labelMessage: 'Message for consent when visiting',
        defaultTitle: 'Copyright consent',
        defaultMessage:
          'Please note that materials from this website are accessible to third parties. Accept the consent if you still need to upload your files.',
      },
      ACTION: {
        labelStatus: 'Show consent when uploading',
        labelTitle: 'Title for consent when uploading',
        labelMessage: 'Message for consent while uploading',
        defaultTitle: 'Copyright consent',
        defaultMessage:
          'Please note that you are going to upload the assets that will be accessible to third parties. Accept the consent if you still need to upload your files.',
      },
    },
  },

  MODIFIED_FIELD: {
    tooltip: (userName) => `This field was edited by ${userName}. ${appName} will not overwrite this field even in case metadata is found in the original file. Please click this mark to allow overwriting.`,
    currentUser: 'you',
    teammate: 'your teammate',
  },

  SUPPORT: {
    ACCESS_TO_YOUR_ACCOUNT: 'Access to your account',
    CONSENT:
      `You grant ${appName} team access to your account for the next 24 hours to use your information in accordance to their terms of service.`,
  },

  MOBILE_APP: {
    IS_NOT_AVAILABLE:
      'Note: This functionality is not available in mobile app. Please use the desktop version to access it.',
  },

  WARNING: 'Warning',
  ERROR: 'Error',
  SOMETHING_WENT_WRONG: {
    TITLE: 'Oops! Something went wrong',
    TEXT:
      `Please try again and contact <a class='picsioLink' href='mailto:${supportEmail}'>${appName}</a> support if the error repeats.`,
  },

  EMPTY_SEARCH: {
    SAVED_SEARCHES: {
      assigned: (displayName) => `This saved search shows all assets that were assigned to a user ${displayName}`,
      restricted: 'This saved search shows all assets that were restricted for download or any other action by somebody on your team',
      uncategorized: 'This saved search shows all assets that are not attached to any collection',
      corrupted: `This saved search shows all files that cannot be previewed within ${appName} because there are damaged or corrupted in some way`,
      spreadsheets: 'This saved search shows all assets that have .xlsx extension',
      jpg: 'This saved search shows all assets that have .jpg or .jpeg extension',
      text: () => <>This saved search shows all assets that are .rtf, .docs, or .txt.</>,
      pdf: 'This saved search shows all assets that have PDF extension',
      ai: 'This saved search shows all assets that were generated with Adobe Illustrator',
      photoshop: 'This saved search shows all assets that were generated with Adobe Photoshop',
      gps: 'This saved search shows all assets that have GPS coordinates in their metadata',
      gpsno: 'This saved search shows all assets that don\'t have GPS coordinates in their metadata',
      video: 'This saved search shows all assets that have any of video extension types (like mp4, mpv, mov etc)',
      createdToday: 'This saved search shows all assets that were created within current date',
      createdWeek: 'This saved search shows all assets that were uploaded during current calendar week',
      createdMonth: 'This saved search shows all assets that were uploaded during current calendar month',
      dublicates: 'This saved search shows all assets that are identical copies of each other',
      raw: 'This saved search shows all assets that have a RAW extension',
      shared: 'This saved search shows all assets that were shared publicly to the web',
      watermarked: 'This saved search shows all assets that have a watermark applied on them.',
    },
  },
  PAGE403: {
    title: 'Access denied',
    text: <p>Team manager limited access to this page for your user role. <br />If you think you need to have access here, contact your team manager.</p>,
  },
  AUTH_METHODS: {
    TEXT: 'All authentication methods are disabled. Leave at least one method enabled.',
  },
  offlineToastText: 'Seems you are offline. Please check your internet connection.',
};

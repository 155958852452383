import React, { useCallback, useState, useMemo } from 'react';
import {
  arrayOf, func, bool, string, any, objectOf, oneOf, shape,
} from 'prop-types';
import { Settings, Transcript as TranscriptIcon } from '@picsio/icons';
import Logger from '../../../../services/Logger';
import { calculateAiMinutes } from '../../../../helpers/calculateAICredits';
import { parseTime } from '../../../../shared/utils';
import DetailsBlock from '../../../DetailsBlock';
import l18n from '../../../../shared/strings';
import { showDialog } from '../../../dialog';
import Toast from '../../../Toast/index';
import TwoLevelMenu from '../../../TwoLevelMenu';
import Placeholder from '../../../DetailsBlock/Placeholder';
import ProcessingItem from '../ProcessingItem';
import AiIcon from '../AiIcon';
import menuControls from './aiSettings';
import './styles.scss';

function Transcript({
  assetIds,
  assets,
  team,
  detailsPanelVisibility,
  toggleVisibility,
  blockTitle,
  blockName,
  isMainApp,
  transcribeAction,
  transcriptPermission,
  subscriptionFeatures,
  transcribing,
}) {
  const defaultLanguage = team?.policies?.transcribingLanguage || 'en';

  const canTranscribe = useMemo(() => assets.some((asset) => asset.isVideo || asset.isAudio), [assets]);

  const hasTranscript = useMemo(() => {
    /** if selected more than one asset or on the asset no transcripts */
    if (assets.length !== 1 || !assets[0]?.transcripts?.length) return false;
    const asset = assets[0];
    const { transcripts, headRevisionId } = asset;
    return transcripts.findIndex(({ revisionId }) => revisionId === headRevisionId) !== -1;
  }, [assets]);

  const [settings, setSettings] = useState({ locale: defaultLanguage });

  const handleChangeMenu = useCallback((menuId, itemId) => {
    setSettings(() => ({ [menuId]: itemId }));
  }, [setSettings]);

  const handleSubmit = useCallback(() => {
    if (isMainApp && assetIds.length) {
      const { locale } = settings;
      Logger.log('User', 'InfoPanelTranscribeClicked', { assetsLength: assetIds.length, language: locale });

      if (assetIds.length >= 2) {
        showDialog({
          title: l18n.TRANSCRIPT.DIALOGS.titleBulkTranscribing,
          text: l18n.TRANSCRIPT.DIALOGS.textBulkTranscribing(assetIds.length),
          textBtnCancel: l18n.TRANSCRIPT.DIALOGS.btnCancelBulkTranscribing,
          textBtnOk: l18n.TRANSCRIPT.DIALOGS.btnCongirmBulkTranscribing,
          onOk: () => {
            transcribeAction({ assetIds, language: locale });
          },
        });
      } else {
        transcribeAction({ assetIds, language: locale });
      }
    } else {
      Toast(l18n.TRANSCRIPT.TOASTS.textTranscribingHonSupported);
    }
  }, [isMainApp, assetIds, settings, transcribeAction]);

  const inProgress = useMemo(
    () => ['waiting', 'running'].includes(transcribing),
    [transcribing],
  );

  const credits = useMemo(() => {
    const aiMinutes = calculateAiMinutes(team);

    return parseTime(aiMinutes * 60, { type: 'hoursAndMinutes' });
  }, [team]);

  const actions = transcriptPermission ? [
    <TwoLevelMenu
      key="settings-menu"
      MainIcon={() => <Settings />}
      controls={menuControls}
      value={settings}
      onChange={handleChangeMenu}
      disabled={inProgress}
      testId="aiSettings"
    />,
    <AiIcon
      key="ai-icon"
      data-testid="icongenerateTranscribe"
      disabled={!subscriptionFeatures}
      inProgress={inProgress}
      tooltipText={l18n.DETAILS.aiIconMinutesTooltipText(inProgress ? l18n.DETAILS.textTranscribing : l18n.DETAILS.textTranscribe, credits, 'text-center')}
      onSubmit={handleSubmit}
    />,
  ] : [];

  /** Don't show block if selected only one asset AND it's not a videos or audio asset */
  if (!canTranscribe) return null;

  return (
    <DetailsBlock
      hideActions
      showAI={false}
      dataQa="transcript"
      detailsPanelVisibility={detailsPanelVisibility}
      toggleVisibility={toggleVisibility}
      blockName={blockName}
      blockTitle={blockTitle}
      showUpgradePlan={!subscriptionFeatures}
      badgeType="minutes"
      actions={actions}
    >
      <If condition={!hasTranscript && assetIds.length === 1}>
        <Placeholder>
          <Choose>
            <When condition={inProgress}>
              <ProcessingItem item={{
                status: 'running',
                name: 'transcribing',
                icon: <TranscriptIcon />,
              }}
              />
            </When>
            <Otherwise>
              {l18n.DETAILS.placeholderTranscribe}
            </Otherwise>
          </Choose>
        </Placeholder>
      </If>
    </DetailsBlock>
  );
}

Transcript.defaultProps = {
  detailsPanelVisibility: {},
  isMainApp: true,
  transcriptPermission: false,
  transcribeAction: Function.prototype,
  subscriptionFeatures: false,
  transcribing: 'skipped',
  assets: [],
};

Transcript.propTypes = {
  assetIds: arrayOf(string).isRequired,
  team: objectOf(any).isRequired,
  detailsPanelVisibility: objectOf(any),
  toggleVisibility: func.isRequired,
  blockTitle: string.isRequired,
  blockName: string.isRequired,
  isMainApp: bool,
  transcriptPermission: bool,
  transcribeAction: func,
  subscriptionFeatures: bool,
  transcribing: oneOf(['skipped', 'waiting', 'running', 'complete', 'failed', 'rejected']),
  assets: arrayOf(shape({
    headRevisionId: string,
    transcripts: arrayOf(shape({
      language: string,
      revisionId: string,
      transcript: shape({
        start: string,
        end: string,
        text: string,
      }),
    })),
  })),
};

export default Transcript;

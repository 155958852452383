import _isObject from 'lodash/isObject';
import picsioConfig from '../../../../config';
import CONSTANTS from '../shared/constants';

const fbEvents = {
  AppStarted: {
    name: 'Lead',
    isLabelAllowed: false,
  },
  SubscriptionChanged: {
    name: 'Subscribe',
    isLabelAllowed: true,
  },
};
const sendFBEvent = (action, label) => {
  const eventName = fbEvents[action].name;
  const eventLabel = fbEvents[action].isLabelAllowed && label;

  let url = `https://www.facebook.com/tr?id=${CONSTANTS.facebook.id}&ev=${eventName}`;
  if (eventLabel) url += `&cd[value]=${eventLabel}`;

  const pixel = new Image(1, 1);
  pixel.src = url;
};
/**
 * Emit Facebook Event
 * @param {string} action
 * @param {string} label
 */
const emitFBEvent = (action, label) => {
  if (!action) throw new Error('action is required');
  if (!fbEvents[action]) throw new Error(`action "${action}" is undefined`);

  sendFBEvent(action, label);
};

/**
 * Tracking Service
 * This service tracks user actions and push events to GoogleAnalytics
 */
const TrackingService = {
  push(category, action, label, value, nonInteraction) {
    const googleAnalyticsIdentifier = window?.websiteConfig?.googleAnalyticsIdentifier || window?.inbox?.googleAnalyticsIdentifier || 'G-DRL97KHPF7';
    // replace category with overrided value if setted before
    // need for using events with correct category from different plugins
    const eventLabel = _isObject(label) ? JSON.stringify(label) : label;

    const sendValue = typeof value === 'number' ? value : undefined;

    const getGA4ClientID = () => new Promise((resolve, reject) => {
      if (typeof gtag !== 'function') {
        const error = new Error('gtag is not defined');
        reject(error);
        return;
      }

      try {
        // eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef
        gtag('get', googleAnalyticsIdentifier, 'client_id', (clientID) => {
          if (!clientID) {
            const error = new Error('Google Client ID is undefined');
            reject(error);
            return;
          }
          resolve(clientID);
        });
      } catch (e) {
        reject(e);
      }
    });

    if (picsioConfig.env === 'production') {
      getGA4ClientID()
        .then((clientID) => {
          if (!clientID) {
            throw new Error('Google Client ID is missing.');
          }

          const trackingParams = {
            event_category: category,
            event_label: `${eventLabel}`,
            value: sendValue,
            non_interaction: Boolean(nonInteraction),
            client_id: clientID,
            api_secret: 'tQ_pHpAtRsCOumtf2UCCIg',
            measurement_id: googleAnalyticsIdentifier,
          };

          window.gtag('event', action, trackingParams);

          if (Object.keys(fbEvents).includes(action)) {
            emitFBEvent(action, eventLabel);
          }
        })
        .catch((err) => {
          console.warn('Tracking Error:', err);
        });
    }
  },
};

export default TrackingService;

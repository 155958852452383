import React, {
  memo, useMemo, useState, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  DownloadCsv,
  DotsVertical,
  Storage,
} from '@picsio/icons';
import cn from 'classnames';
import {
  Menu, IconButton, MenuItem, MenuItemIcon, MenuItemText, Icon,
} from '@picsio/ui';
import localization from '../../../shared/strings';
import { checkUserAccess } from '../../../store/helpers/user';
import picsioConfig from '../../../../../../config';

const AssetsMenu = (props) => {
  const {
    permissions,
    isRestricted,
    exportToCSV,
    isArchived,
    filterGroupsByPermission,
    toggleEditPanel,
    groups,
  } = props;
  const isMainApp = useMemo(() => picsioConfig.isMainApp, []);
  const user = useSelector((state) => state.user);
  const ref = useRef();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = useCallback(() => setMenuOpen((prevValue) => !prevValue), []);
  const isDownloadArchiveAllowed = isArchived ? (checkUserAccess('subscriptions', 'archive', null, user) && checkUserAccess('permissions', 'downloadArchive', null, user)) : true;

  const memoizedControls = useMemo(() => {
    const hasDownloadPermission = permissions?.assetsIsDownloadable === true
      && (!isRestricted || (isRestricted && permissions?.restrictedIsDownloadableOrShareable === true))
      && (isArchived ? isDownloadArchiveAllowed : true);

    const controls = [];

    /** Export CSV */
    if (hasDownloadPermission && !picsioConfig.isPim) {
      controls.push({
        id: 'menuExportToCSV',
        text: localization.DETAILS.textExportToCSV,
        onClick: exportToCSV,
        icon: () => <Icon size="lg"><DownloadCsv /></Icon>,
      });
    }

    if (isMainApp && filterGroupsByPermission(groups).length) {
      controls.push({
        id: 'menuEditWidgets',
        text: localization.DETAILS.editWidgets,
        onClick: toggleEditPanel,
        icon: () => <Icon size="lg"><Storage /></Icon>,
      });
    }

    return controls;
  }, [
    filterGroupsByPermission,
    toggleEditPanel,
    groups,
    isMainApp,
    permissions,
    isRestricted,
    isArchived,
    isDownloadArchiveAllowed,
    exportToCSV,
  ]);

  return (
    <If condition={memoizedControls.length}>
      <IconButton
        ref={ref}
        buttonSize="default"
        className={cn({ isActive: isMenuOpen })}
        color="default"
        component="button"
        disabled={false}
        id="assetMenuOpener"
        onClick={toggleMenu}
        size="xl"
      >
        <DotsVertical />
      </IconButton>
      <Menu
        target={ref}
        arrow
        padding="s"
        placement="bottom-end"
        isOpen={isMenuOpen}
        onClose={toggleMenu}
        outsideClickListener
      >
        {memoizedControls.map((control) => {
          const {
            id, text, onClick, icon: ControlIcon,
          } = control;

          return (
            <MenuItem
              key={id}
              id={id}
              onClick={() => {
                onClick();
                toggleMenu();
              }}
              className="menuItemDefault"
            >
              <MenuItemIcon size="md">
                <ControlIcon />
              </MenuItemIcon>
              <MenuItemText primary={text} />
            </MenuItem>
          );
        })}
      </Menu>
    </If>
  );
};

AssetsMenu.propTypes = {
  isRestricted: PropTypes.bool.isRequired,
  permissions: PropTypes.shape({
    assetsIsDownloadable: PropTypes.bool,
    restrictedIsAttachableOrRemovable: PropTypes.bool,
    restrictedIsDownloadableOrShareable: PropTypes.bool,
    assetsIsRemovable: PropTypes.bool,
    fileNameEditable: PropTypes.bool,
    upload: PropTypes.bool,
    collectionsEditable: PropTypes.bool,
  }).isRequired,
  exportToCSV: PropTypes.func.isRequired,
  isArchived: PropTypes.bool.isRequired,
  filterGroupsByPermission: PropTypes.func.isRequired,
  toggleEditPanel: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }))).isRequired,
};

export default memo(AssetsMenu);

/* eslint-disable react/require-default-props */
import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { IconButton } from '@picsio/ui';
import {
  Settings, CheckIcon, AddTeammates, Copy,
} from '@picsio/icons';
import { useSelector } from 'react-redux';
import localization from '../../../shared/strings';
import picsioConfig from '../../../../../../config';
import { Input, Checkbox } from '../../../UIComponents';
import Logger from '../../../services/Logger';
import { navigate } from '../../../helpers/history';
import UpgradePlan from '../../UpgradePlan';
import isAssetImageChecker from '../helpers/isAssetImageChecker';
import sendEventToIntercom from '../../../services/IntercomEventService';
import copyTextToClipboard from '../../../helpers/copyTextToClipboard';
import { showDialog } from '../../dialog';
import Tooltip from '../../Tooltip';

const Share = (props) => {
  const [isFocused, setFocused] = useState(false);
  const {
    asset,
    assetSharingAllowed,
    onChange,
    isVisible,
    inProgress,
    toggleVisibility,
    disabled = false,
  } = props;
  const assetId = asset?._id;
  const singleSharingSettings = asset?.singleSharingSettings;
  let alias = asset?.singleSharingSettings?.alias;
  const fileExtension = asset?.fileExtension;
  const isShared = singleSharingSettings?.isShared;
  const link = `${picsioConfig.websitesBaseUrl}/preview/${assetId}`;
  const thumbnailLink = `${picsioConfig.apiBaseUrl}/preview/${assetId}/thumbnail`;
  const isAssetImage = isAssetImageChecker(fileExtension);
  const isManageTeam = useSelector((state) => state.user.role.permissions.manageTeam);
  const { branding, customDomains } = useSelector((state) => state.user.subscriptionFeatures || {});
  const customDomainsAllowed = branding || customDomains;
  const url = new URL(link);

  if (!customDomainsAllowed && alias) {
    alias = alias.split('/').toSpliced(0, 1, url.host).join('/');
  }

  const [copied, setCopied] = useState({ shareLink: false, directLink: false });

  const handleChange = (value) => {
    const isRestricted = asset?.restrictSettings?.isRestricted;

    function doShare() {
      onChange(asset?._id, 'isShared', value);

      if (value && !alias) {
        onChange(asset?._id, 'alias', `${url.host}/preview/${assetId}`);
      }

      if (!isVisible) {
        toggleVisibility('detailsAssetShareVisibility');
      }
      sendEventToIntercom('share from ipanel');
      const isAssetShared = !!value;
      if (isAssetShared) {
        Logger.log('User', 'InfoPanelShareSingle', { assetId });
      } else {
        Logger.log('User', 'InfoPanelUnShareSingle', { assetId });
      }
    }

    if (isRestricted && !isShared) {
      showDialog({
        title: localization.DIALOGS.RESTRICTED_ASSET_DETECTED.TITLE,
        text: localization.DIALOGS.RESTRICTED_ASSET_DETECTED.TEXT,
        textBtnOk: localization.DIALOGS.RESTRICTED_ASSET_DETECTED.BTN_OK,
        textBtnCancel: localization.DIALOGS.RESTRICTED_ASSET_DETECTED.BTN_CANCEL,
        onOk: () => doShare(),
      });
    } else {
      doShare();
    }
  };

  const handleTitleClick = () => toggleVisibility('detailsAssetShareVisibility');

  const handleInputFocus = () => setFocused(true);

  const handleInputBlur = () => setFocused(false);

  const openSettings = () => {
    Logger.log('User', 'InfoPanelShareSingleOptionsShow');
    navigate(`/singlesharing/${assetId}`);
  };

  const openTeammates = () => {
    Logger.log('User', 'InfoPanelShareSingleInviteTeammate');
    navigate('/teammates?tab=teammates');
  };

  const copyDirectLink = () => {
    Logger.log('User', 'InfoPanelShareSingleCopyDirectLink');
    const assetShareURL = thumbnailLink;
    setCopied((prev) => ({ ...prev, directLink: true }));
    setTimeout(() => {
      setCopied((prev) => ({ ...prev, directLink: false }));
    }, 2000);
    copyTextToClipboard(assetShareURL);
  };

  const copyShareLink = () => {
    Logger.log('User', 'InfoPanelShareSingleCopyShareLink');
    const assetShareURL = alias ? `https://${alias}` : link;
    setCopied((prev) => ({ ...prev, shareLink: true }));
    setTimeout(() => {
      setCopied((prev) => ({ ...prev, shareLink: false }));
    }, 2000);
    copyTextToClipboard(assetShareURL);
  };

  return (
    <div
      data-qa="details-component-share"
      className={cn('detailsPanel__item', { act: isVisible, disabled })}
    >
      <div className="detailsPanel__title">
        <span
          className={cn('detailsPanel__title_text', { withoutTriangle: !isShared || !assetSharingAllowed })}
          onClick={isShared ? handleTitleClick : null}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => e.key === 'Enter' && isShared && handleTitleClick()}
        >
          {localization.DETAILS.textShare}
          <If condition={!assetSharingAllowed}>
            <UpgradePlan text={localization.UPGRADE_PLAN.textForHigherPlan} />
          </If>
        </span>
        <div className="detailsPanel__title_buttons">
          <If condition={isShared && !disabled && assetSharingAllowed}>
            <Tooltip content="Asset sharing settings" placement="top">
              <IconButton
                id="button-openSettings"
                onClick={openSettings}
                size="lg"
                componentProps={{
                  'data-testid': 'btnOpenSettings',
                }}
              >
                <Settings />
              </IconButton>
            </Tooltip>
            <If condition={isManageTeam}>
              <Tooltip
                content="Invite people to collaborate"
                placement="top"
              >
                <IconButton
                  id="button-inviteTeammates"
                  onClick={openTeammates}
                  size="lg"
                  componentProps={{
                    'data-testid': 'btnInviteTeammates',
                  }}
                >
                  <AddTeammates />
                </IconButton>
              </Tooltip>
            </If>
          </If>
          <If condition={!disabled}>
            <Checkbox
              slide
              inProgress={inProgress}
              value={isShared}
              onChange={handleChange}
              icon="share"
              disabled={!assetSharingAllowed}
            />
          </If>
        </div>
      </div>
      <CSSTransition in={isVisible && isShared} timeout={300} classNames="fade">
        <>
          <If condition={isVisible && assetSharingAllowed && isShared}>
            <div className="sharingLink">{localization.DETAILS.textShareLink}</div>
            <div className={cn('fieldCopyTo', { isFocused })}>
              <div className="fieldCopyToHolder">
                <Input
                  value={alias ? `https://${alias}` : link}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  className="fieldCopyToUrl"
                />
                <Tooltip content={copied.shareLink ? 'Copied!' : 'Copy link'} placement="top">
                  <IconButton
                    onClick={copyShareLink}
                    size="md"
                    className="fieldCopyToSettings"
                    componentProps={{
                      'data-value': alias ? `https://${alias}` : link,
                      'data-testid': 'iconCopySharingLink',
                    }}
                  >
                    <Choose>
                      <When condition={copied.shareLink}>
                        <CheckIcon className="checkIcon" />
                      </When>
                      <Otherwise>
                        <Copy />
                      </Otherwise>
                    </Choose>
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <If condition={isAssetImage}>
              <div className="directLink">{localization.DETAILS.textDirectLink}</div>
              <div className={cn('fieldCopyTo', { isFocused })}>
                <div className="fieldCopyToHolder">
                  <Input
                    value={thumbnailLink}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    className="fieldCopyToUrl"
                  />
                  <Tooltip content={copied.directLink ? 'Copied!' : 'Copy link'} placement="top">
                    <IconButton
                      onClick={copyDirectLink}
                      size="md"
                      className="fieldCopyToSettings"
                      componentProps={{
                        'data-value': thumbnailLink,
                        'data-testid': 'iconCopyDirectURL',
                      }}
                    >
                      <Choose>
                        <When condition={copied.directLink}>
                          <CheckIcon className="checkIcon" />
                        </When>
                        <Otherwise>
                          <Copy />
                        </Otherwise>
                      </Choose>
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </If>
          </If>
        </>
      </CSSTransition>
    </div>
  );
};

Share.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  inProgress: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  assetSharingAllowed: PropTypes.bool.isRequired,
  asset: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    fileExtension: PropTypes.string,
    singleSharingSettings: PropTypes.shape({
      isShared: PropTypes.bool,
    }),
    restrictSettings: PropTypes.shape({
      isRestricted: PropTypes.bool,
    }),
  }).isRequired,
  toggleVisibility: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default memo(Share);

import React, {
  memo, useCallback, useMemo, useRef, useState,
} from 'react';
import {
  TreeItem,
  TreeItemActions,
  TreeItemAction,
  TreeMenu,
  TreeMenuItem,
} from '@picsio/ui';
import {
  FolderIcon,
  WebIcon,
  StarBorder,
  Edit,
  NewCollection,
  TrashIcon,
  DownloadIcon,
  Copy,
  Move,
  Archive,
  Sync,
  Dublicate,
  NewAsset,
  CsvImport,
  ThreePointsIcon,
  CheckIcon,
  Warning,
} from '@picsio/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  any, arrayOf, bool, func, shape, string,
} from 'prop-types';
import {
  ASYNC_JOB_STATUS_WAITING,
  ASYNC_JOB_STATUS_RUNNING,
  ASYNC_JOB_STATUS_FAILED,
  ASYNC_JOB_STATUS_COMPLETE,
} from '@picsio/db/src/constants';
import picsioConfig from '../../../../../config';
import Logger from '../../services/Logger';
import {
  getChildren,
  renameCollection,
  addCollection,
  removeCollection,
  addToFavorites,
  syncFolder,
  moveCollection as moveCollectionAction,
  retryImporting,
} from '../../store/actions/collections';
import { showDialog } from '../dialog';
import { decodeSlash, LocalStorage } from '../../shared/utils';
import l10n from '../../shared/strings';
import showDropAssetsOnlyDialog from '../../helpers/showDropAssetsOnlyDialog';
import downloadCollection from '../../helpers/fileDownloader/downloadCollection';
import { navigate } from '../../helpers/history';
import sendEventToIntercom from '../../services/IntercomEventService';
import makeAlias from '../../helpers/makeAlias';
import copyTextToClipboard from '../../helpers/copyTextToClipboard';
import checkUserAccess from '../../store/helpers/user/checkUserAccess';
import archiveCollection from '../../helpers/archiveCollection';
import useImportMenu from '../../helpers/components/hooks/useImportMenu';
import Tooltip from '../Tooltip';
import NumberOfAssets from '../NumberOfAssets';
import renderWebsitePresetsDialog from '../WebsitePresetsDialog/renderWebsitePresetsDialog';
import ua from '../../ua';
import Toast from '../Toast';

const normalizeName = (name) => decodeSlash(name.trim().toLowerCase());
const { isMainApp } = picsioConfig;

const Item = ({
  children = null,
  node,
  isOpen = false,
  isSelected = false,
  isSortable = false,
  isDraggable = false,
  dragData = {},
  nodeId,
  onSelect,
  onToggle,
  path,
  getNeighbors,
  moveCollection,
  duplicateCollection,
  attachAssets,
  sortCollections = null,
  increaseIndentation = false,
  onDragStart = null,
}) => {
  const dispatch = useDispatch();
  const [renameInProgress, setRenameInProgress] = useState(false);
  const [addInProgress, setAddInProgress] = useState(false);
  const [defaultNewCollectionName, setDefaultNewCollectionName] = useState(
    'New collection',
  );
  const [isBusy, setBusy] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [newCollectionName, setNewCollectionName] = useState('');
  const [isCopied, setCopied] = useState(false);
  const uploadRef = useRef();
  const menuRef = useRef();
  const openTimeout = useRef();
  const { toggleMenu: toggleImportMenu } = useImportMenu({
    targetRef: uploadRef,
    placement: 'right',
    uploadCollectionId: node._id,
    initiatedFrom: 'selected collection',
  });

  const userPermissions = useSelector(
    (state) => state.user?.role?.permissions || {},
  );
  const user = useSelector((state) => state.user);
  const websitePresets = useSelector((state) => state.websiteSettings.presets);

  const isWebsitesAllowed = useSelector(
    (state) => state.user?.subscriptionFeatures?.websites,
  );

  const {
    _id,
    name,
    hasChild,
    deletedByTeammate: deleted,
    website,
    websiteId,
    favorites,
    isFetching,
    permissions = {},
    color,
    path: nodePath,
    count,
  } = node;

  const hasWebsite = useMemo(
    () => !!(website || websiteId),
    [websiteId, website],
  );

  const isRoot = useMemo(() => nodePath === 'root', [nodePath]);

  const handleClickUpload = useCallback(
    (event) => {
      if (event) event.stopPropagation();
      setMenuOpen(false);
      toggleImportMenu();
    },
    [setMenuOpen, toggleImportMenu],
  );

  const treeIcon = useMemo(() => {
    if (!isMainApp) return <FolderIcon />;
    if (hasWebsite) return <WebIcon />;
    if (favorites) return <StarBorder />;
    if (node.importing === ASYNC_JOB_STATUS_FAILED) return <Warning />;
    return <FolderIcon />;
  }, [hasWebsite, favorites, node.importing]);

  const handleToggleMenu = useCallback(
    (event) => {
      if (event) event.stopPropagation();
      setMenuOpen((open) => !open);
    },
    [setMenuOpen],
  );

  const handleToggle = useCallback(() => {
    if (hasChild && !children) dispatch(getChildren(nodeId));
    onToggle(nodeId);
  }, [nodeId, hasChild, children, dispatch, onToggle]);

  const handleClickRename = useCallback(() => {
    setRenameInProgress(true);
    Logger.log('User', 'CollectionsPanelRenameCollection', {
      collectionId: _id,
    });
  }, [setRenameInProgress, _id]);

  const handleEditName = useCallback(
    async (value) => {
      const newName = value.trim();
      if (newName !== node.name) {
        setBusy(true);
        await dispatch(renameCollection(node, newName));
        setBusy(false);
      }
      setRenameInProgress(false);
    },
    [dispatch, node],
  );

  const handleEditCancel = useCallback(() => {
    setRenameInProgress(false);
  }, []);

  const makeNewCollectionName = useCallback(() => {
    const { nodes } = node;
    let newName = 'New collection';

    // we can use while(true) or smth like that but 10000
    // prevent infinite cycle and other stuff which can break down browser:)
    // first folder name without number, second has "2", third "3" etc
    if (nodes && nodes.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i < 10000; i++) {
        const testName = i !== 1 ? `${newName} ${i}` : newName;
        if (
          nodes.every(
            (item) => decodeSlash(item.name) !== decodeSlash(testName),
          )
        ) {
          newName = testName;
          break;
        }
      }
    }

    /* if new name is in progress */
    if (newName === newCollectionName) {
      const nameArr = newName.split(' ');
      const lastNumber = Number(nameArr[nameArr.length - 1]);
      if (Number.isNaN(lastNumber)) {
        newName += ' 1';
      } else {
        nameArr[nameArr.length - 1] += 1;
        newName = nameArr.join(' ');
      }
    }

    return newName;
  }, [node, newCollectionName]);

  const handleClickCreate = useCallback(
    (e) => {
      e.stopPropagation();
      setAddInProgress(true);
      setDefaultNewCollectionName(makeNewCollectionName());
      Logger.log('User', 'CollectionsPanelAddSubCollection');
    },
    [setAddInProgress, makeNewCollectionName],
  );

  const handleCreate = useCallback(
    async (_value) => {
      Logger.log('User', 'CollectionsPanelAddCollectionOK');
      const value = _value.trim();
      if (value.length >= 499) {
        showDialog({
          title: l10n.DIALOGS.WARNING_COLLECTION_NAME_LENGTH.TITLE,
          text: l10n.DIALOGS.WARNING_COLLECTION_NAME_LENGTH.TEXT,
          textBtnOk: l10n.DIALOGS.WARNING_COLLECTION_NAME_LENGTH.OK_TEXT,
          textBtnCancel: null,
        });
        setAddInProgress(false);
        return;
      }

      setNewCollectionName(value);
      await dispatch(addCollection(`${node.path}${name}/`, value, _id));
      setNewCollectionName('');

      setAddInProgress(false);
      if (!isOpen) handleToggle();
    },
    [_id, dispatch, name, node.path, isOpen, handleToggle],
  );

  const handleCreateCancel = useCallback(() => {
    setAddInProgress(false);
  }, []);

  /**
   * Validate name
   * @param {string} value
   * @returns {boolean}
   */
  const validateName = useCallback(
    (value) => {
      /** check child names */
      let names = (node.nodes || []).map((n) => normalizeName(n.name));
      /** check neighbors names */
      if (renameInProgress) names = getNeighbors(_id).map((n) => normalizeName(n.name));

      return (
        value && !value.startsWith('.') && !names.includes(normalizeName(value))
      );
    },
    [_id, getNeighbors, node, renameInProgress],
  );

  const handleClickDelete = useCallback(
    (event) => {
      event.stopPropagation();

      Logger.log('User', 'CollectionsPanelDeleteCollection', {
        collectionId: node._id,
      });
      dispatch(removeCollection(node));
    },
    [dispatch, node],
  );

  const handleClickDownload = useCallback(
    (event) => {
      event.stopPropagation();
      downloadCollection(_id, userPermissions);
    },
    [_id, userPermissions],
  );

  const handleClickPublish = useCallback(
    (event) => {
      event.stopPropagation();
      sendEventToIntercom('website settings');
      if (!isWebsitesAllowed) {
        navigate('/billing?tab=overview');
        return;
      }
      const hideWebsitePresetsDialog = LocalStorage.get(
        'picsio.hideWebsitePresetsDialog',
      );
      if (hasWebsite || hideWebsitePresetsDialog || ua.browser.isNotDesktop()) {
        navigate(`/websites/${_id}?tab=main`);
      } else {
        event.stopPropagation();
        renderWebsitePresetsDialog({ collectionId: _id, websitePresets });
      }
      Logger.log('User', 'CollectionsPanelPublishToWeb', { collectionId: _id });
    },
    [isWebsitesAllowed, _id, hasWebsite, websitePresets],
  );

  const handleClickCopyWebsiteUrl = useCallback(
    (event) => {
      event.stopPropagation();

      const url = makeAlias(website?.alias) || '';
      setCopied(true);
      copyTextToClipboard(url);

      Logger.log('User', 'WebsiteDotsMenuCopyLink');
    },
    [website],
  );

  const handleClickFavorites = useCallback(
    (event) => {
      event.stopPropagation();
      dispatch(addToFavorites(_id, name, node.path, !favorites));
    },
    [_id, dispatch, favorites, name, node.path],
  );

  const handleClickMove = useCallback(
    (event) => {
      event.stopPropagation();
      moveCollection(node);
    },
    [moveCollection, node],
  );

  const handleClickArchive = useCallback(
    (event) => {
      event.stopPropagation();
      Logger.log('User', 'CollectionMoveToArchiveClicked', {
        collectionId: node._id,
      });
      archiveCollection(node);
    },
    [node],
  );

  const handleClickSync = useCallback(
    (event) => {
      event.stopPropagation();

      setBusy(true);
      dispatch(syncFolder({ collectionId: _id }));
      setBusy(false);
    },
    [_id, dispatch],
  );

  const handleClickSyncSubtree = useCallback(
    (event) => {
      event.stopPropagation();

      setBusy(true);
      dispatch(syncFolder({ collectionId: _id, strategy: 'subtree' }));
      setBusy(false);
    },
    [_id, dispatch],
  );

  const handleClickDuplicate = useCallback(
    (event) => {
      event.stopPropagation();
      duplicateCollection(node);
    },
    [duplicateCollection, node],
  );

  const handleClickImportCsv = useCallback((event) => {
    event.stopPropagation();
    Logger.log('User', 'CollectionItemsUploadCsv');
    navigate('/csvUpload');
  }, []);

  const handleDragStart = useCallback(
    (...args) => {
      if (onDragStart) onDragStart(...args);
    },
    [onDragStart],
  );

  const handleDragOver = useCallback(
    (e) => {
      if (!isOpen && hasChild && !openTimeout.current) {
        const { currentTarget } = e;
        openTimeout.current = setTimeout(() => {
          if (
            currentTarget
              .closest('.PicsioTreeItem')
              .classList.contains('onDragenterHighlight')
          ) {
            handleToggle();
          }
          openTimeout.current = null;
        }, 1000);
      }

      if (
        isRoot
        && !dragData.id
        && e.currentTarget
          .closest('.PicsioTreeItem')
          .classList.contains('onDragenterHighlight')
      ) {
        e.currentTarget.classList.remove('onDragenterHighlight');
      }
    },
    [handleToggle, hasChild, isOpen, dragData, isRoot],
  );

  const handleDragLeave = useCallback((e) => {
    if (openTimeout.current) clearTimeout(openTimeout.current);
    openTimeout.current = null;
  }, []);

  const handleDrop = useCallback(
    (e, receiverItemId, position, nodeParentIds) => {
      if (!isMainApp) return;

      if (
        dragData.id
        && typeof sortCollections === 'function'
        && position !== 'center'
      ) {
        sortCollections(receiverItemId, position, nodeParentIds);
        return;
      }
      if (
        dragData.id
        && typeof moveCollection === 'function'
        && position === 'center'
      ) {
        if (window.dragElement) {
          dispatch(
            moveCollectionAction(
              dragData.id,
              node._id,
              node.path === 'root' ? '/' : `${node.path}${node.name}/`,
              dragData.path,
              dragData.name,
            ),
          );
        }
        return;
      }

      if (
        !(e.dataTransfer?.getData('text/plain') || '').includes(
          `${window.origin}/preview/`,
        )
      ) {
        showDropAssetsOnlyDialog();
        return;
      }

      attachAssets(e, node, e.dataTransfer?.getData('text/plain'));
    },
    [attachAssets, dragData, node, sortCollections, dispatch, moveCollection],
  );

  const handlePreDrop = useCallback(
    (e, receiverItemId) => {
      if (dragData.id === receiverItemId) {
        Toast(l10n.TOAST.CANNOT_MOVE_COLLECTION_INTO_ITSELF, { type: 'error' });
      }
    },
    [dragData.id],
  );

  const handleRetryImporting = useCallback((e) => {
    e.stopPropagation();
    dispatch(retryImporting(node._id));
  }, [dispatch, node._id]);

  const rootActions = useMemo(
    () => (
      <TreeItemActions>
        <If condition={userPermissions.importCSV && !picsioConfig.isPim}>
          <Choose>
            <When condition={user.subscriptionFeatures?.csvImport}>
              <TreeItemAction
                tooltip={l10n.CSV_IMPORT.textUploadCsv}
                componentProps={{ 'data-testid': 'collectionItemUploadCsv' }}
                onClick={handleClickImportCsv}
              >
                <CsvImport />
              </TreeItemAction>
            </When>
            <Otherwise>
              <Tooltip
                content={l10n.UPGRADE_PLAN.tooltipForButtons()}
                placement="top"
                interactive
                delayHide={500}
              >
                <TreeItemAction
                  componentProps={{ 'data-testid': 'collectionItemUploadCsv' }}
                  disabled
                >
                  <CsvImport />
                </TreeItemAction>
              </Tooltip>
            </Otherwise>
          </Choose>
        </If>
        <If condition={permissions.upload}>
          <Tooltip
            ref={uploadRef}
            content={l10n.TAGSTREE.textUploadFiles}
            placement="top"
          >
            <TreeItemAction
              onClick={handleClickUpload}
              componentProps={{ 'data-testid': 'collectionUpload' }}
            >
              <NewAsset />
            </TreeItemAction>
          </Tooltip>
        </If>
        <If condition={permissions.createCollections}>
          <TreeItemAction
            tooltip={l10n.TAGSTREE.textCreateCollection}
            onClick={handleClickCreate}
            componentProps={{ 'data-testid': 'collectionCreate' }}
          >
            <NewCollection />
          </TreeItemAction>
        </If>
      </TreeItemActions>
    ),
    [
      handleClickCreate,
      handleClickImportCsv,
      permissions,
      handleClickUpload,
      user.subscriptionFeatures?.csvImport,
      userPermissions.importCSV,
    ],
  );

  const actions = useMemo(() => {
    const listActions = [];
    if (!node.importing || node.importing === ASYNC_JOB_STATUS_COMPLETE) {
    /** For website first icon is "Copy to clipboard", otherwise "Download collection" */
      if (hasWebsite) {
        listActions.push({
          text: isCopied ? l10n.DETAILS.textLinkCopied : l10n.TAGSTREE.textCopyWebsiteLink,
          onClick: handleClickCopyWebsiteUrl,
          icon: isCopied ? <CheckIcon className="checkIcon" /> : <Copy />,
          testId: 'collectionCopyUrl',
        });
      } else if (permissions.downloadFiles) {
        listActions.push({
          text: l10n.TAGSTREE.textDownloadCollection,
          onClick: handleClickDownload,
          icon: <DownloadIcon />,
          testId: 'collectionDownload',
        });
      }

      /** Upload */
      if (permissions.upload) {
        listActions.push({
          text: l10n.TAGSTREE.textUploadFiles,
          onClick: handleClickUpload,
          icon: <NewAsset />,
          testId: 'collectionUpload',
          Button: (
            <Tooltip key="upload" ref={uploadRef} content={l10n.TAGSTREE.textUploadFiles} placement="top">
              <TreeItemAction onClick={handleClickUpload} componentProps={{ 'data-testid': 'collectionUpload' }}>
                <NewAsset />
              </TreeItemAction>
            </Tooltip>
          ),
        });
      }

      /** Create collection */
      if (permissions.createCollections) {
        listActions.push({
          text: l10n.TAGSTREE.textCreateNestedCollection,
          onClick: handleClickCreate,
          icon: <NewCollection />,
          testId: 'collectionCreate',
        });
      }

      /** Share */
      if (permissions.websites && !picsioConfig.isPim) {
        listActions.push({
          text: hasWebsite ? l10n.TAGSTREE.textUpdateWebsite : `${l10n.TAGSTREE.textCreateWebsite}${isWebsitesAllowed ? '' : `. ${l10n.UPGRADE_PLAN.tooltip}`}`,
          onClick: handleClickPublish,
          icon: <WebIcon />,
          iconColor: hasWebsite && 'primary',
          testId: 'collectionShare',
        });
      }

      /** Download */
      if (hasWebsite && permissions.downloadFiles) {
        listActions.push({
          text: l10n.TAGSTREE.textDownloadCollection,
          onClick: handleClickDownload,
          icon: <DownloadIcon />,
          testId: 'collectionDownload',
        });
      }

      /** Rename */
      if (permissions.editCollections) {
        listActions.push({
          text: l10n.TAGSTREE.textRenameCollection,
          onClick: handleClickRename,
          icon: <Edit />,
          testId: 'collectionRename',
        });
      }

      /** Favorites without condition */
      listActions.push({
        text: favorites ? l10n.TAGSTREE.textRemoveFromFavorites : l10n.TAGSTREE.textAdToFavorites,
        onClick: handleClickFavorites,
        icon: <StarBorder />,
        testId: favorites ? 'collectionRemoveFromFavorite' : 'collectionAddToFavorite',
      });

      /** Move collection */
      if (permissions.moveCollections) {
        listActions.push({
          text: l10n.TAGSTREE.textMoveCollection,
          onClick: handleClickMove,
          icon: <Move />,
          testId: 'collectionMove',
        });
      }

      /** Archive */
      if (checkUserAccess('subscriptions', 'archive', null, user) && checkUserAccess('permissions', 'manageArchive', null, user)) {
        listActions.push({
          text: l10n.TAGSTREE.textMoveToArchive,
          onClick: handleClickArchive,
          icon: <Archive />,
          testId: 'collectionArchive',
        });
      }

      /** Sync */
      if (
        user.team?.settings?.allowSubtreePartialSync
        && checkUserAccess('permissions', 'sync', null, user)
        && (user.team.storageType === 'gd' || !user.team.picsioStorage)
      ) {
        listActions.push(
          {
            text: l10n.TAGSTREE.textSyncFolder,
            onClick: handleClickSync,
            icon: <Sync />,
            testId: 'collectionFolderSync',
          },
          {
            text: l10n.TAGSTREE.textSyncFolderSubtree,
            onClick: handleClickSyncSubtree,
            icon: <Sync />,
            testId: 'collectionFolderSyncSubtree',
          },
        );
      }

      /** Duplicate */
      listActions.push({
        text: l10n.TAGSTREE.textDuplicateCollection,
        onClick: handleClickDuplicate,
        icon: <Dublicate />,
        testId: 'collectionDuplicate',
      });

      /** Detele */
      if (permissions.deleteCollections) {
        listActions.push({
          text: l10n.TAGSTREE.textDeleteCollection,
          onClick: handleClickDelete,
          icon: <TrashIcon />,
          testId: 'collectionDelete',
        });
      }
    } else if (node.importing === 'failed') {
      /** Retry importing */
      listActions.push({
        text: l10n.TAGSTREE.textRetryImportCollection,
        onClick: handleRetryImporting,
        icon: <Sync />,
        testId: 'collectionRetryImport',
      });

      /** Detele */
      if (permissions.deleteCollections) {
        listActions.push({
          text: l10n.TAGSTREE.textDeleteCollection,
          onClick: handleClickDelete,
          icon: <TrashIcon />,
          testId: 'collectionDelete',
        });
      }
    }

    const buttons = window.innerWidth < 1024 ? [] : listActions.slice(0, 4);
    const menuItems = window.innerWidth < 1024 ? listActions : listActions.slice(4);

    return (
      <TreeItemActions>
        {buttons.map(
          ({
            text, onClick, icon, iconColor, Button, testId,
          }) => Button || (
            <TreeItemAction
              key={text}
              tooltip={text}
              onClick={onClick}
              componentProps={{ 'data-testid': testId }}
              iconColor={iconColor || 'inherit'}
            >
              {icon}
            </TreeItemAction>
          ),
        )}
        <If condition={menuItems.length}>
          <TreeItemAction
            ref={menuRef}
            onClick={handleToggleMenu}
            componentProps={{ 'data-testid': 'menuOpener' }}
          >
            <ThreePointsIcon />
          </TreeItemAction>
          <TreeMenu ref={menuRef} isOpen={menuOpen} onClose={handleToggleMenu}>
            {menuItems.map(({
              text, onClick, icon, testId,
            }) => (
              <TreeMenuItem
                key={text}
                text={text}
                onClick={(...args) => {
                  setMenuOpen(false);
                  onClick(...args);
                }}
                icon={icon}
                data-testid={testId}
              />
            ))}
          </TreeMenu>
        </If>
      </TreeItemActions>
    );
  }, [
    permissions.downloadFiles,
    permissions.upload,
    permissions.createCollections,
    permissions.websites,
    permissions.deleteCollections,
    permissions.editCollections,
    permissions.moveCollections,
    favorites,
    handleClickFavorites,
    user,
    handleClickDuplicate,
    handleToggleMenu,
    menuOpen,
    handleClickCopyWebsiteUrl,
    isCopied,
    handleClickDownload,
    handleClickUpload,
    handleClickCreate,
    isWebsitesAllowed,
    handleClickPublish,
    handleClickDelete,
    handleClickRename,
    handleClickMove,
    handleClickArchive,
    handleClickSync,
    handleClickSyncSubtree,
    handleRetryImporting,
    node.importing,
    hasWebsite,
  ]);

  if (isRoot) {
    /** className added for QA */
    return (
      <TreeItem
        nodeId={_id}
        root
        className="collectionsMy"
        name={decodeSlash(name)}
        level={0}
        open={isOpen}
        icon={null}
        loading={isFetching}
        selected={isSelected}
        onToggle={handleToggle}
        onClick={onSelect}
        actions={isMainApp ? rootActions : null}
        editValidation={validateName}
        adding={addInProgress}
        newName={newCollectionName}
        defaultNewName={defaultNewCollectionName}
        onAdd={handleCreate}
        onAddCancel={handleCreateCancel}
        disabled={isBusy || node.isBusy}
        editable={false}
        iconColor={color || null}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        endIcon={(
          <If condition={isMainApp && !isSelected}>
            <NumberOfAssets>{count}</NumberOfAssets>
          </If>
        )}
      >
        {!children && hasChild ? [] : children}
      </TreeItem>
    );
  }

  return (
    <Tooltip
      content={(
        <Choose>
          <When condition={window.innerWidth >= 1024 && (node.importing === ASYNC_JOB_STATUS_WAITING || node.importing === ASYNC_JOB_STATUS_RUNNING)}>
            Importing in process
          </When>
          <When condition={window.innerWidth >= 1024 && node.importing === ASYNC_JOB_STATUS_FAILED}>
            Collection import failed
          </When>
        </Choose>
      )}
      placement="right"
    >
      <TreeItem
        nodeId={_id}
        name={decodeSlash(name)}
        level={increaseIndentation ? path.length + 1 : path.length}
        open={isOpen}
        icon={treeIcon}
        loading={isFetching}
        selected={isSelected}
        deleted={deleted}
        onToggle={handleToggle}
        onClick={onSelect}
        actions={
          isMainApp && node.importing !== ASYNC_JOB_STATUS_WAITING && !node.readOnly
            ? actions
            : null
        }
        edit={renameInProgress}
        editValidation={validateName}
        onEdit={handleEditName}
        onEditCancel={handleEditCancel}
        adding={addInProgress}
        newName={newCollectionName}
        defaultNewName={defaultNewCollectionName}
        onAdd={handleCreate}
        onAddCancel={handleCreateCancel}
        persistActions={menuOpen}
        disabled={
          isBusy || node.isBusy || node.importing === ASYNC_JOB_STATUS_WAITING || node.importing === ASYNC_JOB_STATUS_RUNNING
        }
        editable={permissions.editCollections}
        iseditModeOnDoubleClick={permissions.editCollections}
        iconColor={
          color
          || (node.importing === ASYNC_JOB_STATUS_FAILED && '#ff6b00')
          || null
        }
        endIcon={count || null}
        isDenyDND={dragData.id && dragData.id === _id}
        canOnlySort={
          !(dragData.id && (!dragData.lvl.includes(_id) || dragData.id === _id))
        }
        isSortable={isSortable}
        isDraggable={isDraggable}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onPreDrop={handlePreDrop}
        parents={path}
        allowOnlyActions={
          node.importing === ASYNC_JOB_STATUS_FAILED
          || node.importing === ASYNC_JOB_STATUS_WAITING
          || node.importing === ASYNC_JOB_STATUS_RUNNING
        }
      >
        {!children && hasChild ? [] : children}
      </TreeItem>
    </Tooltip>
  );
};

Item.propTypes = {
  children: any,
  path: arrayOf(string).isRequired,
  isOpen: bool,
  isSelected: bool,
  isSortable: bool,
  nodeId: string.isRequired,
  onSelect: func.isRequired,
  onToggle: func.isRequired,
  getNeighbors: func.isRequired,
  moveCollection: func.isRequired,
  duplicateCollection: func.isRequired,
  attachAssets: func.isRequired,
  sortCollections: func,
  onDragStart: func,
  dragData: shape({
    lvl: arrayOf(string),
    id: string,
    name: string,
    path: arrayOf(string),
  }),
  node: shape({
    _id: string,
    name: string,
    hasChild: bool,
    deleted: bool,
    website: any,
    favorites: bool,
    isFetching: bool,
  }).isRequired,
  increaseIndentation: bool,
  isDraggable: bool,
};

export default memo(Item);

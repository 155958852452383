import React from 'react';
import { findDOMNode } from 'react-dom';

import store from '../../../../store';
import * as utils from '../../../../shared/utils';

import UserItem from './Item';

const KEY_ENTER = 13;
const KEY_ARR_TOP = 38;
const KEY_ARR_BOTTOM = 40;

class Mentions extends React.Component {
  state = {
    isVisible: false,
    value: null,
    team: store.getState().teammates.items,
    activeIndex: 0,
  };

  componentDidMount() {
    this.$textarea = findDOMNode(this);

    this.$textarea.addEventListener('mousedown', this.onTextareaCursorMoved);
    this.$textarea.addEventListener('mouseup', this.onTextareaCursorMoved);
    this.$textarea.addEventListener('keydown', this.onKeydownTextarea);
    this.$textarea.addEventListener('keyup', this.onTextareaCursorMoved);
    this.$textarea.addEventListener('keyup', this.onKeyupTextarea);
    this.$textarea.addEventListener('blur', this.onBlur);
    this.$textarea.addEventListener('paste', this.onPaste);
  }

  componentWillUnmount() {
    this.$textarea.removeEventListener('mousedown', this.onTextareaCursorMoved);
    this.$textarea.removeEventListener('mouseup', this.onTextareaCursorMoved);
    this.$textarea.removeEventListener('keydown', this.onKeydownTextarea);
    this.$textarea.removeEventListener('keyup', this.onTextareaCursorMoved);
    this.$textarea.removeEventListener('keyup', this.onKeyupTextarea);
    this.$textarea.removeEventListener('blur', this.onBlur);
    this.$textarea.removeEventListener('paste', this.onPaste);
  }

  onPaste = () => {
    setTimeout(() => {
      this.$textarea.innerHTML = utils.sanitizeXSS(this.$textarea.innerHTML, {
        ALLOWED_TAGS: ['mention', 'br', 'img'],
        ALLOWED_ATTR: ['contenteditable', 'src', 'data-stringify', 'id', 'aria-label', 'data-id', 'class'],
      });
    }, 0);
  }

  onBlur = () => {
    this.setValue(null);
  };

  onTextareaCursorMoved = (e) => {
    if (this.state.isVisible && (e.keyCode === KEY_ARR_TOP || e.keyCode === KEY_ARR_BOTTOM || e.keyCode === KEY_ENTER)) { return; }

    const caretPosition = utils.getCaretPositionEditableDivWithTags(this.$textarea);
    const textareaText = this.$textarea.innerHTML.replaceAll('&nbsp;', ' ');
    const indexOfSignAt = textareaText.slice(0, caretPosition).lastIndexOf('@');

    let value = textareaText.slice(indexOfSignAt + 1, caretPosition).toLowerCase();
    /** fix for <br> */
    if (value === '<') value = '';

    if (indexOfSignAt === -1 || value.includes(' ')) {
      this.setValue(null);
    } else {
      this.setValue(value);
    }
  };

  onKeydownTextarea = (e) => {
    const { state } = this;
    if (state.value === null || state.filteredTeam.length === 0) return;

    switch (e.keyCode) {
    case KEY_ARR_TOP: {
      e.preventDefault();
      if (state.activeIndex > 0) {
        this.setState({ activeIndex: state.activeIndex - 1 });
      }
      break;
    }
    case KEY_ARR_BOTTOM: {
      e.preventDefault();
      if (state.activeIndex < state.filteredTeam.length - 1) {
        this.setState({ activeIndex: state.activeIndex + 1 });
      }
      break;
    }
    case KEY_ENTER: {
      e.preventDefault();
      e.stopPropagation();
      this.submit();
    }
    }
  };

  onKeyupTextarea = (e) => {
    if (!e.currentTarget.textContent) {
      // hack to prevent auto adding font tag
      const tNode = document.createTextNode('');
      this.$textarea.appendChild(tNode);

      const range = document.createRange();
      const sel = window.getSelection();
      range.setStart(tNode, 0);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };

  setValue = (value) => {
    const { state } = this;
    if (value === state.value) return;

    let filteredTeam = state.team;
    if (value !== null && value !== '') {
      filteredTeam = state.team.filter(
        (member) => member.email.toLowerCase().includes(value)
          || member.displayName.toLowerCase().includes(value)
          || member.slackUserId.toLowerCase().includes(value),
      );
    }

    this.setState({ value, activeIndex: 0, filteredTeam });
  };

  submit = (_index) => {
    const { state } = this;
    const isDropdownClicked = typeof _index === 'number';

    const index = isDropdownClicked ? _index : state.activeIndex;
    const caretPosition = utils.getCaretPositionEditableDivWithTags(this.$textarea);
    const html = this.$textarea.innerHTML.replaceAll('&nbsp;', ' ');
    const indexOfSignAt = html.slice(0, caretPosition).lastIndexOf('@');
    const userName = state.filteredTeam[index].displayName;
    const userId = state.filteredTeam[index]._id;

    const start = html.slice(0, indexOfSignAt);
    const centerText = html.slice(indexOfSignAt, caretPosition);
    const center = `<mention class="mentionedUserTextarea" contenteditable="false" id="current-mention" data-id="${userId}">@${userName}</mention>&nbsp;`;
    let end = html.slice(caretPosition);
    if (centerText.includes('<')) {
      /** fix for <br> */
      end = `${centerText.slice(centerText.indexOf('<'))}${end}`;
    }

    this.$textarea.innerHTML = (start + center + end);

    this.setValue(null);

    setTimeout(() => {
      if (isDropdownClicked) this.$textarea.focus();
      const range = document.createRange();
      const sel = window.getSelection();

      const currentMention = this.$textarea.querySelector('#current-mention');
      if (currentMention) {
        range.setStart(currentMention.nextSibling || currentMention, 1);
        currentMention.removeAttribute('id');
      }

      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }, 50);
  };

  render() {
    const { state, props } = this;
    return [
      props.children,
      <div key="dropdown" className="mentionTeamMember">
        {state.value !== null
          && state.filteredTeam.map((member, index) => (
            <UserItem
              key={member._id}
              user={member}
              index={index}
              isActive={state.activeIndex === index}
              submit={this.submit}
            />
          ))}
      </div>,
    ];
  }
}

export default Mentions;

import { bool, string, func } from 'prop-types';
import { render, unmountComponentAtNode } from 'react-dom';
import React, { useCallback, useState } from 'react'; // eslint-disable-line
import { Icon, Button } from '@picsio/ui';
import {
  Computer, Link, Cloud, ArrowPrev,
} from '@picsio/icons';
import { useSelector, useDispatch } from 'react-redux';
import l10n from '../../shared/strings';

import AddFromUrlDialog from '../AddFromUrlDialog';
import DropboxChooser from '../DropboxChooser';
import { isUploadStarted } from '../../store/import/selectors';
import { showBusyDialog } from '../Import/helpers';
import { showDialog } from '../dialog';
import Logger from '../../services/Logger';
import showSelectFromTreeDialog from '../../helpers/showSelectFromTreeDialog';
import {
  addFiles, addToSelection,
} from '../../store/import/actions';
import getChildren from '../../store/actions/collections/getChildren';
import picsioConfig from '../../../../../config';

import './styles.scss';

const UploadPlaceholder = ({
  allowExternal = false, allowCloudStorages = false, withTitle = false, collectionId = null, onFilesUpload = null,
}) => {
  const dispatch = useDispatch();
  const activeCollections = useSelector((state) => state?.collections?.activeCollections || null);
  const inProgress = useSelector(isUploadStarted);
  const collectionsStore = useSelector((state) => state.collections);
  const [showSelectStorage, setShowSelectStorage] = useState(false);

  const addFilesAndSelection = useCallback(async (files, isDropped, uploadCollectionId) => {
    const { payload } = await dispatch(addFiles({ items: files, isDropped, uploadCollectionId }));
    if (payload && Array.isArray(payload)) {
      dispatch(addToSelection(payload.filter((item) => !item?.file?.['.tag']).map((item) => item.id)));
    }
  }, [dispatch]);

  const dropboxUser = useSelector(
    (state) => state.user.connectedCloud?.dropbox,
  );

  const handleAdd = useCallback(
    async ({ detail = {}, isDropped = false }) => {
      const { files, uploadCollectionId } = detail;
      if (inProgress) {
        showBusyDialog();
        return;
      }

      if (!uploadCollectionId && !isDropped) {
        Logger.log('UI', 'SelectCollectionDialog');
        showSelectFromTreeDialog({
          title: l10n.DIALOGS.SELECT_TO_IMPORT.TITLE,
          text: l10n.DIALOGS.SELECT_TO_IMPORT.TEXT,
          onOk: async (collectionIds) => {
            Logger.log('User', 'SelectCollectionDialogContinue');
            addFilesAndSelection(files, isDropped, collectionIds[0]._id);
          },
          treeListItems: [collectionsStore?.collections?.my],
          onLoadChildren: (item) => dispatch(getChildren(item._id)),
          textBtnOk: l10n.DIALOGS.SELECT_TO_IMPORT.OK_TEXT,
          textBtnCancel: l10n.DIALOGS.SELECT_TO_IMPORT.CANCEL_TEXT,
          openedItems: [collectionsStore?.collections?.my],
          canCreateCollection: true,
          multipleSelection: false,
          warningText: l10n.COLLECTION_SELECTION_EXCEEDED,
          type: 'upload',
        });
        return;
      }
      addFilesAndSelection(files, isDropped, uploadCollectionId);
    },
    [dispatch, inProgress, addFilesAndSelection, collectionsStore?.collections?.my],
  );

  const handleFilesUpload = useCallback(
    (event) => {
      const { files } = event.target;
      if (files && files.length > 0) {
        handleAdd({ detail: { files, uploadCollectionId: collectionId || null } });
      }
    }, [handleAdd, collectionId],
  );

  const handleLiveSupport = async (e) => {
    const { cloudName } = e.currentTarget.dataset;
    if (window.Intercom) {
      const {
        title, getContent, textBtnOk, getMessage,
      } = l10n.DIALOGS.MIGRATION_ASSISTANCE;
      const children = getContent(cloudName);
      const message = getMessage(cloudName);

      const onOk = async () => {
        Logger.log('User', 'LiveChat');
        await window.Intercom('showNewMessage');
        setTimeout(() => {
          window.Intercom('private:sendMessage', message);
        }, 2000);
      };

      showDialog({
        title, children, textBtnOk, onOk,
      });
    }
    Logger.log('User', `${cloudName}ImportClicked`, { initiatedFrom: `empty ${collectionId ? 'collection' : 'import'} screen` });
  };

  const handleFromUrlClick = useCallback(() => {
    const $div = document.createElement('div');
    document.body.appendChild($div);

    const closeDialog = () => {
      unmountComponentAtNode($div);
      document.body.removeChild($div);
    };

    render(<AddFromUrlDialog onClose={closeDialog} uploadCollectionId={collectionId || null} />, $div);
  }, []);

  const handleSelectStorageClick = useCallback(() => {
    setShowSelectStorage(!showSelectStorage);
  }, [showSelectStorage]);

  const handleOpenDropboxChooser = async () => {
    if (!dropboxUser) {
      const authWindow = window.open(
        `https://www.dropbox.com/oauth2/authorize?client_id=${picsioConfig.dropboxClientId}&redirect_uri=${picsioConfig.dropboxRedirectUri}&response_type=code&token_access_type=offline`,
        '_blank',
      );
      const checkWindowClosed = setInterval(() => {
        if (authWindow.closed) {
          clearInterval(checkWindowClosed);
          window.dispatchEvent(
            new CustomEvent('window:dropbox:auth:closed'),
          );
        }
      }, 1000);
    }
    DropboxChooser({ uploadCollectionId: collectionId || null });
    Logger.log('User', 'DropboxImportClicked', { initiatedFrom: 'upload placeholder' });
  };

  return (
    <div className="importPlaceholder">
      <If condition={!showSelectStorage && withTitle && activeCollections}>
        <div className="placeholderEmptyTitle">
          {l10n.SEARCH.emptyPlaceholder.emptyCollections(
            activeCollections.map(
              (activeCollection) => activeCollection.name,
            ),
          )}
          <br />
          <If
            condition={
              picsioConfig.isMainApp && activeCollections.length === 1
            }
          >
            {l10n.SEARCH.emptyPlaceholder.clickUpload}
          </If>
        </div>
      </If>
      <Choose>
        <When condition={showSelectStorage}>
          <h3 className="importPlaceholderTitle">
            {l10n.IMPORT.titleSelectStorages}
          </h3>
          <div className="innerContainer">
            <div
              className="inner"
              onClick={!picsioConfig.isPim ? handleOpenDropboxChooser : handleLiveSupport}
              role="presentation"
              data-cloud-name="Dropbox"
            >
              <div className="btnIconUpload">
                <Icon>
                  <img
                    src="https://assets.pics.io/img/icons/importers/dropbox.svg"
                    style={{ width: '55px', height: '55px' }}
                    alt="Dropbox logo"
                  />
                </Icon>
              </div>
              <span className="placeholderTitle">{l10n.IMPORT.labelImportFromDropbox}</span>
              <If condition={picsioConfig.isPim}>
                <span className="labelBeta">{l10n.IMPORT.assisted}</span>
              </If>
            </div>
            <div
              className="inner"
              onClick={handleLiveSupport}
              role="presentation"
              data-cloud-name="Google Drive"
            >
              <div className="btnIconUpload">
                <Icon>
                  <img
                    src="https://assets.pics.io/img/icons/importers/google-drive.svg"
                    style={{ width: '55px', height: '50px' }}
                    alt="Google drive logo"
                  />
                </Icon>
              </div>
              <span className="placeholderTitle">{l10n.IMPORT.labelImportFromGD}</span>
              <span className="labelBeta">{l10n.IMPORT.assisted}</span>
            </div>
            <div
              className="inner"
              onClick={handleLiveSupport}
              role="presentation"
              data-cloud-name="OneDrive"
            >
              <div className="btnIconUpload">
                <Icon>
                  <img
                    src="https://assets.pics.io/img/icons/importers/one-drive.svg"
                    style={{ width: '55px', height: '55px' }}
                    alt="One drive logo"
                  />
                </Icon>
              </div>
              <span className="placeholderTitle">{l10n.IMPORT.labelImportFromOneDrive}</span>
              <span className="labelBeta">{l10n.IMPORT.assisted}</span>
            </div>
            <div
              className="inner"
              onClick={handleLiveSupport}
              role="presentation"
              data-cloud-name="Box"
            >
              <div className="btnIconUpload">
                <Icon>
                  <img
                    src="https://assets.pics.io/img/icons/importers/box.svg"
                    style={{ width: '55px', height: '55px' }}
                    alt="Box logo"
                  />
                </Icon>
              </div>
              <span className="placeholderTitle">{l10n.IMPORT.labelImportFromBox}</span>
              <span className="labelBeta">{l10n.IMPORT.assisted}</span>
            </div>
            <div
              className="inner"
              onClick={handleLiveSupport}
              role="presentation"
              data-cloud-name="SharePoint"
            >
              <div className="btnIconUpload">
                <Icon>
                  <img
                    src="https://assets.pics.io/img/icons/importers/share-point.svg"
                    style={{ width: '55px', height: '55px' }}
                    alt="Share point logo"
                  />
                </Icon>
              </div>
              <span className="placeholderTitle">{l10n.IMPORT.labelImportFromSharePoint}</span>
              <span className="labelBeta">{l10n.IMPORT.assisted}</span>
            </div>
            <Button
              variant="contained"
              color="secondary"
              size="md"
              startIcon={<ArrowPrev />}
              disabled={false}
              onClick={handleSelectStorageClick}
              className="importPlaceholder__btnBack"
              componentProps={{ 'data-testid': 'importBackButton' }}
            >
              Back
            </Button>
          </div>
        </When>
        <Otherwise>
          <div className="innerContainer">
            <div className="inner">
              <div className="btnIconUpload">
                <Icon>
                  <Computer />
                </Icon>
              </div>
              <span className="placeholderTitle">
                {l10n.IMPORT.textSelectMedia}
              </span>
              <input type="file" onChange={onFilesUpload || handleFilesUpload} multiple />
            </div>
            <If condition={allowExternal && !picsioConfig.isPim}>
              <div
                className="inner"
                onClick={handleFromUrlClick}
                role="presentation"
              >
                <div className="btnIconUpload">
                  <Icon>
                    {/* eslint-disable-next-line */}
                    <Link />
                  </Icon>
                </div>
                <span className="placeholderTitle">
                  {l10n.IMPORT.textAddExternal}
                </span>
              </div>
            </If>
            <If condition={window.innerWidth >= 1024 && allowCloudStorages}>
              <div
                className="inner"
                onClick={handleSelectStorageClick}
                role="presentation"
              >
                <div className="btnIconUpload">
                  <Icon>
                    <Cloud />
                  </Icon>
                </div>
                <span className="placeholderTitle">
                  {l10n.IMPORT.textAddFromCloudStorages}
                </span>
              </div>
            </If>
          </div>
        </Otherwise>
      </Choose>
    </div>
  );
};

UploadPlaceholder.propTypes = {
  withTitle: bool,
  allowExternal: bool,
  allowCloudStorages: bool,
  collectionId: string,
  onFilesUpload: func,
};

export default UploadPlaceholder;

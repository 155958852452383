import React, {
  useCallback, useRef, useState, useMemo, memo,
} from 'react';
import {
  arrayOf, bool, element, func, oneOfType, shape, string,
} from 'prop-types';
import {
  Menu, IconButton, MenuItem, Icon, MenuItemText,
} from '@picsio/ui';
import { DotsVertical } from '@picsio/icons';
import Item from './Item';

import styles from './styles.module.scss';
import Logger from '../../services/Logger';

const TagsGroup = ({
  Icon: TitleIcon,
  title,
  items,
  selected,
  onRemove,
  onSelect,
  onDeSelect,
  onClick,
  showNumbers,
  onClickNumber,
  selectedAssetsIds,
  testId,
}) => {
  const ref = useRef();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = useCallback(() => {
    setMenuOpen((prev) => !prev);
  }, [setMenuOpen]);

  const handleRemoveSingle = useCallback((keyword) => {
    Logger.log('User', 'KeywordsRemoveSingle', {
      source: title,
      keyword: `${keyword._id} | ${keyword.title}`,
      assetCount: selectedAssetsIds.length,
      assetId: selectedAssetsIds.join(', '),
    });
    onRemove(keyword, false);
  }, [onRemove, selectedAssetsIds, title]);

  const menuItems = useMemo(() => {
    const result = [];
    if (onSelect) {
      result.push({
        id: 'Select-all',
        text: 'Select all',
        handleClick: () => {
          Logger.log('User', 'KeywordsSelect', {
            source: title,
            keywordCount: items.length,
            keyword: items.map(({ _id, title: t }) => `${_id} | ${t}`).join(' ; '),
            assetCount: selectedAssetsIds.length,
            assetId: selectedAssetsIds.length === 1 ? selectedAssetsIds[0] : undefined,
          });
          onSelect(items.map(({ _id }) => _id));
          toggleMenu();
        },
      });
    }
    if (onRemove) {
      result.push({
        id: 'Remove-all',
        text: 'Remove all',
        handleClick: () => {
          Logger.log('User', 'KeywordsRemoveAll', {
            source: title,
            keywordCount: items.length,
            assetCount: selectedAssetsIds.length,
            assetId: selectedAssetsIds.length === 1 ? selectedAssetsIds[0] : undefined,
          });
          onRemove(items.map(({ _id }) => _id), false);
          toggleMenu();
        },
      });
    }
    return result;
  }, [items, selectedAssetsIds, onRemove, onSelect, title, toggleMenu]);

  const stylesAndTestId = useMemo(() => {
    const result = { className: styles.tagsGroup };
    if (typeof testId === 'string') result['data-testid'] = testId.replaceAll(' ', '-');
    return result;
  }, [testId]);

  return (
    <div {...stylesAndTestId}>
      <If condition={title}>
        <div className={styles.groupTitle} data-testid="groupTitle">
          <If condition={TitleIcon}>
            <Icon><TitleIcon /></Icon>
          </If>
          <span className={styles.text}>
            {title}
          </span>
          <IconButton
            ref={ref}
            buttonSize="default"
            onClick={toggleMenu}
            size="lg"
          >
            <DotsVertical />
          </IconButton>
          <If condition={menuItems.length}>
            <Menu
              target={ref}
              arrow
              padding="s"
              isOpen={menuOpen}
              onClose={toggleMenu}
              outsideClickListener
              disablePortal
            >
              {menuItems.map(({ id, text, handleClick }) => (
                <MenuItem
                  id={id}
                  key={id}
                  onClick={handleClick}
                  className="menuItemDefault"
                >
                  <MenuItemText>{text}</MenuItemText>
                </MenuItem>
              ))}
            </Menu>
          </If>
        </div>
      </If>
      <div className={styles.list} data-testid="groupList">
        {items.map((item) => (
          <Item
            key={item._id}
            isSelected={selected.includes(item._id)}
            onSelect={onSelect}
            onDeSelect={onDeSelect}
            onClick={onClick}
            onRemove={handleRemoveSingle}
            onClickNumber={onClickNumber}
            tooltipText="Cmd+Click for multiple selection"
            showNumbers={showNumbers}
            maxNumber={selectedAssetsIds.length}
            {...item}
          />
        ))}
      </div>
    </div>
  );
};

TagsGroup.defaultProps = {
  Icon: null,
  title: '',
  items: [],
  selected: [],
  onRemove: null,
  onSelect: null,
  onDeSelect: null,
  onClick: null,
  onClickNumber: null,
  showNumbers: true,
  selectedAssetsIds: [],
  testId: 'Tags Group',
};

TagsGroup.propTypes = {
  Icon: oneOfType([func, element]),
  title: string,
  onRemove: func,
  onSelect: func,
  onDeSelect: func,
  onClick: func,
  onClickNumber: func,
  selected: arrayOf(string),
  items: arrayOf(shape({
    _id: string,
    name: string,
  })),
  showNumbers: bool,
  selectedAssetsIds: arrayOf(string),
  testId: string,
};

export default memo(TagsGroup);

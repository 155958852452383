/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useEffect, useCallback, useRef,
} from 'react';
import cn from 'classnames';
import { ArrowLeftSmall, ArrowRightSmall, Dragndrop } from '@picsio/icons';
import { Icon } from '@picsio/ui';
import Tooltip from '../Tooltip';
import l18n from '../../shared/strings';

const THRESHOLD = 5;

const Resizer = ({
  onDrag,
  onClick,
  isTreeResizer = true,
  onMouseUp,
  isResizing = false,
  onButtonMouseEnter,
  onButtonMouseLeave
}) => {
  const startPosRef = useRef(null);

  const handleMouseUp = useCallback((e) => {
    if (e.type !== 'tree:ui:resize') {
      onClick();
    } else if (onMouseUp) {
      onMouseUp();
    }
    startPosRef.current = null;
  }, [onClick, onMouseUp]);

  useEffect(() => {
    window.addEventListener('tree:ui:resize', handleMouseUp);
    return () => {
      document.removeEventListener('tree:ui:resize', handleMouseUp);
    };
  }, [handleMouseUp]);

  const handleMouseDown = useCallback((e) => {
    startPosRef.current = { x: e.clientX, y: e.clientY };
  }, []);

  const handleMouseMove = useCallback((e) => {
    if (!startPosRef.current || e.buttons === 0) return;

    // Calculate the distance moved
    const dx = Math.abs(e.clientX - startPosRef.current.x);
    const dy = Math.abs(e.clientY - startPosRef.current.y);

    if (dx > THRESHOLD || dy > THRESHOLD) {
      onDrag(e);
    }
  }, [onDrag]);

  return (
    <Tooltip content={l18n.RESIZER.hideOrResize} placement="top">
      <div
        className={isTreeResizer ? 'treeResizerNew' : 'detailsResizer'}
      >
        <div
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          className={cn('resizer', { isResizing })}
          onMouseEnter={onButtonMouseEnter}
          onMouseLeave={onButtonMouseLeave}
        >
          <Icon>
            <Choose>
              <When condition={isTreeResizer}>
                <ArrowLeftSmall />
              </When>
              <Otherwise>
                <ArrowRightSmall />
              </Otherwise>
            </Choose>
          </Icon>
          <Icon size="sm">
            <Dragndrop />
          </Icon>
        </div>
      </div>
    </Tooltip>
  );
};
export default Resizer;

import React, { useCallback } from 'react';
import {
  shape, string, number, bool, func,
} from 'prop-types';
import cn from 'classnames';
import { Avatar } from '@picsio/ui';

const Item = ({
  user, index, isActive, submit,
}) => {
  const handleMouseDown = useCallback(
    () => submit(index),
    [index, submit],
  );

  return (
    <div
      className={cn('mentionTeamMember__item', { act: isActive })}
      onMouseDown={handleMouseDown}
      tabIndex={0}
      role="button"
    >
      <Avatar src={user.avatar} alt={user.name} size={30} />
      <div className="mentionTeamMember__item__text">
        <span className="mentionTeamMember__item__text__name">{user.displayName}</span>
        <span className="mentionTeamMember__item__text__email">{user.email}</span>
      </div>
    </div>
  );
};

Item.propTypes = {
  user: shape({
    avatar: string,
    name: string,
    displayName: string,
    email: string,
  }).isRequired,
  index: number.isRequired,
  isActive: bool.isRequired,
  submit: func.isRequired,
};

export default Item;

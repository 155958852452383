import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { AiEngine } from '@picsio/icons';
import {
  IconButton, Menu, MenuItem, MenuItemIcon, MenuItemText,
} from '@picsio/ui';
import SvgCollapseArrowDown from '@picsio/icons/dist/CollapseArrowDown';

import localization from '../../../../shared/strings';
import Tooltip from '../../../Tooltip/Tooltip';
import Logger from '../../../../services/Logger';
import styles from './styles.module.scss';

const AiIcon = (props) => {
  const {
    className, disabled, size, inProgress, tooltipText, placement, isMenuAvailable, menuList, onSubmit,
    ...restProps
  } = props;
  const ref = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onToggleMenu = useCallback(() => {
    if (disabled) return;

    Logger.log('User', 'ToggleToolbarActionsAiIconMenu');
    setIsMenuOpen((prevValue) => !prevValue);
  }, [disabled]);

  const onClick = (e) => {
    e.stopPropagation();

    onSubmit();
  };

  const componentProps = useMemo(
    () => (restProps['data-testid'] ? { 'data-testid': restProps['data-testid'] } : {}),
    [restProps],
  );

  const tooltipContent = inProgress ? localization.TOOLBAR_ACTION_AI.PROCESSING_WITH_AI : tooltipText;

  return (
    <div ref={ref} className={cn(className, styles.aiIcon)}>
      <Tooltip content={tooltipContent} placement={placement}>
        <IconButton
          className={styles.sparks}
          size={size}
          disabled={inProgress || disabled}
          componentProps={componentProps}
          onClick={onClick}
        >
          <AiEngine />
        </IconButton>
      </Tooltip>
      <If condition={isMenuAvailable}>
        <Menu
          className={styles.aiIconMenu}
          outsideClickListener
          target={ref}
          padding="m"
          placement="top"
          isOpen={isMenuOpen}
          onClose={onToggleMenu}
        >
          {menuList.map((listItem) => (
            <Tooltip
              key={listItem.id}
              content={listItem.inProgress ? localization.TOOLBAR_ACTION_AI.PROCESSING_WITH_AI : listItem.tooltipText}
              placement={listItem.placement}
            >
              <MenuItem
                disabled={listItem.disabled}
                onClick={listItem.onClick}
                className="menuItemDefault centered"
              >
                <MenuItemIcon size="lg">
                  {listItem.icon}
                </MenuItemIcon>

                <MenuItemText primary={listItem.text} />

              </MenuItem>
            </Tooltip>
          ))}
        </Menu>
        <Tooltip content={localization.TOOLBAR_ACTION_AI.ARROW_ICON} placement="top">
          <IconButton
            size="xl"
            disabled={disabled}
            className="toolbarButton"
            onClick={onToggleMenu}
          >
            <SvgCollapseArrowDown className={cn('openArrow', { active: isMenuOpen })} />
          </IconButton>
        </Tooltip>
      </If>
    </div>
  );
};

AiIcon.defaultProps = {
  className: '',
  inProgress: false,
  tooltipText: '',
  disabled: false,
  placement: 'left',
  size: 'md',
  isMenuAvailable: false,
  menuList: [],
  'data-testid': null,
  onSubmit: Function.prototype,
};

AiIcon.propTypes = {
  className: PropTypes.string,
  inProgress: PropTypes.bool,
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  'data-testid': PropTypes.string,
  placement: PropTypes.string,
  size: PropTypes.string,
  menuList: PropTypes.arrayOf(PropTypes.object),
  isMenuAvailable: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default AiIcon;

import React from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import styled from 'styled-components';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import cn from 'classnames';
import { IconButton } from '@picsio/ui';
import { ArrowLeftSmall, ArrowNext } from '@picsio/icons';
import ErrorBoundary from '../ErrorBoundary';
import localization from '../../shared/strings';
import picsioConfig from '../../../../../config';
import Logger from '../../services/Logger';
import * as utils from '../../shared/utils';
import BestMatchSearchHint from '../BestMatchSearchHint';
import NotificationPanel from '../notificationPanel';
import calculateGrid from './helpers/grid';
import getRange from './helpers/getRange';
import CatalogItem from '../CatalogItem';
import Spinner from './Spinner';
import PullToRefresh from '../PullToRefresh';
// import Placeholder from '../import/Placeholder';
import PlaceholderEmpty from './PlaceholderEmpty';
import PlaceholderNotAllowedCollections from './PlaceholderNotAllowedCollections';
import Tooltip from '../Tooltip';
import {
  setSearchRoute,
  navigateToRoot,
  isRouteFiltering,
  isRouteSearch,
} from '../../helpers/history';
import isActiveCollectionChanged from './helpers/isActiveCollectionChanged';
import MediaMatchInfoBlock from './MediaMatchInfoBlock';
import PlaceholderMediaMatch from './PlaceholderMediaMatch';
import ua from '../../ua';

const {
  collectionUpdated,
  inboxUpdated,
} = localization.CATALOG_VIEW.notifications;

class CatalogView extends React.Component {
  lastScrollTop = 0;

  recursiveSearchPanelHeight = 0;

  searchHintPanelHeight = 0;

  notificationPanelHeight = 0;

  memoizedCalculateGrid = memoize(calculateGrid);

  memoizedGetRange = memoize(getRange);

  $innerCatalog = React.createRef();

  $wrapperTiles = React.createRef();

  $iframe = React.createRef();

  recursiveSearchPanel = React.createRef();

  searchHintPanel = React.createRef();

  notificationPanel = React.createRef();

  state = {
    wrapperHeight: 0,
    startVisibleIndex: 0,
    visibleItems: [],
    itemsStyles: [],
    changedTags: [],
    searchQuery: {},

    recursiveSearchPanelCollapse: false,
    searchHintPanelCollapse: false,
    searchHintPanelIsVisible: false,
    searchHintManuallyClosed: false,
    isNotificationsReceive: false,
    notificationText: '',
    notificationIcon: 'sync',

    activeCollectionIds: null,
    activeInboxID: null,
  };

  componentDidMount() {
    const { props } = this;
    window.addEventListener('catalog:scrollToTop', this.scrollToTop, false);
    window.addEventListener('images:dropped', this.delayedGetGrid, false);
    window.addEventListener('images:imported', this.delayedGetGrid, false);
    this.$innerCatalog.current.addEventListener('scroll', this.scrollListener);
    this.$iframe.current.contentWindow.addEventListener('resize', this.delayedGetGrid);
    if (this.props.searchQuery) this.handleChangeSearchRoute(this.props.searchQuery);
    if (isRouteSearch()) props.assetsActions.getAssets(true);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { archived } = nextProps.searchQuery;

    if (
      isRouteSearch()
      && !_isEmpty(nextProps.searchQuery)
      && !_isEqual(nextProps.searchQuery, prevState.searchQuery)
    ) {
      return {
        searchQuery: nextProps.searchQuery,
      };
    }

    if (nextProps.suggestedSort?.type === 'score' && !prevState.searchHintManuallyClosed) {
      return {
        searchHintPanelCollapse: false,
        searchHintPanelIsVisible: true,
      };
    }

    if (nextProps.suggestedSort?.type !== 'score') {
      return {
        searchHintPanelIsVisible: false,
      };
    }

    if (
      JSON.stringify(nextProps.changedTags) !== JSON.stringify(prevState.changedTags)
      && (isActiveCollectionChanged(nextProps.changedTags, prevState.activeCollectionIds)
        || nextProps.changedTags.includes(prevState.activeInboxID))
    ) {
      return {
        changedTags: nextProps.changedTags,
        isNotificationsReceive: true,
        notificationsPanelCollapse: false,
        notificationText: prevState.activeCollectionIds ? collectionUpdated : inboxUpdated,
        notificationIcon: 'sync',
      };
    }

    if (
      (!prevState.isNotificationsReceive
        && !_isEqual(nextProps.activeCollectionIds?.sort(), prevState.activeCollectionIds?.sort()))
      || nextProps.activeInboxID !== prevState.activeInboxID
    ) {
      return {
        activeCollectionIds: nextProps.activeCollectionIds,
        activeInboxID: nextProps.activeInboxID,
        recursiveSearchPanelCollapse: false,
        isNotificationsReceive: false,
      };
    }

    if (archived && nextProps.changedTags.includes(nextProps.activeArchivedCollectionId)) {
      this.setState({
        isNotificationsReceive: true,
        notificationsPanelCollapse: false,
        notificationText: collectionUpdated,
        notificationIcon: 'sync',
      });
    }

    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { props, state } = this;
    // this case for archive assets
    if (state.visibleItems.length > nextState.visibleItems.length) {
      return true;
    }
    // this case for delete assets
    if (state.visibleItems.length > props.assets.length) {
      return null;
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    const { state, props } = this;
    if (
      isRouteSearch()
      // TODO last part of condition was added to avoid disappearing of assets from the screen after switching from website tab and scrolling
      && !_isEmpty(props.searchQuery)
      && !_isEqual(props.searchQuery, prevState.searchQuery)
      && !(props.searchQuery?.tab || prevState.searchQuery?.tab)
    ) {
      this.handleChangeSearchRoute(props.searchQuery);
      return;
    }

    if (
      prevProps.catalogViewMode !== props.catalogViewMode
      || prevProps.assets.length !== props.assets.length
      || prevProps.catalogViewItemSize !== props.catalogViewItemSize
    ) {
      if (props.assets.length > 0) {
        return this.getGrid();
      }
    }

    if (prevProps.assets !== props.assets) {
      this.findVisibleItems();
    }
    if (
      this.lastScrollTop === 0
      && /** items not scrolled */ prevState.visibleItems.length !== state.visibleItems.length
      && /** received new assets */ state.visibleItems.length === props.assets.length
      && /** all assets is visible */ props.tmpItemIDsLength !== props.assets.length
      && /** if assets in store is not a temporary */ !props.full
      && /** next page exists */ props.isLoaded /** next page is not loading */
    ) {
      /** Load next page */

      props.assetsActions.getAssets();
    }

    return null;
  }

  componentWillUnmount() {
    window.removeEventListener('catalog:scrollToTop', this.scrollToTop, false);
    window.removeEventListener('images:dropped', this.delayedGetGrid, false);
    window.removeEventListener('images:imported', this.delayedGetGrid, false);
    this.$innerCatalog.current.removeEventListener('scroll', this.scrollListener);
    this.$iframe.current.contentWindow.removeEventListener('resize', this.delayedGetGrid);
  }

  scrollListener = () => {
    const { isLoaded, full, assetsActions } = this.props;
    this.findVisibleItems();
    const { $innerCatalog, state } = this;

    const translateY = 0;
    // Scroll up and down
    const panelHeight = 40;
    const scrollTop = $innerCatalog.current.scrollTop - translateY;
    const windowHeight = window.innerHeight;
    const wrapperTilesHeight = this.$wrapperTiles.current && this.$wrapperTiles.current.clientHeight;
    this.setPanelState(
      scrollTop,
      this.lastScrollTop,
      windowHeight,
      wrapperTilesHeight,
      panelHeight,
    );

    this.lastScrollTop = scrollTop;

    if (!isLoaded || state.wrapperHeight === 0) return;

    if (!full) {
      if (scrollTop >= state.wrapperHeight - window.innerHeight * 2) {
        assetsActions.getAssets();
      }
    }
  };

  setPanelState = (
    scrollTop,
    lastScrollTop,
    windowHeight,
    wrapperTilesHeight,
    panelHeight,
  ) => {
    if (
      (this.recursiveSearchPanel && this.recursiveSearchPanel.current)
      || (this.notificationPanel && this.notificationPanel.current)
      || (this.searchHintPanel && this.searchHintPanel.current)
    ) {
      // Scroll Down
      if (scrollTop > lastScrollTop && scrollTop > panelHeight) {
        if (this.state.recursiveSearchPanelCollapse === false) {
          this.setState({
            recursiveSearchPanelCollapse: true,
            notificationsPanelCollapse: true,
            searchHintPanelCollapse: true,
          });
        }
      }
      // Scroll Up
      if (scrollTop + windowHeight < wrapperTilesHeight) {
        if (this.state.recursiveSearchPanelCollapse === true) {
          this.setState({
            recursiveSearchPanelCollapse: false,
            notificationsPanelCollapse: false,
            searchHintPanelCollapse: false,
          });
        }
      }
    }
  };

  handleChangeSearchRoute = (search) => {
    const catalogViewMode = utils.LocalStorage.get('picsio.catalogViewMode') || 'grid';
    if (catalogViewMode !== 'geo') {
      const { bbox, zoom } = search;
      if (bbox && zoom) {
        this.refreshCatalog();
      }

      /** reset view */
      this.setState({
        wrapperHeight: 0,
        startVisibleIndex: 0,
        visibleItems: [],
        itemsStyles: [],
        changedTags: [],
        isNotificationsReceive: false,
        searchHintPanelIsVisible: false,
        searchHintManuallyClosed: false,
      });
    }
  };

  getGrid = () => {
    const { wrapperHeight, itemsStyles } = this.memoizedCalculateGrid(
      this.props.assets,
      this.$wrapperTiles.current?.offsetWidth,
      this.props.catalogViewMode === 'list',
      this.props.catalogViewItemSize || 1,
    );

    if (this.recursiveSearchPanel.current) {
      this.recursiveSearchPanelHeight = this.recursiveSearchPanel.current.clientHeight;
    }

    if (this.searchHintPanel.current) {
      this.searchHintPanelHeight = this.searchHintPanel.current.clientHeight;
    }

    if (this.notificationPanel.current) {
      this.notificationPanelHeight = this.notificationPanel.current.clientHeight;
    }

    this.setState({ wrapperHeight, itemsStyles }, this.findVisibleItems);
  };

  // eslint-disable-next-line react/sort-comp
  delayedGetGrid = setTimeout.bind(window, this.getGrid, 10);

  scrollToTop = () => {
    this.$innerCatalog.current.scrollTop = 0;
  };

  updateVisibleItems = (range, assets) => {
    const visibleItems = assets.slice(range[0], range[1] + 1);
    this.setState({ visibleItems, startVisibleIndex: range[0] });
  };

  findVisibleItems = () => {
    const { itemsStyles = [] } = this.state;
    const { scrollTop } = this.$innerCatalog.current;
    const topLimit = scrollTop - window.innerHeight * 2;
    const bottomLimit = scrollTop + window.innerHeight * 2;

    const range = this.memoizedGetRange(itemsStyles, topLimit, bottomLimit);
    this.updateVisibleItems(range, this.props.assets);
  };

  goToRoot = () => {
    navigateToRoot();
    this.props.mainActions.changeTree('collections', picsioConfig.isProofing);
  };

  onChangeUpload = (event) => {
    Logger.log('User', 'UploadFilesInEmptyCollectionClicked');
    const { files } = event.target;
    if (files && files.length > 0) {
      window.dispatchEvent(new CustomEvent('importPanel:add', { detail: { files } }));
      event.target.value = '';
    }
  };

  refreshCatalog = () => {
    const { searchQuery } = this.props;
    this.setState({
      isNotificationsReceive: false,
    });
    if (searchQuery.bbox && searchQuery.zoom) {
      delete searchQuery.bbox;
      delete searchQuery.zoom;
      setSearchRoute(searchQuery); // @TODO: fix with keywords and etc.
      this.props.mainActions.setMapViewport(null);
      return;
    }
    this.props.notificationsActions.clearChangedTagsIds();
    this.props.assetsActions.getAssets(true);
  };

  notificationPanelClose = () => {
    this.setState({
      isNotificationsReceive: false,
    });
  };

  handleRefresh = async () => {
    Logger.log('User', 'CatalogPullRefresh');
    this.props.assetsActions.getAssets(true);
  };

  expandRecursivePanel = () => {
    this.setState({ recursiveSearchPanelCollapse: false });
  };

  searchHintPanelClose = () => {
    Logger.log('User', 'BestMatchSearchHintClosed');
    this.setState({
      searchHintPanelIsVisible: false,
      searchHintManuallyClosed: true,
    });
    const searchHintCloseCounter = utils.LocalStorage.get('picsio.searchHintCloseCounter') || 0;
    utils.LocalStorage.set('picsio.searchHintCloseCounter', searchHintCloseCounter + 1);
  }

  getMessage = () => {
    let message;
    const { props } = this;
    if (props.isLoaded && props.assets.length === 0) {
      if (picsioConfig.isProofing) {
        if (isRouteFiltering()) {
          message = localization.NO_PHOTO_IN_SEARCH;
        } else if (props.searchQuery.collectionIds) {
          message = localization.NO_PHOTO_IN_PROOFING_COLLECTION;
        } else {
          message = localization.NO_PHOTO_IN_PROOFING_TEMPLATE;
        }
      } else if (isRouteFiltering()) {
        message = localization.NO_PHOTO_IN_SEARCH;
      } else if (!props.isAllowedUpload) {
        message = localization.NO_PHOTO_IN_COLLECTION_WITHOUT_UPLOAD;
      } else if (props.searchQuery.lightboardId) {
        message = localization.NO_PHOTO_IN_LIGHTBOARD;
      } else if (props.notRecursiveSearch && props.activeCollectionHasChild) {
        message = localization.NO_PHOTO_IN_COLLECTION_RECURSIVE;
      } else {
        message = localization.NO_PHOTO_IN_COLLECTION;
      }
    }
    return message;
  };

  applyBestMatchSort = () => {
    const { props } = this;
    if (props?.searchQuery?.lightboardId) {
      props.lightboardsActions.updateSortType(props.searchQuery.lightboardId, { type: 'score' });
    } else {
      props.collectionsActions.setSortType(props.activeCollectionIds, { type: 'score' });
    }
    Logger.log('User', 'ApplyBestMatchSearchClicked');
  }

  openDetails = () => {
    const { props } = this;
    props.mainActions.openDetails();
    Logger.log('User', 'InfoPanelShow', { screenSize: { width: `${window.screen.width}px`, height: `${window.screen.height}px` } });
  }

  openTree = () => {
    this.props.mainActions.changeTree('collections');
  }

  render() {
    const { state, props } = this;
    const {
      assets,
      catalogViewMode,
      catalogViewItemSize,
      assetsActions,
      isLoaded,
      full,
      uiBlocked = false,
      user,
      isAllowedUpload,
      isMobileView = false,
      searchQuery,
      pathname,
      activeCollectionSortType,
      isMediaMatch,
      isDetailsOpen,
      openedTree,
    } = props;
    const { collectionIds, lightboardId } = searchQuery;
    const { recursiveSearchPanelCollapse, searchHintPanelCollapse } = state;
    const isRecursiveSearchPanelVisible = Boolean(
      this.recursiveSearchPanel && this.recursiveSearchPanel.current,
    );
    const isNotificationPanelVisible = Boolean(
      this.notificationPanel && this.notificationPanel.current,
    );
    const isSearchHintPanelVisible = Boolean(
      this.searchHintPanel && this.searchHintPanel.current,
    );
    const stylesNotifications = state.notificationsPanelCollapse && isNotificationPanelVisible
      ? { transform: `translate3d(0, ${-this.notificationPanelHeight + 3}px, 0)` }
      : {};

    const stylesSearchHint = state.searchHintPanelCollapse && isSearchHintPanelVisible
      ? { transform: `translate3d(0, ${-this.notificationPanelHeight + 3}px, 0)` }
      : {};

    const isLightboardsView = !!lightboardId;
    const size = catalogViewItemSize && catalogViewItemSize.toString().replace('.', '_');
    const searchHintCloseCounter = utils.LocalStorage.get('picsio.searchHintCloseCounter') || 0;

    return (
      <PullToRefresh
        onRefresh={this.handleRefresh}
        spinnerSize={30}
        spinnerColor="var(--secondary-contrast-color)"
        refreshDuration={0}
        shouldPullToRefresh={() => this.$innerCatalog.current.scrollTop <= 0}
        disabled={window.innerWidth > 1024}
        childrenWrapperClassName="pullToRefreshWrapper"
      >
        <If condition={!openedTree && picsioConfig.isProofing && picsioConfig?.access?.tagsTreeShow && !ua.browser.isNotDesktop()}>
          <Tooltip content={localization.RESIZER.showPanel} placement="right">
            <div className="treeOpener">
              <IconButton buttonSize="sm" onClick={this.openTree}>
                <ArrowNext />
              </IconButton>
            </div>
          </Tooltip>
        </If>
        <If condition={!isDetailsOpen}>
          <Tooltip content={localization.RESIZER.showPanel} placement="right">
            <div className="detailsOpener">
              <IconButton buttonSize="sm" onClick={this.openDetails}>
                <ArrowLeftSmall />
              </IconButton>
            </div>
          </Tooltip>
        </If>
        <>
          {/* <If
            condition={
              picsioConfig.isMainApp
              && searchQuery.trashed !== 'true'
              && !state.isNotificationsReceive
              && (props.activeCollectionIds.length || state.isNotificationsReceive)
            }
          >
            <RecursiveSearchPanel
              customRef={this.recursiveSearchPanel}
              activeCollectionHasChild={props.activeCollectionHasChild}
              notRecursiveSearch={props.notRecursiveSearch}
              recursiveSearchToggle={props.collectionsActions.recursiveSearchToggle}
              ids={props.activeCollectionIds}
              expand={this.expandRecursivePanel}
              recursiveSearchPanelCollapse={recursiveSearchPanelCollapse}
              isRecursiveSearchPanelVisible={isRecursiveSearchPanelVisible}
              recursiveSearchPanelHeight={this.recursiveSearchPanelHeight}
            />
          </If> */}
          <If condition={picsioConfig.isMainApp && state.searchHintPanelIsVisible && searchHintCloseCounter < 3}>
            <BestMatchSearchHint
              ref={this.searchHintPanel}
              style={stylesSearchHint}
              text={localization.BEST_MATCH_SEARCH.infoText}
              onClose={this.searchHintPanelClose}
              onClick={this.applyBestMatchSort}
            />
          </If>
          <If condition={state.isNotificationsReceive}>
            <NotificationPanel
              customRef={this.notificationPanel}
              panelHeight={this.notificationPanelHeight}
              refresh={this.refreshCatalog}
              text={state.notificationText}
              icon={state.notificationIcon}
              styles={stylesNotifications}
              close={this.notificationPanelClose}
            />
          </If>

          <InnerCatalog
            ref={this.$innerCatalog}
            className={cn('innerCatalog', isMediaMatch && 'isMediaMatch')}
            $isNotificationsReceive={state.isNotificationsReceive}
            $notificationsPanelCollapse={state.notificationsPanelCollapse}
            $isNotificationPanelVisible={isNotificationPanelVisible}
            $notificationPanelHeight={this.notificationPanelHeight}
            $recursiveSearchPanelCollapse={recursiveSearchPanelCollapse}
            $isRecursiveSearchPanelVisible={isRecursiveSearchPanelVisible}
            $recursiveSearchPanelHeight={this.recursiveSearchPanelHeight}
            $searchHintPanelHeight={this.searchHintPanelHeight}
          >
            <If condition={isLoaded && isMediaMatch}>
              <MediaMatchInfoBlock />
            </If>
            <div ref={this.$wrapperTiles} className="wrapperTiles">
              <Tiles
                id="tiles"
                className={cn(
                  `catalogView_${catalogViewMode}`,
                  catalogViewItemSize && `catalogViewSize_${size}`,
                )}
                $visibleItemsLength={state.visibleItems.length}
                $wrapperHeight={state.wrapperHeight}
              >
                <iframe
                  className="tilesIframe"
                  title="resizer"
                  tabIndex="-1"
                  frameBorder="0"
                  ref={this.$iframe}
                />
                <Choose>
                  <When condition={isLoaded && assets.length === 0}>
                    <>
                      <Choose>
                        <When
                          condition={
                            picsioConfig.isMainApp
                            && collectionIds
                            && isMediaMatch
                          }
                        >
                          <PlaceholderMediaMatch />
                        </When>
                        <When
                          condition={
                            picsioConfig.isMainApp
                            && !user.isAllowedCollections
                            && collectionIds
                            && !isMediaMatch
                          }
                        >
                          <PlaceholderNotAllowedCollections />
                        </When>
                        <Otherwise>
                          <PlaceholderEmpty />
                        </Otherwise>
                      </Choose>
                    </>
                  </When>
                  <Otherwise>
                    {state.visibleItems.map((asset, index) => (
                      <ErrorBoundary
                        className="catalogItem"
                        key={isMediaMatch ? asset?.product?._id + asset._id : asset._id}
                        styles={state.itemsStyles[state.startVisibleIndex + index] || null}
                      >
                        <CatalogItem
                          isLightboardsView={isLightboardsView}
                          asset={asset}
                          number={state.startVisibleIndex + index + 1}
                          styles={state.itemsStyles[state.startVisibleIndex + index] || {}}
                          isListViewMode={catalogViewMode === 'list'}
                          assetsActions={assetsActions}
                          isMobileView={isMobileView}
                          isOdd={!((state.startVisibleIndex + index) % 2)}
                          activeCollectionSortType={activeCollectionSortType}
                          activeCollectionIds={props.activeCollectionIds}
                        />
                      </ErrorBoundary>
                    ))}
                  </Otherwise>
                </Choose>

                {/* for dragNdrop */}
                <div
                  className={`cursorReorderImages${
                    catalogViewMode === 'list' ? ' cursorForCatalogViewMode' : ''
                  }`}
                />

                {/* Load more */}
                <If condition={(!full || uiBlocked) && pathname !== '/selectedAssets'}>
                  <Spinner big={!!uiBlocked} />
                </If>
              </Tiles>
            </div>
          </InnerCatalog>
        </>
      </PullToRefresh>
    );
  }
}

const Tiles = styled.div`
  height: ${(props) => (props.$visibleItemsLength > 0 ? `${props.$wrapperHeight}px` : '100%')};
`;

// const InnerCatalog = styled.div`
//   transform: ${(props) => (props.isNotificationsReceive
//     && props.notificationsPanelCollapse
//     && props.isNotificationPanelVisible
//     ? `translate3d(0, ${-props.notificationPanelHeight + 3}px, 0)`
//     : props.recursiveSearchPanelCollapse && props.isRecursiveSearchPanelVisible
//       ? `translate3d(0, ${-props.recursiveSearchPanelHeight + 3}px, 0)`
//       : 'translate3d(0, 0, 0)')};
// `;
const InnerCatalog = styled.div`
  transform: ${(props) => (props.$isNotificationsReceive
    && props.$notificationsPanelCollapse
    && props.$isNotificationPanelVisible
    ? `translate3d(0, ${-props.$notificationPanelHeight + 3}px, 0)`
    : props.$searchHintPanelCollapse && props.$isSearchHintPanelVisible
      ? `translate3d(0, ${-props.$searchHintPanelHeight + 3}px, 0)`
      : 'translate3d(0, 0, 0)')};
`;

CatalogView.propTypes = {
  isMediaMatch: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  uiBlocked: PropTypes.bool,
  full: PropTypes.bool.isRequired,
  catalogViewMode: PropTypes.string.isRequired,
  catalogViewItemSize: PropTypes.number,
  assets: PropTypes.array.isRequired,
  tmpItemIDsLength: PropTypes.number.isRequired,
  isMobileView: PropTypes.bool,
};

export default CatalogView;

import React, { useCallback, useMemo, forwardRef } from 'react';
import cn from 'classnames';
import {
  string, func, element, bool, oneOfType, arrayOf, any, objectOf, oneOf,
} from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import AiCreditsBadge from '../AiCreditsBadge';
import UpgradePlan from '../UpgradePlan';
import l18n from '../../shared/strings';

const DEFAULT_BLOCK_TITLE = 'Unknown';

const DetailsBlock = forwardRef(({
  detailsPanelVisibility,
  blockName,
  blockTitle,
  children,
  toggleVisibility,
  indicator, // async statuses spinner or some info element
  additionalClass,
  error,
  errorHighlight,
  dataQa,
  openByDefault,
  showUpgradePlan,
  hideActions,
  showAI,
  actions,
  titleActions,
  badgeType,
}, ref) => {
  const toggleOpen = useCallback(
    () => toggleVisibility(blockName),
    [blockName, toggleVisibility],
  );
  const qaIdentifier = useMemo(
    () => (dataQa ? ({ 'data-qa': `details-component-${dataQa}` }) : ({})),
    [dataQa],
  );
  const isOpened = useMemo(
    () => openByDefault || !!detailsPanelVisibility[blockName] || error,
    [openByDefault, detailsPanelVisibility, error, blockName],
  );
  const isHighlighted = useMemo(
    () => errorHighlight && error,
    [errorHighlight, error],
  );

  const actionCondition = useMemo(() => (hideActions ? hideActions && actions.length && isOpened : actions.length), [actions, hideActions, isOpened]);

  return (
    <div
      {...qaIdentifier}
      ref={ref}
      className={cn('detailsPanel__item', {
        act: isOpened,
        [additionalClass]: additionalClass,
        highlightBlink__half: isHighlighted,
      })}
    >
      <div className="detailsPanel__title">
        <span
          className="detailsPanel__title_text"
          tabIndex={0}
          onKeyPress={toggleOpen}
          onClick={toggleOpen}
          role="button"
        >
          {blockTitle}
          <If condition={showAI && isOpened}>
            <AiCreditsBadge type={badgeType} />
          </If>
          <If condition={titleActions.length}>
            {titleActions}
          </If>
          <If condition={showUpgradePlan}>
            <UpgradePlan tooltip={l18n.UPGRADE_PLAN.tooltipPlanLimitations} />
          </If>
        </span>
        {indicator}
        <If condition={actionCondition}>
          <div className="detailsPanel__title_actions">
            {actions}
          </div>
        </If>
      </div>
      <CSSTransition
        in={isOpened}
        timeout={300}
        classNames="fade"
      >
        <>
          <If condition={openByDefault || !!detailsPanelVisibility[blockName] || error}>
            {children}
            <If condition={error}>
              <div className="detailsPanel__item_error">{error}</div>
            </If>
          </If>
        </>
      </CSSTransition>
    </div>
  );
});

DetailsBlock.defaultProps = {
  detailsPanelVisibility: {
    [DEFAULT_BLOCK_TITLE]: true,
  },
  blockName: DEFAULT_BLOCK_TITLE,
  blockTitle: DEFAULT_BLOCK_TITLE,
  toggleVisibility: Function.prototype,
  additionalClass: null,
  indicator: null,
  error: false,
  errorHighlight: false,
  dataQa: null,
  children: null,
  openByDefault: false,
  showUpgradePlan: false,
  showAI: false,
  hideActions: false,
  actions: [],
  titleActions: [],
  badgeType: 'credits',
};

DetailsBlock.propTypes = {
  detailsPanelVisibility: objectOf(bool),
  blockName: string,
  blockTitle: string,
  toggleVisibility: func,
  additionalClass: string,
  indicator: element,
  children: any,
  error: oneOfType([string, bool]),
  errorHighlight: bool,
  dataQa: string,
  openByDefault: bool,
  showUpgradePlan: bool,
  showAI: bool,
  hideActions: bool,
  actions: arrayOf(element),
  titleActions: arrayOf(element),
  badgeType: oneOf(['credits', 'minutes']),
};

export default DetailsBlock;

import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Icon as UiIcon, Avatar } from '@picsio/ui';
import { outy } from '../../shared/utils';
import ua from '../../ua';
import Icon from '../Icon';
import Button from './Button';
import Portal from '../Portal';
import picsioConfig from '../../../../../config';

// @TODO: fix ipad
export default function DropOpener(props) {
  const {
    isToolbarDropdownOpened = false, resetDropdowns = null, isBestMatchVisited = false, openOnClick = false,
  } = props;
  const [showDrop, setShowDrop] = useState(false);
  const outsideClickRef = useRef(null);

  const buttonRef = useRef();
  const dropRef = useRef();

  useEffect(() => {
    if (isToolbarDropdownOpened !== showDrop && !openOnClick) {
      setShowDrop(isToolbarDropdownOpened);
    }
  }, [isToolbarDropdownOpened, openOnClick, showDrop]);

  const handleOutsideClick = useCallback(() => {
    if (!openOnClick && !ua.browser.isNotDesktop()) return;
    setShowDrop(false);
    if (outsideClickRef.current) outsideClickRef.current.remove();
  }, [openOnClick]);

  useEffect(() => {
    if (!openOnClick && window.innerWidth < 1024) return;

    if (showDrop) {
      outsideClickRef.current = outy([dropRef.current, buttonRef.current], ['click'], handleOutsideClick);
    } else if (outsideClickRef.current) outsideClickRef.current.remove();
  }, [handleOutsideClick, showDrop, openOnClick]);

  const handleClick = (e) => {
    if (e.type === 'keypress') return;
    if (!openOnClick && window.innerWidth < 1024) return;

    if (!openOnClick && !ua.browser.isNotDesktop()) return;
    if (isToolbarDropdownOpened) {
      if (resetDropdowns) resetDropdowns();
    }
    setShowDrop((prev) => !prev);
  };

  const handleCloseDrop = () => {
    if (resetDropdowns) resetDropdowns();
  };

  const {
    children,
    icon,
    icon: ControlIcon,
    additionalClass,
    id,
    sortTypeOrder,
    left,
    leftFilter,
    name,
    badge,
    avatarSrc,
  } = props;
  let className = 'toolbarButton';
  let dropdownStyle;

  if (additionalClass) className += ` ${additionalClass}`;
  if (showDrop && !openOnClick) className += ' drop-active';
  if (avatarSrc) className += ' isAvatar';

  if (ua.browser.isNotDesktop()) {
    dropdownStyle = !showDrop ? { display: 'none' } : { display: 'flex' };
  }

  return (
    <div
      className={cn(`${className}`, { viewed: !isBestMatchVisited && name === 'Sorting' && picsioConfig.isMainApp })}
      id={id}
      data-testid={id}
      onClick={handleClick}
      onKeyPress={handleClick}
      tabIndex={0}
      ref={buttonRef}
      role="menu"
    >
      {sortTypeOrder === 'asc' ? <span className="asc" /> : null}
      {sortTypeOrder === 'desc' ? <span className="desc" /> : null}
      {badge && <span className="badge">{badge < 100 ? badge : '99+'}</span>}
      {avatarSrc && <Avatar src={avatarSrc} size={25} enablePolling />}
      <If condition={icon && !avatarSrc}>
        <Choose>
          <When condition={typeof icon === 'function'}>
            <UiIcon size="lg" color="inherit">
              <ControlIcon />
            </UiIcon>
          </When>
          <Otherwise>
            {/* @TODO: remove old Icon */}
            <Icon name={icon} />
          </Otherwise>
        </Choose>
      </If>
      <If condition={props.syncProccess}>
        <div className="jobs-status">
          <Choose>
            <When condition={props.isSyncRunning && props.jobsCount === 0}>
              <span>Syncing</span>
            </When>
            <Otherwise>
              <Choose>
                <When condition={Number(props.jobsCount) > 0}>
                  {props.jobsCount} <span>Processing</span>
                </When>
                <Otherwise>{null}</Otherwise>
              </Choose>
            </Otherwise>
          </Choose>
          <div className="jobs-status-spinner" />
        </div>
      </If>
      <If condition={openOnClick}>
        <Portal isOpen={showDrop} btnRef={buttonRef}>
          <div
            className="toolbarDropdownWrapper"
            ref={dropRef}
            style={{ display: 'flex' }}
            onClick={handleClick}
            role="presentation"
          >
            <div
              className={cn('toolbarDropdown', {
                'drop-active-left': left,
                'drop-active-left-filters': leftFilter,
                [additionalClass]: additionalClass,
              })}
              style={{ top: `${buttonRef.current && buttonRef.current.clientHeight}px` }}
            >
              <header className="toolbarDropdownHeader">
                <Button id="button-close" icon="regularPrevArrow" onClick={handleCloseDrop} />
                <div className="toolbarName">{name || ''}</div>
              </header>
              {children}
            </div>
          </div>
        </Portal>
      </If>
      <If condition={!openOnClick}>
        <div className="toolbarDropdownWrapper" ref={dropRef} style={dropdownStyle}>
          <div
            className={cn('toolbarDropdown', {
              'drop-active-left': left,
              [additionalClass]: additionalClass,
            })}
            style={{ top: `${buttonRef.current && buttonRef.current.clientHeight}px` }}
          >
            <header className="toolbarDropdownHeader">
              <Button id="button-close" icon="regularPrevArrow" onClick={handleCloseDrop} />
              <div className="toolbarName">{name || ''}</div>
            </header>
            {children}
          </div>
        </div>
      </If>
    </div>
  );
}

DropOpener.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  sortTypeOrder: PropTypes.string,
  isActive: PropTypes.bool,
  additionalClass: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  badge: PropTypes.number,
  isAvatar: PropTypes.bool,
  isToolbarDropdownOpened: PropTypes.bool,
  resetDropdowns: PropTypes.func,
  isBestMatchVisited: PropTypes.bool,
  openOnClick: PropTypes.bool,
};

/* eslint-disable react/require-default-props */
import React, {
  memo, useCallback, useRef, useState,
} from 'react';
import cn from 'classnames';
import {
  Menu, IconButton, MenuItem, Icon, MenuItemText,
} from '@picsio/ui';
import {
  AccountMenu,
  ArrowNext,
  ArrowPrev,
  CheckIcon,
} from '@picsio/icons';
import {
  shape, arrayOf, element, func, objectOf, oneOfType, string, bool, number,
} from 'prop-types';

import Tooltip from '../Tooltip';

import s from './styles.module.scss';

const TwoLevelMenu = ({
  MainIcon = AccountMenu,
  controls = [],
  value = {},
  onChange = Function.prototype,
  tooltip = null,
  disablePortal = false,
  menuPlacement = 'bottom-end',
  menuArrow = true,
  className = null,
  disabled = false,
  testId = null,
}) => {
  const ref = useRef();
  const [subMenu, setSubmenu] = useState(null);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = useCallback(() => {
    setMenuOpen(!isMenuOpen);
    /** close submenu when close main menu */
    if (isMenuOpen) setSubmenu(null);
  }, [isMenuOpen, setMenuOpen, setSubmenu]);

  const closeSubMenu = useCallback(() => setSubmenu(null), [setSubmenu]);

  const handleClickSubmenu = useCallback((menuId, itemId) => () => {
    onChange(menuId, itemId);
    setSubmenu(null);
  }, [onChange]);

  return (
    <>
      <span ref={ref}>
        <Tooltip
          content={tooltip}
          placement="top"
          popperProps={{ disablePortal: true, strategy: 'fixed' }}
        >
          <IconButton
            size="lg"
            color={isMenuOpen ? 'primary' : 'secondary'}
            className={className}
            onClick={toggleMenu}
            disabled={disabled}
            componentProps={{ 'data-testid': testId }}
          >
            <MainIcon />
          </IconButton>
        </Tooltip>
      </span>
      <Menu
        target={ref}
        padding="s"
        isOpen={isMenuOpen}
        outsideClickListener
        onClose={toggleMenu}
        disablePortal={disablePortal}
        PopperProps={{ strategy: 'fixed' }}
        placement={menuPlacement}
        arrow={menuArrow}
      >
        <Choose>
          <When condition={subMenu}>
            <MenuItem
              key="back-button"
              onClick={closeSubMenu}
              className={cn('menuItemDefault', s.subMenuItemBack, s.subMenuItem)}
            >
              <Icon size="md" className={s.subMenuIcon}>
                <ArrowPrev />
              </Icon>
              <MenuItemText primary={subMenu.text} />
            </MenuItem>
            <div className={s.submenuWrapper}>
              {subMenu.items.map((item) => (
                <MenuItem
                  key={item.id}
                  id={item.id}
                  onClick={handleClickSubmenu(subMenu.id, item.id)}
                  className={cn('menuItemDefault', s.subMenuItem)}
                  selected={value[subMenu.id] === item.id}
                >
                  <If condition={value[subMenu.id] === item.id}>
                    <Icon key={`check-${item.id}`} size="sm" className={s.subMenuIcon}>
                      <CheckIcon />
                    </Icon>
                  </If>
                  <MenuItemText primary={item.text} />
                </MenuItem>
              ))}
            </div>
          </When>
          <Otherwise>
            {controls.map(({
              id, Icon: ItemIcon, text, items,
            }) => (
              <MenuItem id={id} key={id} onClick={() => setSubmenu({ id, text, items })} className={s.menuItem}>
                <div className={s.menuItemContainer}>
                  <Icon size="xl">
                    <ItemIcon />
                  </Icon>
                  <span>{text}</span>
                </div>
                <If condition={items?.length}>
                  <div key={`submenu-${id}`} className={s.menuItemContainer}>
                    <span>{value[id] ? items.find((i) => i.id === value[id])?.text : null}</span>
                    <Icon size="md">
                      <ArrowNext />
                    </Icon>
                  </div>
                </If>
              </MenuItem>
            ))}
          </Otherwise>
        </Choose>
      </Menu>
    </>
  );
};

TwoLevelMenu.propTypes = {
  tooltip: string,
  value: objectOf(oneOfType([string, number])),
  controls: arrayOf(shape({
    id: string,
    text: string,
    Icon: oneOfType([func, element]),
  })),
  disablePortal: bool,
  menuPlacement: string,
  onChange: func,
  menuArrow: bool,
  MainIcon: func,
  className: string,
  disabled: bool,
  testId: string,
};

export default memo(TwoLevelMenu);

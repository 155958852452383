import React from 'react';
import {
  array, object, string, func, bool, oneOfType,
} from 'prop-types';
import cn from 'classnames';
import { Input, InputHelperText } from '@picsio/ui';
import { connect } from 'react-redux';

import Logger from '../../../services/Logger';
import l10n from '../../../shared/strings';

import ModifiedField from './ModifiedField';
import { calculateAiCredits } from '../../../helpers/calculateAICredits';
import { DIFFERENT_VALUES } from '../../../store/import/helpers/groupFields';
import AiIcon from './AiIcon';

class Description extends React.Component {
  initialTitle = null;

  initialDescription = null;

  state = {
    title: '',
    description: '',
  };

  componentDidMount() {
    this.setTitleAndDescription();
  }

  componentDidUpdate(prevProps) {
    const { selectedAssetsIds } = this.props;
    const isOneAsset = selectedAssetsIds.length === 1;

    if (this.props.title !== prevProps.title || this.props.description !== prevProps.description) {
      this.setTitleAndDescription();
    }

    if (prevProps.selectedAssetsIds.length !== selectedAssetsIds.length) {
      this.setTitleAndDescription();
    } else if (isOneAsset) {
      const activeAssetId = selectedAssetsIds[0];

      if (prevProps.selectedAssetsIds[0] !== activeAssetId) {
        this.setTitleAndDescription();
      }
    }
  }

  setTitleAndDescription() {
    const { title, description } = this.props;
    this.setState({ title, description });
  }

  onTitleFocus = (event) => (this.initialTitle = event.target.value);

  onTitleChange = (event) => this.setState({ title: event.target.value });

  onTitleBlur = (event) => {
    let title = event.target.value;
    if (this.state.title === DIFFERENT_VALUES) return;
    title = title.trim();
    const { initialTitle } = this;
    const onCancel = () => this.setState({ title: initialTitle });

    if (this.initialTitle !== this.state.title) {
      this.props.changeTitle(this.props.selectedAssetsIds, title, onCancel);
      if (this.props.logEvents) Logger.log('User', `${this.props.eventPrefix}ChangeTitle`, { selectedAssetsIds: this.props.selectedAssetsIds });
    }
    this.initialTitle = null;
  };

  onDescriptionFocus = (event) => {
    this.initialDescription = event.target.value;
  };

  onDescriptionChange = (event) => this.setState({ description: event.target.value });

  onDescriptionBlur = (event) => {
    let description = event.target.value;
    if (this.state.description === DIFFERENT_VALUES) return;
    description = description.trim();
    const { initialDescription } = this;
    const onCancel = () => this.setState({ description: initialDescription });

    if (this.initialDescription !== this.state.description) {
      this.props.changeDescription(this.props.selectedAssetsIds, description, onCancel);
      if (this.props.logEvents) Logger.log('User', `${this.props.eventPrefix}ChangeDesc`, { selectedAssetsIds: this.props.selectedAssetsIds });
    }
    this.initialDescription = null;
  };

  onGenerateDescription = () => {
    Logger.log('User', 'InfoPanelDescriptionAutogenerate', {
      assetsLength: this.props.selectedAssetsIds.length,
    });
    this.props.generateDescription({ assetsIds: this.props.selectedAssetsIds });
  }

  render() {
    const { state, props } = this;
    const { team } = props;

    const credits = calculateAiCredits(team);

    let titlePlaceholder = l10n.DETAILS.placeholderEnterTitle;
    if (props.selectedAssetsIds.length > 1 || state.title === DIFFERENT_VALUES) {
      titlePlaceholder = l10n.DETAILS.placeholderMultipleSelection;
    }
    if (!props.titleEditable) titlePlaceholder = l10n.DETAILS.placeholderUneditabledTitle;

    let descriptionPlaceholder = props.isMainApp ? l10n.DETAILS.placeholderAIDescription : l10n.DETAILS.placeholderEnterDescription;
    if (props.selectedAssetsIds.length > 1 || state.description === DIFFERENT_VALUES) {
      descriptionPlaceholder = l10n.DETAILS.placeholderMultipleSelection;
    }
    if (!props.descriptionEditable) descriptionPlaceholder = l10n.DETAILS.placeholderUneditabledDescription;

    return (
      <div data-qa="details-description">
        {
          props.order.map((id) => {
            switch (id) {
            case 'title':
              return (
                <React.Fragment key="title">
                  {props.titleShow && (
                    <div className={cn('inputWithModifiedField', { inputWithModifiedFieldWithIcon: Boolean(props.modifiedTitle) })}>
                      <Input
                        value={state.title === DIFFERENT_VALUES ? '' : state.title}
                        onFocus={this.onTitleFocus}
                        onChange={this.onTitleChange}
                        onBlur={this.onTitleBlur}
                        placeholder={titlePlaceholder}
                        disabled={props.disabled}
                        readOnly={!props.titleEditable}
                        description={props.titleEditable === 'mixed' ? l10n.DETAILS.mixedField : undefined}
                        error={Boolean(props.titleError)}
                        autoFocus={props.autoFocus}
                      />
                      <If condition={props.titleError}>
                        <InputHelperText error>{props.titleError}</InputHelperText>
                      </If>
                      {props.modifiedTitle && <ModifiedField field={props.modifiedTitle} />}
                    </div>
                  )}
                </React.Fragment>
              );
            case 'description':
              return (
                <React.Fragment key="description">
                  {props.descriptionShow && (
                    <div className={cn('inputWithModifiedField', { inputWithModifiedFieldWithIcon: Boolean(props.modifiedDescription) })}>
                      <Input
                        component="textarea"
                        multiline
                        autoResize
                        minHeight={68}
                        maxHeight={148}
                        className="descriptionInput"
                        value={state.description === DIFFERENT_VALUES ? '' : state.description}
                        onFocus={this.onDescriptionFocus}
                        onChange={this.onDescriptionChange}
                        onBlur={this.onDescriptionBlur}
                        placeholder={descriptionPlaceholder}
                        disabled={props.disabled || props.inProgressDescription}
                        readOnly={!props.descriptionEditable}
                        error={Boolean(props.descriptionError)}
                      />
                      <If condition={props.descriptionError}>
                        <InputHelperText error>{props.descriptionError}</InputHelperText>
                      </If>
                      <If condition={props.descriptionEditable === 'mixed'}>
                        <InputHelperText>{l10n.DETAILS.mixedField}</InputHelperText>
                      </If>
                      {props.modifiedDescription && <ModifiedField field={props.modifiedDescription} />}
                      <If condition={props.isMainApp && props.descriptionEditable && props.isDescribingAllowed}>
                        <AiIcon
                          className="ai-icon"
                          data-testid="iconGenerateDescription"
                          tooltipText={l10n.DETAILS.aiIconTooltipText(props.inProgressDescription ? l10n.DETAILS.describingBtnText : l10n.DETAILS.generateDescriptionBtnText, credits, 'text-center')}
                          inProgress={props.inProgressDescription}
                          disabled={!props.describingIsAvailable}
                          onSubmit={this.onGenerateDescription}
                        />
                      </If>
                    </div>
                  )}
                </React.Fragment>
              );
            default:
              return null;
            }
          })
        }
      </div>
    );
  }
}
Description.defaultProps = {
  logEvents: true,
  eventPrefix: '',
  order: ['title', 'description'],
  isDescribingAllowed: true,
  team: {},
};
Description.propTypes = {
  logEvents: bool,
  collection: array,
  selectedAssetsIds: array,
  titleShow: bool,
  titleEditable: oneOfType([string, bool]),
  descriptionShow: bool,
  descriptionEditable: oneOfType([string, bool]),
  textareaHeightNameLS: string,
  team: object,
  eventPrefix: string,
  changeTitle: func,
  changeDescription: func,
  generateDescription: func,
  order: array,
  isMainApp: bool,
  describingIsAvailable: bool,
  inProgressDescription: bool,
  isDescribingAllowed: bool,
};

export default connect((store) => ({
  team: store.user.team,
}))(Description);

export const calculateAiCredits = (team) => {
  const existingCredits = Math.max((team?.aiCreditsPaid || 0) - (team?.aiCreditsUsed || 0), 0);
  const availableNewCredits = Math.max((team?.aiCounters?.credits || 0) - (team?.aiCounters?.creditsUsed || 0), 0);
  return existingCredits + availableNewCredits;
};

export const calculateAiMinutes = (team) => {
  const aiCounters = team?.aiCounters || {};
  const transcribingMinutesUsed = aiCounters.transcribingMinutesUsed || 0;
  const transcribingMinutes = aiCounters.transcribingMinutes || 0;
  const paidMinutes = team?.transcribingMinutesPaid || 0;
  const usedMinutes = team?.transcribingMinutesUsed || 0;

  const paid = transcribingMinutes + paidMinutes;
  const used = transcribingMinutesUsed + usedMinutes;

  return paid - used;
};

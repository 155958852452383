/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Popper } from '@picsio/ui';
import { resizePanel, changeTree } from '../../store/actions/main';
import Resizer from '../Resizer';

import ua from '../../ua';
import useDelayedHover from '../../helpers/components/hooks/useDelayedHover';

export default function TreeResizer({ children, treeName = '' }) {
  const dispatch = useDispatch();
  const ref = useRef();
  const [isResizing, setIsResizing] = useState(false);
  const width = useSelector((state) => state.main.panelsWidth.catalogView.left);
  const openedTree = useSelector((state) => state.main.openedTree);
  const panelHover = useDelayedHover(500);
  const resizerHover = useDelayedHover(500);
  const buttonHover = useDelayedHover(500);

  const handleMouseUp = useCallback(() => {
    setIsResizing(false);
  }, []);

  const handleMouseDown = useCallback((event, isTree = false) => {
    setIsResizing(true);
    dispatch(resizePanel(isTree ? ref?.current : event, 'left', isTree, treeName));
  }, [dispatch, treeName]);

  const handleOnClick = useCallback(() => {
    dispatch(changeTree(openedTree));
  }, [dispatch, openedTree]);

  const widthStyle = useMemo(() => ({ width }), [width]);

  return (
    <div
      className="folderTreeView"
      ref={ref}
      style={widthStyle}
      onMouseEnter={panelHover.handleMouseEnter}
      onMouseLeave={panelHover.handleMouseLeave}
    >
      <If condition={(panelHover.isHovered || isResizing) && !ua.browser.isNotDesktop()}>
        <Popper
          target={ref}
          isOpen
          placement="right"
          offset={[0, 0]}
          portalContainer={document.querySelector('#resizer')}
        >
          <Resizer
            onClick={handleOnClick}
            onDrag={(e) => handleMouseDown(e, true)}
            isTreeResizer
            onMouseUp={handleMouseUp}
            isResizing={isResizing}
            onButtonMouseEnter={buttonHover.handleMouseEnter}
            onButtonMouseLeave={buttonHover.handleMouseLeave}
          />
        </Popper>
      </If>
      <div
        className={cn('treeResizer', { hover: resizerHover.isHovered || buttonHover.isHovered || isResizing })}
        onMouseDown={handleMouseDown}
        role="presentation"
        onMouseEnter={resizerHover.handleMouseEnter}
        onMouseLeave={resizerHover.handleMouseLeave}
      />
      {children}
    </div>
  );
}

TreeResizer.propTypes = {
  children: PropTypes.node,
};

import React, {
  useEffect, useRef, useCallback, useMemo,
} from 'react';
import cn from 'classnames';
import _uniqBy from 'lodash/uniqBy';
import _isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';
import { Button, Input, DatePicker } from '@picsio/ui';
import {
  Colors, Flags, StarRating, ReactSelect,
} from '../../UIComponents';
import sendEventToIntercom from '../../services/IntercomEventService';
import { SINGLE_OPERATORS } from './config';
import l10n from '../../shared/strings';
import Keywords from '../Search/Keywords';
import Logger from '../../services/Logger';
import DropdownTree, { DropdownTreeWithStore } from '../DropdownTree';
import Faces from '../Search/Faces';
import Dropdown from '../dropdown';
import AssigneesDropdown from '../assigneesDropdown';
import InputDateRange from '../../UIComponents/inputDateRange';

export default function SearchFilterDropOpener({
  id,
  isDropdownOpened,
  operators,
  options,
  type,
  subtype,
  filterData = {},
  confirmedData = {},
  onSelectFilter,
  onConfirmFilter,
  onResetFilter,
  onCloseDropdown,
  isSelected = false,
  btnRef,
  filters,
  title,
}) {
  const treeListItems = useSelector((state) => state.collections.collections?.my);
  const lightboards = useSelector((state) => state.lightboards.lightboards);
  const inboxes = useSelector((state) => state.inboxes.inboxes);
  const watermarks = useSelector((state) => state.assets.watermarks);
  const customFields = useSelector((state) => state.customFields.items);
  const dropRef = useRef();

  const handleConfirm = useCallback(() => {
    onConfirmFilter(id, filterData);
    sendEventToIntercom('AdvFilterPanelUsed');
  }, [filterData, id, onConfirmFilter]);

  const isSingleOperator = useMemo(() => SINGLE_OPERATORS.includes(filterData.operator), [filterData.operator]);

  const isDisabled = useMemo(() => {
    const isFilterValueEmpty = Array.isArray(filterData.value) ? !filterData.value.length : !filterData.value;

    const isFilterUnchanged = _isEqual(filterData, confirmedData) && isSelected && !isSingleOperator;

    return (!isSingleOperator && isFilterValueEmpty) || isFilterUnchanged;
  }, [filterData, confirmedData, isSelected, isSingleOperator]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropRef.current
        && !btnRef.current.contains(event.target)
        && !dropRef.current.contains(event.target)
        && !event.target.closest('.react-select__indicator')
        && !event.target.closest('.dropdown-item')
      ) {
        onCloseDropdown(event);
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && !isDisabled) {
        handleConfirm();
      }
    };

    if (isDropdownOpened) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpened, onCloseDropdown, handleConfirm]);

  const createFilterData = useCallback((operatorValue, value) => ({ operator: operatorValue, value }), []);

  const handleChangeOption = useCallback((data) => {
    onSelectFilter(id, createFilterData(filterData.operator || operators[0], data.value));
  }, [createFilterData, filterData, id, onSelectFilter, operators]);

  const selectKeyword = (items) => {
    let selectedKeywords = filterData.value || [];
    const operator = filterData.operator || operators[0];
    if (operator === 'is' || operator === 'isNot') {
      selectedKeywords = [items];
    } else if (items.length) {
      selectedKeywords = [...selectedKeywords, ...items];
    } else {
      selectedKeywords = [...selectedKeywords, items];
    }
    selectedKeywords = _uniqBy(selectedKeywords, '_id');

    onSelectFilter(id, createFilterData(operator, selectedKeywords));
    Logger.log('User', 'SearchAdvancedSelectKeywords', {
      value: selectedKeywords.map((kw) => kw.title),
    });
  };

  const deselectKeyword = (item) => {
    const selectedKeywords = [...(filterData.value || [])];
    const itemIndex = selectedKeywords.findIndex((keyword) => keyword._id === item._id);
    const operator = filterData.operator || operators[0];
    if (itemIndex !== -1) {
      selectedKeywords.splice(itemIndex, 1);
      onSelectFilter(id, createFilterData(operator, selectedKeywords));
      Logger.log('User', 'SearchAdvancedDeselectKeywords', {
        value: selectedKeywords.map((kw) => kw.title),
      });
    }
  };

  const selectFace = (items) => {
    const operator = filterData.operator || operators[0];
    let selectedFaces = filterData.value || [];
    if (items.length) {
      selectedFaces = [...selectedFaces, ...items];
    } else {
      selectedFaces = [...selectedFaces, items];
    }
    selectedFaces = _uniqBy(selectedFaces, '_id');

    onSelectFilter(id, createFilterData(operator, selectedFaces));
    Logger.log('User', 'SearchAdvancedSelectFaces', {
      value: selectedFaces.map((face) => face.name),
    });
  };

  const deselectFace = (item) => {
    const operator = filterData.operator || operators[0];
    const selectedFaces = [...(filterData.value || [])];
    const itemIndex = selectedFaces.findIndex((face) => face._id === item._id);

    if (itemIndex !== -1) {
      selectedFaces.splice(itemIndex, 1);
      onSelectFilter(id, createFilterData(operator, selectedFaces));
      Logger.log('User', 'SearchAdvancedDeselectKeywords', {
        value: selectedFaces.map((face) => face.name),
      });
    }
  };

  const customFieldsOptions = useMemo(() => {
    if (id.startsWith('meta.') && type === 'enum') {
      const selectedCustomField = customFields.find((customField) => customField.title === id.split('meta.')[1]);
      selectedCustomField.items = selectedCustomField.options.map((option) => ({ title: option, value: option, _id: option }));
      return selectedCustomField;
    }
    return null;
  }, [id, type, customFields]);

  const selectDropdown = (item) => {
    const operator = filterData.operator || operators[0];
    let selectedItems = filterData.value || [];

    // if (operator === 'equals' || (customFieldsOptions && !customFieldsOptions.multiple)) {
    if (operator === 'equals') {
      selectedItems = [item];
    } else {
      selectedItems = [...selectedItems, item];
    }
    onSelectFilter(id, createFilterData(operator, selectedItems));
  };

  const unselectDropdown = (item) => {
    const operator = filterData.operator || operators[0];
    let selectedItems = filterData.value || [];
    selectedItems = selectedItems.filter(({ _id }) => _id !== item._id);
    onSelectFilter(id, createFilterData(operator, selectedItems));
  };

  const onChangeFlags = (value) => {
    const operator = filterData.operator || operators[0];
    let flags = [...(filterData.value || [])];
    const flagIndex = flags.indexOf(value);

    if (flagIndex > -1) {
      flags.splice(flagIndex, 1);
    } else if (operator === 'equals') {
      flags = [value];
    } else {
      flags.push(value);
    }

    onSelectFilter(id, createFilterData(operator, flags));
    Logger.log('User', 'SearchAdvancedFlags', { value: flags });
  };

  const deselectFlags = () => {
    onResetFilter(id);
  };

  const onChangeColors = (value) => {
    const operator = filterData.operator || operators[0];
    let colors = [...(filterData.value || [])];
    const colorIndex = colors.indexOf(value);

    if (colorIndex > -1) {
      colors.splice(colorIndex, 1);
    } else if (operator === 'equals') {
      colors = [value];
    } else {
      colors.push(value);
    }

    onSelectFilter(id, createFilterData(operator, colors));
    Logger.log('User', 'SearchAdvancedColors', { value: colors });
  };

  const deselectColors = () => {
    onResetFilter(id);
  };

  const onChangeRating = (value) => {
    const operator = filterData.operator || operators[0];
    const rating = filterData.value === value ? 0 : value;
    onSelectFilter(id, createFilterData(operator, rating));
    Logger.log('User', 'SearchAdvancedRating', { value: rating });
  };

  const deselectStars = () => {
    onResetFilter(id);
  };

  const onChangeDate = (date) => {
    let newDate = date;
    if (typeof date !== 'string') {
      newDate = new Date(date).getTime();
    }
    const operator = filterData.operator || operators[0];
    onSelectFilter(id, createFilterData(operator, newDate));
    Logger.log('User', 'SearchAdvancedDate', { value: newDate });
  };

  const handleSelectCollection = useCallback((collectionItem, isChecked) => {
    const operator = filterData.operator || operators[0];
    if (isChecked) {
      if (filterData.value?.length > 1) {
        const items = filterData.value.filter((checkedItem) => checkedItem._id !== collectionItem._id);
        onSelectFilter(id, { value: items, operator });
      }
      return;
    }
    onSelectFilter(id, { value: (filterData.value || []).concat(collectionItem), operator });
  }, [filterData.operator, filterData.value, id, onSelectFilter, operators]);

  const handleSelectLightboard = useCallback((collectionItem) => {
    const operator = filterData.operator || operators[0];
    onSelectFilter(id, { value: [collectionItem], operator });
  }, [filterData.operator, id, onSelectFilter, operators]);

  const handleSelectInbox = useCallback((collectionItem) => {
    const operator = filterData.operator || operators[0];
    onSelectFilter(id, { value: [collectionItem], operator });
  }, [filterData.operator, id, onSelectFilter, operators]);

  const handleResetFilter = useCallback(() => {
    onResetFilter(id);
  }, [id, onResetFilter]);

  const checkIsValueSelected = useCallback((value) => {
    if (isSingleOperator) return true;
    if (Array.isArray(value)) return !!value.length;
    return !!value;
  }, [isSingleOperator]);

  const handleChangeSearchInput = useCallback((event) => {
    const operator = filterData.operator || operators[0];
    const { value } = event.target;
    onSelectFilter(id, createFilterData(operator, value));
  }, [createFilterData, filterData.operator, id, onSelectFilter, operators]);

  const handleDoubleInput = useCallback((index) => (event) => {
    const operator = filterData.operator || operators[0];
    const value = event.target.value.trim();
    const values = (filterData.value || []);
    values[index] = Number(value);
    onSelectFilter(id, createFilterData(operator, values));
  }, [createFilterData, filterData.operator, filterData.value, id, onSelectFilter, operators]);

  const onChangeDoubleRating = useCallback((index) => (value) => {
    const operator = filterData.operator || operators[0];
    const values = (filterData.value || []);
    values[index] = Number(value);
    onSelectFilter(id, createFilterData(operator, values));
  }, [createFilterData, filterData.operator, filterData.value, id, onSelectFilter, operators]);

  const watermarksOptions = useMemo(() => watermarks.map(
    (watermark) => ({
      ...watermark, title: watermark.name, value: watermark._id,
    }),
  ), [watermarks]);

  const handleSelectOperator = useCallback((data) => {
    if (SINGLE_OPERATORS.includes(data.value)) {
      onSelectFilter(id, createFilterData(data.value, true));
    } else if (isSelected && !SINGLE_OPERATORS.includes(confirmedData.operator) && confirmedData.operator !== 'between' && data.value !== 'between') {
      onSelectFilter(id, createFilterData(data.value, confirmedData.value));
    } else {
      onSelectFilter(id, createFilterData(data.value, null));
    }
  }, [createFilterData, id, onSelectFilter, isSelected, confirmedData]);

  const getOptionsLabel = useMemo(() => {
    const selectedOption = options?.find((option) => option.value === filterData.value);
    if (selectedOption) {
      return { label: selectedOption.label, value: selectedOption.value };
    } return null;
  }, [filterData.value, options]);

  const operatorValue = useMemo(() => {
    if (filterData.operator) {
      if (id.startsWith('meta.')) {
        if (type === 'number') {
          return { label: filters.meta.operatorNamesNumber[filterData.operator], value: filterData.operator };
        }
        if (type === 'date') {
          return { label: filters.meta.operatorNamesDate[filterData.operator], value: filterData.operator };
        }
        return { label: filters.meta.operatorNamesString[filterData.operator], value: filterData.operator };
      }
      return { label: filters[id].operatorNames[filterData.operator], value: filterData.operator };
    } return null;
  }, [filterData.operator, id, type]);

  const operatorLabel = useCallback((item) => {
    if (id.startsWith('meta.')) {
      if (type === 'number') {
        return filters.meta.operatorNamesNumber[item];
      }
      if (type === 'date') {
        return filters.meta.operatorNamesDate[item];
      }
      return filters.meta.operatorNamesString[item];
    }
    return filters[id].operatorNames[item];
  }, [id, type]);

  return (
    <div
      className={cn('searchFilterDrop', {})}
      id={id}
      data-testid={id}
      ref={dropRef}
    >
      <If condition={operators && operators.length > 1}>
        <div className="searchFilterDrop__operators">
          <ReactSelect
            options={operators.map((item) => ({ label: operatorLabel(item), value: item }))}
            value={operatorValue}
            onChange={handleSelectOperator}
            placeholder="Select operator"
          />
        </div>
      </If>
      <If condition={options && options.length && !isSingleOperator}>
        <div className="searchFilterDrop__options">
          <ReactSelect
            options={options}
            value={getOptionsLabel}
            onChange={handleChangeOption}
            placeholder={title.toLowerCase().includes('status') ? 'Select status' : 'Select value'}
            useCustomIndicator={false}
          />
        </div>
      </If>
      <If condition={(!isSingleOperator && (filterData.operator || operators.length === 1))}>
        <div className="searchFilterDrop__content">
          <If condition={type === 'keywords'}>
            <Keywords
              selectedKeywords={filterData.value || []}
              addKeyword={selectKeyword}
              removeKeyword={deselectKeyword}
              changed={filterData.value?.length > 0}
              withoutLabel
              position="right"
            />
          </If>
          <If condition={type === 'collectionIds'}>
            <DropdownTreeWithStore
              checkedItems={(filterData.value || []).map((item) => item._id)}
              openedItems={[]}
              treeListItems={treeListItems || []}
              onClick={handleSelectCollection}
              iconSpecial="folder"
              canCreateCollection={false}
              multipleSelection
            />
          </If>
          <If condition={type === 'lightboard'}>
            <DropdownTree
              checkedItems={(filterData.value || []).map((item) => item._id)}
              openedItems={[]}
              treeListItems={lightboards || []}
              onClick={handleSelectLightboard}
              iconSpecial="folder"
              canCreateCollection={false}
              multipleSelection
            />
          </If>
          <If condition={type === 'inbox'}>
            <DropdownTree
              checkedItems={(filterData.value || []).map((item) => item._id)}
              openedItems={[]}
              treeListItems={(inboxes || []).map((inbox) => ({ ...inbox, path: '' }))}
              onClick={handleSelectInbox}
              iconSpecial="folder"
              canCreateCollection={false}
              multipleSelection
            />
          </If>
          <If condition={type === 'faces'}>
            <Faces
              selectedFaces={filterData.value || []}
              addFace={selectFace}
              removeFace={deselectFace}
              changed={(filterData.value || []).length > 0}
              position="right"
              withoutLabel
            />
          </If>
          <If condition={type === 'flag'}>
            <div className="searchFilterDrop__field">
              <Flags
                value={filterData.value || []}
                onChange={onChangeFlags}
                deselectFlags={deselectFlags}
              />
            </div>
          </If>
          <If condition={type === 'color'}>
            <div className="searchFilterDrop__field">
              <Colors
                value={filterData.value || []}
                onChange={onChangeColors}
                deselectColors={deselectColors}
              />
            </div>
          </If>
          <If condition={type === 'rating'}>
            <Choose>
              <When condition={filterData.operator === 'between'}>
                <div className="searchFilterDrop__field">
                  <StarRating
                    value={(filterData.value || [])[0]}
                    onChange={onChangeDoubleRating(0)}
                    deselectStars={deselectStars}
                  />
                </div>
                <div className="searchFilterDrop__field">
                  <StarRating
                    value={(filterData.value || [])[1]}
                    onChange={onChangeDoubleRating(1)}
                    deselectStars={deselectStars}
                  />
                </div>
              </When>
              <Otherwise>
                <div className="searchFilterDrop__field">
                  <StarRating
                    value={filterData.value || 0}
                    onChange={onChangeRating}
                    deselectStars={deselectStars}
                  />
                </div>
              </Otherwise>
            </Choose>
          </If>
          <If condition={type === 'assignees' || type === 'user'}>
            <div className="searchFilterDrop__field">
              <AssigneesDropdown
                placeholder={l10n.SEARCH.userSearchFilterPlaceholder}
                placeholderIcon="emptyAvatar"
                icon="avatar"
                filterText={l10n.SEARCH.userSearchFilterLabel}
                checkedItems={filterData.value || []}
                onCheckedHandler={selectDropdown}
                onUncheckedHandler={unselectDropdown}
                createHandler={null}
                isAllowClickItem
                position="right"
              />
            </div>
          </If>
          <If condition={type === 'watermark'}>
            <div className="searchFilterDrop__field">
              <Dropdown
                position="right"
                items={watermarksOptions}
                filterText="Select watermarks"
                onCheckedHandler={selectDropdown}
                onUncheckedHandler={unselectDropdown}
                createHandler={null}
                checkedItems={filterData.value || []}
              />
            </div>
          </If>
          <If condition={type === 'date'}>
            <div className="searchFilterDrop__field">
              <Choose>
                <When condition={filterData.operator === 'between'}>
                  <InputDateRange dontShowSelector value={filterData.value || 'any'} onChange={onChangeDate} />
                </When>
                <Otherwise>
                  <DatePicker
                    selected={filterData.value ? new Date(Number(filterData.value)) : new Date()}
                    datePickerPlaceholder="Select date"
                    onChange={onChangeDate}
                  />
                </Otherwise>
              </Choose>
            </div>
          </If>
          <If condition={type === 'number'}>
            <div className="searchFilterDrop__field">
              <Choose>
                <When condition={filterData.operator === 'between'}>
                  <div className="searchFilterDrop__doubleInput">
                    <Input
                      label="From"
                      placeholder={id === 'fileSize' ? 'From (bytes)' : 'From'}
                      value={(filterData.value || [])[0]}
                      onChange={handleDoubleInput(0)}
                      type="number"
                    />
                    <Input
                      label="To"
                      placeholder={id === 'fileSize' ? 'To (bytes)' : 'To'}
                      value={(filterData.value || [])[1]}
                      onChange={handleDoubleInput(1)}
                      type="number"
                    />
                  </div>
                </When>
                <Otherwise>
                  <Input
                    placeholder={id === 'fileSize' ? 'Value (bytes)' : 'Value'}
                    value={filterData.value || ''}
                    onChange={handleChangeSearchInput}
                    type="number"
                  />
                </Otherwise>
              </Choose>
            </div>
          </If>
          <If condition={type === 'string' || (type === 'text' && id.startsWith('meta.'))}>
            <div className="searchFilterDrop__string">
              <Choose>
                <When condition={subtype === 'text'}>
                  <Input
                    component="textarea"
                    multiline
                    autoResize
                    minHeight={68}
                    maxHeight={148}
                    value={filterData.value || ''}
                    onChange={handleChangeSearchInput}
                    placeholder="Value"
                  />
                </When>
                <Otherwise>
                  <Input
                    placeholder="Value"
                    value={filterData.value || ''}
                    onChange={handleChangeSearchInput}
                  />
                </Otherwise>
              </Choose>
            </div>
          </If>
          <If condition={type === 'enum' && id.startsWith('meta.')}>
            <Dropdown
              position="right"
              items={customFieldsOptions.items}
              filterText="Select custom field options"
              onCheckedHandler={selectDropdown}
              onUncheckedHandler={unselectDropdown}
              createHandler={null}
              checkedItems={filterData.value || []}
            />
          </If>
        </div>
      </If>
      <div className="searchFilterDrop__controls">
        <If condition={checkIsValueSelected(filterData.value)}>
          <span
            className={cn('picsioLink')}
            onClick={checkIsValueSelected(filterData.value) ? handleResetFilter : Function.prototype}
          >
            {l10n.SEARCH.resetFilterBtn}
          </span>
        </If>
        <Button
          onClick={handleConfirm}
          variant="contained"
          color="primary"
          size="md"
          disabled={isDisabled}
        >
          {l10n.SEARCH.filterConfirmBtn}
        </Button>
      </div>
    </div>
  );
}
